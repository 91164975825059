import backend from "@/api/backend";

let unauthorizedHandler = async () => {};

const setUnauthorizedHandler = (callback) => {
  unauthorizedHandler = callback;
};

const connect = (app) => {
  app.use(backend, {
    name: 'api',
    errorHandler: async ({data, status}) => {
      // skip form errors
      if (400 === status) {
        return;
      }

      if (401 === status) {
        await unauthorizedHandler(data);
        return;
      }

      // TODO: Show global app errors in pop-up
      console.log(data);
    },
  });
}

export default connect;
export { setUnauthorizedHandler };