import { read } from "@/api/rest";

const fingerprint = {
  index: async (options) => {
    let search = "";
    for (let key of Object.keys(options)) {
      search += "&" + key + "=" + options[key];
    }
    return await read("/fingerprint" + (search ? "?" + search : ""));
  },
  groups: async (options) => {
    let search = "";
    for (let key of Object.keys(options)) {
      search += "&" + key + "=" + options[key];
    }
    search += "&by_group=true";
    return await read("/fingerprint" + (search ? "?" + search : ""));
  },
};

export default fingerprint;
