<template>
  <div class="main_title">
    <h1>
      <slot></slot>
    </h1>
    <div class="main_title__description">
      <slot name="description"></slot>
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
@import "../assets/_map-for-breakpoints";
@import "../../../assets/styles/_breakpoints";

.main_title {
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    color: #fff;
    text-transform: none;
    text-align: center;
    font-family: "Nunito Sans", sans-serif;
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.19;
    max-width: 950px;
    width: 100%;

    @include breakpoint(max, sm) {
      font-size: 24px;
    }
    @include breakpoint(between, sm md) {
      font-size: 30px;
    }
    @include breakpoint(between, md lg) {
      font-size: 34px;
    }
    @include breakpoint(min, lg) {
      font-size: 42px;
    }
  }
  .main_title__description {
    color: #718096;
    text-align: center;
    font-variant-numeric: slashed-zero;
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}
</style>
