<template>
  <div class="reject__form">
    <div v-if="denyStatus === DOCUMENT_APPROVAL_STATUS_REMOVED">
      <h2>Снять проект с публикации</h2>
      <p>
        Укажите причину, по которой Вы решили снять проект с публикации, в поле
        ниже
      </p>
    </div>

    <div v-else>
      <h2>Отклонить публикацию проекта</h2>
      <p>
        Укажите причину, по которой Вы решили отклонить публикацию проекта, в
        поле ниже
      </p>
    </div>

    <a-form
      class="form"
      :model="model"
      :rules="rules"
      @validate="errors.onValidate"
      @finish="onFinish"
    >
      <a-form-item
        name="rejectedReason"
        :help="errors.getError('rejectedReason')"
        :validate-status="errors.getStatus('rejectedReason')"
      >
        <a-textarea
          class="form__textarea"
          id="rejectedReason"
          v-model:value="model.rejectedReason"
          placeholder="Укажите корректировки здесь"
        />
      </a-form-item>
      <a-form-item class="reject__form-button">
        <c-button-danger :loading="isLoading" html-type="submit">
          Отправить
        </c-button-danger>
      </a-form-item>
    </a-form>
  </div>
</template>

<script setup>
  import { reactive, ref } from 'vue';
  import { useApi } from '@/api/use-api';
  import ErrorsHandler from '@/components/form/errors-handler';
  import CButtonDanger from '@/components/UI/CButtonDanger';
  import { TEXT_LENGTH_ERROR } from '@/constants/error';
  import { DOCUMENT_APPROVAL_STATUS_REMOVED } from '@/api/models/DocumentApprovalStatus';

  const api = useApi();
  const emit = defineEmits(['close', 'update']);
  const isLoading = ref(false);

  const props = defineProps({
    approval: {
      type: Object,
      default: null,
    },
    denyStatus: {
      type: String,
      required: true,
    },
  });

  const model = reactive({
    rejectedReason: '',
  });

  const rules = {
    rejectedReason: [
      {
        type: 'string',
        required: true,
        min: 2,
        max: 5000,
        message: TEXT_LENGTH_ERROR,
        trigger: 'change',
      },
    ],
  };

  const errors = new ErrorsHandler(
    {
      rejectedReason: '',
    },
    (fieldName) => {
      if ('approval' === fieldName) {
        return 'rejectedReason';
      }
      return fieldName;
    }
  );

  const onFinish = async () => {
    isLoading.value = true;
    try {
      if (props.denyStatus === DOCUMENT_APPROVAL_STATUS_REMOVED) {
        await api.documentApproval.remove({
          docApprovalId: props.approval.id,
          rejectedReason: model.rejectedReason,
        });
      } else {
        await api.documentApproval.reject({
          docApprovalId: props.approval.id,
          rejectedReason: model.rejectedReason,
        });
      }

      emit('update');
      emit('close');
    } catch (error) {
      console.log(error);
      errors.handleApiErrors(error);
    } finally {
      isLoading.value = false;
    }
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  * {
    margin: 0;
  }

  .reject__form {
    padding: 112px 17px 0 17px;

    h2 {
      font: 700 18px/26px 'Nunito Sans';
    }

    p {
      margin-top: 21px;
    }

    .form {
      margin-top: 30px;

      &__textarea {
        min-height: 125px;
        resize: none;
      }
    }

    &-button {
      margin-top: 50px;
      text-align: right;
    }
  }
</style>
