import { ref } from "vue";

/**
 * @property apiErrors - список ошибок полученных от API для i-ой компоненты табличной формы
 * @property inputErrors - состояние ошибок форм ввода для i-ой компоненты табличной формы 
 */
export function useTableErrorHandler() {
  const apiErrors = ref([]);
  const inputErrors = ref([]);

  const init = (table) => {
    apiErrors.value = new Array(table.length).fill({ errors: null });
    inputErrors.value = new Array(table.length).fill(false);
  };

  const reset = () => {
    apiErrors.value = [];
    inputErrors.value = [];
  };

  const addHandler = () => {
    apiErrors.value.push({ errors: null });
    inputErrors.value.push(false)
  };
  
  const removeHandler = (id) => {
    apiErrors.value.splice(id, 1);
    inputErrors.value.splice(id, 1);
  };

  const getApiError = (id) =>
    apiErrors.value && id < apiErrors.value.length ? apiErrors.value[id] : null;

  const setApiError = (id, error) => {
    if (apiErrors.value && id < apiErrors.value.length) {
      apiErrors.value[id] = {errors: error};
    }
  };

  const getApiErrors = () => apiErrors.value;

  const setTableApiErrors = (tableApiErrors) => {
    if (tableApiErrors) {
      tableApiErrors.forEach((item, index) => apiErrors.value[index] = item);
    }
  };

  const clearApiErrors = () =>
    apiErrors.value = new Array(apiErrors.value.length).fill({ errors: null });

  const getInputErrors = () => inputErrors.value;

  const setInputError = (id, hasError) => {
    if (id < inputErrors.value.length) {
      inputErrors.value[id] = hasError;
    }
  };

  const inputsHaveErrors = () => {
    return  inputErrors.value.some((item) => item);
  };

  return {
    init,
    reset,
    addHandler,
    removeHandler,
    getApiError,
    setApiError,
    getApiErrors,
    setTableApiErrors,
    clearApiErrors,
    getInputErrors,
    setInputError,
    inputsHaveErrors,
  };
}
