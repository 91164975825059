import { read } from '@/api/rest';

const publication = {
  /**
   * @param options
   * @returns {Promise<*>}
   */
  index: async (options) => await read('/publication?' + options.toString()),
  /**
   * @param options
   * @returns {Promise<*>}
   */
  total: async (options) =>
    await read('/publication/total?' + options.toString()),
  /**
   * @param options
   * @returns {Promise<*>}
   */
  totalByTypes: async (options) =>
    await read('/publication/total-by-types?' + options.toString()),
  view: async (id) => await read('/publication/' + id),
};

export default publication;
