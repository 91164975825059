<template>
  <a-row class="profile__info">
    <a-col :span="24">
      <a-row>
        <a-col>
          <div class="profile-avatar">
            <div class="profile-avatar__image">
              <img
                :src="getAvatarUrl(executor.avatar)"
                alt=""
                v-if="executor.avatar"
              />
              <div class="profile-avatar__skeleton" v-else>
                <img src="@/assets/icons/camera.svg" alt="" />
              </div>
            </div>
          </div>
        </a-col>
        <a-col>
          <div class="profile-description">
            <div class="profile-description__item">
              <div class="profile-description__label">Фамилия:</div>
              <div class="profile-description__content">
                {{ executor.lastName }}
              </div>
            </div>
            <div class="profile-description__item">
              <div class="profile-description__label">Имя:</div>
              <div class="profile-description__content">
                {{ executor.firstName }}
              </div>
            </div>
            <div class="profile-description__item">
              <div class="profile-description__label">Отчество:</div>
              <div class="profile-description__content">
                {{ executor.middleName }}
              </div>
            </div>
            <div class="profile-description__item">
              <div class="profile-description__label">E-mail:</div>
              <div class="profile-description__content">
                {{ executor.email }}
              </div>
            </div>
            <div class="profile-description__item">
              <div class="profile-description__label">Телефон:</div>
              <div class="profile-description__content">
                {{ executor.phone }}
              </div>
            </div>
            <div class="profile-description__item">
              <SocialIcons :person="executor" />
            </div>
          </div>
        </a-col>
        <a-col class="profile-edit">
          <a @click="emit('close')">К команде</a>
        </a-col>
      </a-row>
    </a-col>
  </a-row>

  <a-divider />

  <a-row>
    <h4>Задачи</h4>
  </a-row>
  <a-row class="profile__tasks-counter">
    <a-col class="tasks-counter total">
      <h4>Всего задач</h4>
      <span class="tasks-counter__num">{{ tasksCount['total'] }}</span>
    </a-col>
    <a-col class="tasks-counter inwork">
      <h4>В работе</h4>
      <span class="tasks-counter__num">{{
        tasksCount[STATUS_INCOMPLETE]
      }}</span>
    </a-col>
    <a-col class="tasks-counter completed">
      <h4>Выполнено</h4>
      <span class="tasks-counter__num">{{ tasksCount[STATUS_COMPLETED] }}</span>
    </a-col>
    <a-col class="tasks-counter expired">
      <h4>Просрочено</h4>
      <span class="tasks-counter__num">{{
        tasksCount[STATUS_COMPLETED_OVERDUE]
      }}</span>
    </a-col>
    <a-col class="tasks-counter incompleted">
      <h4>Невыполнено</h4>
      <span class="tasks-counter__num">{{
        tasksCount[STATUS_INCOMPLETE_OVERDUE]
      }}</span>
    </a-col>
  </a-row>

  <a-row class="profile__tasks-label">
    <span
      >В работе /
      {{
        tasksCount[STATUS_INCOMPLETE] + tasksCount[STATUS_INCOMPLETE_OVERDUE]
      }}</span
    >
  </a-row>

  <task-card
    v-for="(task, index) in tasksInWork"
    :key="index++"
    :task="{ number: index++, ...toRaw(task) }"
    :preview="true"
    :disabled="disabled"
  />

  <a-row class="profile__tasks-label">
    <span
      >Выполнено /
      {{
        tasksCount[STATUS_COMPLETED] + tasksCount[STATUS_COMPLETED_OVERDUE]
      }}</span
    >
  </a-row>

  <task-card
    v-for="(task, index) in tasksDone"
    :key="index++"
    :task="{ number: index++, ...toRaw(task) }"
    :preview="true"
    :disabled="disabled"
  />
</template>

<script setup>
  import TaskCard from '@/views/leader/projects/management/Task/TaskCard.vue';
  import { computed, toRaw } from 'vue';
  import {
    STATUS_COMPLETED,
    STATUS_COMPLETED_OVERDUE,
    STATUS_INCOMPLETE,
    STATUS_INCOMPLETE_OVERDUE,
  } from '@/api/models/Checkpoint';
  import SocialIcons from '@/components/social/SocialIcons.vue';
  import {
    useTaskCount,
    useTasksDone,
    useTasksInWork,
  } from '@/composables/taskCount';

  const props = defineProps({
    executor: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
  });
  const emit = defineEmits(['close']);

  const tasksCount = computed(() => {
    return props.executor
      ? useTaskCount(props.executor.tasks, props.executor.id)
      : {};
  });

  const tasksInWork = computed(() => {
    return props.executor ? useTasksInWork(props.executor.tasks) : [];
  });

  const tasksDone = computed(() => {
    return props.executor ? useTasksDone(props.executor.tasks) : [];
  });

  const getAvatarUrl = (avatar) =>
    avatar ? `/api/v1/attachment/${avatar}` : null;
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors';

  * {
    font-family: 'Nunito Sans', sans-serif;
    user-select: none;
  }

  .profile__info {
    padding: 10px 0 44px 0;

    .profile-avatar {
      height: 180px;
      width: 180px;

      .profile-avatar__image {
        position: absolute;

        img {
          border-radius: 12px;
          display: block;
          resize: both;
          height: 100%;
          width: 100%;
        }

        .profile-avatar__skeleton {
          align-items: center;
          background-color: $color-bg-secondary-grey;
          border-radius: 12px;
          display: flex;
          height: 170px;
          justify-content: center;
          width: 170px;

          img {
            width: 27px;
            height: 23px;
          }
        }
      }

      .profile-avatar__button {
        border-radius: 8px;
        background-color: $color-bg-primary-white;
        box-shadow: 0 2px 5.5px 0 rgba(0, 0, 0, 0.06);
        cursor: pointer;
        height: 55px;
        width: 55px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 0;
        bottom: 0;

        &:hover {
          background-color: $color-btn-special-hover;

          img {
            filter: sepia(100%) brightness(200%);
          }
        }

        img {
          width: 19px;
          height: 19px;
        }
      }
    }

    .profile-description {
      color: $color-text-secondary-1;
      display: flex;
      flex-direction: column;
      gap: 14px 0;
      margin-left: 50px;
      user-select: none;

      .profile-description__item {
        display: flex;
        gap: 0 10px;

        .profile-description__label {
          font: 700 14px/20px 'Nunito Sans';
        }

        .profile-description__content {
          font: 400 14px/20px 'Nunito Sans';
        }

        .profile-description__socials {
          cursor: pointer;
        }
      }
    }

    .profile-edit {
      flex: 1;
      text-align: end;

      a {
        color: $color-link-primary;
        font: 400 14px/19px 'Nunito Sans';
        text-decoration: underline;
        text-underline-position: under;
      }
    }
  }

  .profile__tasks-counter {
    flex-wrap: nowrap;
    height: 159px;

    .tasks-counter {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      row-gap: 10px;
      width: 100%;

      &.total {
        background-color: $color-bg-seconary-black-95;
      }

      &.inwork {
        background-color: $color-btn-secondary;
        h4 {
          color: $color-bg-primary-black-100;
        }

        .tasks-counter__num {
          color: $color-bg-primary-black-100;
        }
      }

      &.completed {
        background-color: $color-bg-status-done;
      }

      &.expired {
        background-color: $color-bg-status-expired;
      }

      &.incompleted {
        background-color: $color-bg-status-reject;
      }

      h4 {
        color: $color-bg-primary-white;
      }

      &__num {
        color: $color-bg-primary-white;
        font-size: 62px;
        font-weight: 700;
        line-height: 72px;
      }
    }
  }

  .profile__tasks-label {
    align-items: center;
    background-color: $color-bg-primary-black-100;
    color: $color-bg-primary-white;
    display: flex;
    height: 32px;
    justify-content: center;
    margin: 40px 0 20px 0;
    text-align: center;
  }
</style>
