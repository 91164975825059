<template>
  <a-row class="indexes__root" :gutter="[20, 20]">
    <template v-if="!indexes || showForm">
      <a-col class="title">Накопительные показатели</a-col>
      <a-col class="instructions">
        Заполните базовые показатели своего портфеля, чтобы достичь их
        выполнения с помощью проектов университета. В поле ниже начните вводить
        наименование показателя и выберите из списка предложенных. Если
        совпадение не обнаружится, допишите полное наименование и нажмите
        "Сохранить".
      </a-col>
    </template>

    <template v-else>
      <a-col class="title" :span="24">Накопительные показатели</a-col>
      <a-col :span="24">
        <a-row class="filters">
          <c-button-primary
            @click="tab = 'active'"
            :type="tab !== 'active' ? 'text' : ''"
            >Активные</c-button-primary
          >
          <c-button-primary
            @click="tab = 'archive'"
            :type="tab !== 'archive' ? 'text' : ''"
            >Архивные</c-button-primary
          >
        </a-row>
      </a-col>
      <template v-for="(index, i) in indexes" :key="'portfolio-index-' + i">
        <a-col :span="24" v-if="index?.status === tab">
          <portfolio-index-card
            :index="index"
            @change="getPortfolioIndexes()"
          />
        </a-col>
      </template>
    </template>

    <a-col v-if="showForm" :span="24">
      <add-index-form
        :onSubmit="createIndex"
        :indexCount="indexes.length + 1"
      />
    </a-col>

    <a-col
      v-if="
        route.meta.role === USER_ROLE_ADMINISTRATOR &&
        !showForm &&
        tab === 'active'
      "
      class="action"
      :span="24"
    >
      <c-button-add @click="handleShowForm">Добавить показатель</c-button-add>
    </a-col>
  </a-row>
</template>

<script setup>
  import { onMounted, ref } from 'vue';
  import { useApi } from '@/api/use-api';
  import { useRoute } from 'vue-router';
  import CButtonAdd from '@/components/UI/CButtonAdd.vue';
  import AddIndexForm from '@/components/projects/portfolio/components/AddIndexForm.vue';
  import PortfolioIndexCard from '@/components/projects/portfolio/components/PortfolioIndexCard.vue';
  import CButtonPrimary from '@/components/UI/CButtonPrimary.vue';
  import { USER_ROLE_ADMINISTRATOR } from '@/api/models/UserRole';

  const api = useApi();
  const route = useRoute();
  const tab = ref('active'); // active | archive | delete
  const showForm = ref(false);
  const indexes = ref([]);

  onMounted(() => {
    getPortfolioIndexes();
  });

  const getPortfolioIndexes = async () => {
    try {
      const response = await api.briefcase.indexes(route.params.id);
      indexes.value = response.indexes;
    } catch (error) {
      console.error('error', error);
    }
  };

  const createIndex = async (model) => {
    try {
      model.briefcaseId = route.params.id;
      await api.briefcaseIndex.create(model);
      await getPortfolioIndexes();
      handleShowForm();
    } catch (error) {
      console.error('error', error);
    }
  };

  const handleShowForm = () => (showForm.value = !showForm.value);
</script>

<style scoped lang="scss">
  * {
    font-family: 'Nunito Sans', sans-serif;
  }

  .indexes__root {
    margin-top: 40px;

    .title {
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
    }

    .instructions {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin-top: 10px;
    }

    .filters {
      justify-content: flex-end;
      column-gap: 6px;
    }

    .action {
      margin-top: 25px;
      margin-left: 20px;
    }
  }
</style>
