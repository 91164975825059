<template>
  <a-row :gutter="[20, 20]" class="projects">
    <a-col
      :span="12"
      v-for="(project, index) in projects"
      :key="{ id: index++, ...project }"
      @click="router.push(`/performer/project/${project.id}/tasks`)"
    >
      <project-card :project="project" />
    </a-col>
  </a-row>
</template>

<script setup>
  import { useRouter } from 'vue-router';
  import ProjectCard from './ProjectCard.vue';

  defineProps({ projects: Array });
  const router = useRouter();
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  .projects {
    margin-top: 30px;
    padding: 0 0 100px 0;
  }
</style>
