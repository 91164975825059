import { read } from '@/api/rest';

const organisationalUnit = {
  index: async (options) => await read('/organisational-unit?' + options.toString()),
  view: async (id) => await read('/organisational-unit/' + id),
  types: async () => await read('/organisational-unit/types'),
  totalInNSU: async () => await read('/organisational-unit/total'),
};

export default organisationalUnit;
