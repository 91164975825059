<template>
  <guest-layout>
    <template #navigation>
      <back-arrow page-name="sign-in" />
    </template>
    <template #default>
      <mega-title text="Партнер НГУ" />
      <div class="instruction">
        Чтобы восстановить пароль, введите e-mail, указанный при регистрации. Мы
        отправим на него ссылку для сброса пароля.
      </div>
      <a-form
        ref="formRef"
        :model="formState"
        :rules="rules"
        @finish="() => null"
        @finishFailed="() => null"
        @submit.prevent
      >
        <a-form-item name="email">
          <c-input-dadata
            v-model:value="formState.email"
            type="email"
            placeholder="Email*"
          />
        </a-form-item>
        <div class="buttons-block">
          <a-form-item>
            <a-button @click="onSubmit" type="primary" html-type="submit">
              Отправить
            </a-button>
          </a-form-item>
        </div>
      </a-form>
    </template>
  </guest-layout>
</template>

<script>
import GuestLayout from "@/layouts/GuestLayout";
import BackArrow from "@/components/navigation/BackArrow";
import MegaTitle from "@/components/titles/MegaTitle";
import CInputDadata from "@/components/UI/CInputDadata.vue";
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { useApi } from "@/api/use-api";

export default {
  name: "ResetPasswordRequestPage",
  components: {
    CInputDadata,
    GuestLayout,
    BackArrow,
    MegaTitle,
  },
  setup() {
    const router = useRouter();
    const api = useApi();
    const formRef = ref();
    const formState = reactive({
      email: "",
    });
    const rules = {
      email: [
        {
          required: true,
          type: "email",
          message: "Введён некорректный E-Mail",
          trigger: "change",
        },
      ],
    };
    const onSubmit = () => {
      formRef.value
        .validate()
        .then(async () => {
          await api.password.requestReset({
            email: formState.email,
          });

          await router.push({ name: "reset-password-request-success" });
        })
        .catch((error) => {
          console.log("error", error);
        });
    };
    const resetForm = () => {
      formRef.value.resetFields();
    };

    return {
      formRef,
      formState,
      rules,
      onSubmit,
      resetForm,
    };
  },
};
</script>

<style lang="scss"></style>
