<template>
  <button class="btn-icon">
    <slot></slot>
  </button>
</template>

<script setup></script>

<style lang="scss" scoped>
.btn-icon {
  width: 46px;
  height: 46px;
  background: #f2f4f8;
  border: none;
  outline: none;
  border-radius: 0;
  display: flex;
  padding: 11px;

  :deep(svg) {
    width: 24px;
    height: 24px;
  }

  &:hover {
    background: #383d4f;
    color: #f2f4f8;
    :deep(svg) * {
      fill: #f2f4f8;
    }
  }

  &:focus {
    background: #2d3346;
    color: #f2f4f8;
    :deep(svg) * {
      fill: #f2f4f8;
    }
  }
}
</style>
