export default {
  briefcases: [
    {
      type: 'array',
      required: true,
      message: 'Необходимо ввести данные как минимум об одном портфеле.',
      trigger: 'blur',
      validator: (_rule, value) => {
        if (
          value &&
          value.some(
            (item) => item.isSelected === true && item.indexesCount > 0
          )
        ) {
          return Promise.resolve();
        } else {
          return Promise.reject('Rule validation failed.');
        }
      },
    },
  ],
};
