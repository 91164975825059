<template>
  <table class="table">
    <thead class="header">
      <tr>
        <th class="col1"><span>Номер</span></th>
        <th class="col2"><span>Дата</span></th>
        <th class="col3"><span>Партнер</span></th>
        <th class="col4"><span>Тип запроса</span></th>
        <th class="col6" style="width: 5%"><span>Статус</span></th>
      </tr>
    </thead>
    <tbody>
      <request-item
        v-for="request in requests"
        :key="request.id"
        :request="request"
        @click="redirectTo(request)"
      />
    </tbody>
  </table>
</template>

<script setup>
  import { useRouter } from 'vue-router';
  import { useApi } from '@/api/use-api';
  import RequestItem from './RequestItem';

  defineProps({
    requests: Array,
  });

  const router = useRouter();
  const api = useApi();

  const redirectTo = async (request) => {
    try {
      let response = await api.leaderPage.checkRequestExistence(request.id);

      if (request.status.type === 'new') {
        await router.push({
          path: '/leader/requests/' + request.id,
        });
      } else {
        await router.push({
          path: '/leader/project/' + response.projectId + '/form#1',
        });
      }
    } catch (error) {
      console.error('error', error);
    }
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';
  $color-text-secondary-grey-216: #718096;

  .table {
    border-collapse: separate;
    border-spacing: 0 15px;
    padding-top: 30px;
    width: 100%;

    th[class*='col'] {
      span {
        border-bottom: 1px solid #f2f4f8;
        display: block;
        padding-bottom: 10px;
        width: 100%;
      }
    }

    th:first-child {
      padding-left: 20px;
    }

    .header {
      border-bottom: 1px solid $color-bg-secondary-grey;
      color: $color-text-secondary-grey-216;
      font-variant-numeric: slashed-zero;
      font-family: Nunito Sans, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 10px;
      text-align: left;
    }
  }
</style>
