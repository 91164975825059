<template>
  <stats-row v-if="model.showStats" :portfolio="model.portfolio" />

  <a-form
    ref="formRef"
    class="main-form"
    layout="vertical"
    :model="model"
    :rules="rules"
    @validate="errors.onValidate"
    @finish="onFinish"
    :scrollToFirstError="{
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    }"
  >
    <a-row class="form-step">
      <a-col
        v-if="route.meta.role === USER_ROLE_ADMINISTRATOR"
        :span="24"
        class="portfolio__instructions"
      >
        <div class="form-step__instructions">
          Вы назначены администратором портфеля. Заполните информацию и добавьте
          <br />
          показатели, чтобы руководители проектов могли выполнять их.
        </div>
      </a-col>
      <a-col :span="24">
        <a-row
          v-if="route.meta.role !== USER_ROLE_ADMINISTRATOR"
          class="portfolio__label"
        >
          Название портфеля
        </a-row>
        <a-row :gutter="[20, 14]">
          <a-col :span="12">
            <a-form-item name="shortName">
              <a-input
                id="shortName"
                :defaultValue="model.shortName"
                v-model:value="model.shortName"
                disabled
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item name="name">
              <a-input
                id="name"
                :defaultValue="model.name"
                v-model:value="model.name"
                disabled
              />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item
              name="description"
              label="Информация о портфеле"
              :help="errors.getError('description')"
              :validate-status="errors.getStatus('description')"
            >
              <a-textarea
                id="description"
                class="test"
                v-model:value="model.description"
                placeholder="Опишите портфель"
                show-count
                :maxlength="1000"
                :autoSize="{ minRows: 6, maxRows: 6 }"
                :disabled="disabled"
              />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item
              name="targets"
              label="Целевые ориентиры"
              :help="errors.getError('targets')"
              :validate-status="errors.getStatus('targets')"
            >
              <a-textarea
                id="targets"
                v-model:value="model.targets"
                placeholder="Перечислите, чего необходимо добиться в рамках портфеля"
                show-count
                :maxlength="1000"
                :autoSize="{ minRows: 6, maxRows: 6 }"
                :disabled="disabled"
              />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item
              name="typesProjects"
              label="Какие типы проектов в рамках портфеля могут быть поддержаны"
              :help="errors.getError('typesProjects')"
              :validate-status="errors.getStatus('typesProjects')"
            >
              <a-textarea
                id="typesProjects"
                v-model:value="model.typesProjects"
                placeholder="Перечислите, чего необходимо добиться в рамках портфеля"
                show-count
                :maxlength="1000"
                :autoSize="{ minRows: 6, maxRows: 6 }"
                :disabled="disabled"
              />
            </a-form-item>
          </a-col>
          <attachments-input
            :error="errors.getError('attachmentUids')"
            :init-attachments="model.attachments"
            :is-editable="!disabled"
            :accept="ATTACHMENT_COMMON_EXTENSIONS"
            @update="handleUpdateAttachments"
          />
        </a-row>
      </a-col>
    </a-row>

    <div
      v-if="!!model.portfolio && route.meta.role === USER_ROLE_ADMINISTRATOR"
    >
      <a-row v-if="model.isEditing" class="form__buttons" :gutter="[10, 0]">
        <a-col>
          <a-form-item>
            <c-button-secondary @click="onCancel">
              Отменить
            </c-button-secondary>
          </a-form-item>
        </a-col>
        <a-col>
          <a-form-item>
            <c-button-primary class="submit-button" html-type="submit">
              Сохранить
            </c-button-primary>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row v-else class="form__buttons" :gutter="[10, 0]">
        <a-col>
          <a-form-item>
            <c-button-primary @click="onEditClick">
              Редактировать
            </c-button-primary>
          </a-form-item>
        </a-col>
      </a-row>
    </div>
  </a-form>
</template>

<script setup>
  import { computed, onMounted, reactive, watch } from 'vue';
  import { useApi } from '@/api/use-api';
  import { useRoute, useRouter } from 'vue-router';
  import ErrorsHandler from '@/components/form/errors-handler';
  import AttachmentsInput from '@/components/attachments/AttachmentsInput.vue';
  import CButtonSecondary from '@/components/UI/CButtonSecondary.vue';
  import CButtonPrimary from '@/components/UI/CButtonPrimary.vue';
  import StatsRow from '@/components/projects/portfolio/components/PortfolioStatsRow.vue';
  import { ATTACHMENT_COMMON_EXTENSIONS } from '@/components/attachments/AttachmentExtentions';
  import {
    USER_ROLE_ADMINISTRATOR,
    USER_ROLE_LEADER,
  } from '@/api/models/UserRole';

  const api = useApi();
  const route = useRoute();
  const router = useRouter();
  const emit = defineEmits(['update']);

  const props = defineProps({
    portfolio: Object,
  });

  const model = reactive({
    portfolio: null,
    name: '',
    shortName: '',
    description: '',
    targets: '',
    typesProjects: '',
    attachments: [],
    attachmentUids: [],
    isEditing: false,
    showStats: false,
  });

  const disabled = computed(() => {
    return route.meta.role === USER_ROLE_LEADER || !model.isEditing;
  });

  const rules = {
    description: [
      {
        required: true,
        min: 2,
        max: 1000,
        message: 'Поле должно содержать не менее 2, но не более 1000 символов',
        trigger: ['change', 'blur'],
      },
    ],
    targets: [
      {
        required: false,
        min: 2,
        max: 1000,
        message: 'Поле должно содержать не менее 2, но не более 1000 символов',
        trigger: ['change', 'blur'],
      },
    ],
    typesProjects: [
      {
        required: false,
        min: 2,
        max: 1000,
        message: 'Поле должно содержать не менее 2, но не более 1000 символов',
        trigger: ['change', 'blur'],
      },
    ],
  };

  const errors = new ErrorsHandler({
    description: '',
    targets: '',
    typesProjects: '',
    attachmentUids: '',
  });

  onMounted(() => {
    fillModel();
  });

  watch(
    () => props.portfolio,
    () => {
      fillModel();
    }
  );

  const fillModel = () => {
    if (!props.portfolio) return;

    try {
      model.portfolio = props.portfolio;
      model.name = props.portfolio.name;
      model.shortName = props.portfolio.shortName;
      model.description = props.portfolio.description;
      model.targets = props.portfolio.targets;
      model.typesProjects = props.portfolio.typesProjects;
      model.attachments = props.portfolio.attachments;
      model.showStats = !!props.portfolio.description;
      model.isEditing = !props.portfolio.description;
    } catch (error) {
      console.error('error', error);
    }
  };

  const onFinish = async () => {
    try {
      model.isLoading = true;
      await api.briefcase.update(route.params.id, {
        description: model.description,
        targets: model.targets,
        typesProjects: model.typesProjects,
        attachmentUids: model.attachmentUids,
      });

      emit('update');
    } catch (error) {
      errors.handleApiErrors(error);
    } finally {
      model.isEditing = false;
      model.showStats = true;
    }
  };

  const handleUpdateAttachments = (uidList) => {
    model.attachmentUids = uidList;
  };

  const onCancel = () => {
    router.replace({ name: 'administrator-portfolios' });
  };

  const onEditClick = () => {
    model.isEditing = true;
  };
</script>

<style lang="scss" scoped>
  @import '@/components/form/main-form';

  :deep(.ant-input-disabled) {
    color: $color-text-primary;
  }

  .portfolio__instructions {
    margin-bottom: 30px;
  }

  .portfolio__label {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.42;
    margin-bottom: 8px;
  }
</style>
