<template>
  <h3>Шаг 1. Информация о проекте</h3>
  <p>Проверьте информацию и при необходимости скорректируйте её.</p>
  <div>
    <a-form
      class="form leader-project-form"
      layout="vertical"
      :model="model"
      :rules="rules"
      @validate="errors.onValidate"
      @finish="onFinish"
    >
      <leader-project-form-row
        :show-available="model.isExternal"
        :available="model.availabilityList['name']"
        :disabled="disabled"
        @change="changeAvailability('name')"
      >
        <a-form-item
          name="name"
          label="Название проекта"
          :help="errors.getError('name')"
          :validate-status="errors.getStatus('name')"
        >
          <a-input
            v-model:value="model.name"
            placeholder="Введите название проекта"
            :disabled="disabled"
          />
        </a-form-item>
      </leader-project-form-row>

      <leader-project-form-row
        v-if="!model.isExternal"
        :show-available="model.isExternal"
        :available="model.availabilityList['sourceLink']"
        :disabled="disabled"
        @change="changeAvailability('sourceLink')"
      >
        <a-form-item name="sourceLink" label="Ссылка на связанный проект">
          <a-input
            v-model:value="model.sourceLink"
            placeholder="Укажите ссылку на связанный проект"
            :disabled="disabled"
          />
        </a-form-item>
      </leader-project-form-row>

      <leader-project-form-row
        :show-available="model.isExternal"
        :available="model.availabilityList['type']"
        :disabled="disabled"
        @change="changeAvailability('type')"
      >
        <a-form-item
          name="typeId"
          label="Тип проекта"
          :help="errors.getError('typeId')"
          :validate-status="errors.getStatus('typeId')"
        >
          <a-select
            :value="model.typeId ? { value: model.typeId } : undefined"
            :options="store.getters.projectTypeOptions()"
            :disabled="disabled"
            placeholder="Выберите тип проекта"
            @change="setTypeId"
          />
        </a-form-item>
      </leader-project-form-row>

      <leader-project-form-row
        v-if="model.organizationName"
        :show-available="model.isExternal"
        :available="model.availabilityList['company']"
        :disabled="disabled"
        @change="changeAvailability('company')"
      >
        <a-form-item
          name="organizationName"
          label="Компания-партнёр"
          :help="errors.getError('organizationName')"
          :validate-status="errors.getStatus('organizationName')"
        >
          <a-input v-model:value="model.organizationName" disabled />
        </a-form-item>
      </leader-project-form-row>

      <leader-project-form-row
        :show-available="model.isExternal"
        :available="model.availabilityList['leader']"
        :disabled="disabled"
        @change="changeAvailability('leader')"
      >
        <a-form-item
          name="leaderName"
          label="Руководитель проекта"
          :help="errors.getError('leaderName')"
          :validate-status="errors.getStatus('leaderName')"
        >
          <a-input v-model:value="model.leaderName" disabled />
        </a-form-item>
      </leader-project-form-row>

      <leader-project-form-row
        v-if="false"
        :show-available="model.isExternal"
        :available="model.availabilityList['unit']"
        :disabled="disabled"
        @change="changeAvailability('unit')"
      >
        <a-form-item
          v-if="model.isExternal"
          name="unit"
          label="Подразделение-исполнитель"
          :help="errors.getError('unit')"
          :validate-status="errors.getStatus('unit')"
        >
          <a-input v-model:value="model.unit" disabled />
        </a-form-item>
        <a-form-item
          v-else
          name="unitId"
          label="Подразделение-исполнитель"
          :help="errors.getError('unitId')"
          :validate-status="errors.getStatus('unitId')"
        >
          <a-select
            v-model:value="model.unitId"
            :options="units"
            placeholder="Выберите подразделение-исполнитель"
            @change="onUnitSelect"
          />
        </a-form-item>
      </leader-project-form-row>

      <leader-project-form-row
        :show-available="model.isExternal"
        :available="model.availabilityList['scientificAdviser']"
        :disabled="disabled"
        @change="changeAvailability('scientificAdviser')"
      >
        <a-form-item
          name="scientificAdviser"
          label="Научный руководитель"
          :help="errors.getError('scientificAdviser')"
          :validate-status="errors.getStatus('scientificAdviser')"
        >
          <a-input
            v-model:value="model.scientificAdviser"
            placeholder="Укажите научного руководителя"
            :disabled="disabled"
          />
        </a-form-item>
      </leader-project-form-row>

      <leader-project-form-row
        :show-available="model.isExternal"
        :available="model.availabilityList['tags']"
        :disabled="disabled"
        @change="changeAvailability('tags')"
      >
        <a-form-item
          name="tags"
          label="Ключевые слова"
          :help="errors.getError('tags')"
          :validate-status="errors.getStatus('tags')"
        >
          <c-input-tags
            placeholder="Задайте ключевые слова проекту"
            :tags="model.tags"
            :disabled="disabled"
            @update="updateTags"
          />
        </a-form-item>
      </leader-project-form-row>

      <leader-project-form-row
        :show-available="false"
        :available="model.availabilityList['scientificAdviser']"
        :disabled="disabled"
        @change="changeAvailability('scientificAdviser')"
      >
        <a-form-item name="showAllTasks" label="Видимось задач">
          <c-checkbox
            :checked="model.showAllTasks"
            :disabled="disabled"
            @change="() => (model.showAllTasks = !model.showAllTasks)"
          >
            Исполнитель может видеть все задачи проекта
          </c-checkbox>
        </a-form-item>
      </leader-project-form-row>

      <leader-project-form-row :show-available="false">
        <leader-project-form-buttons
          v-if="project?.isMyProject"
          :project-status-id="project?.projectStatusId"
          :loading="isLoading"
          @click="setGoNext"
          @confirm-update="() => emit('confirm-update')"
        />
      </leader-project-form-row>
    </a-form>
  </div>
</template>

<script setup>
  import { onMounted, reactive, ref, watch } from 'vue';
  import { useRouter } from 'vue-router';
  import { useApi } from '@/api/use-api';
  import ErrorsHandler from '@/components/form/errors-handler';
  import CInputTags from '@/components/UI/CInputTags.vue';
  import LeaderProjectFormRow from '@/views/leader/projects/form/LeaderProjectFormRow.vue';
  import LeaderProjectFormButtons from '@/views/leader/projects/form/LeaderProjectFormButtons.vue';
  import rules from './rules/RulesStep1';
  import { useStore } from 'vuex';
  import CCheckbox from '@/components/UI/CCheckbox.vue';

  const router = useRouter();
  const api = useApi();
  const emit = defineEmits(['update', 'update-type', 'confirm-update']);
  const store = useStore();
  const units = ref([]);
  const isLoading = ref(false);

  const props = defineProps({
    project: {
      type: Object,
      require: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  });

  const model = reactive({
    id: '',
    name: '',
    typeId: '',
    isExternal: false,
    organizationName: '',
    leaderName: '',
    sourceLink: '',
    unit: '',
    unitId: null,
    adminId: null,
    scientificAdviser: '',
    tags: [],
    showAllTasks: false,
    availabilityList: [],
  });

  watch(
    () => props.project,
    () => {
      fillModel();
    }
  );

  const fillModel = () => {
    try {
      model.id = props.project.id;
      model.name = props.project.name;
      model.typeId = props.project.typeId;
      model.isExternal = props.project.isExternal;
      model.organizationName = props.project.organizationName;
      model.leaderName = props.project.leaderName;
      model.sourceLink = props.project.sourceLink;
      model.unit = props.project.unit;
      model.unitId = props.project.unitId;
      model.adminId = props.project.adminId;
      model.scientificAdviser = props.project.scientificAdviser;
      model.tags = props.project.tags;
      model.showAllTasks = props.project.showAllTasks;
      model.availabilityList = props.project.availabilityList;
    } catch (error) {
      console.error('error', error);
    }
  };

  async function getUnits() {
    try {
      const response = await api.leaderPage.adminBriefcases();
      units.value = response.briefcases.map((item) => {
        return {
          adminId: parseInt(item.adminId),
          value: parseInt(item.id),
          label: item.name,
        };
      });
    } catch (error) {
      console.error(error);
    }
  }

  const errors = new ErrorsHandler({
    name: '',
    typeId: '',
    organizationName: '',
    leaderName: '',
    sourceLink: '',
    unitId: '',
    scientificAdviser: '',
    tags: '',
  });

  const updateTags = (newTags) => {
    model.tags = newTags;
  };

  const setTypeId = (value) => {
    model.typeId = value;
    emit('update-type', value);
  };

  const goNext = ref(false);
  const setGoNext = (value) => {
    goNext.value = !!value;
  };

  const changeAvailability = (key) => {
    model.availabilityList[key] = !model.availabilityList[key];
  };

  const onFinish = async () => {
    try {
      isLoading.value = true;
      await api.leaderPage.updateProject({
        id: model.id,
        name: model.name,
        typeId: model.typeId,
        sourceLink: model.sourceLink,
        unitId: model.unitId,
        adminId: model.adminId,
        scientificAdviser: model.scientificAdviser,
        tags: model.tags,
        showAllTasks: model.showAllTasks,
        isNameAvailable: model.availabilityList['name'],
        isTypeAvailable: model.availabilityList['type'],
        isCompanyAvailable: model.availabilityList['company'],
        isLeaderAvailable: model.availabilityList['leader'],
        isUnitAvailable: model.availabilityList['unit'],
        isScientificAdviserAvailable:
          model.availabilityList['scientificAdviser'],
        isTagsAvailable: model.availabilityList['tags'],
        isSourceLinkAvailable: model.availabilityList['sourceLink'],
      });
      emit('update', model);
      if (goNext.value) {
        await router.push({ hash: '#2' });
      }
    } catch (error) {
      errors.handleApiErrors(error);
    } finally {
      isLoading.value = false;
    }
  };

  const onUnitSelect = (value) => {
    model.unitId = value;
    units.value.forEach((item) => {
      if (item.value === value) {
        model.adminId = item.adminId;
      }
    });
  };

  onMounted(() => {
    getUnits();
    props.project && fillModel();
  });

  defineExpose({ onFinish });
</script>

<style lang="scss" scoped>
  @import './leader-project-form';
</style>
