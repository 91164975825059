<template>
  <h3>Шаг 6. Ресурсы</h3>
  <p>Опишите необходимые для выполнения проекта ресурсы.</p>
  <div>
    <a-form
      class="form leader-project-form"
      layout="vertical"
      :model="model"
      :rules="rules"
      @validate="errors.onValidate"
      @finish="onFinish"
    >
      <leader-project-form-row
        :show-available="model.isExternal"
        :available="model.availabilityList['resources']"
        :disabled="disabled"
        @change="changeAvailability('resources')"
      >
        <a-form-item
          name="resources"
          label="Требуемые ресурсы"
          :help="errors.getError('resources')"
          :validate-status="errors.getStatus('resources')"
        >
          <a-textarea
            v-model:value="model.resources"
            placeholder="Опишите необходимые для выполнения проекта ресурсы"
            :rows="7"
            :disabled="disabled"
          />
        </a-form-item>
      </leader-project-form-row>

      <leader-project-form-row :show-available="false">
        <leader-project-form-buttons
          v-if="project?.isMyProject"
          :project-status-id="project?.projectStatusId"
          :loading="isLoading"
          @click="setGoNext"
          @confirm-update="() => emit('confirm-update')"
        />
      </leader-project-form-row>
    </a-form>
  </div>
</template>

<script setup>
  import { onMounted, reactive, ref, watch } from 'vue';
  import { useRouter } from 'vue-router';
  import { useApi } from '@/api/use-api';
  import LeaderProjectFormRow from '@/views/leader/projects/form/LeaderProjectFormRow.vue';
  import LeaderProjectFormButtons from '@/views/leader/projects/form/LeaderProjectFormButtons.vue';
  import ErrorsHandler from '@/components/form/errors-handler';
  import rules from './rules/RulesStep6';

  const router = useRouter();
  const api = useApi();
  const emit = defineEmits(['update', 'confirm-update', 'update-type']);
  const isLoading = ref(false);

  const props = defineProps({
    project: {
      type: Object,
      require: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  });

  watch(
    () => props.project,
    () => {
      fillModel();
    }
  );

  const model = reactive({
    id: null,
    isExternal: false,
    resources: '',
    availabilityList: [],
  });

  const errors = new ErrorsHandler({
    resources: '',
  });

  const goNext = ref(false);
  const setGoNext = (value) => {
    goNext.value = !!value;
  };

  onMounted(() => {
    props.project && fillModel();
  });

  const fillModel = () => {
    try {
      model.id = props.project.id;
      model.isExternal = props.project.isExternal;
      model.resources = props.project.resources;
      model.availabilityList = props.project.availabilityList;
    } catch (error) {
      console.error('error', error);
    }
  };

  const changeAvailability = (key) => {
    model.availabilityList[key] = !model.availabilityList[key];
  };

  const onFinish = async () => {
    try {
      isLoading.value = true;
      await api.leaderPage.updateProject({
        id: model.id,
        resources: model.resources,
        isResourcesAvailable: model.availabilityList['resources'],
      });

      emit('update', model);
      if (goNext.value) {
        await router.push({ hash: '#7' });
      }
    } catch (error) {
      errors.handleApiErrors(error);
    } finally {
      isLoading.value = false;
    }
  };

  defineExpose({ onFinish });
</script>

<style lang="scss" scoped>
  @import './leader-project-form';
</style>
