<template>
  <white-block class="footer-portal__whiteline"></white-block>
  <div class="footer-portal">
    <content-container>
      <a-row justify="space-between">
        <a-col :span="8" class="footer-portal__copy">
          <main-logo></main-logo>
          <p class="copy">
            © {{ currentYear }} Новосибирский государственный университет
          </p>
        </a-col>
        <a-col :span="9" :offset="1" class="footer-portal__info">
          <p>
            Новосибирский государственный университет<br />
            Телефон: <span rel="phone">+7 (383) 363-40-06</span><br />
            E-mail:
            <a href="mailto:priem@nsu.ru"> priem@nsu.ru </a>
            <br />
            Техническая поддержка:
            <a href="mailto:support@nsu.ru"> support@nsu.ru </a>
          </p>
        </a-col>
        <a-col :span="5" :offset="1" class="footer-portal__links">
          <button-dark-border
            type="external-link"
            to="/"
          >
            Личный кабинет
          </button-dark-border>
        </a-col>
      </a-row>
    </content-container>
  </div>
</template>

<script setup>
  import MainLogo from './MainLogo.vue';
  import ButtonDarkBorder from '@/views/portal/components/UI/ButtonDarkBorder.vue';
  import ContentContainer from '@/views/portal/components/ContentContainer.vue';
  import WhiteBlock from '@/views/portal/components/WhiteBlock.vue';
  import dayjs from 'dayjs';

  const currentYear = dayjs().year();
</script>

<style lang="scss" scoped>
  @import '../../portal/assets/_map-for-breakpoints';
  @import '../../../assets/styles/_breakpoints';

  .footer-portal__whiteline {
    height: 80px;
  }
  .footer-portal {
    padding: 40px 35px 40px 35px;
    background: #2d3346;
    color: #ffffff;

    .footer-portal__copy {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      flex-direction: column;
      padding-bottom: 2px; /* чтобы нивелировать line-height у p.copy */
      p.copy {
        color: #fff;
        font-family: 'PT Sans', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5;
        margin: 20px 0 0 0;
      }
    }

    .footer-portal__info {
      p {
        color: #fff;
        font-family: 'PT Sans', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.71429;
        margin-bottom: 10px;

        a {
          color: #00bde8;
        }
      }
    }

    .footer-portal__links {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    @include breakpoint(max, md) {
      & > .container {
        flex-direction: column;

        & > div {
          flex: 0 0 100%;
          max-width: 100%;
          margin-inline-start: 0;
          margin-bottom: 20px;
        }
      }

      .footer-portal__copy {
        p {
          font-size: 12px;
          font-weight: 400;
        }
      }
      .footer-portal__info {
        p {
          font-size: 12px;
          font-weight: 400;
        }
      }
      .footer-portal__links {
        align-items: flex-start;
      }
    }
  }
</style>
