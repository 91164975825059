<template>
  <partner-layout>
    <main-title>Отчёты</main-title>
    <slot></slot>
  </partner-layout>
</template>

<script>
import { defineComponent } from "vue";
import PartnerLayout from "@/layouts/PartnerLayout.vue";
import MainTitle from "@/views/portal/components/MainTitle.vue";

export default defineComponent({
  components: { MainTitle, PartnerLayout },
});
</script>

<style lang="scss" scoped></style>
