<template>
  <router-view v-if="undefined !== isPending && !isPending"></router-view>
</template>

<script setup>
import {
  signOut,
  useAuthenticated,
  usePending,
} from "@/api/authentication/token-provider";
import { useRouter, useRoute } from "vue-router";
import { onMounted, watch} from "vue";
import { useStore } from "vuex";
import { useApi } from "@/api/use-api";
import { getDefaultRouteByRole } from "@/router"
import { setUnauthorizedHandler } from "@/services/backend/connect";

const store = useStore();
const isAuthenticated = useAuthenticated();
const isPending = usePending();
const api = useApi();
const router = useRouter();
const route = useRoute();

watch(isAuthenticated, async (newValue) => {
  newValue && await updateReferenceData();

  // Pure module redirect
  if (route.meta.module === "pure") {
    return await router.push({ name: route.name });
  }

  // If current role are not set - internal user login for the first time
  if (newValue === true && !localStorage.getItem("currentRole")) {
    const response = await api.user.roles();
    store.commit("setCurrentRole", response.roles[0].value);
    localStorage.setItem("currentRole", response.roles[0].value);
    return await router.push(getDefaultRouteByRole(store.state.currentRole));
  }

  // If current role are set - internal user is authenticated in keycloack
  if (newValue === true && localStorage.getItem("currentRole")) {
    store.commit("setCurrentRole", localStorage.getItem("currentRole"));

    // Redirect to current role page, if route is unprotected
    if (route.meta.requiresAuth === false) {
      return await router.push(getDefaultRouteByRole(store.state.currentRole));
    }
    const response = await api.user.roles();
    const roles = response.roles.map((role) => role.value)
    // If role in route are unavailable for user
    if (!roles.includes(route.meta.role)) {
      return await router.push(getDefaultRouteByRole(store.state.currentRole));
    }
  }
});

watch(isPending, async (newValue) => {
  // Pure module redirect
  if (route.meta.module === "pure") {
    return await router.push({ name: route.name });
  }

  // If current role not set and route is protected
  if (
    newValue === false &&
    !localStorage.getItem("currentRole") &&
    route.meta.requiresAuth === true
  ) {
    await router.push({ name: "home" });
  }

  // If current role and access token is set, we can't go to unprotected routes and always can go to pure module
  if (
    newValue === false &&
    localStorage.getItem("currentRole") &&
    localStorage.getItem("access_token")
  ) {
    store.commit("setCurrentRole", localStorage.getItem("currentRole"));

    const response = await api.user.roles();
    const roles = response.roles.map((role) => role.value)

    // If role in route are unavailable for user
    if (!roles.includes(route.meta.role) || route.meta.requiresAuth === false) {
      return await router.push(getDefaultRouteByRole(store.state.currentRole));
    }

    // Everything ok
    await router.push({ name: route.name });
  }
});

onMounted(async () => {
  isAuthenticated.value && await updateReferenceData();
});

const updateReferenceData = async () => {
  await store.dispatch('fetchRequestTypes', api);
  await store.dispatch('fetchProjectTypes', api);
};

setUnauthorizedHandler(async () => {
  await signOut(() => {
    localStorage.removeItem('currentRole');
  });

  await router.push({name: 'home'});
});
</script>

<style></style>
