<template>
  <h3>Шаг 1. Информация о проекте</h3>
  <p>Общая информация о проекте</p>
  <div>
    <a-form class="form leader-project-form" layout="vertical">
      <performer-project-form-row>
        <a-form-item name="name" label="Название проекта">
          <a-input
            v-model:value="model.name"
            placeholder="Введите название проекта"
            disabled
          />
        </a-form-item>
      </performer-project-form-row>

      <performer-project-form-row v-if="!model.isExternal">
        <a-form-item name="sourceLink" label="Ссылка на связанный проект">
          <a-input
            v-model:value="model.sourceLink"
            placeholder="Укажите ссылку на связанный проект"
            disabled
          />
        </a-form-item>
      </performer-project-form-row>

      <performer-project-form-row>
        <a-form-item name="projectType" label="Тип проекта">
          <a-input
            v-model:value="model.projectType"
            placeholder="Выберите тип проекта"
            disabled
          />
        </a-form-item>
      </performer-project-form-row>

      <performer-project-form-row v-if="model.isExternal">
        <a-form-item name="companyName" label="Компания-партнёр">
          <a-input v-model:value="model.companyName" disabled />
        </a-form-item>
      </performer-project-form-row>

      <performer-project-form-row>
        <a-form-item name="leaderName" label="Руководитель проекта">
          <a-input v-model:value="model.leaderName" disabled />
        </a-form-item>
      </performer-project-form-row>

      <performer-project-form-row v-if="fasle">
        <a-form-item name="unit" label="Подразделение-исполнитель">
          <a-input v-model:value="model.unit" disabled />
        </a-form-item>
      </performer-project-form-row>

      <performer-project-form-row>
        <a-form-item name="scientificAdviser" label="Научный руководитель">
          <a-input v-model:value="model.scientificAdviser" disabled />
        </a-form-item>
      </performer-project-form-row>

      <performer-project-form-row :show-available="false">
        <a-form-item name="new-tag" label="Ключевые слова">
          <div class="input-tags__list" v-if="model.tags.length">
            <div class="tags">
              <span
                class="tag"
                v-for="(tag, index) in model.tags"
                :key="`tag-` + index"
              >
                #{{ tag }}
              </span>
            </div>
          </div>
        </a-form-item>
      </performer-project-form-row>
    </a-form>
  </div>
</template>

<script setup>
  import { onMounted, reactive, watch } from 'vue';
  import PerformerProjectFormRow from '../PerformerProjectFormRow.vue';

  const props = defineProps({
    project: {
      type: Object,
      require: true,
    },
  });

  const model = reactive({
    id: '',
    isExternal: true,
    name: '',
    projectType: '',
    companyName: '',
    leaderName: '',
    sourceLink: '',
    unit: '',
    scientificAdviser: '',
    tags: [],
  });

  onMounted(() => {
    props.project && fillModel();
  });

  watch(
    () => props.project,
    () => {
      props.project && fillModel();
    }
  );

  const fillModel = () => {
    try {
      model.id = props.project.id;
      model.isExternal = props.project.isExternal;
      model.name = props.project.name;
      model.projectType = props.project.type;
      model.companyName = props.project.organizationName;
      model.leaderName = props.project.leaderName;
      model.sourceLink = props.project.sourceLink;
      model.unit = props.project.unit;
      model.scientificAdviser = props.project.scientificAdviser;
      model.tags = props.project.tags;
    } catch (error) {
      console.error('error', error);
    }
  };
</script>

<style lang="scss" scoped>
  @import './performer-project-form';
</style>
