<template>
  <h3>Шаг 6. Ресурсы</h3>
  <p>
    Проверьте информацию в карточке проекта и, если согласны, согласуйте проект.
  </p>
  <div>
    <a-form class="form partner-project-form" layout="vertical">
      <partner-project-form-row
        :show-available="false"
        v-if="model.availabilityList['resources']"
      >
        <a-form-item name="resources" label="Требуемые ресурсы">
          <a-textarea
            v-model:value="model.resources"
            placeholder="Опишите необходимые для выполнения проекта ресурсы"
            rows="7"
            disabled
          />
        </a-form-item>
      </partner-project-form-row>
    </a-form>
  </div>
</template>

<script setup>
import {onMounted, reactive, watch} from 'vue';
  import PartnerProjectFormRow from '../PartnerProjectFormRow.vue';

  const props = defineProps({
    project: {
      type: Object,
      require: true,
    },
  });

  const model = reactive({
    id: null,
    resources: '',
    availabilityList: [],
  });

  onMounted(() => {
    props.project && fillModel();
  });

  watch(() => props.project, () => { fillModel(); });

  const fillModel = () => {
    try {
      model.id = props.project.id;
      model.resources = props.project.resources;
      model.availabilityList = props.project.availabilityList;
    } catch (error) {
      console.error('error', error);
    }
  };
</script>

<style lang="scss" scoped></style>
