<template>
  <a-row>
    <a-col :span="12">
      <div class="project-type">
        {{ type }}
      </div>
      <div class="project-description">
        Номер проекта:
        <span class="project-number">{{ number }}</span>
      </div>
    </a-col>
    <a-col :span="12" class="project-link">
      <nav-link
        page-name=""
        text="Ссылка на запрос"
        @click="router.push({ path: `/partner/requests/${requestId}` })"
      />
    </a-col>
  </a-row>
</template>

<script setup>
  import { useRouter } from 'vue-router';
  import NavLink from '@/components/navigation/NavLink.vue';

  defineProps({
    type: {
      type: String,
      default: '',
    },
    number: {
      type: String,
      default: '',
    },
    requestId: {
      type: Number,
      default: 0,
    },
  });

  const router = useRouter();
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  .project-type,
  .project-description {
    color: $color-text-secondary-grey;
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
  }

  .project-description {
    margin-top: 10px;

    .project-number {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .project-link {
    text-align: right;
  }
</style>
