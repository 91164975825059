<template>
  <a-spin
    :spinning="isLoading"
    class="custom-spinner"
    style="height: 100vh; top: 20%"
  >
    <administrator-project-layout :model="model">
      <template #underInfo>
        <div
          class="administrator-project__common-progress"
          v-if="checkpoints.length"
        >
          <progress-bar :items="checkpoints" showPercent />
        </div>
      </template>
      <div class="project-report">
        <!-- Эта секция недоступна при множественном согласовании (доступна только если согласует один админ) -->
        <div
          v-if="
            model.projectStatusId === PROJECT_STATUS_ACCEPTANCE &&
            model.approval?.id
          "
        >
          <a-form
            class="form administrator-project-form"
            layout="vertical"
            :model="model"
            @validate="errors.onValidate"
            @finish="() => null"
          >
            <h3>Отчет по проекту</h3>
            <a-form-item
              name="approval"
              :help="errors.getError('approval')"
              :validate-status="errors.getStatus('approval')"
            >
              <a-row class="administrator-report">
                <a-col class="administrator-report-title">
                  <h4>Этот проект отправлен на закрытие</h4>
                </a-col>
                <a-col class="administrator-report-info">
                  <p>
                    Один из участников проекта инициировал его закрытие.
                    Пожалуйста, ознакомьтесь с отчетом по результатам выполнения
                    проекта ниже. Если вы согласны закрыть проект, пожалуйста,
                    подтвердите это. Если вы считаете, что проект должен быть
                    продолжен, верните проект в работу.
                  </p>

                  <p>
                    <span>Инициатор закрытия:&nbsp;</span
                    >{{
                      report.closedByFio + ', ' + report.roleText.toLowerCase()
                    }}
                  </p>

                  <p>
                    <span>Основание для закрытия:&nbsp;</span
                    >{{ report.closeReason }}
                  </p>

                  <p>
                    <span>Комментарий:&nbsp;</span>{{ report.closeComment }}
                  </p>
                </a-col>

                <a-col class="administrator-report-buttons">
                  <a-row :gutter="[10, 0]">
                    <a-col>
                      <c-button-danger @click="() => (showForm = true)">
                        Вернуть в работу
                      </c-button-danger>
                    </a-col>
                    <a-col>
                      <c-button-primary
                        @click="handleApproval"
                        :loading="isLoading"
                      >
                        Закрыть проект
                      </c-button-primary>
                    </a-col>
                  </a-row>
                </a-col>
              </a-row>
            </a-form-item>
          </a-form>

          <a-divider class="report-divider" />
        </div>
        <div>
          <slot>
            <h3>Отчет</h3>
            <h4 class="disabled">Результат *</h4>
          </slot>
          <a-textarea
            v-model:value="report.result"
            placeholder="Опишите полученный результат"
            :autoSize="{ minRows: 8, maxRows: 8 }"
            style="padding: 10px"
            disabled
          />
          <div class="attachments">
            <attachments-input
              :init-attachments="report.attachments"
              :is-editable="false"
            />
          </div>
        </div>
      </div>
      <c-drawer v-model:open="showForm" destroyOnClose>
        <component
          :is="AdministratorReportRejectForm"
          :project-approval-id="model.approval.id"
          @close="() => (showForm = !showForm)"
          @update="updateOnReject"
        />
      </c-drawer>
    </administrator-project-layout>
  </a-spin>
</template>

<script setup>
  import { onMounted, reactive, ref } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useApi } from '@/api/use-api';
  import AdministratorProjectLayout from '../components/AdministratorProjectLayout.vue';
  import ProgressBar from '@/components/projects/management/ProgressBar';
  import AttachmentsInput from '@/components/attachments/AttachmentsInput.vue';
  import CButtonPrimary from '@/components/UI/CButtonPrimary';
  import CButtonDanger from '@/components/UI/CButtonDanger';
  import CDrawer from '@/components/UI/CDrawer';
  import { useCheckpoints } from '@/composables/taskCount';
  import AdministratorReportRejectForm from './AdministratorReportRejectForm';
  import ErrorsHandler from '@/components/form/errors-handler';
  import { PROJECT_STATUS_ACCEPTANCE } from '@/api/models/ProjectStatus';

  const api = useApi();
  const route = useRoute();
  const router = useRouter();
  const emit = defineEmits(['update']); // Parent update

  const model = reactive({
    id: null,
    number: '',
    name: 'Новый проект',
    description: '',
    projectType: '',
    requestId: null,
    status: {
      type: 'draft',
      text: 'Черновик',
    },
    projectStatusId: null,
    approval: null,
  });
  const report = reactive({
    closedByFio: '',
    roleText: '',
    closeReason: '',
    closeComment: '',
    result: '',
    attachments: [],
  });
  const checkpoints = ref([]);
  const isLoading = ref(true);
  const showForm = ref(false);

  const errors = new ErrorsHandler({
    approval: '',
  });

  onMounted(async () => {
    await getModel();
    await getCheckpoints();
    await getReport();
    isLoading.value = false;
  });

  const getReport = async () => {
    try {
      const response = await api.projectReport.view(route.params.id);
      report.closedByFio = response.report.closedByFio;
      report.roleText = response.report.roleText;
      report.closeReason = response.report.closeReason;
      report.closeComment = response.report.closeComment;
      report.result = response.report.result;
      report.attachments = response.report.attachments;
    } catch (error) {
      console.error('error', error);
    }
  };

  const getModel = async () => {
    try {
      const response = await api.administratorPage.getProject(route.params.id);
      model.name = response.project.name;
      model.number = response.project.id;
      model.id = response.project.id;
      model.type = response.project.type;
      model.requestId = response.project.requestId;
      model.status = response.project.status;
      model.projectStatusId = response.project.projectStatusId;
      model.approval = response.project.approval;
    } catch (error) {
      console.error('error', error);
    }
  };

  const getCheckpoints = async () => {
    try {
      const response = await api.projectManagementPage.tasks(
        new URLSearchParams({ projectId: route.params.id })
      );
      checkpoints.value = useCheckpoints(response.tasks, null, null);
    } catch (error) {
      console.error('error', error);
    }
  };

  const handleApproval = async () => {
    try {
      isLoading.value = true;
      errors.clearErrors();
      await api.administratorPage.approveProject({
        projectApprovalId: model.approval.id,
      });
      emit('update');
      await router.push({
        path: '/administrator/project/' + route.params.id + '/agreement',
      });
    } catch (error) {
      errors.handleApiErrors(error);
    } finally {
      isLoading.value = false;
    }
  };

  const updateOnReject = async () => {
    emit('update');
    await router.push(`/administrator/project/` + model.id + `/form`);
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  * {
    margin: 0;
    padding: 0;
  }

  .ant-input {
    font-family: 'Nunito Sans', sans-serif;
    &[disabled] {
      color: $color-text-primary;
    }
  }

  .administrator-project__common-progress {
    margin-top: 42px;
  }

  .project-report {
    margin-top: 10px;
    max-width: 902px;
    padding-bottom: 100px;

    h3 {
      font-size: 18px;
      margin-bottom: 10px;
    }

    h4 {
      font-size: 14px;
      margin: 20px 0 6px;

      &.disabled {
        color: $color-bg-status-new !important;
      }
    }

    .description {
      font-family: 'Nunito Sans', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      max-width: 678px;
    }

    .report-divider {
      margin: 40px 0;
    }

    .administrator-report {
      background-color: $color-bg-secondary-grey;
      margin-top: 30px;
      min-height: 207px;
      padding: 20px;

      .administrator-report-title {
        h4 {
          font-size: 16px;
          font-weight: 700;
          line-height: 22px;
          margin: 0;
        }
      }

      .administrator-report-info {
        color: $color-bg-status-new;
        display: flex;
        flex-direction: column;
        gap: 18px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-top: 20px;
        width: 785px;

        span {
          font-weight: 700;
        }
      }

      .administrator-report-buttons {
        margin-top: 40px;
      }
    }

    .attachments {
      margin-top: 14px;
    }

    .actions {
      text-align: right;
      margin-top: 26px;
    }
  }
</style>
