import { create, read, update } from '@/api/rest';

const mainPage = {
  index: async () => await read('/main-page'),
  view: async (id, inn) => await read('/main-page/' + id + (inn ? `?inn=${inn}` : '')),
  saveRequest: async (data) => await create('/main-page/save-request', data),
  userData: async () => await read('/main-page/user-data'),
  requestData: async (inn) => await read('/main-page/request-data?inn=' + inn),
  updateUserData: async (data) => await update('/main-page/update-user-data', data),
  updateRequest: async (id, data) =>
    await update('/main-page/update-request?id=' + id, data),
};

export default mainPage;
