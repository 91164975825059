<template>
  <div class="input-tags">
    <a-input
      v-model:value="newTag"
      :disabled="disabled"
      @pressEnter="onPressEnter"
      @blur="onPressEnter"
    />
    <div class="input-tags__list" v-if="tags.length">
      <div class="tags">
        <span
          :class="`tag${disabled ? '' : ' tag-enabled'}`"
          v-for="(tag, index) in tags"
          :key="`tag-` + index"
          @click="removeTag(tags, tag)"
        >
          #{{ tag }}
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';

  const props = defineProps({
    tags: {
      type: Array,
      default: function () {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  });

  const newTag = ref('');
  const emit = defineEmits(['update']);

  const removeTag = (tags, value) => {
    const newTags = tags.filter((tag) => {
      return tag !== value;
    });
    emit('update', newTags);
  };

  const onPressEnter = (event) => {
    event.preventDefault();
    if (
      !props.tags.find((tag) => tag === newTag.value) &&
      newTag.value.trim() !== ''
    ) {
      const newTags = [...props.tags, newTag.value];
      emit('update', newTags);
    }
    newTag.value = '';
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  .input-tags {
    .input-tags__list {
      border-radius: 7px;
      background: $color-bg-secondary-grey;
      padding: 14px;
      margin-top: 14px;
    }

    .tags {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0 -2px;

      .tag {
        color: $color-text-secondary-1;
        background: $color-bg-primary-white;
        text-align: center;
        font-size: 12px;
        line-height: 1;
        border-radius: 30px;
        padding: 6px 10px;
        margin: 0 2px 4px 2px;
        white-space: nowrap;
        cursor: pointer;

        &:hover {
          color: $color-text-secondary-white;
          background: $color-tag-hover;

          &:after {
            background: url('./assets/cross-white.svg') no-repeat center center
              transparent;
          }
        }
      }

      .tag-enabled {
        &:after {
          content: '';
          display: inline-block;
          background: url('./assets/cross-black.svg') no-repeat center center
            transparent;
          width: 10px;
          height: 10px;
          margin: 0 0 0 10px;
        }
      }
    }
  }
</style>
