<template>
  <a :href="url" target="_blank" rel="noopener noreferrer" v-if="url">
    <div class="sidebar-menu-item">
      <img class="sidebar-menu-item__icon" :src="icon[type]" alt="" />
      <span class="sidebar-menu-item__text">{{ text }}</span>
    </div>
  </a>

  <router-link
    :to="{ name: !disabled ? path : '', hash: hash }"
    :class="{ disabled }"
    v-else
  >
    <div
      class="sidebar-menu-item"
      :class="{ active: active, submenu: isSubmenuItem }"
      @click="onClick"
    >
      <img
        class="sidebar-menu-item__icon"
        :class="{ submenu: isSubmenuItem }"
        :src="itemIcon"
        alt=""
      />
      <span
        class="sidebar-menu-item__text"
        :class="{ submenu: isSubmenuItem }"
        >{{ text }}</span
      >
      <div v-if="!isSubmenu">
        <c-badge :count="count" v-if="count > 0" />
      </div>
      <div v-else>
        <img
          v-if="isCollapsed"
          class="sidebar-submenu-item__collapse_icon"
          :class="{ submenu: isSubmenuItem }"
          :src="ChevronDownIcon"
          alt=""
        />
        <img
          v-else
          class="sidebar-submenu-item__collapse_icon"
          :class="{ submenu: isSubmenuItem }"
          :src="ChevronUpIcon"
          alt=""
        />
      </div>
    </div>
  </router-link>

  <div v-if="isSubmenu && !isCollapsed">
    <SidebarMenuItem
      v-for="item in submenu"
      :type="item.type"
      :text="item.text"
      :path="item.path"
      :hash="item.hash"
      :key="item.id"
      :count="item.count ? item.count.toString() : ''"
      :url="item?.url"
      :submenu="item.submenu"
      :is-submenu-item="true"
      :disabled="item.disabled"
    />
  </div>
</template>

<script setup>
  import { computed, reactive, ref, watch } from 'vue';
  import CBadge from '@/components/UI/CBadge';
  import ProjectsIcon from '../../assets/projects.svg';
  import DocsIcon from '../../assets/docs.svg';
  import ReportIcon from '../../assets/report.svg';
  import VacansiesIcon from '../../assets/vacansies.svg';
  import RequestsIcon from '../../assets/requests.svg';
  import InfoIcon from '../../assets/collections.svg';
  import PortfolioIcon from '../../assets/portfolio.svg';
  import ChevronUpIcon from '../../assets/chevron-up.svg';
  import ChevronDownIcon from '../../assets/chevron-down.svg';
  import DotIcon from '../../assets/dot.svg';
  import DotFillIcon from '../../assets/dot-filled-green.svg';
  import { useRoute } from 'vue-router';

  const route = useRoute();

  const props = defineProps({
    type: String,
    text: String,
    path: String,
    hash: String,
    count: String,
    url: String,
    submenu: {
      type: Array,
      default: () => [],
    },
    isSubmenuItem: {
      type: Boolean,
      default: false,
    },
    disabled: Boolean,
  });

  const isCollapsed = ref(true);

  const icon = reactive({
    request: RequestsIcon,
    tasks: RequestsIcon,
    projects: ProjectsIcon,
    vacancies: VacansiesIcon,
    report: ReportIcon,
    docs: DocsIcon,
    info: InfoIcon,
    portfolios: PortfolioIcon,
    showcases: RequestsIcon,
  });

  const active = computed(() => {
    return (
      props.type === route.meta.type && //!props.isSubmenuItem
      (props.isSubmenuItem ? props.hash === route.hash : true)
    );
  });

  const isSubmenu = computed(() => props.submenu.length > 0);

  const itemIcon = computed(() => {
    if (props.isSubmenuItem) {
      return active.value ? DotFillIcon : DotIcon;
    } else {
      return props.type ? icon[props.type] : null;
    }
  });

  watch(active, (newValue) => {
    if (!newValue) {
      isCollapsed.value = true;
    }
  });

  const onClick = () => {
    if (isSubmenu.value) isCollapsed.value = !isCollapsed.value;
  };
</script>

<style lang="scss" scoped>
  @import '@/components/sidebar/SideBar';
  @import 'SidebarMenuItem';
</style>
