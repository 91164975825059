import PerformerProjects from '@/views/performer/PerformerProjects.vue';
import PerformerProject from '@/views/performer/form/PerformerProjectForm.vue';
import PerformerProjectTasks from '@/views/performer/tasks/PerformerProjectTasks.vue';
import PerformerTasks from '@/views/performer/PerformerTasks.vue';

export const performerRoutes = [
  {
    path: '/performer/tasks',
    name: 'performer-tasks',
    component: PerformerTasks,
    meta: { requiresAuth: true, type: 'tasks', role: 'performer' },
  },
  {
    path: '/performer/project/:id/tasks',
    name: 'performer-project-tasks',
    component: PerformerProjectTasks,
    meta: { requiresAuth: true, type: 'projects', role: 'performer' },
  },
  {
    path: '/performer/projects',
    name: 'performer-projects',
    component: PerformerProjects,
    meta: {
      requiresAuth: true,
      type: 'projects',
      role: 'performer',
      default: true,
    },
  },
  {
    path: '/performer/project/:id/form',
    name: 'performer-project-form',
    component: PerformerProject,
    meta: { requiresAuth: true, type: 'projects', role: 'performer' },
  },
];
