<template>
  <a-spin
    :spinning="isLoading"
    class="custom-spinner"
    style="height: 100vh; top: 50%"
  >
    <main-title>Запросы</main-title>
    <horizontal-filter>
      <template #default>
        <filter-item hash="" text="Новые запросы" />
        <filter-item hash="#inwork" text="В работе" />
        <filter-item hash="#archive" text="Архив" />
      </template>
      <template #right>
        <label class="panel__total-requests" v-if="totalRequest">
          Всего: {{ totalRequest }}
        </label>
      </template>
    </horizontal-filter>
    <requests-table :requests="requests" v-if="route.hash === ''" />
    <requests-table
      :requests="requests"
      :inwork="true"
      v-if="route.hash === '#inwork'"
    />
    <requests-table :requests="requests" v-if="route.hash === '#archive'" />
  </a-spin>
</template>

<script setup>
  import { onMounted, ref, watch } from 'vue';
  import { useApi } from '@/api/use-api';
  import { useRoute } from 'vue-router';
  import MainTitle from '@/components/titles/MainTitle.vue';
  import HorizontalFilter from '@/components/filter/horizontal/HorizontalFilter.vue';
  import FilterItem from '@/components/filter/components/FilterItem.vue';
  import RequestsTable from './components/RequestsTable.vue';

  const api = useApi();
  const route = useRoute();
  const isLoading = ref(false);
  const totalRequest = ref(0);
  const requests = ref([]);

  onMounted(() => {
    getRequests();
  });

  watch(route, () => {
    getRequests();
  });

  const getRequests = async () => {
    try {
      isLoading.value = true;
      requests.value = await filterRequestsByGroup(
        route.hash ? route.hash.replace('#', '') : 'new'
      );
    } catch (error) {
      console.error('error', error);
    } finally {
      isLoading.value = false;
    }
  };

  const filterRequestsByGroup = async (group) => {
    switch (group) {
      case 'new': {
        const response = await api.administratorPage.index();
        const filteredRequests = response.requests.filter(
          (request) =>
            request.status.type === 'new' || request.status.type === 'in_queue'
        );
        totalRequest.value = filteredRequests.length;
        return filteredRequests;
      }

      case 'in_work':
      case 'inwork': {
        const response = await api.administratorPage.index();
        const filteredRequests = response.requests.filter(
          (request) => request.status.type === 'in_work'
        );
        totalRequest.value = filteredRequests.length;
        return filteredRequests;
      }

      case 'archive': {
        const response = await api.administratorPage.archive();
        totalRequest.value = response.requests.length;
        return response.requests;
      }
    }
  };
</script>

<style lang="scss" scoped></style>
