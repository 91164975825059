<template>
  <portal-layout class="persons-page">
    <template v-slot:header>
      <main-title>
        <template v-slot:default>СОТРУДНИКИ</template>
        <template v-slot:description v-if="total">
          {{ totalPersonsString() }} в НГУ
        </template>
      </main-title>
      <div class="persons-page__search">
        <input-search
          placeholder="Ищите подразделения, сотрудников, публикации и многое другое"
        />
      </div>
    </template>
    <template v-slot:default>
      <white-block>
        <content-container class="content-padding pt-40 pb-40">
          <a-row justify="space-between" :wrap="false">
            <a-col class="sorting">
              <span class="sorting__label">Сортировать</span>
              <a-select :options="sortingList" v-model:value="sorting" />
            </a-col>
            <a-col>
              <persons-filter />
            </a-col>
          </a-row>
        </content-container>
        <a-spin :spinning="isLoading" />
      </white-block>

      <white-block>
        <content-container class="content-padding">
          <a-row :gutter="[20, 20]" class="employees">
            <a-col
              :sm="24"
              :md="12"
              :lg="6"
              v-for="person in persons"
              :key="`person-` + person.id"
            >
              <person-card
                :id="person.id"
                :name="[person.lastName, person.firstName, person.middleName]"
                :avatar="person.photo"
                :employments="person.employments"
              />
            </a-col>
          </a-row>

          <div v-if="total > persons.length">
            <a-pagination
              v-model:current="currentPage"
              v-model:pageSize="pageSize"
              :total="total"
              :show-size-changer="false"
            >
              <template #itemRender="{ type, originalElement }">
                <pagination-arrows
                  :type="type"
                  :original-element="originalElement"
                />
              </template>
            </a-pagination>
          </div>
        </content-container>
      </white-block>
    </template>
  </portal-layout>
</template>

<script setup>
  import { onMounted, ref, watch} from 'vue';
  import {useRoute, useRouter} from "vue-router";
  import {useApi} from '@/api/use-api';

  import WhiteBlock from '@/views/portal/components/WhiteBlock.vue';
  import PersonCard from '@/views/portal/components/cards/PersonCard.vue';
  import ContentContainer from '@/views/portal/components/ContentContainer.vue';
  import PaginationArrows from '@/views/portal/components/UI/PaginationArrows.vue';
  import PortalLayout from '@/layouts/PortalLayout.vue';
  import InputSearch from '@/views/portal/components/UI/InputSearch.vue';
  import MainTitle from '@/views/portal/components/MainTitle.vue';
  import pluralize from 'pluralize-ru';
  import PersonsFilter from "@/views/portal/components/persons/PersonsFilter.vue";

  const isLoading = ref(true);
  const api = useApi();
  const route = useRoute();
  const router = useRouter();
  const persons = ref([]);
  const sorting = ref('last_name');
  const currentPage = ref(1);
  const pageSize = ref(16);
  const total = ref(0);
  const sortingList = [
    { value: 'last_name', label: 'В алфавитном порядке а-я' },
    { value: '-last_name', label: 'В обратном порядке я-а' },
  ];


  const getPersons = async () => {
    try {
      isLoading.value = true;
      const response = await api.person.index(
        new URLSearchParams({
          isEmployee: true,
          page: currentPage.value,
          'per-page': pageSize.value,
          sort: sorting.value,
        })
      );
      persons.value = response.persons;
      total.value = response.total;
    } catch (error) {
      console.error('error', error);
    } finally {
      isLoading.value = false;
    }
  };

  const totalPersonsString = () =>
    pluralize(
      total.value,
      '%d сотрудников',
      '%d сотрудник',
      '%d сотрудника',
      '%d сотрудников'
    );

  const prepareUrl = async () => {
    if (route.query.page) {
      currentPage.value = parseInt(route.query.page);
    }
    // if (route.query['per-page']) {
    //   pageSize.value = route.query['per-page'];
    // }
    if (route.query.sort) {
      sorting.value = route.query.sort;
    }
  }

  onMounted(() => {
    prepareUrl();
    getPersons();
  });

  watch(sorting, () => {
    router.push({
      name: 'portal-persons-page',
      params: {},
      query: {
        ...route.query,
        sort: sorting.value,
      }
    });
  });

  watch(currentPage, () => {
    router.push({
      name: 'portal-persons-page',
      params: {},
      query: {
        ...route.query,
        page: currentPage.value,
      }
    });
  });

  watch(pageSize, () => {
    router.push({
      name: 'portal-persons-page',
      params: {},
      query: {
        ...route.query,
        page: '1',
        'per-page': pageSize.value,
      }
    });
  });

  watch(() => route.query, () => {
    prepareUrl();
    getPersons();
  });
</script>

<style lang="scss" scoped>
  .sorting {
    .sorting__label {
      color: #718096;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      margin-right: 15px;
    }
  }

  .persons-page {
    .persons-page__search {
      margin-top: 80px;
      margin-bottom: 40px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
</style>
