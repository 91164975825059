<template>
  <h3>Шаг 7. Портфели и показатели</h3>
  <p>Выберите портфели, в рамках которых планируется реализация проекта.</p>

  <a-divider />

  <div>
    <a-form
      class="form leader-project-form"
      layout="vertical"
      :model="model"
      :rules="rules"
      @validate="errors.onValidate"
      @finish="onFinish"
    >
      <template
        v-for="(portfolio, index) in model.projectPortfolios"
        :key="`portfolio-${index}`"
      >
        <portfolio-input-card
          ref="portfolioRefs"
          :show-available="model.isExternal"
          :index="index"
          :portfolio="portfolio"
          :portfolio-options="model.portfolioOptions"
          :disabled="disabled"
          @submit-result="onSubmitResult"
          @remove="removePortfolio"
          @portfolio-select="onPortfolioSelect"
        />
      </template>

      <leader-project-form-row v-if="!disabled" :show-available="false">
        <a-form-item>
          <c-button-add @click="addEmptyPortfolio">
            Добавить портфель
          </c-button-add>
        </a-form-item>
      </leader-project-form-row>

      <br /><br />
      <leader-project-form-row :show-available="false">
        <leader-project-form-buttons
          v-if="project?.isMyProject"
          :project-status-id="project?.projectStatusId"
          :loading="isLoading"
          @click="setGoNext"
          @confirm-update="() => emit('confirm-update')"
        />
      </leader-project-form-row>
    </a-form>
  </div>
</template>

<script setup>
  import { onMounted, reactive, ref, watch } from 'vue';
  import { useApi } from '@/api/use-api';
  import LeaderProjectFormRow from '@/views/leader/projects/form/LeaderProjectFormRow.vue';
  import ErrorsHandler from '@/components/form/errors-handler';
  import LeaderProjectFormButtons from '@/views/leader/projects/form/LeaderProjectFormButtons.vue';
  import rules from './rules/RulesStep7';
  import { useRoute, useRouter } from 'vue-router';
  import PortfolioInputCard from '@/views/leader/projects/form/steps/inputs/PortfolioInputCard.vue';
  import CButtonAdd from '@/components/UI/CButtonAdd.vue';

  const router = useRouter();
  const route = useRoute();
  const api = useApi();
  const emit = defineEmits(['update', 'confirm-update', 'update-type']);
  const portfolioRefs = ref([]);
  const submitResult = ref([]);
  const isLoading = ref(false);

  const props = defineProps({
    project: {
      type: Object,
      require: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  });

  const model = reactive({
    isExternal: false,
    projectPortfolios: [],
    portfolioOptions: [],
  });

  const errors = new ErrorsHandler({});

  onMounted(async () => {
    fillModel();
  });

  watch(
    () => portfolioRefs.value.length,
    (newValue) => {
      if (newValue === 0) {
        addEmptyPortfolio();
      }
    }
  );

  watch(
    submitResult,
    async () => {
      // Check if all submits have been received
      if (submitResult.value.every((item) => item !== null)) {
        await checkSubmitResult();
      }
    },
    { deep: true }
  );

  const fillModel = () => {
    try {
      model.isExternal = props.project.isExternal;
      fillPortfolios();
    } catch (error) {
      console.error('error', error);
    }
  };

  const fillPortfolios = () => {
    try {
      model.projectPortfolios = props.project.briefcases
        .filter((item) => item.isSelected)
        .map((item) => ({
          id: item.id,
          isAvailable: item.isAvailable,
          briefcaseProjectId: item.briefcaseProjectId,
          indexes: [],
        }));

      if (model.projectPortfolios.length === 0) {
        addEmptyPortfolio();
      }

      model.portfolioOptions = props.project.briefcases.map((item) => {
        return {
          value: parseInt(item.id),
          label: item.name,
          ...item,
        };
      });
    } catch (error) {
      console.error(error);
    }
  };

  const goNext = ref(false);
  const setGoNext = (value) => {
    goNext.value = !!value;
  };

  const deselectPortfolioId = (portfolioId) =>
    updatePortfolioOptions(portfolioId, false);

  const selectPortfolioId = (portfolioId) =>
    updatePortfolioOptions(portfolioId, true);

  const updatePortfolioOptions = (portfolioId, isSelected) => {
    model.portfolioOptions.forEach((item, index) => {
      if (item.id === portfolioId) {
        model.portfolioOptions[index].isSelected = isSelected;
      }
    });
  };

  const addEmptyPortfolio = () => {
    model.projectPortfolios.push({
      id: null,
      isAvailable: true,
      briefcaseProjectId: null,
      indexes: [],
    });
  };

  const removePortfolio = async ({ index }) => {
    const portfolioId = model.projectPortfolios[index].id;
    model.projectPortfolios.splice(index, 1);
    deselectPortfolioId(portfolioId);
  };

  const onPortfolioSelect = ({ index, newPortfolioId }) => {
    const oldPortfolioId = model.projectPortfolios[index].id;

    if (newPortfolioId === oldPortfolioId) return;

    model.projectPortfolios[index].id = newPortfolioId;
    deselectPortfolioId(oldPortfolioId);
    selectPortfolioId(newPortfolioId);
  };

  const onFinish = async () => {
    isLoading.value = true;
    submitResult.value = new Array(portfolioRefs.value.length).fill(null);
    for (let i = 0; i < portfolioRefs.value.length; i++) {
      await portfolioRefs.value[i].submit();
    }
  };

  const checkSubmitResult = async () => {
    const formsSubmitResult = submitResult.value.every((item) => item === true);
    if (formsSubmitResult) {
      await onFinisSuccess();
    } else {
      isLoading.value = false;
    }
  };

  const onFinisSuccess = async () => {
    const briefcaseProjectList = model.projectPortfolios.map((item) => ({
      id: item.briefcaseProjectId,
      briefcaseId: item.id,
      isAvailable: item.isAvailable,
      indexes: item.indexes,
    }));
    try {
      await api.briefcase.saveManyProjectBriefcases({
        projectId: route.params.id,
        briefcases: briefcaseProjectList,
      });

      emit('update', model);
      if (goNext.value) {
        await router.push({ hash: '#8' });
      }
    } catch (error) {
      errors.handleApiErrors(error);
      console.error('error', error);
    } finally {
      isLoading.value = false;
    }
  };

  const onSubmitResult = ({
    index,
    portfolioId,
    isAvailable,
    indexes,
    formResult,
  }) => {
    model.projectPortfolios[index].id = portfolioId;
    model.projectPortfolios[index].isAvailable = isAvailable;
    model.projectPortfolios[index].indexes = indexes;
    submitResult.value[index] = formResult;
  };

  defineExpose({ onFinish });
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  :deep(.ant-input) {
    font-family: 'Nunito Sans', sans-serif;
    &[disabled] {
      color: $color-text-primary;
    }
  }
</style>
