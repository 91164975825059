<template>
  <project-manager-layout :spinning="isLoading">
    <leader-project-layout :model="model">
      <template #underInfo>
        <div class="leader-project__common-progress" v-if="checkpoints.length">
          <progress-bar :items="checkpoints" showPercent />
        </div>
      </template>
      <!-- <template> -->
      <div class="project-management__tasks">
        <task-card
          v-for="(task, index) in tasks"
          :key="index++"
          :task="{ number: index++, ...task }"
          :disabled="disabled"
          :show-availability="model.isExternal"
          @update="updateTasks"
          @change="changeVisibility(task)"
        />
      </div>
      <!-- </template> -->
      <c-drawer v-model:open="isShowForm" destroyOnClose>
        <component
          :is="AddTask"
          @close="handleCloseForm"
          @update="updateTasks"
        />
      </c-drawer>
      <div
        v-if="!disabled"
        style="cursor: pointer; position: fixed; bottom: 0; right: 0"
        @click="handleShowForm"
      >
        <img src="@/assets/icons/circle-plus-fill.svg" alt="" />
      </div>
    </leader-project-layout>
  </project-manager-layout>
</template>

<script setup>
  import { computed, onMounted, reactive, ref } from 'vue';
  import { useRoute } from 'vue-router';
  import { useApi } from '@/api/use-api';
  import ProjectManagerLayout from '@/layouts/ProjectManagerLayout.vue';
  import LeaderProjectLayout from '@/views/leader/projects/components/LeaderProjectLayout.vue';
  import ProgressBar from '@/components/projects/management/ProgressBar';
  import TaskCard from './Task/TaskCard';
  import AddTask from './Task/AddTask';
  import CDrawer from '@/components/UI/CDrawer';
  import {
    PROJECT_STATUS_ACCEPTANCE,
    PROJECT_STATUS_CLOSED,
  } from '@/api/models/ProjectStatus';

  const api = useApi();
  const route = useRoute();
  const isShowForm = ref(false);
  const model = reactive({
    id: null,
    projectStatusId: null,
    isExternal: false,
    isMyProject: false,
    number: '',
    name: '',
    description: '',
    projectType: '',
    requestId: null,
    status: null,
  });
  const tasks = ref([]);
  const checkpoints = ref([]);
  const isLoading = ref(true);

  const disabled = computed(() => {
    return [PROJECT_STATUS_ACCEPTANCE, PROJECT_STATUS_CLOSED].includes(
      model.projectStatusId
    );
  });

  onMounted(async () => {
    await getModel();
    await getTasks();
    isLoading.value = false;
  });

  const getModel = async () => {
    try {
      const response = await api.leaderPage.view(route.params.id);
      model.name = response.project.name;
      model.number = response.project.id;
      model.id = response.project.id;
      model.type = response.project.type;
      model.projectStatusId = response.project.projectStatusId;
      model.isExternal = response.project.isExternal;
      model.isMyProject = response.project.isMyProject;
      model.requestId = response.project.requestId;
      model.status = response.project.status;
    } catch (error) {
      console.error('error', error);
    }
  };

  const getTasks = async () => {
    try {
      const response = await api.projectManagementPage.tasks(
        new URLSearchParams({ projectId: route.params.id })
      );
      tasks.value = response.tasks;
      getAllCheckpoints(tasks.value);
    } catch (error) {
      console.error('error', error);
    }
  };

  const getAllCheckpoints = (tasks) => {
    const temp = [];

    tasks.map((task) => {
      task.checkpoints.map((checkpoint) => temp.push(checkpoint));

      if (task.subtasks) {
        task?.subtasks.map((subtask) => {
          subtask?.checkpoints.map((checkpoint) => temp.push(checkpoint));
        });
      }
    });

    checkpoints.value = temp;
  };

  const updateTasks = () => {
    getTasks();
  };

  const changeVisibility = async (task) => {
    let isAvailable = Boolean(task.visibility);

    await api.projectManagementPage.saveTask({
      id: task.id,
      projectId: task.projectId,
      userId: task.userId,
      description: task.name,
      parentId: task.parentId,
      isAvailable: !isAvailable,
    });

    // TODO - update from backend only for updated task. Or don't update and save result at frontend.
    updateTasks();
  };

  const handleShowForm = () => (isShowForm.value = true);
  const handleCloseForm = () => (isShowForm.value = false);
</script>

<style lang="scss" scoped>
  @import 'leader-project-management';
</style>
