import {read} from "@/api/rest";

const user = {
  /**
   * @deprecated Use me.index instead of this method.
   */
  index: async () => await read('/user'),
  /**
   * @deprecated Use me.role instead of this method.
   */
  roles: async () => await read('/user/roles'),
  /**
   * @param query URLSearchParams
   * @returns {Promise<*>}
   */
  executors: async (query) => await read(`/user/executor?${query.toString()}`),
  /**
   * @param query
   * @returns {Promise<*>}
   */
  leaders: async (query) => await read(`/user/leader?${query.toString()}`),
};

export default user;