<template>
    <a-input
      class="search-input"
      :placeholder="placeholder"
      v-model:value="searchString"
      @keyup.enter="runSearching(page)"
    />
</template>

<script setup>
import {onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";

const route = useRoute();
const router = useRouter();

let searchString = ref("");

defineProps({
  page: {
    type: String,
    default: 'search',
  },
  placeholder: {
    type: String,
    default: "Поиск...",
  },
});

const runSearching = (page) => {
  console.log(searchString);
  if (!searchString.value) return;
  router.push({
    path: '/portal/' + page,
    query: {
      ...route.query,
      q: searchString.value,
      page: 1,
      'per-page': 10
    }
  });
}

onMounted(() => {
  searchString.value = route.query.q ?? '';
});
</script>

<style lang="scss" scoped>
.search-input {
  width: 100%;
  max-width: 555px;
  background: url("assets/search.svg") no-repeat 14px center #f2f4f8;
  padding-left: 55px;

  &.dark {
    background-color: #383D4F;
    border-color: #383D4F;
    color: #BABBC2;

    &::placeholder {
      color: #BABBC2;
    }
  }
}
</style>
