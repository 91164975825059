<template>
  <h3>Шаг 3. Календарный план-график</h3>
  <p>
    Проверьте информацию в карточке проекта и, если согласны, согласуйте проект.
  </p>
  <div>
    <a-form class="form administrator-project-form" layout="vertical">
      <administrator-project-form-row :show-available="false" class="bordered">
        <a-row>
          <a-col :span="12">
            <a-form-item name="startDate" label="Старт проекта">
              <a-date-picker
                v-model:value="model.startDate"
                placeholder=""
                :locale="locale"
                disabled
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item name="endDate" label="Завершение проекта">
              <a-date-picker
                v-model:value="model.endDate"
                placeholder=""
                :locale="locale"
                disabled
              />
            </a-form-item>
          </a-col>
        </a-row>
      </administrator-project-form-row>
      <template v-for="(stage, index) in model.stages" :key="`stage-` + index">
        <project-stage :stage="stage" />
      </template>
    </a-form>
  </div>
</template>

<script setup>
  import { onMounted, reactive, watch } from 'vue';
  import locale from 'ant-design-vue/es/date-picker/locale/ru_RU';
  import AdministratorProjectFormRow from '../AdministratorProjectFormRow.vue';
  import dayjs from 'dayjs';
  import ProjectStage from '../../components/ProjectStage.vue';

  const props = defineProps({
    project: {
      type: Object,
      require: true,
    },
  });

  const model = reactive({
    startDate: '',
    endDate: '',
    stages: [],
  });

  onMounted(() => {
    props.project && fillModel();
  });

  watch(
    () => props.project,
    () => {
      fillModel();
    }
  );

  const fillModel = () => {
    try {
      model.startDate = props.project.startDate
        ? dayjs(props.project.startDate, 'YYYY-MM-DD')
        : '';
      model.endDate = props.project.endDate
        ? dayjs(props.project.endDate, 'YYYY-MM-DD')
        : '';
      model.stages = props.project.stages.map((stage, index) => {
        return {
          id: index + 1,
          name: stage.name,
          startDate: dayjs(stage.startDate, 'YYYY-MM-DD'),
          endDate: dayjs(stage.endDate, 'YYYY-MM-DD'),
        };
      });
    } catch (error) {
      console.error('error', error);
    }
  };
</script>

<style lang="scss" scoped>
  @import './administrator-project-form';
</style>
