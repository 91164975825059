<template>
  <h3>Шаг 4. Команда проекта</h3>
  <p>Сформируйте команду проекта.</p>
  <div>
    <a-form
      class="form leader-project-form"
      layout="vertical"
      :model="model"
      :rules="rules"
      @validate="errors.onValidate"
      @finish="onFinish"
      :scrollToFirstError="{
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      }"
    >
      <template
        v-for="(member, index) in model.members"
        :key="`members-` + index"
      >
        <team-input-row
          :show-available="model.isExternal"
          ref="memberRefs"
          :index="index"
          :max-index="model.members.length - 1"
          :data="member"
          :api-errors="memberErrors.getApiError(index)"
          :disabled="disabled"
          @update="onMemberUpdate"
          @remove="removeMember"
          class="bordered"
        />
      </template>

      <leader-project-form-row v-if="!disabled" :show-available="false">
        <a-form-item>
          <c-button-add @click="addEmptyMember"
            >Добавить сотрудника</c-button-add
          >
        </a-form-item>
      </leader-project-form-row>

      <leader-project-form-row :show-available="false">
        <leader-project-form-buttons
          v-if="project?.isMyProject"
          :project-status-id="project?.projectStatusId"
          :loading="isLoading"
          @click="setGoNext"
          @confirm-update="() => emit('confirm-update')"
        />
      </leader-project-form-row>
    </a-form>
  </div>
</template>

<script setup>
  import { onMounted, reactive, ref, watch } from 'vue';
  import { useApi } from '@/api/use-api';
  import { useRoute, useRouter } from 'vue-router';
  import LeaderProjectFormRow from '@/views/leader/projects/form/LeaderProjectFormRow.vue';
  import CButtonAdd from '@/components/UI/CButtonAdd.vue';
  import LeaderProjectFormButtons from '@/views/leader/projects/form/LeaderProjectFormButtons.vue';
  import rules from './rules/RulesStep4';
  import TeamInputRow from '@/views/leader/projects/form/steps/inputs/TeamInputRow.vue';
  import { useTableErrorHandler } from '@/composables/tableErrorHandler';
  import ErrorsHandler from '@/components/form/errors-handler';

  const router = useRouter();
  const route = useRoute();
  const api = useApi();
  const emit = defineEmits(['update', 'confirm-update']);
  const errors = new ErrorsHandler({});
  const memberRefs = ref([]);
  const isLoading = ref(false);

  const props = defineProps({
    project: {
      type: Object,
      require: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  });

  const model = reactive({
    id: null,
    isExternal: false,
    members: [],
  });

  const memberErrors = useTableErrorHandler();

  onMounted(() => {
    props.project && fillModel();
  });

  watch(
    () => props.project,
    () => {
      fillModel();
    }
  );

  watch(
    () => memberRefs.value.length,
    () => {
      validateForms();
    }
  );

  const fillModel = () => {
    try {
      model.id = props.project.id;
      model.isExternal = props.project.isExternal;

      model.members = [];
      memberErrors.reset();
      if (props.project.members.length > 0) {
        model.members = [...props.project.members];
        memberErrors.init(model.members);
      } else {
        addEmptyMember();
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  const goNext = ref(false);
  const setGoNext = (value) => {
    goNext.value = !!value;
  };

  const addEmptyMember = () => {
    model.members.push({
      id: null,
      fio: '',
      position: '',
      unit: '',
      workload: '',
      projectRole: '',
      isAvailable: true,
    });
    memberErrors.addHandler();
  };

  const removeMember = (id) => {
    model.members.splice(id, 1);
    memberErrors.removeHandler(id);
    if (model.members.length === 0) {
      addEmptyMember();
    }
  };

  const onFinish = async () => {
    isLoading.value = true;
    validateForm();
    if (memberErrors.inputsHaveErrors()) {
      isLoading.value = false;
      return;
    }

    if (await saveTeam()) {
      emit('update', model);
      if (goNext.value) {
        await router.push({ hash: '#5' });
      }
    }
    isLoading.value = false;
  };

  const saveTeam = async () => {
    memberErrors.clearApiErrors();
    // Если у последнего участника ФИО не заполнено, то мы его не записываем
    const lastMember = model.members.at(-1);
    const membersToSave = lastMember.fio
      ? model.members
      : model.members.slice(0, -1);

    try {
      await api.leaderPage.updateProjectTeam({
        projectId: route.params.id,
        payload: membersToSave,
      });
      return true;
    } catch (error) {
      if (error.tableErrors) {
        memberErrors.setTableApiErrors(error.tableErrors);
      }
      console.error('error', error);
      return false;
    }
  };

  const onMemberUpdate = (value) => {
    model.members[value.index] = {
      ...model.members[value.index],
      ...value.data,
    };
    memberErrors.setInputError(value.index, value.hasError);
  };

  /**
   * Проверяем, что у первого участника команды заполнено ФИО
   * Если нет, то устанавлеваем ошибку для первого элемента
   */
  const validateForm = () => {
    if (!model.members[0].fio) {
      memberErrors.setInputError(0, true);
      memberErrors.setApiError(0, { fio: [rules.members[0].message] });
    }
  };

  const validateForms = async () => {
    for (let i = 0; i < memberRefs.value.length; i++) {
      await memberRefs.value[i].validateAndUpdate();
    }
  };

  defineExpose({ onFinish });
</script>

<style lang="scss" scoped>
  @import './leader-project-form';
  .leader-project-form-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    & > button {
      margin-left: 10px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .form-item__label {
    padding-top: 15px;
  }

  .button-remove {
    margin-left: 10px;
  }
</style>
