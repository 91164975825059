<template>
  <a-row :span="24" >
    <a-col class="request-info__title">
      <main-title>{{ requestNumber }}</main-title>
    </a-col>
    <a-col v-if="showStatus">
      <status-label :status="request.status"/>
    </a-col>
  </a-row>
  <a-row :span="24" class="request-info__description">
    <p>{{ requestType }}</p>
  </a-row>
  <a-row
      v-if="requestDateRow"
      :span="24"
      class="request-info__request-date"
  >
    <p> {{ requestDateRow }} </p>
  </a-row>
</template>

<script setup>
  import { computed } from 'vue';
  import { useRoute } from 'vue-router';
  import MainTitle from '@/components/titles/MainTitle.vue';
  import { useStore } from "vuex";
  import StatusLabel from "@/components/projects/components/StatusLabel.vue";
  import dayjs from "dayjs";

  const route = useRoute();
  const store = useStore();

  const props = defineProps({
    request: Object,
  });

  const showStatus = computed(() => {
    return props.request?.id && props.request?.status;
  });

  const requestType = computed(() => {
    return  props.request?.typeId
        ? store.getters.requestTypeTextById(props.request.typeId)
        : store.getters.requestTypeTextByType(route.params.type);
  });

  const requestNumber = computed(() => {
    return route.name === 'new-partner-request'
        ? 'Новый запрос'
        : `Запрос № ${route.params?.id}`;
  });

  const requestDateRow = computed(() => {
    return props.request?.id && props.request?.requestDate
        ? `Дата подачи: ${dayjs(props.request.requestDate).locale("ru").format("DD MMMM YYYY")}`
        : ''
  });
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  * {
    font-family: 'Nunito Sans', sans-serif;
    margin: 0;
  }

  .request-info__title {
    margin-top: 15px;
    margin-right: 15px;

    h1 {
      font-size: 32px;
      font-weight: 700;
      line-height: 20px;
    }
  }

  .request-info__description {
    color: $color-text-secondary-grey;
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
    margin-top: 10px;
    max-width: 555px;
  }

  .request-info__request-date {
    color: $color-text-secondary-grey;
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
    margin-top: 10px;
  }
</style>
