<template>
  <div class="carousel-cards">
    <div class="carousel-cards__title">
      <h3>{{ title }}</h3>
      <button-arrow direction="left" @click="prev" />
      <button-arrow direction="right" @click="next" />
    </div>
    <div class="carousel-cards__items">
      <gray-block>
        <slot></slot>
      </gray-block>
    </div>
  </div>
</template>

<script setup>
import ButtonArrow from "@/views/portal/components/UI/ButtonArrow.vue";
import GrayBlock from "@/views/portal/components/GrayBlock.vue";

defineProps({
  title: {
    type: String,
    required: true,
    default: "",
  },
});

const prev = () => {
  console.log("prev");
};

const next = () => {
  console.log("next");
};
</script>

<style lang="scss" scoped>
.carousel-cards {
  .carousel-cards__title {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 6px;
    margin-bottom: 20px;

    & > h3 {
      margin-right: auto;
      margin-bottom: 0;
    }
  }
}
</style>
