<template>
  <a-row class="edit-company__form">
    <a-col>
      <a-row class="form__title">
        <a-col :span="24">
          <h3>Редактировать организацию</h3>
        </a-col>
      </a-row>
      <a-row class="form__fields">
        <a-form
          :model="model"
          :rules="rules"
          @validate="errors.onValidate"
          @finish="onFinish"
        >
          <a-row :gutter="[0, 14]">
            <a-col :span="24">
              <a-form-item
                name="name"
                :help="errors.getError('name')"
                :validate-status="errors.getStatus('name')"
              >
                <a-input
                  v-model:value="model.name"
                  placeholder="Название организации *"
                />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item
                name="site"
                :help="errors.getError('site')"
                :validate-status="errors.getStatus('site')"
              >
                <a-input
                  v-model:value="model.site"
                  placeholder="Сайт компании"
                />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item
                name="organizationUnit"
                :help="errors.getError('organizationUnit')"
                :validate-status="errors.getStatus('organizationUnit')"
              >
                <a-input
                  v-model:value="model.unit"
                  placeholder="Подразделение"
                />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item
                name="position"
                :help="errors.getError('position')"
                :validate-status="errors.getStatus('position')"
              >
                <a-input
                  v-model:value="model.position"
                  placeholder="Должность"
                />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item
                name="about"
                :help="errors.getError('about')"
                :validate-status="errors.getStatus('about')"
              >
                <a-textarea
                  v-model:value="model.about"
                  placeholder="О компании"
                />
              </a-form-item>
            </a-col>
            <attachments-input
              :error="errors.getError('attachmentUids')"
              :init-attachments="props.item.attachments"
              :is-editable="true"
              :accept="ATTACHMENT_COMMON_EXTENSIONS"
              @update="handleUpdateAttachments"
            />
            <a-col :span="24">
              <a-row justify="end">
                <a-form-item class="form__button">
                  <c-button-primary html-type="submit"
                    >Сохранить</c-button-primary
                  >
                </a-form-item>
              </a-row>
            </a-col>
          </a-row>
        </a-form>
      </a-row>
    </a-col>
  </a-row>
</template>

<script setup>
  import { onMounted, reactive } from 'vue';
  import { useApi } from '@/api/use-api';
  import CButtonPrimary from '@/components/UI/CButtonPrimary';
  import ErrorsHandler from '@/components/form/errors-handler';
  import AttachmentsInput from '@/components/attachments/AttachmentsInput.vue';
  import { ATTACHMENT_COMMON_EXTENSIONS } from '@/components/attachments/AttachmentExtentions';

  const props = defineProps({
    item: Object,
  });

  const model = reactive({
    name: '',
    site: '',
    unit: '',
    position: '',
    about: '',
    attachments: [],
  });

  const rules = {
    name: [
      {
        required: true,
        min: 2,
        max: 255,
        message: 'Необходимо ввести название организации, не менее 2 символов',
        trigger: 'blur',
      },
    ],
  };

  const api = useApi();
  const errors = new ErrorsHandler({
    name: '',
    site: '',
    unit: '',
    position: '',
    about: '',
    organizationUnit: '',
    attachmentUids: '',
  });

  const emit = defineEmits(['close', 'update']);

  const onFinish = async () => {
    try {
      await api.profilePage.updateCompany({
        id: props.item.id,
        name: model.name,
        site: model.site,
        position: model.position,
        about: model.about,
        unit: model.unit,
        attachmentUids: model.attachmentUids,
      });

      emit('close');
      emit('update');
    } catch (error) {
      errors.handleApiErrors(error);
    }
  };

  function setData() {
    model.name = props.item.name;
    model.site = props.item.site;
    model.unit = props.item.unit;
    model.position = props.item.position;
    model.about = props.item.about;
  }

  function handleUpdateAttachments(uidList) {
    model.attachmentUids = uidList;
  }

  onMounted(() => {
    setData();
  });
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  .edit-company__form {
    padding: 112px 17px 0 17px;

    .form__title {
      h3 {
        font: 700 18px/26px 'Nunito Sans';
      }
    }

    .form__fields {
      margin-top: 35px;

      #organizationAbout {
        height: 124px;
        resize: none;
      }

      .attachments {
        display: flex;
        flex-direction: column;
        background-color: $color-bg-secondary-grey;
        border-radius: 7px;
        gap: 10px;
        height: 189px;
        flex-wrap: wrap;
        padding: 14px;
      }

      .attachment-file {
        display: block;
        font-size: 12px;
        font-weight: 700;
        line-height: 12px;
        text-align: left;
        user-select: none;
      }

      .ant-form-item {
        margin: 0;
      }

      .ant-upload-text {
        color: #aeb4dd;
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        width: 194px;
      }

      .form__button {
        button {
          margin-top: 10px;
        }
      }
    }
  }
</style>
