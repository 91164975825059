<template>
  <button type="button" class="button-collapse" @click="handleCollapse">
    <svg
      width="28"
      height="28"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="collapsed"
      :class="isCollapse ? 'active' : ''"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.5503 17L9.13604 15.5858L12.6716 12.0503L9.13604 8.51472L10.5503 7.1005L15.5 12.0503L10.5503 17ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 2 12 2 12ZM4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 4 12 4 12Z"
        fill="#00bde8"
      />
    </svg>
    <span>{{ !isCollapse ? defaultLabel : collapseLabel }}</span>
  </button>
</template>

<script setup>
  import { ref } from 'vue';

  defineProps({
    defaultLabel: String,
    collapseLabel: String,
  });

  const isCollapse = ref(false);

  const handleCollapse = () => (isCollapse.value = !isCollapse.value);
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  .button-collapse {
    padding: 0;
    background: transparent;
    border: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    cursor: pointer;

    .collapsed {
      transform: rotate(90deg);
      transition: all 0.2s ease-in;
      user-select: none;
    }

    .active {
      transform: rotate(-90deg);
      transition: all 0.2s ease-in;
    }

    & > svg {
      margin-right: 15px;
    }

    & > span {
      color: $color-link-primary;
      font-family: 'Nunito Sans', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-decoration-line: underline;
    }

    &:hover {
      & > span {
        text-decoration-line: none;
      }
    }
  }
</style>
