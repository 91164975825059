<template>
  <aside class="sidebar">
    <div class="sidebar__logo">
      <router-link :to="{ name: 'home' }">
        <img
          src="/images/logo-white.svg"
          alt="Новосибирский государственный университет"
        />
      </router-link>
    </div>
    <div class="sidebar__menu">
      <SidebarMenuItem
        v-for="item in items"
        :type="item.type"
        :text="item.text"
        :path="item.path"
        :hash="item.hash"
        :key="item.id"
        :count="item.count ? item.count.toString() : ''"
        :url="item?.url"
        :submenu="item.submenu"
        :is-submenu-item="false"
        :disabled="item.disabled"
      />
    </div>
    <div class="sidebar__support">
      <div class="sidebar__support-title">Нужна помощь?</div>
      <div class="sidebar__support-text">
        Напиши <br />
        в техподдержку
      </div>
      <c-button-secondary
        class="sidebar__support-button"
        @click="handleShowForm"
        >Написать</c-button-secondary
      >
      <div class="sidebar__support-text copyright">© 2024 ООО "ФСофт"</div>
    </div>
  </aside>
  <c-drawer v-model:open="showForm" destroyOnClose>
    <request-support-form @close="sendForm" v-if="!isSend" />
    <request-support-success v-else />
  </c-drawer>
</template>

<script setup>
  import { ref, watch } from 'vue';
  import CButtonSecondary from '@/components/UI/CButtonSecondary';
  import SidebarMenuItem from './components/SideBarMenuItem/SidebarMenuItem';
  import RequestSupportForm from './components/RequestSupportForm/RequestSupportForm';
  import RequestSupportSuccess from './components/RequestSupportForm/RequestSupportSuccess';
  import CDrawer from '@/components/UI/CDrawer.vue';

  defineProps({
    items: [Object],
  });

  const showForm = ref(false);
  const isSend = ref(false);

  const emit = defineEmits(['close']);

  watch(showForm, (currentValue) => {
    if (!currentValue) {
      isSend.value = false;
    }
  });

  function handleShowForm() {
    showForm.value = true;
  }

  function sendForm() {
    emit('close');
    isSend.value = true;
  }
</script>

<style lang="scss">
  @import 'SideBar';
</style>
