<template>
  <h3>Шаг 2. Техническое задание</h3>
  <p>Опишите техническое задание проекта.</p>
  <div>
    <a-form
      class="form leader-project-form"
      layout="vertical"
      :model="model"
      :rules="rules"
      @validate="errors.onValidate"
      @finish="onFinish"
      :scrollToFirstError="{
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      }"
    >
      <leader-project-form-row
        :show-available="model.isExternal"
        :available="model.availabilityList['description']"
        :disabled="disabled"
        @change="changeAvailability('description')"
      >
        <a-form-item
          name="description"
          label="Краткое описание проекта"
          :help="errors.getError('description')"
          :validate-status="errors.getStatus('description')"
        >
          <a-textarea
            v-model:value="model.description"
            placeholder="Опишите суть проекта"
            :rows="7"
            :disabled="disabled"
          />
        </a-form-item>
      </leader-project-form-row>

      <leader-project-form-row
        :show-available="model.isExternal"
        :available="model.availabilityList['customerProblem']"
        :disabled="disabled"
        @change="changeAvailability('customerProblem')"
      >
        <a-form-item
          name="customerProblem"
          label="Проблема/потребность заказчика"
          :help="errors.getError('customerProblem')"
          :validate-status="errors.getStatus('customerProblem')"
        >
          <a-textarea
            v-model:value="model.customerProblem"
            placeholder="Опишите потребности заказчика"
            :rows="7"
            :disabled="disabled"
          />
        </a-form-item>
      </leader-project-form-row>

      <leader-project-form-row
        :show-available="model.isExternal"
        :available="model.availabilityList['projectGoal']"
        :disabled="disabled"
        @change="changeAvailability('projectGoal')"
      >
        <a-form-item
          name="projectGoal"
          label="Цель проекта"
          :help="errors.getError('projectGoal')"
          :validate-status="errors.getStatus('projectGoal')"
        >
          <a-textarea
            v-model:value="model.projectGoal"
            placeholder="Опишите цель проекта"
            :rows="7"
            :disabled="disabled"
          />
        </a-form-item>
      </leader-project-form-row>

      <leader-project-form-row
        :show-available="model.isExternal"
        :available="model.availabilityList['projectObjectives']"
        :disabled="disabled"
        @change="changeAvailability('projectObjectives')"
      >
        <a-form-item
          name="projectObjectives"
          label="Задачи проекта"
          :help="errors.getError('projectObjectives')"
          :validate-status="errors.getStatus('projectObjectives')"
        >
          <a-textarea
            v-model:value="model.projectObjectives"
            placeholder="Опишите задачи проекта"
            :rows="7"
            :disabled="disabled"
          />
        </a-form-item>
      </leader-project-form-row>

      <leader-project-form-row
        :show-available="model.isExternal"
        :available="model.availabilityList['devObjects']"
        :disabled="disabled"
        @change="changeAvailability('devObjects')"
      >
        <a-form-item
          name="devObjects"
          label="Объекты разработки"
          :help="errors.getError('devObjects')"
          :validate-status="errors.getStatus('devObjects')"
        >
          <a-textarea
            v-model:value="model.devObjects"
            placeholder="Опишите объекты разработки"
            :rows="7"
            :disabled="disabled"
          />
        </a-form-item>
      </leader-project-form-row>

      <leader-project-form-row
        :show-available="model.isExternal"
        :available="model.availabilityList['expectedContent']"
        :disabled="disabled"
        @change="changeAvailability('expectedContent')"
      >
        <a-form-item
          name="expectedContent"
          label="Ожидаемое содержание работ"
          :help="errors.getError('expectedContent')"
          :validate-status="errors.getStatus('expectedContent')"
        >
          <a-textarea
            v-model:value="model.expectedContent"
            placeholder="Опишите ожидаемое содержание работ"
            :rows="7"
            :disabled="disabled"
          />
        </a-form-item>
      </leader-project-form-row>

      <leader-project-form-row
        :show-available="model.isExternal"
        :available="model.availabilityList['techRequirements']"
        :disabled="disabled"
        @change="changeAvailability('techRequirements')"
      >
        <a-form-item
          name="techRequirements"
          label="Технические требования к отдельным задачам"
          :help="errors.getError('techRequirements')"
          :validate-status="errors.getStatus('techRequirements')"
        >
          <a-textarea
            v-model:value="model.techRequirements"
            placeholder="Опишите технические требования к отдельным задачам"
            :rows="7"
            :disabled="disabled"
          />
        </a-form-item>
      </leader-project-form-row>

      <leader-project-form-row
        :show-available="model.isExternal"
        :available="model.availabilityList['result']"
        :disabled="disabled"
        @change="changeAvailability('result')"
      >
        <a-form-item
          name="result"
          label="Результат"
          :help="errors.getError('result')"
          :validate-status="errors.getStatus('result')"
        >
          <a-textarea
            v-model:value="model.result"
            placeholder="Опишите планируемый результат"
            :rows="7"
            :disabled="disabled"
          />
        </a-form-item>
      </leader-project-form-row>

      <leader-project-form-row
        :show-available="model.isExternal"
        :available="model.availabilityList['resultRequirements']"
        :disabled="disabled"
        @change="changeAvailability('resultRequirements')"
      >
        <a-form-item
          name="resultRequirements"
          label="Требования к представлению и оформлению результатов работ"
          :help="errors.getError('resultRequirements')"
          :validate-status="errors.getStatus('resultRequirements')"
        >
          <a-textarea
            v-model:value="model.resultRequirements"
            placeholder="Опишите требования к предоставлению и оформлению результатов работ"
            :rows="7"
            :disabled="disabled"
          />
        </a-form-item>
      </leader-project-form-row>

      <leader-project-form-row
        :show-available="model.isExternal"
        :available="model.availabilityList['otherInfo']"
        :disabled="disabled"
        @change="changeAvailability('otherInfo')"
      >
        <a-form-item
          name="otherInfo"
          label="Другая необходимая информация"
          :help="errors.getError('otherInfo')"
          :validate-status="errors.getStatus('otherInfo')"
        >
          <a-textarea
            v-model:value="model.otherInfo"
            placeholder="Внести другую необходимую информацию при необходимости"
            :rows="7"
            :disabled="disabled"
          />
        </a-form-item>
      </leader-project-form-row>

      <leader-project-form-row :show-available="false">
        <leader-project-form-buttons
          v-if="project?.isMyProject"
          :project-status-id="project?.projectStatusId"
          :loading="isLoading"
          @click="setGoNext"
          @confirm-update="() => emit('confirm-update')"
        />
      </leader-project-form-row>
    </a-form>
  </div>
</template>

<script setup>
  import { onMounted, reactive, ref, watch } from 'vue';
  import { useRouter } from 'vue-router';
  import { useApi } from '@/api/use-api';
  import ErrorsHandler from '@/components/form/errors-handler';
  import LeaderProjectFormRow from '@/views/leader/projects/form/LeaderProjectFormRow.vue';
  import LeaderProjectFormButtons from '@/views/leader/projects/form/LeaderProjectFormButtons.vue';
  import rules from './rules/RulesStep2';

  const router = useRouter();
  const api = useApi();
  const emit = defineEmits(['update', 'confirm-update']);
  const isLoading = ref(false);

  const props = defineProps({
    project: {
      type: Object,
      require: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  });

  watch(
    () => props.project,
    () => {
      fillModel();
    }
  );

  const model = reactive({
    id: '',
    isExternal: false,
    description: '',
    customerProblem: '',
    projectGoal: '',
    projectObjectives: '',
    devObjects: '',
    expectedContent: '',
    techRequirements: '',
    result: '',
    resultRequirements: '',
    otherInfo: '',
    availabilityList: [],
  });

  const fillModel = () => {
    try {
      model.id = props.project.id;
      model.isExternal = props.project.isExternal;
      model.description = props.project.description;
      model.customerProblem = props.project.customerProblem;
      model.projectGoal = props.project.projectGoal;
      model.projectObjectives = props.project.projectObjectives;
      model.devObjects = props.project.devObjects;
      model.expectedContent = props.project.expectedContent;
      model.techRequirements = props.project.techRequirements;
      model.result = props.project.result;
      model.resultRequirements = props.project.resultRequirements;
      model.otherInfo = props.project.otherInfo;
      model.availabilityList = props.project.availabilityList;
    } catch (error) {
      console.error('error', error);
    }
  };

  const errors = new ErrorsHandler({
    description: '',
    customerProblem: '',
    projectGoal: '',
    projectObjectives: '',
    devObjects: '',
    expectedContent: '',
    techRequirements: '',
    result: '',
    resultRequirements: '',
    otherInfo: '',
  });

  const goNext = ref(false);
  const setGoNext = (value) => {
    goNext.value = !!value;
  };

  const changeAvailability = (key) => {
    model.availabilityList[key] = !model.availabilityList[key];
  };

  const onFinish = async () => {
    try {
      isLoading.value = true;
      await api.leaderPage.updateProject({
        id: model.id,
        description: model.description,
        customerProblem: model.customerProblem,
        projectGoal: model.projectGoal,
        projectObjectives: model.projectObjectives,
        devObjects: model.devObjects,
        expectedContent: model.expectedContent,
        techRequirements: model.techRequirements,
        result: model.result,
        resultRequirements: model.resultRequirements,
        otherInfo: model.otherInfo,
        isDescriptionAvailable: model.availabilityList['description'],
        isCustomerProblemAvailable: model.availabilityList['customerProblem'],
        isProjectGoalAvailable: model.availabilityList['projectGoal'],
        isProjectObjectivesAvailable:
          model.availabilityList['projectObjectives'],
        isDevObjectsAvailable: model.availabilityList['devObjects'],
        isExpectedContentAvailable: model.availabilityList['expectedContent'],
        isTechRequirementsAvailable: model.availabilityList['techRequirements'],
        isResultAvailable: model.availabilityList['result'],
        isResultRequirementsAvailable:
          model.availabilityList['resultRequirements'],
        isOtherInfoAvailable: model.availabilityList['otherInfo'],
      });

      emit('update', model);
      if (goNext.value) {
        await router.push({ hash: '#3' });
      }
    } catch (error) {
      errors.handleApiErrors(error);
    } finally {
      isLoading.value = false;
    }
  };

  onMounted(() => {
    props.project && fillModel();
  });

  defineExpose({ onFinish });
</script>

<style lang="scss" scoped>
  @import './leader-project-form';
</style>
