import axios from 'axios';
import {useAxios} from '@vueuse/integrations/useAxios';
import {getAccessToken, useAuthenticated} from "@/api/authentication/token-provider";

// Create an instance of axios with the base URL read from the environment
const isAuthenticated = useAuthenticated();
const instance = axios.create({baseURL: process.env.VUE_APP_API_ROOT});

const prepareHeaders = async (headers = {}) => {
  if (isAuthenticated.value) {
    headers['Authorization'] = `Bearer ${await getAccessToken()}`;
  }

  return headers;
};

let errorHandler = async (error) => error;

const setErrorsHandler = (callback) => {
  errorHandler = callback;
};

instance.interceptors.request.use(
  async (config) => {
    config.headers = await prepareHeaders(config.headers);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use((response) => {
    return response.data.payload || {};
  }, async (error) => {
    await errorHandler(error.response);
    return Promise.reject(error.response.data);
  }
);

// Utility methods for a CRUD API
const useCreate = (headers = {}) => useAxios({method: 'POST', headers}, instance);
const useRead = () => useAxios({method: 'GET'}, instance);
const useUpdate = (headers = {}) => useAxios({method: 'PUT', headers}, instance);
const useDelete = () => useAxios({method: 'DELETE'}, instance);

export {setErrorsHandler, useCreate, useRead, useUpdate, useDelete};