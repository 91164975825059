<template>
  <div class="person-tab-profile">
    <white-block>
      <content-container class="content-padding pt-40 pb-40">
        <bordered-block>
          <menu-person :id="person.id" :active="route.params?.tab" />
        </bordered-block>
      </content-container>
      <a-spin :spinning="isLoading" />
    </white-block>

    <white-block>
      <content-container class="content-padding">
        <div class="person-page__info pb-80">
          <h3>Направления работы</h3>
          <!--
          <p>
            Эксперт по атомно-силовой микроскопии одномерных (вискеры) и
            двумерных (тонкие плёнки) наноструктур. Основные области интересов:
            сканирующая зондовая микроскопия, полупроводниковые нанопровода,
            GaAs, InP, углеродные наноструктуры, магнитные явления,
            наноцеллюлоза, электридная керамика, AlN, Большие Данные.
          </p>

          <p>
            В 2018 - Doctor of Science (Technology), Lappeenranta University of
            Technology (LUT) (Finland), тема диплома: "Atomic Force Microscopy
            of electrical, mechanical and piezo properties of nanowires".
          </p>
          <p>
            В 2013 - Master of Science (Technology), Lappeenranta University of
            Technology (LUT) (Finland), тема диплома: "Kelvin Probe Force
            Microscopy (KPFM) characterization of lanthanum lutetium oxide
            high-k dielectric thin films".
          </p>
          <p>
            В 2012 - специалист, РХТУ им. Д.И.Менделеева (г.Москва), тема
            диплома: "Исследование проводимости и ёмкости композиционных жидких
            кристаллов на основе лаурата калия с наночастицами серебра методом
            импедансной спектроскопии".
          </p>
          -->

          <div class="mb-40">
            <tags-percent-list type="big-gray" :tags="fingerprints" />
          </div>
          <button-primary
            type="link"
            :href="`/portal/person/` + person.id + `/concepts`"
          >
            Полный список
          </button-primary>
        </div>
      </content-container>
    </white-block>

    <gray-block class="pt-80 pb-80">
      <content-container class="content-padding">
        <two-columns>
          <template v-slot:left>
            <white-block class="h-100 p-30 round">
              <gray-block class="h-100 p-30 round">
                <chart-wrapper
                    title="Количество научных публикаций за последние 5 лет"
                >
                  <chart-vertical :items="chart2Items"></chart-vertical>
                </chart-wrapper>
              </gray-block>
            </white-block>
          </template>
          <template v-slot:right>
            <white-block class="h-100 p-30 round">
              <gray-block class="h-100 p-30 round">
                <h4>Место работы</h4>
                <div class="employments">
                  <div
                      v-for="(employment, index) in person.employments"
                      :key="`employmentm-`+index"
                  >
                    <div class="employments__organisational">
                      <span class="employments__num">{{ index + 1 }}.&nbsp;</span>
                      <router-link :to="`/portal/department/` + employment.organisationalId">
                        {{ employment.organisational }}
                      </router-link>
                    </div>
                    <div class="employments__position" v-if="employment.position"><label>Должность:</label> {{ employment.position }}</div>
                  </div>
                </div>
              </gray-block>
            </white-block>
          </template>
        </two-columns>
      </content-container>
    </gray-block>

    <white-block class="pt-80 pb-80">
      <content-container class="content-padding">
        <h3>Научные публикации</h3>
        <a-row :wrap="false" :gutter="[20, 20]">
          <a-col v-for="publication in publications" :key="`publication-` + publication.id" style="flex:1 1 100%">
            <gray-block class="h-100 p-10 round">
              <publication-item
                  :publication="publication"
              />
            </gray-block>
          </a-col>
        </a-row>
      </content-container>
    </white-block>

    <gray-block class="pt-80 pb-80" v-if="informations">
      <content-container class="content-padding slider">
        <collapse-panels :items="informations" />
      </content-container>
    </gray-block>
  </div>
</template>

<script setup>
  import {onMounted, ref, watch} from 'vue';
  import { useRoute } from 'vue-router';
  import {useApi} from "@/api/use-api";
  import ContentContainer from '@/views/portal/components/ContentContainer.vue';
  import WhiteBlock from '@/views/portal/components/WhiteBlock.vue';
  import ChartVertical from '@/views/portal/components/charts/ChartVertical.vue';
  import TwoColumns from '@/views/portal/components/TwoColumns.vue';
  import GrayBlock from '@/views/portal/components/GrayBlock.vue';
  import ChartWrapper from '@/views/portal/components/charts/ChartWrapper.vue';
  import BorderedBlock from '@/views/portal/components/BorderedBlock.vue';
  import MenuPerson from '@/views/portal/components/menus/MenuPerson.vue';
  import PublicationItem from "@/views/portal/components/publications/PublicationItem.vue";
  import {TYPE_PUBLICATIONS, TYPE_INTELLECTUALS} from "@/api/models/Publication";
  import CollapsePanels from "@/views/portal/components/UI/CollapsePanels.vue";
  import TagsPercentList from "@/views/portal/components/tags/TagsPercentList.vue";
  import ButtonPrimary from "@/views/portal/components/UI/ButtonPrimary.vue";

  const props = defineProps({
    person: {
      type: Object,
      required: true,
    },
  });

  const isLoading = ref(true);
  const api = useApi();
  const route = useRoute();
  //const fingerprints = ref([]);
  const publications = ref([]);
  const informations = ref([]);
  const fingerprints = ref([]);
  const chart1Items = ref([]);
  const chart2Items = ref([]);

  const getPublications = async () => {
    if (undefined === props.person.uuid || !props.person.uuid) return;
    try {
      const response = await api.publication.index(
          new URLSearchParams({
            page: 1,
            'per-page': 2,
            sort: '-modified_at',
            author_uuid: props.person.uuid,
            type: TYPE_PUBLICATIONS,
          })
      );

      publications.value = response.publications;
    } catch (error) {
      console.error('error', error);
    }
  };

  const getFingerprints = async () => {
    if (undefined === props.person.uuid || !props.person.uuid) return;
    try {
      const response = await api.fingerprint.index({
        page: 1,
        'per-page': 10,
        sort: '-weighted_rank',
        content_family: 3,
        content_uuid: props.person.uuid,
      });
      response.fingerprints.map((fp) => {
        fp.description = fp.thesauriName;
        fp.tooltip = fp.weightedRank;
      });
      fingerprints.value = response.fingerprints;
    } catch (error) {
      console.error('error', error);
    }
  };

  const getInformations = async () => {
    if (undefined === props.person.uuid || !props.person.uuid) return;
    try {
      const response = await api.profileInformation.index(
          new URLSearchParams({
            sort: 'title',
            person_uuid: props.person.uuid,
          })
      );

      informations.value = response.profileInformations;
    } catch (error) {
      console.error('error', error);
    }
  };

  const getTotalPublicationsByYears = async () => {
    if (undefined === props.person.uuid || !props.person.uuid) return;
    try {
      const response = await api.publication.total(
          new URLSearchParams({
            author_uuid: props.person.uuid,
            year: new Date().getFullYear() - 4,
            group: 'year',
            type: TYPE_PUBLICATIONS,
          })
      );

      chart2Items.value = response.items;
    } catch (error) {
      console.error('error', error);
    }
  };

  const getTotalIntellectualsByYears = async () => {
    if (undefined === props.person.uuid || !props.person.uuid) return;
    try {
      const response = await api.publication.total(
          new URLSearchParams({
            author_uuid: props.person.uuid,
            year: new Date().getFullYear() - 4,
            group: 'year',
            type: TYPE_INTELLECTUALS,
          })
      );

      chart1Items.value = response.items;
    } catch (error) {
      console.error('error', error);
    }
  };

  const loadContent = () => {
    isLoading.value = true;
    Promise.all([
      getPublications(),
      getInformations(),
      getFingerprints(),
      getTotalPublicationsByYears(),
      getTotalIntellectualsByYears()
    ]).then(
        () => {
          isLoading.value = false;
        },
        () => {
          isLoading.value = false;
        }
    )
  }

  onMounted(() => {
    loadContent();
  });

  watch(
      () => props.person.id,
      () => {
        loadContent();
      }
  );
</script>

<style lang="scss" scoped>
.person-tab-profile {
  .slider {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .employments {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .employments__organisational {
      color: #101426;
      font-family: "Nunito Sans", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 1.71429;
      margin-bottom: 10px;
      padding-left: 20px;

      .employments__num {
        margin-left: -20px;
      }

      a {
        color: inherit;

        &:hover {
          text-decoration: underline;
        }
      }
    }
    .employments__position {
      color: #718096;
      font-family: "Nunito Sans", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.42857;
      margin-bottom: 10px;
      padding-left: 20px;

      label {
        font-weight: 700;
      }
    }
  }
}
</style>
