<template>
  <router-link to="/showcase" class="logo"></router-link>
</template>

<script setup></script>

<style lang="scss" scoped>
  .logo {
    display: block;
    max-width: 150px;
    width: 100%;
    height: 44px;
    background: url('../assets/logo-white.svg') no-repeat center center;
    background-size: contain;
  }
</style>
