<template>
  <div class="project-card">
    <a-row class="project-card__meta">
      <a-col class="project-card__name">
        {{ project.name }}
      </a-col>
      <a-col class="project-card__status">
        <status-label :status="project.status" />
      </a-col>
    </a-row>

    <a-row class="project-card__info">
      <div>
        Номер проекта: <span>{{ project.id }}</span>
      </div>
      <div>
        Тип проекта:
        <span>{{ project.type }}</span>
      </div>
      <div>
        Компания:
        <span>{{ project.organizationName }}</span>
      </div>
      <div>Руководитель проекта</div>
      <project-person
        :avatar="getAvatarUrl(project.leaderAvatarUid)"
        :name="project.leaderName"
        :email="project.leaderEmail"
      />
    </a-row>
  </div>
</template>

<script setup>
  import StatusLabel from '@/components/projects/components/StatusLabel.vue';
  import ProjectPerson from './ProjectPerson.vue';

  defineProps({
    project: Object,
  });

  const getAvatarUrl = (avatar) => avatar ? `/api/v1/attachment/${avatar}` : null;

</script>

<style lang="scss">
  @import 'project-card.scss';
</style>
