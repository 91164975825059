<template>
  <span class="tag">
    <slot></slot>
  </span>
</template>

<script setup></script>

<style lang="scss">
  @import '@/assets/styles/_colors.scss';

  .tag {
    color: $color-text-secondary-grey;
    background: $color-tag;
    text-align: center;
    font-size: 1rem;
    border-radius: 30px;
    padding: 6px 16px;

    &:hover {
      color: $color-text-secondary-white;
      background: $color-tag-hover;
    }
  }
</style>
