<template>
  <h3>Шаг 6. Ресурсы</h3>
  <p>Общая информация о проекте</p>
  <div>
    <a-form class="form performer-project-form" layout="vertical">
      <performer-project-form-row>
        <a-form-item name="resources" label="Требуемые ресурсы">
          <a-textarea
            v-model:value="model.resources"
            placeholder="Опишите необходимые для выполнения проекта ресурсы"
            rows="7"
            disabled
          />
        </a-form-item>
      </performer-project-form-row>
    </a-form>
  </div>
</template>

<script setup>
  import { onMounted, reactive, watch } from 'vue';
  import PerformerProjectFormRow from '../PerformerProjectFormRow.vue';

  const props = defineProps({
    project: {
      type: Object,
      require: true,
    },
  });

  const model = reactive({
    resources: '',
  });

  onMounted(() => { props.project && fillModel(); })

  watch(()=>props.project, () => {
    props.project && fillModel();
  });

  const fillModel = () => {
    try {
      model.resources = props.project.resources;
    } catch (error) {
      console.error('error', error);
    }
  };
</script>

<style lang="scss" scoped></style>
