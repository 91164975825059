<template>
  <performer-projects-layout :spinning="isLoading">
    <performer-project-layout :model="model">
      <template #underInfo>
        <div
          class="performer-project__common-progress"
          v-if="checkpoints.length"
        >
          <progress-bar :items="checkpoints" showPercent />
        </div>
      </template>
      <a-row class="performer-project-tasks-counter">
        <a-col class="tasks-counter total">
          <h4>Всего задач</h4>
          <span class="tasks-counter__num">{{ tasksCount['total'] }}</span>
        </a-col>
        <a-col class="tasks-counter inwork">
          <h4>В работе</h4>
          <span class="tasks-counter__num">{{
            tasksCount[STATUS_INCOMPLETE]
          }}</span>
        </a-col>
        <a-col class="tasks-counter completed">
          <h4>Выполнено</h4>
          <span class="tasks-counter__num">{{
            tasksCount[STATUS_COMPLETED]
          }}</span>
        </a-col>
        <a-col class="tasks-counter expired">
          <h4>Просрочено</h4>
          <span class="tasks-counter__num">{{
            tasksCount[STATUS_COMPLETED_OVERDUE]
          }}</span>
        </a-col>
        <a-col class="tasks-counter incompleted">
          <h4>Невыполнено</h4>
          <span class="tasks-counter__num">{{
            tasksCount[STATUS_INCOMPLETE_OVERDUE]
          }}</span>
        </a-col>
      </a-row>

      <a-row class="performer-project-tasks-label">
        <span
          >В работе /
          {{
            tasksCount[STATUS_INCOMPLETE] +
            tasksCount[STATUS_INCOMPLETE_OVERDUE]
          }}</span
        >
      </a-row>

      <task-card
        v-for="(task, index) in tasksInWork"
        :key="index++"
        :task="{ number: index++, ...task }"
        :disabled="
          [PROJECT_STATUS_ACCEPTANCE, PROJECT_STATUS_CLOSED].includes(
            model.projectStatusId
          )
        "
        :editor-user-id="model.userId"
        @update="updateTasks"
      />

      <a-row class="performer-project-tasks-label">
        <span
          >Выполнено /
          {{
            tasksCount[STATUS_COMPLETED] + tasksCount[STATUS_COMPLETED_OVERDUE]
          }}</span
        >
      </a-row>

      <task-card
        v-for="(task, index) in tasksDone"
        :key="index++"
        :task="{ number: index++, ...toRaw(task) }"
        :disabled="
          [PROJECT_STATUS_ACCEPTANCE, PROJECT_STATUS_CLOSED].includes(
            model.projectStatusId
          )
        "
        :editor-user-id="model.userId"
        @update="updateTasks"
      />
    </performer-project-layout>
  </performer-projects-layout>
</template>

<script setup>
  import { computed, onMounted, reactive, ref } from 'vue';
  import { useRoute } from 'vue-router';
  import { useApi } from '@/api/use-api';
  import { toRaw } from 'vue';
  import {
    useCheckpoints,
    useTaskCount,
    useTasksDone,
    useTasksInWork,
  } from '@/composables/taskCount';
  import ProgressBar from '@/components/projects/management/ProgressBar';
  import PerformerProjectsLayout from '../layouts/PerformerProjectsLayout.vue';
  import PerformerProjectLayout from '../layouts/PerformerProjectLayout.vue';
  import TaskCard from '@/views/leader/projects/management/Task/TaskCard.vue';
  import {
    STATUS_COMPLETED,
    STATUS_COMPLETED_OVERDUE,
    STATUS_INCOMPLETE,
    STATUS_INCOMPLETE_OVERDUE,
  } from '@/api/models/Checkpoint';
  import {
    PROJECT_STATUS_ACCEPTANCE,
    PROJECT_STATUS_CLOSED,
  } from '@/api/models/ProjectStatus';

  const api = useApi();
  const route = useRoute();
  const model = reactive({
    id: null,
    projectStatusId: null,
    number: '',
    name: 'Новый проект',
    description: '',
    projectType: '',
    requestId: null,
    status: {
      type: 'draft',
      text: 'Черновик',
    },
    userId: null,
    showAllTasks: false,
  });
  const isLoading = ref(true);
  const tasks = ref([]);
  const checkpoints = ref([]);

  const tasksCount = computed(() => {
    return model.userId
      ? model.showAllTasks
        ? useTaskCount(tasks.value, null)
        : useTaskCount(tasks.value, model.userId)
      : [];
  });

  const tasksInWork = computed(() => {
    return useTasksInWork(tasks.value);
  });

  const tasksDone = computed(() => {
    return useTasksDone(tasks.value);
  });

  onMounted(async () => {
    await getModel();
    await getTasks();
    isLoading.value = false;
  });

  const getModel = async () => {
    try {
      const response = await api.performerPage.project(route.params.id);
      model.id = response.project.id;
      model.userId = response.project.userId;
      model.projectStatusId = response.project.projectStatusId;
      model.name = response.project.name;
      model.number = response.project.id;
      model.type = response.project.type;
      model.requestId = response.project.requestId;
      model.status = response.project.status;
      model.showAllTasks = response.project.showAllTasks;
    } catch (error) {
      console.error('error', error);
    }
  };

  const getTasks = async () => {
    const searchFilter = new URLSearchParams({ projectId: route.params.id });
    if (!model.showAllTasks) {
      searchFilter.append('userId', model.userId);
    }
    try {
      const response = await api.projectManagementPage.tasks(searchFilter);
      tasks.value = response.tasks;
      checkpoints.value = useCheckpoints(response.tasks, null, model.userId);
    } catch (error) {
      console.error('error', error);
    }
  };

  const updateTasks = () => {
    getTasks();
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  h3 {
    color: $color-black;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.1;
  }

  .performer-project__common-progress {
    margin-top: 42px;
  }

  .performer-project-tasks-counter {
    flex-wrap: nowrap;
    height: 159px;

    .tasks-counter {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      row-gap: 10px;
      width: 100%;

      &.total {
        background-color: $color-bg-seconary-black-95;
      }

      &.inwork {
        background-color: $color-btn-secondary;
        h4 {
          color: $color-bg-primary-black-100;
        }

        .tasks-counter__num {
          color: $color-bg-primary-black-100;
        }
      }

      &.completed {
        background-color: $color-bg-status-done;
      }

      &.expired {
        background-color: $color-bg-status-expired;
      }

      &.incompleted {
        background-color: $color-bg-status-reject;
      }

      h4 {
        color: $color-bg-primary-white;
      }

      &__num {
        color: $color-bg-primary-white;
        font-size: 62px;
        font-weight: 700;
        line-height: 72px;
      }
    }
  }

  .performer-project-tasks-label {
    align-items: center;
    background-color: $color-bg-primary-black-100;
    color: $color-bg-primary-white;
    display: flex;
    height: 32px;
    justify-content: center;
    margin: 40px 0 20px 0;
    text-align: center;
  }
</style>
