<template>
  <div class="gray-block">
    <slot></slot>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.gray-block {
  background: #f2f4f8;
}
</style>
