<template>
  <h3>Шаг 2. Техническое задание</h3>
  <p>Общая информация о проекте</p>
  <div>
    <a-form class="form leader-project-form" layout="vertical">
      <performer-project-form-row>
        <a-form-item name="description" label="Краткое описание проекта *">
          <a-textarea
            v-model:value="model.description"
            placeholder="Опишите суть проекта"
            rows="7"
            disabled
          />
        </a-form-item>
      </performer-project-form-row>

      <performer-project-form-row>
        <a-form-item
          name="customerProblem"
          label="Проблема/потребность заказчика"
        >
          <a-textarea
            v-model:value="model.customerProblem"
            placeholder="Опишите потребности заказчика"
            rows="7"
            disabled
          />
        </a-form-item>
      </performer-project-form-row>

      <performer-project-form-row>
        <a-form-item name="projectGoal" label="Цель проекта">
          <a-textarea
            v-model:value="model.projectGoal"
            placeholder="Опишите цель проекта"
            rows="7"
            disabled
          />
        </a-form-item>
      </performer-project-form-row>

      <performer-project-form-row>
        <a-form-item name="projectObjectives" label="Задачи проекта">
          <a-textarea
            v-model:value="model.projectObjectives"
            placeholder="Опишите задачи проекта"
            rows="7"
            disabled
          />
        </a-form-item>
      </performer-project-form-row>

      <performer-project-form-row>
        <a-form-item name="devObjects" label="Объекты разработки">
          <a-textarea
            v-model:value="model.devObjects"
            placeholder="Опишите объекты разработки"
            rows="7"
            disabled
          />
        </a-form-item>
      </performer-project-form-row>

      <performer-project-form-row>
        <a-form-item name="expectedContent" label="Ожидаемое содержание работ">
          <a-textarea
            v-model:value="model.expectedContent"
            placeholder="Опишите ожидаемое содержание работ"
            rows="7"
            disabled
          />
        </a-form-item>
      </performer-project-form-row>

      <performer-project-form-row>
        <a-form-item
          name="techRequirements"
          label="Технические требования к отдельным задачам"
        >
          <a-textarea
            v-model:value="model.techRequirements"
            placeholder="Опишите технические требования к отдельным задачам"
            rows="7"
            disabled
          />
        </a-form-item>
      </performer-project-form-row>

      <performer-project-form-row>
        <a-form-item name="result" label="Результат *">
          <a-textarea
            v-model:value="model.result"
            placeholder="Опишите планируемый результат"
            rows="7"
            disabled
          />
        </a-form-item>
      </performer-project-form-row>

      <performer-project-form-row>
        <a-form-item
          name="resultRequirements"
          label="Требования к представлению и оформлению результатов работ"
        >
          <a-textarea
            v-model:value="model.resultRequirements"
            placeholder="Опишите требования к предоставлению и оформлению результатов работ"
            rows="7"
            disabled
          />
        </a-form-item>
      </performer-project-form-row>

      <performer-project-form-row>
        <a-form-item name="otherInfo" label="Другая необходимая информация">
          <a-textarea
            v-model:value="model.otherInfo"
            placeholder="Внести другую необходимую информацию при необходимости"
            rows="7"
            disabled
          />
        </a-form-item>
      </performer-project-form-row>
    </a-form>
  </div>
</template>

<script setup>
  import { onMounted, reactive, watch } from 'vue';
  import PerformerProjectFormRow from '../PerformerProjectFormRow.vue';

  const props = defineProps({
    project: {
      type: Object,
      require: true,
    },
  });

  onMounted(() => { props.project && fillModel(); })

  watch(()=>props.project, () => {
    props.project && fillModel();
  });

  const model = reactive({
    id: '',
    description: '',
    customerProblem: '',
    projectGoal: '',
    projectObjectives: '',
    devObjects: '',
    expectedContent: '',
    techRequirements: '',
    result: '',
    resultRequirements: '',
    otherInfo: '',
    availabilityList: [],
  });

  const fillModel = () => {
    try {
      model.id = props.project.id;
      model.description = props.project.description;
      model.customerProblem = props.project.customerProblem;
      model.projectGoal = props.project.projectGoal;
      model.projectObjectives = props.project.projectObjectives;
      model.devObjects = props.project.devObjects;
      model.expectedContent = props.project.expectedContent;
      model.techRequirements = props.project.techRequirements;
      model.result = props.project.result;
      model.resultRequirements = props.project.resultRequirements;
      model.otherInfo = props.project.otherInfo;
      model.availabilityList = props.project.availabilityList;
    } catch (error) {
      console.error('error', error);
    }
  };
</script>

<style lang="scss" scoped>
  @import './performer-project-form';
</style>
