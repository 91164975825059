<template>
  <div class="add-checkpoints__form">
    <h3>Добавить контрольную точку</h3>
    <p>Вы можете добавить одну или сразу несколько контрольных точек.</p>
    <a-form
      class="form"
      :model="model"
      :rules="rules"
      @finish="onFinish"
      @validate="errors.onValidate"
    >
      <h2>Контрольные точки</h2>

      <template
        v-for="(checkpoint, index) in model.checkpoints"
        :key="checkpoint.index"
      >
        <!-- 
          TODO: not working with dynamic elements?
        :help="errors.getError('description')"
        :validate-status="errors.getStatus('description')"
       -->
        <a-form-item
          :name="['checkpoints', index, 'description']"
          :rules="{
            required: true,
            min: 2,
            max: 5000,
            message: TEXT_LENGTH_ERROR,
            trigger: 'change',
          }"
        >
          <a-input
            v-model:value="checkpoint.description"
            placeholder="Сформулируйте результат"
          >
            <template #suffix>
              <img
                v-if="model.checkpoints.length > 1"
                src="@/assets/icons/delete.svg"
                style="cursor: pointer"
                @click="removeCheckpointInput(checkpoint)"
                alt=""
              />
            </template>
          </a-input>
        </a-form-item>

        <!-- TODO (NMD): Warning: Obsolete attribute -->
        <a-row align="middle" justify="space-between">
          <a-col :span="17">
            <!-- 
          TODO: not working with dynamic elements?
              :help="errors.getError('checkpoint')"
              :validate-status="errors.getStatus('checkpoint')"
             -->
            <a-form-item
              :name="['checkpoints', index, 'date']"
              :rules="{
                required: !checkpoint.noMatter,
                message: 'Необходимо указать дату выполнения',
                trigger: 'change',
              }"
            >
              <a-date-picker
                v-model:value="checkpoint.date"
                placeholder="Дата выполнения"
                format="DD.MM.YYYY"
                :locale="locale"
                :disabled="checkpoint.noMatter"
              >
                <template #suffixIcon></template>
              </a-date-picker>
            </a-form-item>
          </a-col>
          <a-col>
            <a-form-item :name="['checkpoints', index, 'noMatter']">
              <c-checkbox
                v-model:checked="checkpoint.noMatter"
                @change="onNoMatterChange(checkpoint)"
              >
                Неважно
              </c-checkbox>
            </a-form-item>
          </a-col>
        </a-row>
      </template>

      <a-form-item>
        <a-col :span="24" class="form__add-checkpoint">
          <!-- TODO (NMD): Warning: Obsolete attribute -->
          <a-row align="middle">
            <a-col>
              <img src="@/assets/icons/circle-plus.svg" alt="" />
            </a-col>
            <a-col>
              <a @click="addNewCheckpointInput">Добавить контрольную точку</a>
            </a-col>
          </a-row>
        </a-col>
      </a-form-item>
      <a-form-item class="form__button">
        <c-button-primary html-type="submit"> Добавить </c-button-primary>
      </a-form-item>
    </a-form>
  </div>
</template>

<script setup>
  import { reactive } from 'vue';
  import { useApi } from '@/api/use-api';
  import ErrorsHandler from '@/components/form/errors-handler';
  import CButtonPrimary from '@/components/UI/CButtonPrimary';
  import CCheckbox from '@/components/UI/CCheckbox';
  import locale from 'ant-design-vue/es/date-picker/locale/ru_RU';
  import dayjs from 'dayjs';
  import { TEXT_LENGTH_ERROR } from '@/constants/error';

  const props = defineProps({
    checkpoint: Object,
    taskId: Number,
  });
  const api = useApi();
  const emit = defineEmits(['close', 'update']);

  const model = reactive({
    id: null,
    taskId: null,
    name: '',
    checkpoints: [
      {
        description: '',
        date: '',
        status: '',
        noMatter: false,
      },
    ],
  });

  const rules = {
    name: [
      {
        type: 'string',
        required: true,
        min: 2,
        max: 255,
        message: 'Необходимо сформулировать задачу, не менее 2 символов',
        trigger: 'change',
      },
    ],
    // executor: [
    //   {
    //     type: "string",
    //     required: true,
    //     message: "Необходимо выбрать исполнителя",
    //     trigger: "change",
    //   },
    // ],
  };

  const errors = new ErrorsHandler({
    name: '',
    // executor: "",
    description: '',
    checkpoint: '',
  });

  const onFinish = async () => {
    try {
      for (let i = 0; i < model.checkpoints.length; i++) {
        if (model.checkpoints[i].description) {
          await api.projectManagementPage.saveCheckpoint({
            id: null,
            taskId: props.taskId,
            description: model.checkpoints[i].description,
            date: dayjs(model.checkpoints[i].date).format('YYYY-MM-DD'),
          });
        }
      }

      emit('close');
      emit('update');
    } catch (error) {
      console.log(error);
      errors.handleApiErrors(error);
    }
  };

  const addNewCheckpointInput = () => {
    model.checkpoints.push({
      description: '',
      date: '',
      status: '',
      noMatter: false,
    });
  };

  const removeCheckpointInput = (checkpoint) => {
    const index = model.checkpoints.indexOf(checkpoint);
    if (index !== -1) {
      model.checkpoints.splice(index, 1);
    }
  };

  const onNoMatterChange = (checkpoint) => {
    if (checkpoint.noMatter) {
      checkpoint.date = null;
    }
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  .add-checkpoints__form {
    padding: 112px 17px 0 17px;

    h3 {
      font: 700 18px/26px 'Nunito Sans';
    }

    h2 {
      color: $color-bg-status-rework;
      font: 700 14px/20px 'Nunito Sans';
      margin-top: 30px;
    }

    p {
      margin-top: 21px;
    }

    .ant-form-item {
      margin-bottom: 14px;
    }

    .form__add-checkpoint {
      margin-left: 15px;

      a {
        border-bottom: 1px solid;
        color: $color-link-primary;
        text-decoration: none;
      }
    }

    .form__button {
      margin-top: 36px;
      text-align: right;
    }
  }
</style>
