<template>
  <div class="company-card">
    <div class="company-card__header">
      <div class="header__title">{{ name }}</div>
      <a-dropdown
        class="header-dropdown"
        v-if="menu"
        :trigger="['click']"
        v-model:open="isShowDropdown"
        placement="bottomRight"
        destroyPopupOnHide
      >
        <div class="header-dropdown__icon">
          <img src="./assets/more_vertical.svg" alt="" />
        </div>
        <template #overlay>
          <a-menu class="header-dropdown__menu">
            <a-menu-item class="header-dropdown__menu-item">
              <a @click.stop="handleEdit">
                <img src="./assets/edit.svg" alt="" />
                <span>Редактировать</span>
              </a>
            </a-menu-item>
            <a-menu-item class="header-dropdown__menu-item" v-if="listId !== 1">
              <a @click.stop="handleDelete">
                <img src="./assets/trash.svg" alt="" />
                <span>Удалить</span>
              </a>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </div>
    <div class="company-card__content">
      <div class="content-description">
        <div class="content-description__item">
          <span class="content-description__label">ИНН:</span>
          {{ inn }}
        </div>
        <div class="content-description__item">
          <span class="content-description__label">Подразделение:</span>
          {{ unit }}
        </div>
        <div class="content-description__item">
          <span class="content-description__label">Должность:</span>
          {{ position }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';

  defineProps({
    listId: Number,
    name: String,
    inn: String,
    unit: String,
    position: String,
    menu: {
      type: Boolean,
      default: true,
    },
  });
  const isShowDropdown = ref(false);

  const emit = defineEmits(['edit', 'delete']);

  function handleEdit() {
    emit('edit');
    isShowDropdown.value = false;
  }

  function handleDelete() {
    emit('delete');
    isShowDropdown.value = false;
  }
</script>

<style lang="scss" scoped>
  $color-bg: #f2f4f8;
  $color-bg-button: #ffffff;
  $color-bg-menu-item-hover: #f2f4f8;
  $color-text-title: #101426;
  $color-text-content: #718096;
  $color-text-profile-primary: #383d4f;
  $color-text-profile-secondary: #2d3346;
  $font-profile-dropdown: 700 16px/26px 'Nunito Sans';

  .company-card {
    background-color: $color-bg;
    padding: 20px;
    height: 100%;
    user-select: none;

    .company-card__header {
      display: flex;
      justify-content: space-between;

      .header__title {
        color: $color-text-title;
        font: 700 16px/22px 'Nunito Sans';
      }

      .header-dropdown__icon {
        background-color: $color-bg-button;
        border-radius: 5px;
        cursor: pointer;
        height: 30px;
        padding: 3px;
        position: absolute;
        right: 20px;
        top: 10px;
        width: 30px;
      }
    }

    .company-card__content {
      margin-top: 20px;

      .content-description {
        color: $color-text-content;
        display: flex;
        flex-direction: column;
        gap: 14px 0;

        .content-description__item {
          font: 400 14px/20px 'Nunito Sans';

          .content-description__label {
            font: 700 14px/20px 'Nunito Sans';
            margin-right: 5px;
          }
        }
      }
    }
  }

  .header-dropdown__menu {
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    height: 100px;
    justify-content: center;
    padding: 20px 10px;

    .header-dropdown__menu-item a {
      color: $color-text-profile-secondary;
      display: flex;
      font: $font-profile-dropdown;
      gap: 14px;
    }
  }
</style>
