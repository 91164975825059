<template>
  <a-row class="pict-text-block" :class="pictTextBlockClass(type)">
    <a-col :span="12" class="pict-text-block__image">
      <div>
        <img :src="image" alt="" />
      </div>
    </a-col>
    <a-col :span="12" class="pict-text-block__content">
      <slot></slot>
    </a-col>
  </a-row>
</template>

<script setup>
defineProps({
  image: {
    type: String,
    default: "",
  },
  type: {
    type: String,
    default: "left", // 'left' or 'right'
  },
});

const pictTextBlockClass = (type) => {
  return "pict-text-block-" + type;
};
</script>

<style lang="scss" scoped>
@import "../assets/_map-for-breakpoints";
@import "../../../assets/styles/_breakpoints";

$margin-bottom: 55px;

.pict-text-block {
  display: flex;
  margin-bottom: $margin-bottom;
  margin-left: -40px;
  margin-right: -40px;

  @include breakpoint(max, lg) {
    align-items: flex-start;
  }

  &.pict-text-block-left {
    flex-direction: row;

    .pict-text-block__image {
      padding: 0 115px 0 0;

      @include breakpoint(max, lg) {
        padding: 0 20px 0 0;
      }
    }

    .pict-text-block__content {
      padding: 47px 50px 50px 10px;
      @include breakpoint(max, xl) {
        padding: 27px 50px 30px 10px;
      }
    }
  }

  &.pict-text-block-right {
    flex-direction: row-reverse;

    .pict-text-block__image {
      padding: 0 0 0 115px;

      @include breakpoint(max, lg) {
        padding: 0 0 0 20px;
      }
    }

    .pict-text-block__content {
      padding: 47px 10px 50px 50px;
      @include breakpoint(max, xl) {
        padding: 27px 10px 30px 50px;
      }
    }
  }

  &.no-top-padding {
    .pict-text-block__content {
      padding-top: 0;
    }
  }

  .pict-text-block__image {
    img {
      width: 100%;
      height: auto;
      display: block;
      margin-bottom: -$margin-bottom;
      @include breakpoint(max, lg) {
        margin-bottom: 30px;
        margin-top: 30px;
      }
    }
  }

  .pict-text-block__content {
  }
}
</style>
