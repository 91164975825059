<template>
  <div class="delete-checkpoint__form">
    <h3>Удалить контрольную точку?</h3>
    <p>Вы действительно хотите удалить задачу из списка?</p>
    <a-row class="form__checkpoint-info">
      <a-col>
        <a-row>
          <h2>{{ checkpoint.description }}</h2>
        </a-row>
        <a-row class="form__checkpoint-group">
          Срок:<span class="counter">{{
            checkpoint.date
              ? dayjs(checkpoint.date).format('DD.MM.YYYY')
              : 'Неважно'
          }}</span>
        </a-row>
        <a-row class="form__checkpoint-group">
          Статус:<span class="counter">{{
            checkpoint.status === 'completed' ? 'Выполнена' : 'Не выполнена'
          }}</span>
        </a-row>
        <a-row v-if="!!checkpoint.comment" class="form__checkpoint-group">
          Комментарий: <span class="counter">{{ checkpoint.comment }}</span>
        </a-row>
      </a-col>
    </a-row>

    <div class="delete-checkpoint__form-button">
      <c-button-danger @click="deleteCheckpoint"> Удалить </c-button-danger>
    </div>
  </div>
</template>

<script setup>
  import dayjs from 'dayjs';
  import { useApi } from '@/api/use-api';
  import CButtonDanger from '@/components/UI/CButtonDanger';

  const props = defineProps({
    checkpoint: Object,
  });

  const api = useApi();
  const emit = defineEmits(['close', 'update']);

  const deleteCheckpoint = async () => {
    try {
      await api.projectManagementPage.deleteCheckpoint({
        id: props.checkpoint.id,
      });

      emit('close');
      emit('update');
    } catch (error) {
      console.error('error', error);
    }
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';
  * {
    margin: 0;
  }

  .delete-checkpoint__form {
    padding: 112px 17px 0 17px;

    h2 {
      font: 700 16px/22px 'Nunito Sans';
    }

    p {
      margin-top: 21px;
    }

    .form__checkpoint-info {
      background: $color-bg-secondary-grey;
      flex-direction: column;
      max-height: 300px;
      margin-top: 40px;
      padding: 20px;

      .form__checkpoint-group {
        color: $color-bg-status-new;
        font: 700 14px/20px 'Nunito Sans';
        margin-top: 18px;

        .counter {
          font-weight: 400;
          margin-left: 10px;
        }
      }
    }

    &-button {
      margin-top: 50px;
      text-align: right;
    }
  }
</style>
