<template>
  <a-row class="project-manager__form">
    <a-col :span="24" class="form__title">
      <h3>Назначить руководителя проекта</h3>
    </a-col>
    <a-col class="form__description" :span="24">
      <p>Вы действительно хотите назначить руководителя?</p>
    </a-col>
    <a-col class="form__fields" :span="24">
      <a-form :model="model" @finish="onFinish">
        <a-row :gutter="[0, 14]">
          <a-col :span="24">
            <a-form-item name="employee">
              <a-select
                id="employee"
                placeholder="Выберите сотрудника"
                v-model:value="model.employee"
                :options="model.employeesList"
                :not-found-content="isLoading ? undefined : null"
                optionFilterProp="label"
                @search="searchUser"
                showSearch
              >
                <template v-if="isLoading" #notFoundContent>
                  <a-spin size="small" />
                </template>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item name="unit">
              <a-select
                v-model:value="model.unit"
                placeholder="Выберите подразделение *"
                v-bind="unitsSelectOptions()"
                disabled
              />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-row justify="end">
              <a-form-item class="form__button">
                <c-button-primary html-type="submit" :loading="isLoading">
                  Сохранить
                </c-button-primary>
              </a-form-item>
            </a-row>
          </a-col>
        </a-row>
      </a-form>
    </a-col>
  </a-row>
</template>

<script setup>
  import { onMounted, reactive, ref } from 'vue';
  import { useApi } from '@/api/use-api';
  import { useRoute, useRouter } from 'vue-router';
  import CButtonPrimary from '@/components/UI/CButtonPrimary.vue';
  import { REQUEST_STATUS_LEADER } from '@/api/models/RequestStatus';
  import {debounce} from "lodash-es";

  const api = useApi();
  const emit = defineEmits(['close', 'update']);
  const router = useRouter();
  const route = useRoute();
  const isLoading = ref(false);
  const model = reactive({
    lastFetchId: 0,
    employee: null,
    employeesList: null,
    isFetching: false,
    unit: null,
    unitsList: [],
  });

  // TODO - add rules and errors
  // const rules = {
  //   employee: [
  //     {
  //       type: 'string',
  //       required: true,
  //       message: 'Необходимо выбрать сотрудника',
  //       trigger: 'change',
  //     },
  //   ],
  //   unit: [
  //     {
  //       type: 'string',
  //       required: true,
  //       message: 'Необходимо выбрать подразделение',
  //       trigger: 'change',
  //     },
  //   ],
  // };

  onMounted(() => {
    getUnits();
  });

  const usersToList = (users) => {
    return users.map((user) => ({value: user.id, label: user.fullName}));
  };

  const loadLeaders = async (name) => {
    try {
      const query = new URLSearchParams();

      if ('' !== name) {
        query.set('name', name);
      }

      const response = await api.user.leaders(query);
      model.employeesList = usersToList(response.user.items);
    } catch (error) {
      console.log(error);
    }
  };

  const searchUser = debounce(
    (value) => {
      loadLeaders(value);
    },
    500
  );

  async function getUnits() {
    try {
      const response = await api.moderatorPage.units();
      model.unitsList = response.units;
    } catch (error) {
      console.log(error);
    }
  }

  // const errors = new ErrorsHandler({
  //   employee: '',
  //   unit: '',
  // });

  const unitsSelectOptions = () => {
    if (model.unitsList?.length === 1) {
      model.unit = model.unitsList[0].value;
      return {
        options: model.unitsList,
        disabled: true,
      };
    }
    return {
      options: model.unitsList,
    };
  };

  const onFinish = async () => {
    try {
      isLoading.value = true;
      await api.administratorPage.saveRequest({
        id: route.params.id,
        status: REQUEST_STATUS_LEADER,
        leaderId: model.employee,
      });

      emit('close');
      emit('update');
      await router.replace({ name: 'administrator-requests' });
    } catch (error) {
      console.log(error);
      // errors.handleApiErrors(error);
    } finally {
      isLoading.value = false;
    }
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  .project-manager__form {
    padding: 112px 17px 0 17px;

    .form__title {
      h3 {
        font: 700 18px/26px 'Nunito Sans';
      }
    }

    .form__fields {
      margin-top: 35px;

      #organizationAbout {
        height: 124px;
        resize: none;
      }

      .ant-form-item {
        margin: 0;
      }

      .form__button {
        button {
          margin-top: 10px;
        }
      }
    }
  }
</style>
