import MainPage from '@/views/portal/MainPage';
import DepartmentsPage from '@/views/portal/DepartmentsPage.vue';
import DepartmentPage from '@/views/portal/DepartmentPage.vue';
import PersonsPage from '@/views/portal/PersonsPage.vue';
import PersonPage from '@/views/portal/PersonPage.vue';
import PublicationsPage from "@/views/portal/PublicationsPage.vue";
import PublicationPage from "@/views/portal/PublicationPage.vue";
import IntellectualsPage from "@/views/portal/IntellectualsPage.vue";
import IntellectualPage from "@/views/portal/IntellectualPage.vue";
import SearchPage from "@/views/portal/SearchPage.vue";

const module = 'pure';

export const portalRoutes = [
  {
    path: '/portal',
    name: 'portal-main-page',
    component: MainPage,
    meta: { requiresAuth: false, module: module },
  },
  {
    path: '/portal/departments',
    name: 'portal-departments-page',
    component: DepartmentsPage,
    meta: { requiresAuth: false, module: module },
  },
  {
    path: '/portal/department/:id',
    name: 'portal-department-page',
    component: DepartmentPage,
    meta: { requiresAuth: false, module: module },
  },
  {
    path: '/portal/department/:id/:tab',
    name: 'portal-department-tab-page',
    component: DepartmentPage,
    meta: { requiresAuth: false, module: module },
  },
  {
    path: '/portal/persons',
    name: 'portal-persons-page',
    component: PersonsPage,
    meta: { requiresAuth: false, module: module },
  },
  {
    path: '/portal/person/:id',
    name: 'portal-person-page',
    component: PersonPage,
    meta: { requiresAuth: false, module: module },
  },
  {
    path: '/portal/person/:id/:tab',
    name: 'portal-person-page-tab',
    component: PersonPage,
    meta: { requiresAuth: false, module: module },
  },
  {
    path: '/portal/publications',
    name: 'portal-publications-page',
    component: PublicationsPage,
    meta: { requiresAuth: false, module: module },
  },
  {
    path: '/portal/publication/:id',
    name: 'portal-publication-page',
    component: PublicationPage,
    meta: { requiresAuth: false, module: module },
  },
  {
    path: '/portal/publication/:id/:tab',
    name: 'portal-publication-page-tab',
    component: PublicationPage,
    meta: { requiresAuth: false, module: module },
  },
  {
    path: '/portal/intellectuals',
    name: 'portal-intellectuals-page',
    component: IntellectualsPage,
    meta: { requiresAuth: false, module: module },
  },
  {
    path: '/portal/intellectual/:id',
    name: 'portal-intellectual-page',
    component: IntellectualPage,
    meta: { requiresAuth: false, module: module },
  },
  {
    path: '/portal/intellectual/:id/:tab',
    name: 'portal-intellectual-page-tab',
    component: IntellectualPage,
    meta: { requiresAuth: false, module: module },
  },
  {
    path: '/portal/search',
    name: 'portal-search-page',
    component: SearchPage,
    meta: { requiresAuth: false, module: module },
  },
];
