<template>
  <a :class="classObject" @click.prevent="goToPage(pageName)">
    <slot />
  </a>
</template>

<script setup>
  import { computed } from 'vue';
  import { useRouter } from 'vue-router';
  // Базовый компонент навигации. Блочная и встроенная ссылка

  const props = defineProps({
    class: {
      type: String,
      default: '',
    },
    isBlock: {
      type: Boolean,
      default: false,
    },
    pageName: String,
  });

  const router = useRouter();

  const classObject = computed(() => {
    return {
      'navigation-item': true,
      'navigation-item_block': props.isBlock,
      [props.class]: true,
    };
  });

  function goToPage(name) {
    router.push({ name });
  }
</script>

<style lang="scss">
  .navigation-item_block {
    display: block;
  }
</style>
