<template>
  <a-row class="performer-projects-layout">
    <a-col flex="250px" class="performer-projects-layout__left-side">
      <SideBar :items="sidebarItems" />
    </a-col>
    <a-col flex="auto" class="performer-projects-layout__right-side">
      <div class="performer-projects-layout__header">
        <HeaderPartnerAccount />
      </div>
      <div ref="scrollElement" class="performer-projects-layout__content">
        <a-spin
          :spinning="spinning"
          class="custom-spinner"
          style="height: 100vh; top: 20%"
        >
          <slot></slot>
        </a-spin>
      </div>
    </a-col>
  </a-row>
</template>

<script setup>
  import { onMounted, onUnmounted, ref } from 'vue';
  import SideBar from '@/components/sidebar/SideBar';
  import HeaderPartnerAccount from '@/components/header/HeaderPartnerAccount';

  document.title = 'Цифровой университет. НГУ';

  defineProps({
    spinning: {
      type: Boolean,
      default: false,
    },
  });
  const emit = defineEmits(['list-update'])

  const scrollElement = ref(null);

  onMounted(() => {
    window.addEventListener('scroll', handleScroll)
  })

  onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll)
  })

  const handleScroll = () => {
    const elementBottom = scrollElement.value?.getBoundingClientRect().bottom;
    if (elementBottom && elementBottom < window.innerHeight) {
      emit('list-update');
    }
  }

  const sidebarItems = ref([
    {
      id: 1,
      text: 'Мои задачи',
      path: 'performer-tasks',
      type: 'tasks',
      count: 0,
    },
    {
      id: 1,
      text: 'Мои проекты',
      path: 'performer-projects',
      type: 'projects',
      count: 0,
    },
  ]);
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  .performer-projects-layout {
    min-height: 100vh;
    flex-wrap: nowrap;

    .performer-projects-layout__header {
      border-bottom: 1px solid $color-bg-secondary-grey;
      background-color: $color-bg-primary-white;
      position: sticky;
      padding: 17px 40px 17px 20px;
      top: 0;
      z-index: 1;
    }

    .performer-projects-layout__content {
      padding: 30px 40px 0 20px;
    }
  }
</style>
