<template>
  <a-row class="partner-layout">
    <a-col flex="250px" class="partner-layout__left-side">
      <SideBar :items="sidebarItems" />
    </a-col>
    <a-col flex="auto" class="partner-layout__right-side">
      <div class="partner-layout__header">
        <HeaderPartnerAccount />
      </div>
      <div class="partner-layout__content">
        <a-spin
          :spinning="spinning"
          class="custom-spinner"
          style="height: 100vh; top: 20%"
        >
          <slot></slot>
        </a-spin>
      </div>
    </a-col>
  </a-row>
</template>

<script setup>
  // import { ref } from "vue";
  import SideBar from '@/components/sidebar/SideBar';
  import HeaderPartnerAccount from '@/components/header/HeaderPartnerAccount';

  document.title = 'Цифровой университет. НГУ';

  defineProps({
    spinning: {
      type: Boolean,
      default: false,
    },
  });

  // TODO - 3,4,5 - change for actual name, when they become available
  // Path here - is name from router
  const sidebarItems = [
    {
      id: 1,
      text: 'Запрос на проект',
      path: 'new',
      type: 'request',
    },
    {
      id: 2,
      text: 'Мои проекты',
      path: 'main',
      type: 'projects',
    },
    {
      id: 3,
      text: 'Вакансии',
      path: 'partner-vacancies',
      type: 'vacancies',
      disabled: true,
    },
    {
      id: 4,
      text: 'Отчеты',
      path: 'partner-reports',
      type: 'report',
      disabled: true,
    },

    {
      id: 5,
      text: 'Документы',
      path: 'partner-documents',
      type: 'docs',
      disabled: true,
    },
  ];
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  .partner-layout {
    min-height: 100vh;
    flex-wrap: nowrap;

    .partner-layout__header {
      padding: 17px 40px 17px 20px;
      border-bottom: 1px solid $color-bg-secondary-grey;
    }

    .partner-layout__content {
      padding: 30px 40px 10px 20px;
    }
  }
</style>
