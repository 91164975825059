<template>
  <div class="white-block">
    <slot></slot>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.white-block {
  background: #ffffff;
}
</style>
