<template>
  <h3>Шаг 8. Документы</h3>
  <p>Общая информация о проекте</p>
  <div>
    <a-form class="form performer-project-form" layout="vertical">
      <performer-project-form-row
        :show-available="false"
        :offset-available="false"
      >
        <attachments-input
          :init-attachments="model.attachments"
          :is-editable="false"
          @update="null"
        />
      </performer-project-form-row>
    </a-form>
  </div>
</template>

<script setup>
  import { onMounted, reactive, watch } from 'vue';
  import PerformerProjectFormRow from '../PerformerProjectFormRow.vue';
  import AttachmentsInput from '@/components/attachments/AttachmentsInput.vue';

  const props = defineProps({
    project: {
      type: Object,
      require: true,
    },
  });

  const model = reactive({
    attachments: [],
  });

  onMounted(() => {
    props.project && fillModel();
  });

  watch(
    () => props.project,
    () => {
      props.project && fillModel();
    }
  );

  const fillModel = () => {
    try {
      model.attachments = props.project.attachments;
    } catch (error) {
      console.error('error', error);
    }
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  .attachments {
    display: flex;
    flex-direction: row;
    background-color: $color-bg-secondary-grey;
    border-radius: 7px;
    gap: 44px;
    flex-wrap: wrap;
    padding: 24px;
    margin-top: 20px;
  }

  .ant-upload-text {
    color: #aeb4dd;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    width: 194px;
  }
</style>
