import MainPage from '@/views/projects-showcase/MainPage.vue';
import ProjectPage from '@/views/projects-showcase/ProjectPage.vue';

const module = 'pure';

export const showcaseRoutes = [
  {
    path: '/showcase',
    name: 'showcase-main-page',
    component: MainPage,
    meta: { requiresAuth: false, module: module },
  },
  {
    path: '/showcase/project/:id',
    name: 'project-page',
    component: ProjectPage,
    meta: { requiresAuth: false, module: module },
  },
];
