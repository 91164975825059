<template>
  <div class="project-card">
    <a-row class="project-card__meta">
      <a-col class="project-card__name">
        {{ project.name }}
      </a-col>
      <a-col class="project-card__status" v-if="!isPortfolio">
        <status-label :status="project.status" />
      </a-col>
    </a-row>

    <a-row class="project-card__info">
      <div>
        Номер проекта: <span>{{ project.id }}</span>
      </div>
      <div>
        Тип проекта:
        <span>{{ project.type }}</span>
      </div>
      <div>
        Компания:
        <span>{{ project.organizationName }}</span>
      </div>
      <div>Руководитель проекта</div>
      <project-person
        :avatar="getAvatarUrl(project.leaderAvatarUid)"
        :name="project.leaderName"
        :email="project.leaderEmail"
      />

      <template v-if="isPortfolio">
        <a-col>
          <a-tag
            v-for="(tag, index) in project.tags"
            :key="index"
            class="project-card__tag"
            closable
            @close.prevent
            >{{ `#${tag}` }}
          </a-tag>
        </a-col>
        <a-col>
          <progress-bar-generated
            class="progress-bar"
            :completed="project?.completedCount"
            :completed-overdue="project?.completedOverdueCount"
            :incomplete-overdue="project?.incompleteOverdueCount"
            :incomplete="project?.incompleteCount"
          />
        </a-col>
      </template>
    </a-row>
  </div>
</template>

<script setup>
  import StatusLabel from '@/components/projects/components/StatusLabel.vue';
  import ProjectPerson from '@/components/projects/components/ProjectPerson.vue';
  import ProgressBarGenerated from '@/components/projects/portfolio/components/ProgressBarGenerated.vue';

  defineProps({
    project: Object,
    isPortfolio: {
      type: Boolean,
      default: false,
    },
  });

  const getAvatarUrl = (avatar) =>
    avatar ? `/api/v1/attachment/${avatar}` : null;
</script>

<style lang="scss">
  @import 'project-card.scss';
</style>
