<template>
  <h3>Шаг 6. Ресурсы</h3>
  <p>
    Проверьте информацию в карточке проекта и, если согласны, согласуйте проект.
  </p>
  <div>
    <a-form class="form administrator-project-form" layout="vertical">
      <administrator-project-form-row :show-available="false">
        <a-form-item name="resources" label="Требуемые ресурсы">
          <a-textarea
            v-model:value="model.resources"
            placeholder="Опишите необходимые для выполнения проекта ресурсы"
            :rows="7"
            disabled
          />
        </a-form-item>
      </administrator-project-form-row>
    </a-form>
  </div>
</template>

<script setup>
  import { onMounted, reactive, watch } from 'vue';
  import AdministratorProjectFormRow from '../AdministratorProjectFormRow.vue';

  const model = reactive({
    resources: '',
  });

  const props = defineProps({
    project: {
      type: Object,
      require: true,
    },
  });

  onMounted(() => {
    props.project && fillModel();
  });

  watch(
    () => props.project,
    () => {
      fillModel();
    }
  );

  const fillModel = () => {
    try {
      model.resources = props.project.resources;
    } catch (error) {
      console.error('error', error);
    }
  };
</script>

<style lang="scss" scoped>
  @import './administrator-project-form';
</style>
