<template>
  <div class="project-showcase">
    <div>
      <h3>Опубликовать проект на витрине</h3>

      <div class="description" v-if="route.meta.role === USER_ROLE_LEADER">
        Пожалуйста, опишите свой проект в текстовом поле ниже и обоснуйте,
        почему он должен быть выставлен на витрине. Если Вы набираете
        исполнителей в команду проекта, отметьте это, а таже опишите,
        специалисты с какими комптенциями Вам нужны и какие будут стоять задачи
        перед ними.
      </div>

      <div v-if="showcaseIsVisible">
        <slot></slot>
      </div>

      <a-form
        class="form project-showcase-form"
        layout="vertical"
        :model="showcase"
        :rules="rules"
        @validate="errors.onValidate"
        @finish="onFinish"
      >
        <project-showcase-form-row :available="true">
          <template v-slot:main>
            <a-form-item
              name="description"
              label="О проекте"
              class="project-showcase-form-row"
              :help="errors.getError('description')"
              :validate-status="errors.getStatus('description')"
            >
              <a-textarea
                v-model:value="showcase.description"
                placeholder="Опишите проект здесь"
                :autosize="{ minRows: 5, maxRows: 10 }"
                style="padding: 10px"
                :disabled="isDisabled"
              />
            </a-form-item>
          </template>
          <template v-slot:sub>
            <a-col class="project-showcase-form__checkboxes">
              <c-checkbox
                :checked="showcase.isLookingForPartner"
                :disabled="isDisabled"
                @change="
                  () =>
                    (showcase.isLookingForPartner =
                      !showcase.isLookingForPartner)
                "
              >
                Ищем партнеров
              </c-checkbox>
              <c-checkbox
                :checked="showcase.isLookingForPerformer"
                :disabled="isDisabled"
                @change="
                  () =>
                    (showcase.isLookingForPerformer =
                      !showcase.isLookingForPerformer)
                "
              >
                Ищем исполнителей
              </c-checkbox>
              <c-checkbox
                :checked="showcase.isWaiting"
                :disabled="isDisabled"
                @change="() => (showcase.isWaiting = !showcase.isWaiting)"
              >
                Ждем похожие запросы
              </c-checkbox>
            </a-col>
          </template>
        </project-showcase-form-row>

        <project-showcase-form-row :available="true">
          <template v-slot:main>
            <a-form-item
              name="expectedResults"
              label="Ожидаемые результаты"
              class="project-showcase-form-row"
              :help="errors.getError('expectedResults')"
              :validate-status="errors.getStatus('expectedResults')"
            >
              <a-textarea
                v-model:value="showcase.expectedResults"
                placeholder="Опишите проект здесь"
                :autosize="{ minRows: 5, maxRows: 10 }"
                style="padding: 10px"
                :disabled="isDisabled"
              />
            </a-form-item>
          </template>
          <template v-slot:sub />
        </project-showcase-form-row>

        <project-showcase-form-row :available="true">
          <template v-slot:main>
            <a-form-item
              name="tags"
              label="Ключевые слова"
              class="project-showcase-form-row"
              :help="errors.getError('tags')"
              :validate-status="errors.getStatus('tags')"
            >
              <c-input-tags
                placeholder="Задайте ключевые слова проекту"
                :tags="showcase.tags"
                :disabled="isDisabled"
                @update="updateTags"
              />
            </a-form-item>
          </template>
          <template v-slot:sub />
        </project-showcase-form-row>

        <project-showcase-form-row
          :available="true"
          v-if="route.meta.role === USER_ROLE_LEADER"
        >
          <template v-slot:main>
            <a-form-item class="actions">
              <!--              <c-button-danger-->
              <!--                v-if="-->
              <!--                  showcase.statusId === SHOWCASE_STATUS_PUBLISHED &&-->
              <!--                  publicShowcase-->
              <!--                "-->
              <!--                class="actions-remove_button"-->
              <!--                :loading="isLoading"-->
              <!--                :disabled="true"-->
              <!--                @click="remove"-->
              <!--              >-->
              <!--                Убрать с витрины-->
              <!--              </c-button-danger>-->

              <c-button-primary
                v-if="
                  [
                    SHOWCASE_STATUS_DRAFT,
                    SHOWCASE_STATUS_REJECTED,
                    SHOWCASE_STATUS_REMOVED,
                  ].includes(showcase.statusId)
                "
                html-type="submit"
                :loading="isLoading"
                :disabled="isDisabled"
              >
                {{
                  showcase.statusId === SHOWCASE_STATUS_DRAFT
                    ? 'Согласовать'
                    : 'Согласовать повторно'
                }}
              </c-button-primary>

              <c-button-primary
                v-if="showcase.statusId === SHOWCASE_STATUS_PUBLISHED"
                :loading="isLoading"
                @click="edit"
              >
                Изменить
              </c-button-primary>
            </a-form-item>
          </template>
          <template v-slot:sub />
        </project-showcase-form-row>
      </a-form>
    </div>
  </div>
</template>

<script setup>
  import { computed, onMounted, reactive, ref, watch } from 'vue';
  import { useRoute } from 'vue-router';
  import { useApi } from '@/api/use-api';
  import CButtonPrimary from '@/components/UI/CButtonPrimary';
  import ErrorsHandler from '@/components/form/errors-handler';
  import { TEXT_LENGTH_ERROR } from '@/constants/error';
  import CInputTags from '@/components/UI/CInputTags.vue';
  import ProjectShowcaseFormRow from '@/components/projects/showcase/ProjectShowcaseFormRow.vue';
  import CCheckbox from '@/components/UI/CCheckbox.vue';
  import {
    USER_ROLE_ADMINISTRATOR,
    USER_ROLE_LEADER,
  } from '@/api/models/UserRole';
  import {
    SHOWCASE_STATUS_AGREEMENT,
    SHOWCASE_STATUS_DRAFT,
    SHOWCASE_STATUS_PUBLISHED,
    SHOWCASE_STATUS_REJECTED,
    SHOWCASE_STATUS_REMOVED,
  } from '@/api/models/ProjectShowcaseStatus';
  // import CButtonDanger from '@/components/UI/CButtonDanger.vue';

  const props = defineProps({
    showcase: {
      type: Object,
      default: null,
    },
    publicShowcase: {
      type: Object,
      default: null,
    },
  });

  const api = useApi();
  const route = useRoute();
  const emit = defineEmits(['update']);

  const showcase = reactive({
    id: null,
    statusId: 0,
    description: '',
    expectedResults: '',
    isLookingForPartner: true,
    isLookingForPerformer: true,
    isWaiting: true,
    tags: [],
    approval: null,
  });
  const publicShowcase = reactive({
    id: null,
    statusId: null,
  });
  const isLoading = ref(false);
  const showcaseIsVisible = ref(false);

  const isDisabled = computed(() => {
    return (
      !props.showcase ||
      route.meta.role === USER_ROLE_ADMINISTRATOR ||
      [SHOWCASE_STATUS_AGREEMENT, SHOWCASE_STATUS_PUBLISHED].includes(
        showcase.statusId
      )
    );
  });

  onMounted(async () => {
    fillShowcase();
  });

  watch(
    () => props.showcase,
    () => {
      fillShowcase();
      fillPublicShowcase();
    }
  );

  watch(
    () => props.publicShowcase,
    () => {
      fillPublicShowcase();
    }
  );

  const fillShowcase = () => {
    if (!props.showcase) return;

    try {
      showcase.id = props.showcase.id;
      showcase.statusId = props.showcase.statusId;
      showcase.description = props.showcase.description;
      showcase.expectedResults = props.showcase.expectedResults;
      showcase.isLookingForPartner = props.showcase.isLookingForPartner;
      showcase.isLookingForPerformer = props.showcase.isLookingForPerformer;
      showcase.isWaiting = props.showcase.isWaiting;
      showcase.tags = props.showcase.tags;
      showcase.approval = props.showcase.approvals[0];
      showcaseIsVisible.value = true;
    } catch (error) {
      console.error('error', error);
    }
  };

  const fillPublicShowcase = () => {
    if (!props.publicShowcase) return;

    try {
      publicShowcase.id = props.publicShowcase.id;
      publicShowcase.statusId = props.showcase.statusId;
    } catch (error) {
      console.error('error', error);
    }
  };

  const rules = {
    description: [
      {
        required: true,
        min: 2,
        max: 5000,
        message: TEXT_LENGTH_ERROR,
        trigger: 'change',
      },
    ],
    expectedResults: [
      {
        required: true,
        min: 2,
        max: 5000,
        message: TEXT_LENGTH_ERROR,
        trigger: 'change',
      },
    ],
  };

  const errors = new ErrorsHandler({
    description: '',
    expectedResults: '',
    tags: '',
  });

  const onFinish = async () => {
    try {
      const dataToSave = {
        showcaseId: showcase.id,
        description: showcase.description,
        expectedResults: showcase.expectedResults,
        isLookingForPartner: showcase.isLookingForPartner,
        isLookingForPerformer: showcase.isLookingForPerformer,
        isWaiting: showcase.isWaiting,
        tags: showcase.tags,
      };

      if (showcase.statusId === SHOWCASE_STATUS_DRAFT) {
        await api.showcase.saveAndApprove(dataToSave);
      }
      if (
        showcase.statusId === SHOWCASE_STATUS_REJECTED ||
        showcase.statusId === SHOWCASE_STATUS_REMOVED
      ) {
        await api.showcase.saveAndReApprove(dataToSave);
      }

      emit('update');
      window.scrollTo({ top: 0, left: 0 });
    } catch (error) {
      errors.handleApiErrors(error);
    }
  };

  const edit = async () => {
    try {
      await api.documentApproval.edit({
        docApprovalId: showcase.approval.id,
      });

      emit('update');
      window.scrollTo({ top: 0, left: 0 });
    } catch (error) {
      errors.handleApiErrors(error);
    }
  };

  // const remove = async () => {
  //   try {
  //     console.log(`remove showcase is not implemented`);
  //   } catch (error) {
  //     errors.handleApiErrors(error);
  //   }
  // };

  const updateTags = (newTags) => {
    showcase.tags = newTags;
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  * {
    margin: 0;
    padding: 0;
  }

  .ant-input {
    font-family: 'Nunito Sans', sans-serif;
    &[disabled] {
      color: $color-text-primary;
    }
  }

  .project-showcase {
    margin-top: 10px;
    padding-bottom: 100px;

    h3 {
      font-size: 18px;
      margin-bottom: 10px;
    }

    h4 {
      font-size: 14px;
      margin: 30px 0 6px;

      &.disabled {
        color: $color-bg-status-new !important;
      }
    }

    .description {
      font-family: 'Nunito Sans', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      max-width: 678px;
    }

    .actions {
      text-align: right;
      margin-top: 26px;

      .actions-remove_button {
        margin-right: 10px;
      }
    }

    .project-showcase-form {
      margin-top: 30px;
    }

    .project-showcase-form-row {
      margin-top: 14px;
    }
  }

  .project-showcase-form__checkboxes {
    & .c-checkbox {
      margin-bottom: 16px;
    }
  }
</style>
