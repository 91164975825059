<template>
  <a-spin
    :spinning="isLoading"
    class="custom-spinner"
    style="height: 100vh; top: 50%"
  >
    <main-title>Мои проекты</main-title>
    <horizontal-filter>
      <template #default>
        <filter-item hash="#new" text="Новые проекты" />
        <filter-item hash="" text="В работе" />
        <filter-item hash="#archive" text="Архив" />
      </template>
      <template #right>
        <label class="panel__total-projects" v-if="totalProjects">
          Всего: {{ totalProjects }}
        </label>
      </template>
    </horizontal-filter>
    <administrator-projects-card-grid :projects="projects" />
  </a-spin>
</template>

<script setup>
  import { onMounted, ref, watch } from 'vue';
  import { useRoute } from 'vue-router';
  import { useApi } from '@/api/use-api';
  import MainTitle from '@/components/titles/MainTitle';
  import HorizontalFilter from '@/components/filter/horizontal/HorizontalFilter';
  import FilterItem from '@/components/filter/components/FilterItem';
  import AdministratorProjectsCardGrid from './AdministratorProjectCardGrid.vue';

  const api = useApi();
  const route = useRoute();
  const isLoading = ref(false);
  const totalProjects = ref(0);
  const projects = ref([]);

  onMounted(() => {
    getProjects();
  });

  watch(route, () => {
    getProjects();
  });

  const getProjects = async () => {
    try {
      isLoading.value = true;
      const tab = route.hash ? route.hash.replace('#', '') : 'inwork';
      const response = await api.administratorPage.projects(tab);
      projects.value = response.projects;
      totalProjects.value = projects.value.length;
    } catch (error) {
      console.error('error', error);
    } finally {
      isLoading.value = false;
    }
  };
</script>

<style lang="scss" scoped></style>
