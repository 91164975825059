<template>
  <div class="portal-top-menu">
    <ul>
      <li>
        <router-link
          to="/portal/departments"
          :class="{ active: $route.path.indexOf('/portal/department') > -1 }"
        >
          <span>Подразделения</span>
        </router-link>
      </li>
      <li>
        <router-link
          to="/portal/persons"
          :class="{ active: $route.path.indexOf('/portal/person') > -1 }"
        >
          <span>Сотрудники</span>
        </router-link>
      </li>
      <li>
        <router-link
          to="/portal/publications"
          :class="{ active: $route.path.indexOf('/portal/publication') > -1 }"
        >
          <span>Публикации</span>
        </router-link>
      </li>
      <li>
        <router-link
          to="/portal/intellectuals"
          :class="{ active: $route.path.indexOf('/portal/intellectual') > -1 }"
        >
          <span>Интеллектуальная собственность</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.portal-top-menu {
  margin-left: auto;
  ul {
    padding: 0;
    margin: 0 -20px;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    li {
      display: block;
      padding: 0;
      a {
        display: block;
        padding: 5px 20px;
        box-sizing: border-box;
        color: #ffffff;
        text-decoration: none;

        span {
        }

        &:hover,
        &.active {
          span {
            border-bottom: 1px solid #ffffff;
          }
        }

        &:focus {
          outline: none;
          span {
            border-bottom: 1px solid #72c81e;
          }
        }
      }
    }
  }
}
</style>
