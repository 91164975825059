<template>
  <a-row :gutter="[20, 20]" class="projects">
    <a-col
      :span="12"
      v-for="(project, index) in projects"
      :key="{ id: index++, ...project }"
      @click="handleProjectClick(project)"
    >
      <project-card :project="project" :isPortfolio="isPortfolio" />
    </a-col>
  </a-row>
</template>

<script setup>
  import { useRouter } from 'vue-router';
  import ProjectCard from './components/ProjectCard.vue';
  import {
    PROJECT_STATUS_ACCEPTANCE,
    PROJECT_STATUS_AGREEMENT,
    PROJECT_STATUS_IN_WORK,
  } from '@/api/models/ProjectStatus';

  defineProps({
    projects: {
      type: Array,
      default: () => [],
    },
    isPortfolio: {
      type: Boolean,
      default: false,
    },
  });

  const router = useRouter();

  const handleProjectClick = async (project) => {
    if (project.projectStatusId === PROJECT_STATUS_AGREEMENT) {
      await router.push(`/administrator/project/` + project.id + `/form#9`);
    } else if (project.projectStatusId === PROJECT_STATUS_ACCEPTANCE) {
      await router.push(`/administrator/project/` + project.id + `/agreement`);
    } else if (project.projectStatusId === PROJECT_STATUS_IN_WORK) {
      await router.push(`/administrator/project/` + project.id + `/management`);
    } else {
      await router.push(`/administrator/project/` + project.id + `/form`);
    }
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  .projects {
    margin-top: 30px;
    padding: 0 0 100px 0;
  }

  .horizontal-filter__panel {
    margin: 0 -40px 0 -20px;
    padding: 8px 40px 8px 20px;
    border-top: 1px solid $color-bg-secondary-grey;
    border-bottom: 1px solid $color-bg-secondary-grey;
  }
</style>
