<template>
  <h3>Шаг 8. Документы</h3>
  <p>Прикрепите нужные документы.</p>
  <div>
    <a-form
      class="form leader-project-form"
      layout="vertical"
      :model="model"
      :rules="rules"
      @validate="errors.onValidate"
      @finish="onFinish"
    >
      <leader-project-form-row
        :show-available="model.isExternal && !disabled"
        :available="model.availabilityList['attachments']"
        :offset-available="false"
        :disabled="disabled"
        @change="changeAvailability('attachments')"
      >
        <attachments-input
          :error="errors.getError('attachmentUids')"
          :init-attachments="model.attachments"
          :is-editable="!disabled"
          :accept="ATTACHMENT_COMMON_EXTENSIONS"
          @update="handleUpdateAttachments"
        />
      </leader-project-form-row>

      <br /><br />
      <leader-project-form-row :show-available="false">
        <leader-project-form-buttons
          v-if="project?.isMyProject"
          :project-status-id="project?.projectStatusId"
          :loading="isLoading"
          @click="setGoNext"
          @confirm-update="() => emit('confirm-update')"
        />
      </leader-project-form-row>
    </a-form>
  </div>
</template>

<script setup>
  import { onMounted, reactive, ref, watch } from 'vue';
  import { useRouter } from 'vue-router';
  import { useApi } from '@/api/use-api';
  import LeaderProjectFormRow from '@/views/leader/projects/form/LeaderProjectFormRow.vue';
  import ErrorsHandler from '@/components/form/errors-handler';
  import LeaderProjectFormButtons from '@/views/leader/projects/form/LeaderProjectFormButtons.vue';
  import AttachmentsInput from '@/components/attachments/AttachmentsInput.vue';
  import rules from './rules/RulesStep7';
  import { ATTACHMENT_COMMON_EXTENSIONS } from '@/components/attachments/AttachmentExtentions';

  const router = useRouter();
  const emit = defineEmits(['update', 'confirm-update']);
  const isLoading = ref(false);

  const props = defineProps({
    project: {
      type: Object,
      require: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  });

  watch(
    () => props.project,
    () => {
      fillModel();
    }
  );

  const model = reactive({
    id: null,
    isExternal: false,
    docsStepPassed: false,
    attachments: [],
    attachmentUids: [],
    availabilityList: [],
  });

  const api = useApi();
  const errors = new ErrorsHandler({
    attachmentUids: '',
  });

  const goNext = ref(false);
  const setGoNext = (value) => {
    goNext.value = !!value;
  };

  onMounted(() => {
    props.project && fillModel();
  });

  const fillModel = () => {
    try {
      model.id = props.project.id;
      model.isExternal = props.project.isExternal;
      model.docsStepPassed = props.project.docsStepPassed;
      model.attachments = props.project.attachments;
      model.attachmentUids = props.project.attachments.map((item) => item.uid);
      model.availabilityList = props.project.availabilityList;
    } catch (error) {
      console.error('error', error);
    }
  };

  const changeAvailability = (key) => {
    model.availabilityList[key] = !model.availabilityList[key];
  };

  const onFinish = async () => {
    try {
      isLoading.value = true;
      await api.leaderPage.updateProject({
        id: model.id,
        docsStepPassed: true,
        attachmentUids: model.attachmentUids,
        isAttachmentsAvailable: model.availabilityList['attachments'],
      });
      emit('update', model);
      if (goNext.value) {
        await router.push({ hash: '#9' });
      }
    } catch (error) {
      errors.handleApiErrors(error);
    } finally {
      isLoading.value = false;
    }
  };

  function handleUpdateAttachments(uidList) {
    model.attachmentUids = uidList;
  }

  defineExpose({ onFinish });
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  .attachments {
    display: flex;
    flex-direction: row;
    background-color: $color-bg-secondary-grey;
    border-radius: 7px;
    gap: 44px;
    flex-wrap: wrap;
    padding: 24px;
    margin-top: 20px;
  }

  .ant-upload-text {
    color: #aeb4dd;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    width: 194px;
  }
</style>
