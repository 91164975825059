<template>
  <a-col :span="24" ref="attachmentsRef">
    <a-form-item
      v-if="isEditable"
      name="attachments"
      :help="errors.getError('attachments')"
      :validate-status="errors.getStatus('attachments')"
    >
      <a-upload-dragger
        v-model:fileList="state.attachments"
        :multiple="false"
        :show-upload-list="false"
        :show-download-icon="true"
        :accept="accept"
        :custom-request="fileUpload"
        :before-upload="beforeUpload"
        @change="handleAddFile"
        @reject="handleReject"
      >
        <div class="ant-upload-text">
          <div>Нажмите или перетащите, <br />чтобы загрузить файлы</div>
          <div v-if="accept">({{ accept }})</div>
        </div>
      </a-upload-dragger>
    </a-form-item>
    <div class="attachments" v-if="state.attachments.length && showFiles">
      <attachment-file
        v-for="file in state.attachments"
        :file="file"
        :key="file.uid"
        :is-editable="isEditable"
        @remove="handleRemoveFile(file)"
      />
    </div>
  </a-col>
</template>

<script setup>
  import { onMounted, watch, reactive, ref } from 'vue';
  import AttachmentFile from '@/components/attachment-file/AttachmentFile.vue';
  import { useApi } from '@/api/use-api';
  import ErrorsHandler from '@/components/form/errors-handler';
  import { onClickOutside } from '@vueuse/core';

  const attachmentsRef = ref(null);
  const props = defineProps({
    initAttachments: {
      type: Array,
      default: () => [],
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
    accept: {
      type: String,
      default: '',
    },
    showFiles: {
      type: Boolean,
      default: true,
    },
  });

  const api = useApi();
  const state = reactive({
    attachments: [],
    addedUids: [],
  });
  const errors = new ErrorsHandler({
    attachments: '',
  });

  const emit = defineEmits(['update']);

  onMounted(() => {
    setAttachments();
  });

  watch(
    () => props.initAttachments?.length,
    () => setAttachments()
  );

  watch(
    () => props.error,
    () => errors.setError('attachments', props.error)
  );

  onClickOutside(attachmentsRef, () => {
    errors.setError('attachments', '');
  });

  const getUidList = () => {
    return state.attachments.length > 0
      ? state.attachments.map((item) => item.uid)
      : [];
  };

  async function fileUpload({ onSuccess, onError, file }) {
    try {
      const formData = new FormData();
      formData.append('file', file);
      const result = await api.attachment.saveAttachment(formData);
      onSuccess(result);
    } catch (e) {
      onError(e);
    }
  }

  function setAttachments() {
    if (!props.initAttachments) return;

    const currentUids = state.attachments.map((item) => item.uid);
    props.initAttachments.forEach((item) => {
      if (!currentUids.includes(item.uid)) {
        state.attachments.push({
          uid: item.uid,
          name: item.filename,
          percent: 100,
          status: 'done',
          type: item.content_type,
          lastModified: item.created_at,
        });
      }
    });
  }

  function handleReject() {
    errors.setError(
      'attachments',
      'Разрешены следующие типы файлов: ' + props.accept
    );
  }

  function handleAddFile(info) {
    if (!info.file.status && !info.file.originFileObj) {
      removeFileFromList(info.file);
    } else if (info.file.status === 'done') {
      info.file.uid = info.file.response.uid;
      state.addedUids.push(info.file.response.uid);
      emit('update', getUidList());
      errors.setError('attachments', '');
    } else if (info.file.status === 'error') {
      removeFileFromList(info.file);
      errors.setError('attachments', info.file.error.message);
    }
  }

  function beforeUpload(file) {
    const checkResult = checkFileExt(file);
    if (!checkResult) {
      handleReject();
    }

    return checkResult;
  }

  async function handleRemoveFile(file) {
    try {
      if (state.addedUids.includes(file.uid)) {
        state.addedUids = state.addedUids.filter((_uid) => _uid !== file.uid);
        await api.attachment.deleteAttachment('/attachment/' + file.uid);
      }
      removeFileFromList(file);
    } catch (error) {
      errors.setError('attachments', error.message);
    }
  }

  const checkFileExt = (file) => {
    if (!props.accept) return true;

    const extList = props.accept
      .split(',')
      .map((ext) => ext.trim().replace('.', '').toLowerCase());

    if (file.name.includes('.')) {
      const ext = file.name
        .substring(file.name.lastIndexOf('.') + 1)
        .toLowerCase();
      return extList.includes(ext);
    } else {
      return false;
    }
  };

  const removeFileFromList = (file) => {
    state.attachments = state.attachments.filter(
      (item) => item.uid !== file.uid
    );
    emit('update', getUidList());
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  .ant-upload-text {
    color: #aeb4dd;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    width: 80%;
  }

  .attachments {
    align-items: center;
    display: flex;
    background-color: $color-bg-secondary-grey;
    border-radius: 7px;
    gap: 44px;
    min-height: 125px;
    justify-content: center;
    margin-bottom: 24px;
    flex-wrap: wrap;
    padding: 1rem;
  }
</style>
