<template>
  <div class="navbar">
    <ul>
      <li>
        <router-link
          to="/showcase"
          :class="{ active: $route.path.indexOf('/portal/department') > -1 }"
        >
          <span>Активные проекты</span>
        </router-link>
      </li>
      <li>
        <router-link
          to="/showcase"
          :class="{ active: $route.path.indexOf('/portal/person') > -1 }"
        >
          <span>Завершенные проекты</span>
        </router-link>
      </li>
      <li>
        <router-link
          to="/showcase"
          :class="{ active: $route.path.indexOf('/portal/publication') > -1 }"
        >
          <span>О сервисе</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
  .navbar {
    margin-left: auto;
    ul {
      padding: 0;
      margin: 0 -20px;
      list-style-type: none;
      display: flex;
      flex-direction: row;
      li {
        display: block;
        padding: 0;
        a {
          display: block;
          padding: 5px 20px;
          box-sizing: border-box;
          color: #ffffff;
          text-decoration: none;

          &:hover,
          &.active {
            span {
              border-bottom: 1px solid #ffffff;
            }
          }

          &:focus {
            outline: none;
            span {
              border-bottom: 1px solid #72c81e;
            }
          }
        }
      }
    }
  }
</style>
