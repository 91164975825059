<template>
  <div class="project-form__menu">
    <router-link
      v-for="item in model.menuSteps"
      :key="`project-menu-` + item.step"
      :to="`#` + item.step"
      :class="{ active: item.step === step }"
    >
      <done-circle-icon
        :hide="!isVisible(item.step)"
        :completed="item.completed"
      />
      {{ item.label }}
    </router-link>
  </div>
</template>

<script setup>
  import { useRoute } from 'vue-router';
  import DoneCircleIcon from '@/components/done-circle/DoneCircleIcon.vue';
  import { computed, onMounted, reactive, watch } from 'vue';
  import Schema from 'async-validator';
  import rulesStep1 from '@/views/leader/projects/form/steps/rules/RulesStep1';
  import rulesStep2 from '@/views/leader/projects/form/steps/rules/RulesStep2';
  import rulesStep3 from '@/views/leader/projects/form/steps/rules/RulesStep3';
  import rulesStep4 from '@/views/leader/projects/form/steps/rules/RulesStep4';
  import rulesStep5 from '@/views/leader/projects/form/steps/rules/RulesStep5';
  import rulesStep6 from '@/views/leader/projects/form/steps/rules/RulesStep6';
  import rulesStep7 from '@/views/leader/projects/form/steps/rules/RulesStep7';
  import rulesStep8 from '@/views/leader/projects/form/steps/rules/RulesStep8';
  import rulesStep9 from '@/views/leader/projects/form/steps/rules/RulesStep9';

  const props = defineProps({
    project: Object,
    menuSteps: Object,
  });

  const emit = defineEmits(['validate']);
  const route = useRoute();
  const model = reactive({
    menuSteps: [],
  });

  onMounted(() => {
    fillSteps();
  });

  const fillSteps = () =>
    (model.menuSteps = props.menuSteps.map((item) => ({
      ...item,
      completed: false,
    })));

  watch(
    () => props.project,
    async (newValue) => {
      for (const stepItem of model.menuSteps) {
        await checkStep(newValue, stepItem.step);
      }
      // Checks only first 8 steps
      const projectIsValid = model.menuSteps
        .filter((item) => item.step < 9 && item.validate)
        .reduce((acc, item) => acc && item.completed, true);
      emit('validate', projectIsValid);
    }
  );

  const checkStep = async (project, stepId) => {
    const stepItem = model.menuSteps.find((item) => item.step === stepId);
    if (!stepItem.validate) return;

    const validator = new Schema(getStepRules(stepId));
    const result = await validator
      .validate(project, (errors) => {
        if (errors)
          console.debug(`ProjectMenu.checkStep(${stepId}): errors=`, errors);
      })
      .catch((reason) =>
        console.debug(
          `ProjectMenu.checkStep(${stepId}).catch: ${reason.message}`
        )
      );
    stepItem.completed = !!result;
  };

  const getStepRules = (stepId) => {
    switch (stepId) {
      case 1:
        return rulesStep1;
      case 2:
        return rulesStep2;
      case 3:
        return rulesStep3;
      case 4:
        return rulesStep4;
      case 5:
        return rulesStep5;
      case 6:
        return rulesStep6;
      case 7:
        return rulesStep7;
      case 8:
        return rulesStep8;
      case 9:
        return rulesStep9;
    }
  };

  const step = computed(() => {
    return route.hash ? Number(route.hash.replace('#', '')) : 1;
  });

  const isVisible = (stepId) => {
    const modelStep = model.menuSteps.find((item) => item.step === stepId);
    return !modelStep.hide && (stepId === step.value || modelStep.completed);
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  .project-form__menu {
    background: #f2f4f8;
    height: calc(100vh - 75px);
    position: sticky;
    padding: 14px 6px;
    top: 75px;

    a {
      color: $color-text-primary;
      font-variant-numeric: slashed-zero;
      font-family: 'Nunito Sans', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.4;

      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      margin: 6px 0;
      padding: 6px 6px 6px 30px;
      border-radius: 4px;

      &:hover,
      &.active {
        background: #ffffff;
        font-weight: 700;
      }

      :deep(.done-circle-icon) {
        margin-left: -24px;
        margin-right: 9px;
      }
    }
  }
</style>
