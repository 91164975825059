<template>
  <h3>Шаг 3. Календарный план-график</h3>
  <p>Сформируйте план-график выполнения проекта</p>
  <div>
    <a-form
      class="form leader-project-form"
      layout="vertical"
      :model="model"
      :rules="rules"
      @validate="errors.onValidate"
      @finish="onFinish"
      :scrollToFirstError="{
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      }"
    >
      <leader-project-form-row
        :show-available="model.isExternal"
        :available="model.isDateAvailable"
        :disabled="disabled"
        @change="() => (model.isDateAvailable = !model.isDateAvailable)"
        class="bordered"
      >
        <a-row>
          <a-col :span="12">
            <a-form-item
              name="startDate"
              label="Старт проекта"
              :help="errors.getError('startDate')"
              :validate-status="errors.getStatus('startDate')"
            >
              <a-date-picker
                v-model:value="model.startDate"
                placeholder="Выберите дату"
                format="DD.MM.YYYY"
                :locale="locale"
                :disabled-date="disabledProjectStartDate"
                :disabled="disabled"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              name="endDate"
              label="Завершение проекта"
              :help="errors.getError('endDate')"
              :validate-status="errors.getStatus('endDate')"
            >
              <a-date-picker
                v-model:value="model.endDate"
                placeholder="Выберите дату"
                format="DD.MM.YYYY"
                :locale="locale"
                :disabled-date="disabledProjectEndDate"
                :disabled="disabled"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </leader-project-form-row>

      <template v-for="(stage, index) in model.stages" :key="`stage-` + index">
        <a-row>
          <h3>Этап {{ index + 1 }}</h3>
          <c-button-remove
            v-if="!disabled"
            class="button-remove"
            @click="removeStage(stage)"
          />
        </a-row>

        <stage-input-row
          :show-available="model.isExternal"
          ref="stageRefs"
          :index="index"
          :max-index="model.stages.length - 1"
          :data="stage"
          :project-start-date="model.startDate"
          :project-end-date="model.endDate"
          :api-errors="stageErrors.getApiError(index)"
          :disabled="disabled"
          @update="onStageUpdate"
          class="bordered"
        />
      </template>

      <leader-project-form-row v-if="!disabled" :show-available="false">
        <a-form-item>
          <c-button-add @click="addEmptyStage">Добавить этап</c-button-add>
        </a-form-item>
      </leader-project-form-row>

      <leader-project-form-row :show-available="false">
        <leader-project-form-buttons
          v-if="project?.isMyProject"
          :project-status-id="project?.projectStatusId"
          :loading="isLoading"
          @click="setGoNext"
          @confirm-update="() => emit('confirm-update')"
        />
      </leader-project-form-row>
    </a-form>
  </div>
</template>

<script setup>
  import { onMounted, reactive, ref, watch } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useApi } from '@/api/use-api';
  import locale from 'ant-design-vue/es/date-picker/locale/ru_RU';
  import ErrorsHandler from '@/components/form/errors-handler';
  import LeaderProjectFormRow from '@/views/leader/projects/form/LeaderProjectFormRow.vue';
  import CButtonAdd from '@/components/UI/CButtonAdd.vue';
  import CButtonRemove from '@/components/UI/CButtonRemove.vue';
  import dayjs from 'dayjs';
  import LeaderProjectFormButtons from '@/views/leader/projects/form/LeaderProjectFormButtons.vue';
  import rules from './rules/RulesStep3';
  import StageInputRow from '@/views/leader/projects/form/steps/inputs/StageInputRow.vue';
  import { useTableErrorHandler } from '@/composables/tableErrorHandler';

  const router = useRouter();
  const route = useRoute();
  const api = useApi();
  const emit = defineEmits(['update', 'confirm-update']);
  const stageRefs = ref([]);
  const isLoading = ref(false);

  const props = defineProps({
    project: {
      type: Object,
      require: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  });

  const model = reactive({
    id: null,
    isExternal: false,
    startDate: null,
    endDate: null,
    isDateAvailable: true,
    stages: [],
  });

  const errors = new ErrorsHandler({
    startDate: '',
    endDate: '',
  });

  const stageErrors = useTableErrorHandler();

  onMounted(() => {
    props.project && fillModel();
  });

  watch(
    () => props.project,
    () => {
      fillModel();
    }
  );

  watch(
    () => stageRefs.value.length,
    () => {
      validateForms();
    }
  );

  const fillModel = () => {
    try {
      model.id = props.project.id;
      model.isExternal = props.project.isExternal;
      model.startDate = props.project.startDate
        ? dayjs(props.project.startDate)
        : null;
      model.endDate = props.project.endDate
        ? dayjs(props.project.endDate)
        : null;
      model.isDateAvailable = props.project.availabilityList['date'];

      model.stages = [];
      stageErrors.reset();
      if (props.project.stages.length > 0) {
        model.stages = props.project.stages.map((item) => {
          return {
            ...item,
            startDate: dayjs(item.startDate),
            endDate: dayjs(item.endDate),
          };
        });
        stageErrors.init(model.stages);
      } else {
        addEmptyStage();
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  const goNext = ref(false);
  const setGoNext = (value) => {
    goNext.value = !!value;
  };

  const addEmptyStage = () => {
    model.stages.push({
      id: null,
      name: '',
      stageId: null,
      startDate: null,
      endDate: null,
      isAvailable: true,
    });
    stageErrors.addHandler();
  };

  const removeStage = (stage) => {
    const stageId = model.stages.indexOf(stage);
    model.stages.splice(stageId, 1);
    stageErrors.removeHandler(stageId);
    if (model.stages.length === 0) {
      addEmptyStage();
    }
  };

  const onFinish = async () => {
    isLoading.value = true;
    const saveStepResult = await saveStep();

    let saveStagesResult = false;
    if (!stageErrors.inputsHaveErrors()) {
      saveStagesResult = await saveStages();
    } else {
      console.log(`Stages Form has errors.`);
    }

    if (saveStepResult && saveStagesResult) {
      emit('update', model);
      if (goNext.value) {
        await router.push({ hash: '#4' });
      }
    }
    isLoading.value = false;
  };

  const saveStep = async () => {
    try {
      await api.leaderPage.updateProject({
        id: model.id,
        startDate: dayjs(model.startDate).format('YYYY-MM-DD'),
        endDate: dayjs(model.endDate).format('YYYY-MM-DD'),
        isDateAvailable: model.isDateAvailable,
      });
      return true;
    } catch (error) {
      errors.handleApiErrors(error);
      return false;
    }
  };

  const saveStages = async () => {
    stageErrors.clearApiErrors();
    // Если у последнего этапа не заполнены поля, то мы его не записываем
    const lastStage = model.stages.at(-1);
    const stagesToSave =
      lastStage.name || lastStage.startDate || lastStage.endDate
        ? model.stages
        : model.stages.slice(0, -1);

    // Data Normalization
    stagesToSave.forEach((item, index) => {
      item.stageId = index;
      item.startDate = item.startDate
        ? dayjs(item.startDate).locale('ru').format('YYYY-MM-DD')
        : '';
      item.endDate = item.endDate
        ? dayjs(item.endDate).locale('ru').format('YYYY-MM-DD')
        : '';
    });

    try {
      await api.leaderPage.updateProjectStages({
        projectId: route.params.id,
        payload: stagesToSave,
      });
      return true;
    } catch (error) {
      if (error.tableErrors) {
        stageErrors.setTableApiErrors(error.tableErrors);
      }
      console.error('error', error);
      return false;
    }
  };

  const onStageUpdate = (value) => {
    model.stages[value.index] = { ...model.stages[value.index], ...value.data };
    stageErrors.setInputError(value.index, value.hasError);
  };

  const validateForms = async () => {
    for (let i = 0; i < stageRefs.value.length; i++) {
      await stageRefs.value[i].validateAndUpdate();
    }
  };

  const disabledProjectStartDate = (currentDate) =>
    currentDate && currentDate > dayjs(model.endDate);

  const disabledProjectEndDate = (currentDate) => {
    return currentDate && currentDate < dayjs(model.startDate);
  };

  defineExpose({ onFinish });
</script>

<style lang="scss" scoped>
  @import './leader-project-form';
  .leader-project-form-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    & > button {
      margin-left: 10px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .button-remove {
    margin-left: 10px;
  }
</style>
