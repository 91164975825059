<template>
  <a-drawer
    placement="right"
    :open="open"
    :title="title"
    :maskClosable="false"
    :keyboard="false"
    :closable="false"
    width="500"
  >
    <template #extra>
      <button-close @click="handleClose" />
    </template>
    <slot></slot>
  </a-drawer>
</template>

<script setup>
import ButtonClose from "@/views/portal/components/UI/ButtonClose.vue";

const emit = defineEmits(["update:open"]);

defineProps({
  title: {
    type: String,
    default: "",
  },
  open: {
    type: Boolean,
    default: false,
  },
});

const handleClose = () => {
  emit("update:open", false);
};
</script>

<style lang="scss" scoped></style>
