<template>
  <div class="input-tags">
    <a-input
      v-model:value="newTag"
      @keyup.enter="createTag(tags)"
      :placeholder="placeholder"
    />
    <div class="input-tags__list" v-if="tags.length">
      <a-row class="tags" :gutter="[14, 14]" :wrap="true">
        <a-col v-for="(tag, index) in tags" :key="`tag-` + index">
          <span class="tag" @click="removeTag(tags, tag)"> #{{ tag }} </span>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

defineProps({
  tags: {
    type: Array,
    default: function () {
      return [];
    },
  },
  placeholder: {
    type: String,
    default: "",
  },
});

const newTag = ref("");
const emit = defineEmits(["update"]);

const removeTag = (tags, value) => {
  const newTags = tags.filter((tag) => {
    return tag !== value;
  });
  emit("update", newTags);
};

const createTag = (tags) => {
  const newTags = tags;
  newTags.push(newTag.value);
  newTag.value = "";
  emit("update", newTags);
};
</script>

<style lang="scss" scoped>
.input-tags {
  .input-tags__list {
    margin-top: 14px;
    padding: 14px;
    border-radius: 4px;
    border: 1px solid #aeb4dd;
  }

  .tags {
    .tag {
      background: #f2f4f8;
      color: #2d3346;
      text-align: center;
      font-size: 12px;
      line-height: 1;
      border-radius: 30px;
      padding: 6px 10px;
      white-space: nowrap;
      cursor: pointer;
      margin: 0;

      &:after {
        content: "";
        display: inline-block;
        background: url("./assets/cross-black.svg") no-repeat center center
          transparent;
        width: 10px;
        height: 10px;
        margin: 0 0 0 10px;
      }

      &:hover {
        &:after {
          background: url("./assets/cross-white.svg") no-repeat center center
            transparent;
        }
      }
    }
  }
}
</style>
