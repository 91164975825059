<template>
  <h3>Шаг 9. Согласование</h3>
  <p>
    Согласуйте проект. Вы можете отправить на согласование одновременно
    администратору и партнеру или выбрать нужную Вам очередность согласования.
  </p>
  <div class="project-agreement-grid" v-if="isValid">
    <template
      v-for="(approval, index) in model.approvals"
      :key="`approval-${index}`"
    >
      <agreement-row
        class="agreement-row"
        :status="approval.status"
        :reason="approval.rejectedReason"
        :action="() => handleApproval(approval, index)"
        :reapprove="() => reApprove(approval, index)"
        :action-button-text="'Согласовать'"
        :loading="loaders[index]"
        :error="errors[index].getError('approval')"
      >
        <template #icon>
          <img
            v-if="approval.role === USER_ROLE_PARTNER"
            src="@/assets/icons/icon-person.svg"
            alt=""
          />
          <img v-else src="@/assets/icons/icon-bag.svg" alt="" />
        </template>

        <template #labelText>
          {{ getActionText(approval) }}
        </template>
      </agreement-row>
    </template>
  </div>
</template>

<script setup>
  import ErrorsHandler from '@/components/form/errors-handler';
  import { onMounted, reactive, ref, watch } from 'vue';
  import { useApi } from '@/api/use-api';
  import AgreementRow from '@/components/projects/agreement/AgreementRow.vue';
  import { USER_ROLE_PARTNER } from '@/api/models/UserRole';

  const api = useApi();
  const emit = defineEmits(['update']);
  const errors = ref([]);
  const loaders = ref([]);

  const props = defineProps({
    project: {
      type: Object,
      require: true,
    },
    isValid: {
      type: Boolean,
      default: false,
    },
  });

  watch(
    () => props.project,
    () => {
      fillModel();
    }
  );

  const model = reactive({
    id: null,
    isExternal: true,
    project: null,
    approvals: [],
    administratorApproval: null,
    partnerApproval: null,
  });

  const getActionText = (approval) => {
    return approval.role === USER_ROLE_PARTNER
      ? 'Согласовать проект с партнером'
      : `Согласовать проект с администратором порфеля: ${approval.briefcaseName}`;
  };

  onMounted(() => {
    props.project && fillModel();
  });

  const fillModel = () => {
    try {
      model.id = props.project.id;
      model.isExternal = props.project.isExternal;
      model.project = props.project;
      model.approvals = props.project.approvals;
      model.administratorApproval = props.project.administratorApproval;
      model.partnerApproval = props.project.partnerApproval;

      errors.value = model.approvals.map(() => {
        return new ErrorsHandler({ approval: '' });
      });
      loaders.value = model.approvals.map(() => false);
    } catch (error) {
      console.error('error', error);
    }
  };

  const handleApproval = async (approval, index) => {
    try {
      loaders.value[index] = true;
      errors.value[index].clearErrors();
      await api.leaderPage.createProjectApproval({
        projectId: model.id,
        role: approval.role,
        briefcaseProjectId: approval.briefcaseProjectId,
      });
      emit('update', model);
    } catch (error) {
      errors.value[index].handleApiErrors(error);
    } finally {
      loaders.value[index] = false;
    }
  };

  const reApprove = async (approval, index) => {
    try {
      loaders.value[index] = true;
      errors.value[index].clearErrors();
      await api.leaderPage.reApproveProject({
        projectApprovalId: approval.id,
      });
      emit('update', model);
    } catch (error) {
      errors.value[index].handleApiErrors(error);
    } finally {
      loaders.value[index] = false;
    }
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  .project-agreement-grid {
    margin-top: 10px;
    max-width: 902px;
    padding-bottom: 100px;

    .agreement-row {
      margin-top: 30px;
    }
  }
</style>
