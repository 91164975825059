<template>
  <a-col :span="7" class="card">
    <a-row class="avatar">
      <div class="avatar__wrapper">
        <template v-if="model.img">
          <a-spin :spinning="model.img" />
          <img :src="model.img" alt="" />
        </template>
        <template v-else>
          <img class="skeleton" src="@/assets/icons/camera.svg" alt="" />
        </template>
      </div>
    </a-row>
    <a-row class="person">
      <div class="person__fullname">
        {{ model.fullname }}
      </div>
      <div class="person__position">
        {{ model.position }}
      </div>
      <div class="person__email">{{ model.email }}</div>
      <div class="person__phone">{{ model.phone }}</div>
    </a-row>

    <a-divider />

    <a-row class="tasks">
      <a-col :span="8" class="tasks__total">
        <div>Всего задач</div>
        <div class="count">{{ tasksCount.total }}</div>
      </a-col>
      <a-divider type="vertical" />
      <a-col :span="8" class="tasks__expired">
        <div>Просрочено</div>
        <div class="count">{{ tasksCount[STATUS_INCOMPLETE_OVERDUE] }}</div>
      </a-col>
      <a-divider type="vertical" />
      <a-col :span="8" class="tasks__done">
        <div>Выполнено</div>
        <div class="count">
          {{
            tasksCount[STATUS_COMPLETED] + tasksCount[STATUS_COMPLETED_OVERDUE]
          }}
        </div>
      </a-col>
    </a-row>
  </a-col>
</template>

<script setup>
  import { computed, reactive } from 'vue';
  import {
    STATUS_INCOMPLETE_OVERDUE,
    STATUS_COMPLETED,
    STATUS_COMPLETED_OVERDUE,
  } from '@/api/models/Checkpoint';
  import { useTaskCount } from '@/composables/taskCount';

  const props = defineProps({ executor: Object });
  const getAvatarUrl = (avatar) =>
    avatar ? `/api/v1/attachment/${avatar}` : null;

  const model = reactive({
    img: getAvatarUrl(props.executor.avatar),
    fullname: props.executor.fullName,
    position: props.executor.position,
    email: props.executor.email,
    phone: props.executor.phone,
  });

  const tasksCount = computed(() => {
    return props.executor
      ? useTaskCount(props.executor.tasks, props.executor.id)
      : {};
  });
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors';

  * {
    font-family: 'Nunito Sans', sans-serif;
  }

  .card {
    background-color: $color-bg-secondary-grey;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 389px;
    overflow: hidden;
    padding: 20px;
    user-select: none;
    width: 325px;

    .avatar {
      &__wrapper {
        border-radius: 50%;
        background-color: $color-bg-primary-white;
        overflow: hidden;
        padding: 2rem;
        position: relative;
        height: 5rem;
        width: 5rem;

        img {
          margin: auto;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
        }

        .skeleton {
          width: auto;
        }
      }
    }

    .person {
      color: $color-bg-status-new;
      display: flex;
      flex-direction: column;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      margin-top: 20px;
      row-gap: 20px;

      &__fullname {
        color: $color-text-primary;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
      }
    }

    .tasks {
      flex-wrap: nowrap;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      justify-content: center;
      text-align: center;

      .count {
        color: $color-text-secondary-1;
        font-weight: 700;
      }
    }

    .ant-divider {
      border-color: $color-bg-primary-white;

      &.ant-divider-vertical {
        height: 50px;
      }
    }
  }
</style>
