<template>
  <performer-projects-layout @list-update="onListUpdate">
    <template #default>
      <main-title>Мои задачи</main-title>
      <horizontal-filter>
        <template #default>
          <filter-item hash="" text="В работе" />
          <filter-item hash="#done" text="Выполненные" />
        </template>
        <template #right>
          <label class="panel__total-requests">
            Всего: {{ totalCheckpoints }}
          </label>
        </template>
      </horizontal-filter>
      <task-list :tasks="checkpoints" />
    </template>
  </performer-projects-layout>
</template>

<script setup>
  import { onMounted, ref, watch } from 'vue';
  import { useApi } from '@/api/use-api';
  import PerformerProjectsLayout from './layouts/PerformerProjectsLayout.vue';
  import MainTitle from '@/components/titles/MainTitle';
  import HorizontalFilter from '@/components/filter/horizontal/HorizontalFilter';
  import FilterItem from '@/components/filter/components/FilterItem';
  import TaskList from '@/views/performer/components/TaskList.vue';
  import { useRoute } from 'vue-router';

  const PAGE_SIZE = 20;

  const api = useApi();
  const route = useRoute();
  const checkpoints = ref([]);
  const totalCheckpoints = ref(0);
  const totalPages = ref(0);
  const currentPage = ref(0);

  onMounted(async () => {
    await getCheckpoints();
  });

  watch(
    () => route.hash,
    async () => {
      resetState();
      await getCheckpoints();
    }
  );

  const resetState = () => {
    checkpoints.value = [];
    totalCheckpoints.value = 0;
    totalPages.value = 0;
    currentPage.value = 0;
  };

  const onListUpdate = async () => {
    // We can update the list on scrolling only after receiving the first page.
    if (currentPage.value > 0) {
      await getCheckpoints();
    }
  };

  const getCheckpoints = async () => {
    // totalPages.value > 0 means that we have already received a response with totalPages
    if (totalPages.value > 0 && currentPage.value + 1 > totalPages.value) {
      return;
    }

    try {
      const tab = route.hash ? route.hash.replace('#', '') : 'inwork';
      const response = await api.performerPage.checkpoints(
        new URLSearchParams({
          filter: tab,
          pageSize: PAGE_SIZE,
          page: currentPage.value + 1,
        })
      );
      checkpoints.value = checkpoints.value.concat(response.checkpoints);
      totalCheckpoints.value = response.total;
      totalPages.value = response.pages.total;
      currentPage.value = response.pages.current;
    } catch (error) {
      console.error('error', error);
    }
  };
</script>

<style lang="scss" scoped></style>
