<template>
  <div class="person-tab-intellectuals">
    <white-block>
      <content-container class="content-padding pt-40 pb-40">
        <bordered-block>
          <menu-person :id="person.id" :active="route.params?.tab" />
        </bordered-block>
        <a-spin :spinning="isLoading" />
      </content-container>
    </white-block>

    <white-block class="pt-80 pb-80 mb-80">
      <content-container class="content-padding">
        <publications-list :publications="publications" url="intellectual" />
        <div
            class="person-tab-intellectuals-page__pages"
            v-if="total > publications.length"
        >
          <a-pagination
              v-model:current="currentPage"
              v-model:pageSize="pageSize"
              :total="total"
              :show-size-changer="false"
          >
            <template #itemRender="{ type, originalElement }">
              <pagination-arrows
                  :type="type"
                  :original-element="originalElement"
              />
            </template>
          </a-pagination>
        </div>
      </content-container>
    </white-block>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useApi } from '@/api/use-api';
import WhiteBlock from '@/views/portal/components/WhiteBlock.vue';
import ContentContainer from '@/views/portal/components/ContentContainer.vue';
import BorderedBlock from '@/views/portal/components/BorderedBlock.vue';
import PaginationArrows from '@/views/portal/components/UI/PaginationArrows.vue';
import MenuPerson from "@/views/portal/components/menus/MenuPerson.vue";
import PublicationsList from "@/views/portal/components/publications/PublicationsList.vue";
import {TYPE_INTELLECTUALS} from "@/api/models/Publication";

const isLoading = ref(true);
const api = useApi();
const route = useRoute();
const publications = ref([]);
const sorting = ref('title');
const currentPage = ref(1);
const pageSize = ref(6);
const total = ref(0);

const props = defineProps({
  person: {
    type: Object,
    required: true,
  },
});

const getPublications = async () => {
  if (undefined === props.person.uuid || !props.person.uuid) return;
  try {
    isLoading.value = true;
    const response = await api.publication.index(
        new URLSearchParams({
          page: currentPage.value,
          'per-page': pageSize.value,
          sort: sorting.value,
          author_uuid: props.person.uuid,
          type: TYPE_INTELLECTUALS,
        })
    );

    publications.value = response.publications;
    total.value = response.total;
  } catch (error) {
    console.error('error', error);
  } finally {
    isLoading.value = false;
  }
};

onMounted(() => {
  getPublications();
});

watch(
    () => props.person.id,
    () => {
      getPublications();
    }
);

watch(sorting, () => {
  getPublications();
});

watch(currentPage, () => {
  getPublications();
});

watch(pageSize, () => {
  currentPage.value = 1;
  getPublications();
});
</script>

<style lang="scss" scoped>
</style>
