import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/home/HomePage';
import ResetPasswordRequestPage from '../views/reset-password/ResetPasswordRequestPage';
import ResetPasswordRequestMessage from '../views/reset-password/ResetPasswordRequestMessage';
import ResetPasswordMessage from '../views/reset-password/ResetPasswordMessage';
import ResetPasswordPage from '../views/reset-password/ResetPasswordPage';
import SignInPage from '../views/sign-in/SignInPage';
import SignUpPage from '../views/sign-up/SignUpPage';
import SignUpSuccessPage from '../views/sign-up/SignUpSuccessPage';
// import {
//   useAuthenticated,
//   usePending,
// } from "@/api/authentication/token-provider";
import { administratorRoutes } from './administrator-routes';
import { moderatorRoutes } from './moderator-routes';
import { partnerRoutes } from './partner-routes';
import { projectManagerRoutes } from './project-manager-routes';
import { portalRoutes } from './portal-routes';
import { performerRoutes } from './performer-routes';
import { showcaseRoutes } from './showcase-routes';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage,
    meta: { requiresAuth: false },
  },
  {
    path: '/sign-in',
    name: 'sign-in',
    component: SignInPage,
    meta: { requiresAuth: false },
  },
  {
    path: '/sign-up/success',
    name: 'sign-up-success',
    component: SignUpSuccessPage,
    meta: { requiresAuth: false },
  },
  {
    path: '/sign-up',
    name: 'sign-up',
    component: SignUpPage,
    meta: { requiresAuth: false },
  },
  {
    path: '/reset-password-request/success',
    name: 'reset-password-request-success',
    component: ResetPasswordRequestMessage,
    meta: { requiresAuth: false },
  },
  {
    path: '/reset-password-request',
    name: 'reset-password-request',
    component: ResetPasswordRequestPage,
    meta: { requiresAuth: false },
  },
  {
    path: '/reset-password/success',
    name: 'reset-password-success',
    component: ResetPasswordMessage,
    meta: { requiresAuth: false },
  },
  {
    path: '/reset-password/:token',
    name: 'reset-password',
    component: ResetPasswordPage,
    meta: { requiresAuth: false },
  },
  ...portalRoutes,
  ...partnerRoutes,
  ...moderatorRoutes,
  ...administratorRoutes,
  ...projectManagerRoutes,
  ...performerRoutes,
  ...showcaseRoutes,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

/**
 * Выбирает моаршрут по умолчанию для роли на онсновании мета-тэга rout.meta.default=true.
 * Если маршут не найден, то возвращает маршрут 'home'.
 * @param {string} role
 */
export const getDefaultRouteByRole = (role) => {
  const defaultRoute = routes.find(
    (route) => route.meta.role === role && route.meta.default
  );
  return defaultRoute || routes.find((route) => route.name === 'home');
};

// const isAuthenticated = useAuthenticated();
// const isPending = usePending();

// router.afterEach((to, from) => {
//   console.log(to.name);
//   console.log(from);
//   console.log(isPending.value);
//   console.log(isAuthenticated.value);
//
//   if (isPending) {
//     return false;
//   }
//
//   if (!isPending && isAuthenticated) {
//     return { name: from.name };
//   }
//
//   //TODO - for unprotected routes
//   //
//   // if (to.meta.requiresAuth && !isAuthenticated.value) {
//   //   return {name: 'home'};
//   // }
//   //
//   // if (!to.meta.requiresAuth && isAuthenticated.value) {
//   //   return {name: 'main'};
//   // }
//   //
//   // return true;
// });

export default router;
