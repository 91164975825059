<template>
  <moderator-layout :requestCount="requests.length">
    <template #default>
      <main-title>Запросы</main-title>
      <horizontal-filter>
        <template #default>
          <filter-item hash="" text="Новые запросы" />
          <filter-item hash="#archive" text="Архив" />
        </template>
        <template #right>
          <label class="panel__total-requests">
            Всего: {{ requests?.length }}
          </label>
        </template>
      </horizontal-filter>
      <requests-table :requests="requests" v-if="route.hash === ''" />
      <requests-table
        :requests="archiveRequests"
        v-if="route.hash === '#archive'"
      />
    </template>
  </moderator-layout>
</template>

<script setup>
  import { onMounted, ref } from 'vue';
  import { useApi } from '@/api/use-api';
  import ModeratorLayout from '@/layouts/ModeratorLayout';
  import MainTitle from '@/components/titles/MainTitle';
  import HorizontalFilter from '@/components/filter/horizontal/HorizontalFilter';
  import FilterItem from '@/components/filter/components/FilterItem';
  import RequestsTable from './components/RequestsTable';
  import { useRoute } from 'vue-router';

  const api = useApi();
  const requests = ref([]);
  const archiveRequests = ref([]);
  const route = useRoute();

  onMounted(() => {
    getRequests();
  });

  async function getRequests() {
    try {
      const response = await api.moderatorPage.index();
      const archiveResponse = await api.moderatorPage.archive();
      requests.value = response.requests;
      archiveRequests.value = archiveResponse.requests;
    } catch (error) {
      console.error('error', error);
    }
  }
</script>

<style lang="scss" scoped></style>
