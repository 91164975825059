<template>
  <guest-layout>
    <template #navigation>
      <back-arrow :page-name="goBackTo" />
    </template>
    <template #default>
      <mega-title text="Партнер НГУ" />
      <div class="instruction">
        Новый пароль успешно сохранен. Теперь Вы можете зайти на сайт, используя
        новый пароль.
      </div>
      <nav-button page-name="sign-in" text="Понятно" />
    </template>
  </guest-layout>
</template>

<script setup>
  import { ref } from 'vue';
  import GuestLayout from '@/layouts/GuestLayout';
  import BackArrow from '@/components/navigation/BackArrow';
  import MegaTitle from '@/components/titles/MegaTitle';
  import NavButton from '@/components/navigation/NavButton';

  const goBackTo = ref('sign-in');
</script>

<style lang="scss"></style>
