<template>
  <administrator-layout
    :request-count="newRequestCount"
    :project-count="newProjectCount"
    :showcase-count="newShowcaseCount"
  >
    <administrator-portfolios
      v-if="route.name === 'administrator-portfolios'"
    />
    <administrator-portfolio-layout
      v-if="
        route.name === 'administrator-portfolio-details' ||
        route.name === 'administrator-portfolio-projects' ||
        route.name === 'administrator-portfolio-indexes'
      "
    />
    <administrator-requests v-if="route.name === 'administrator-requests'" />
    <administrator-request-form
      v-if="route.name === 'administrator-requests-form'"
    />
    <administrator-projects v-if="route.name === 'administrator-projects'" />
    <administrator-project-form
      v-if="route.name === 'administrator-project-form'"
      @update="handleUpdate"
    />
    <administrator-project-management
      v-if="route.name === 'administrator-project-management'"
    />
    <administrator-project-team
      v-if="route.name === 'administrator-project-team'"
    />
    <administrator-project-showcase
      v-if="route.name === 'administrator-project-showcase'"
      @update="handleUpdate"
    />
    <administrator-project-indexes
      v-if="route.name === 'administrator-project-indexes'"
    />
    <administrator-project-report
      v-if="route.name === 'administrator-project-report'"
      @update="handleUpdate"
    />
    <administrator-project-agreement
      v-if="route.name === 'administrator-project-agreement'"
      @update="handleUpdate"
    />
    <administrator-showcases v-if="route.name === 'administrator-showcases'" />
  </administrator-layout>
</template>

<script setup>
  import { onMounted, ref } from 'vue';
  import { useRoute } from 'vue-router';
  import { useApi } from '@/api/use-api';
  import AdministratorLayout from '@/layouts/AdministratorLayout.vue';
  import AdministratorProjects from '@/views/administrator/projects/AdministratorProjects.vue';
  import AdministratorRequests from '@/views/administrator/requests/AdministratorRequests.vue';
  import AdministratorProjectForm from '@/views/administrator/projects/form/AdministratorProjectForm.vue';
  import AdministratorRequestForm from '@/views/administrator/requests/components/AdministratorRequestForm.vue';
  import AdministratorProjectManagement from '@/views/administrator/projects/management/AdministratorProjectManagement.vue';
  import AdministratorProjectReport from '@/views/administrator/projects/report/AdministratorProjectReport.vue';
  import AdministratorProjectTeam from '@/views/administrator/projects/team/AdministratorProjectTeam.vue';
  import AdministratorProjectAgreement from '@/views/administrator/projects/agreement/AdministratorProjectAgreement.vue';
  import AdministratorPortfolios from '@/views/administrator/portfolios/AdministratorPortfolios.vue';
  import AdministratorPortfolioLayout from '@/views/administrator/portfolios/AdministratorPortfolioLayout.vue';
  import AdministratorProjectIndexes from '@/views/administrator/projects/indexes/AdministratorProjectIndexes.vue';
  import AdministratorProjectShowcase from '@/views/administrator/projects/showcase/AdministratorProjectShowcase.vue';
  import AdministratorShowcases from '@/views/administrator/showcases/AdministratorShowcases.vue';

  const api = useApi();
  const route = useRoute();
  const newRequestCount = ref(0);
  const newProjectCount = ref(0);
  const newShowcaseCount = ref(0);

  onMounted(() => {
    getNewItemsCount();
  });

  const getNewItemsCount = async () => {
    try {
      const response = await api.administratorPage.newItemsCount();
      newRequestCount.value = response.requestCount;
      newProjectCount.value = response.projectCount;
      newShowcaseCount.value = response.showcaseCount;
    } catch (error) {
      console.error('error', error);
    }
  };

  const handleUpdate = async () => {
    await getNewItemsCount();
  };
</script>

<style scoped lang="scss"></style>
