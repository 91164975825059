import { createStore } from "vuex";

export default createStore({
  state: {
    token: "",
    isAuth: false,
    roles: [],
    currentRole: "",
    requestTypes: [],
    projectTypes: [],
    expertiseTypes: [],
  },
  getters: {
    requestTypeTextByType: (state) => (type) => {
      if (state.requestTypes) {
        const foundItem = state.requestTypes.find((item) => item.type === type);
        return foundItem? foundItem.description : null;
      } else {
        return null;
      }
    },
    requestTypeTextById: (state) => (typeId) => {
      if (state.requestTypes) {
        const foundItem = state.requestTypes.find((item) => item.id === typeId);
        return foundItem? foundItem.description : null;
      } else {
        return null;
      }
    },
    projectTypeOptions: (state) => (type) => {
      return state.projectTypes.map((item) => ({
        value: item.id,
        label: item.description,
        type: type,
      }));

    },
    projectTypeTextByType: (state) => (type) => {
      if (state.projectTypes) {
        const foundItem = state.projectTypes.find((item) => item.name === type);
        return foundItem? foundItem.description : null;
      } else {
        return null;
      }
    },
    projectTypeTextById: (state) => (typeId) => {
      if (state.projectTypes) {
        const foundItem = state.projectTypes.find((item) => item.id === typeId);
        return foundItem? foundItem.description : null;
      } else {
        return null;
      }
    },
  },
  mutations: {
    setIsAuth(state, isAuth) {
      state.isAuth = isAuth;
    },
    setRoles(state, roles) {
      state.roles = roles;
    },
    setCurrentRole(state, role) {
      state.currentRole = role;
    },
    setRequestTypes(state, requestTypes) {
      state.requestTypes = requestTypes;
    },
    setProjectTypes(state, requestTypes) {
      state.projectTypes = requestTypes;
    },
  },
  actions: {
    async fetchRequestTypes({ commit }, api) {
      const response = await api.requestType.index();
      commit('setRequestTypes', response);
    },
    async fetchProjectTypes({ commit }, api) {
      const response = await api.projectType.index();
      commit('setProjectTypes', response);
    },
  },
  modules: {},
});
