<template>
  <a-spin
    :spinning="isLoading"
    class="custom-spinner"
    style="height: 100vh; top: 50%"
  >
    <template v-if="currentView === '#table'">
      <project-card-table
        :projects="projects"
        @project-click="handleProjectClick"
      />
    </template>
    <template v-else>
      <project-card-grid
        :projects="projects"
        isPortfolio
        @project-click="handleProjectClick"
      />
    </template>
  </a-spin>
</template>

<script setup>
  import { computed, onMounted, ref } from 'vue';
  import { useApi } from '@/api/use-api';
  import { useRoute, useRouter } from 'vue-router';
  import ProjectCardGrid from '@/components/projects/portfolio/components/ProjectCardGrid.vue';
  import ProjectCardTable from '@/components/projects/portfolio/components/ProjectCardTable.vue';
  import {
    PROJECT_STATUS_ACCEPTANCE,
    PROJECT_STATUS_AGREEMENT,
    PROJECT_STATUS_IN_WORK,
  } from '@/api/models/ProjectStatus';
  import { USER_ROLE_LEADER } from '@/api/models/UserRole';

  const api = useApi();
  const route = useRoute();
  const router = useRouter();
  const currentView = computed(() => route.hash);
  const projects = ref([]);
  const isLoading = ref(false);

  onMounted(() => {
    getProjects();
  });

  const getProjects = async () => {
    try {
      isLoading.value = true;
      const response = await api.project.projectsStats(
        new URLSearchParams({
          role: route.meta.role,
          filter: 'portfolio',
          briefcaseId: route.params.id,
        })
      );
      projects.value = response.projects;
    } catch (error) {
      console.error('error', error);
    } finally {
      isLoading.value = false;
    }
  };

  const handleProjectClick = async (
    projectId,
    projectStatusId,
    isMyProject
  ) => {
    const role = route.meta.role;

    if (role === USER_ROLE_LEADER && !isMyProject) {
      await router.push(`/${role}/project/` + projectId + `/form`);
    } else if (projectStatusId === PROJECT_STATUS_AGREEMENT) {
      await router.push(`/${role}/project/` + projectId + `/form#9`);
    } else if (projectStatusId === PROJECT_STATUS_ACCEPTANCE) {
      await router.push(`/${role}/project/` + projectId + `/agreement`);
    } else if (projectStatusId === PROJECT_STATUS_IN_WORK) {
      await router.push(`/${role}/project/` + projectId + `/management`);
    } else {
      await router.push(`/${role}/project/` + projectId + `/form`);
    }
  };
</script>

<style scoped lang="scss"></style>
