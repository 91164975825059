<template>
  <h3>Шаг 8. Документы</h3>
  <p>
    Проверьте информацию в карточке проекта и, если согласны, согласуйте проект.
  </p>
  <div>
    <a-form class="form partner-project-form" layout="vertical">
      <partner-project-form-row
        :show-available="false"
        :offset-available="false"
      >
        <attachments-input
          v-if="model.availabilityList['attachments']"
          :init-attachments="model.attachments"
          :is-editable="false"
          @update="null"
        />
      </partner-project-form-row>
    </a-form>
  </div>
</template>

<script setup>
  import { onMounted, reactive, watch } from 'vue';
  import PartnerProjectFormRow from '../PartnerProjectFormRow.vue';
  import AttachmentsInput from '@/components/attachments/AttachmentsInput.vue';

  const props = defineProps({
    project: {
      type: Object,
      require: true,
    },
  });

  const model = reactive({
    id: null,
    attachments: [],
    availabilityList: [],
  });

  onMounted(() => {
    props.project && fillModel();
  });

  watch(
    () => props.project,
    () => {
      fillModel();
    }
  );

  const fillModel = async () => {
    try {
      model.id = props.project.id;
      model.attachments = props.project.attachments;
      model.availabilityList = props.project.availabilityList;
    } catch (error) {
      console.error('error', error);
    }
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  .attachments {
    display: flex;
    flex-direction: row;
    background-color: $color-bg-secondary-grey;
    border-radius: 7px;
    gap: 44px;
    flex-wrap: wrap;
    padding: 24px;
    margin-top: 20px;
  }

  .ant-upload-text {
    color: #aeb4dd;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    width: 194px;
  }
</style>
