<template>
  <router-link :to="{ path: toPath }">
    <div class="application-type-card">
      <div class="application-type-card__icon">
        <project-icon :type="type" />
      </div>
      <div class="application-type-card__label">
        {{ title }}
      </div>
      <div class="application-type-card__button"></div>
    </div>
  </router-link>
</template>

<script setup>
  import ProjectIcon from '@/components/projects/components/icons/ProjectIcon';
  import { computed } from "vue";

  const props = defineProps({
    title: String,
    type: String,
    path: String,
  });

  const toPath = computed(() => {
    return props.path ? `/new/request/${props.type}` : props.path;
  });
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  .application-type-card {
    align-items: center;
    background: $color-bg-secondary-grey;
    border-radius: 7px;
    color: $color-link-secondary;
    display: flex;
    height: 100px;
    padding: 20px 0;

    &:hover,
    &:focus,
    &:active {
      color: $color-text-secondary-white;

      .application-type-card__button {
        background: url('./assets/arrow-white.svg') left center no-repeat;
      }
    }

    &:hover {
      background: $color-btn-special-hover;
    }

    &:focus,
    &:active {
      background: $color-btn-special-active;
    }

    .application-type-card__icon {
      margin-left: 20px;
    }

    .application-type-card__label {
      font: 600 16px/22px 'Nunito Sans';
      flex: 1 1 auto;
      padding: 0 20px;
    }

    .application-type-card__button {
      background: url('./assets/arrow-black.svg') left center no-repeat;
      flex: 0 0 24px;
      height: 24px;
      padding: 0 38px;
    }
  }
</style>
