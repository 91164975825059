import NewApplicationPage from '@/views/new-application/NewApplicationPage';
import PartnerRequestForm from '@/views/partner-requests/PartnerRequestForm.vue';
import PartnerProfile from '@/views/partner-profile/PartnerProfile';
import PartnerRequests from '@/views/partner-projects/PartnerRequests.vue';
import PartnerProjectForm from '@/views/partner-projects/form/PartnerProjectForm.vue';
import PartnerVacanciesPage from '@/views/partner-vacancies/PartnerVacanciesPage.vue';
import PartnerReportsPage from '@/views/partner-reports/PartnerReportsPage.vue';
import PartnerDocumentsPage from '@/views/partner-documents/PartnerDocumentsPage.vue';
import PartnerProjectManagement from '@/views/partner-projects/management/PartnerProjectManagement.vue';
import PartnerProjectReport from '@/views/partner-projects/report/PartnerProjectReport';
import PartnerProjectAgreement from '@/views/partner-projects/agreement/PartnerProjectAgreement';

const role = 'partner';

export const partnerRoutes = [
  {
    path: '/profile',
    name: 'profile',
    component: PartnerProfile,
    meta: { requiresAuth: true },
  },
  {
    path: '/new',
    name: 'new',
    component: NewApplicationPage,
    meta: { requiresAuth: true, type: 'request', role: role },
  },
  {
    path: '/new/request/:type',
    name: 'new-partner-request',
    component: PartnerRequestForm,
    meta: { requiresAuth: true, type: 'request', role: role },
  },
  {
    path: '/partner/requests',
    name: 'main',
    component: PartnerRequests,
    meta: { requiresAuth: true, type: 'projects', role: role, default: true },
  },
  {
    path: '/partner/projects',
    component: PartnerRequests,
    meta: { requiresAuth: true, type: 'projects', role: role },
  },
  {
    path: '/partner/requests/:id',
    component: PartnerRequestForm,
    meta: { requiresAuth: true, type: 'rquests', role: role },
  },
  {
    path: '/partner/project/:id/form',
    component: PartnerProjectForm,
    meta: { requiresAuth: true, type: 'rquests', role: role },
  },
  {
    path: '/partner/project/:id/management',
    component: PartnerProjectManagement,
    meta: { requiresAuth: true, type: 'projects', role: role },
  },
  {
    path: '/partner/project/:id/report',
    name: 'partner-project-report',
    component: PartnerProjectReport,
    meta: { requiresAuth: true, type: 'projects', role: role },
  },
  {
    path: '/partner/project/:id/agreement',
    name: 'partner-project-agreement',
    component: PartnerProjectAgreement,
    meta: { requiresAuth: true, type: 'request', role: role },
  },
  {
    path: '/partner/vacancies',
    name: 'partner-vacancies',
    component: PartnerVacanciesPage,
    meta: { requiresAuth: true, type: 'vacancies', role: role },
  },
  {
    path: '/partner/reports',
    name: 'partner-reports',
    component: PartnerReportsPage,
    meta: { requiresAuth: true, type: 'report', role: role },
  },
  {
    path: '/partner/documents',
    name: 'partner-documents',
    component: PartnerDocumentsPage,
    meta: { requiresAuth: true, type: 'docs', role: role },
  },
];
