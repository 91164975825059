<template>
  <table class="table">
    <thead class="header">
      <tr>
        <th class="col1"><span>Номер</span></th>
        <th class="col2"><span>Дата</span></th>
        <th class="col3"><span>Партнер</span></th>
        <th class="col4"><span>Название проекта</span></th>
        <th class="col6" style="width: 5%"><span>Статус</span></th>
      </tr>
    </thead>
    <tbody>
      <project-item
        v-for="project in projects"
        :key="project.id"
        :project="project"
        @click="handlerProjectClick(project)"
      />
    </tbody>
  </table>
</template>

<script setup>
  import { useRoute, useRouter } from 'vue-router';
  import ProjectItem from './ProjectItem';

  defineProps({
    projects: Array,
  });

  const route = useRoute();
  const router = useRouter();

  const handlerProjectClick = async (project) => {
    if (route.hash === '') {
      await router.push({ path: '/leader/project/' + project.id });
    } else if (route.hash === '#inwork') {
      await router.push({ path: '/leader/project/' + project.id + '/form#1' });
    } else if (route.hash === '#archive') {
      await router.push({ path: '/leader/project/' + project.id + '/report' });
    }
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';
  $color-text-secondary-grey-216: #718096;

  .table {
    border-collapse: separate;
    border-spacing: 0 15px;
    padding-top: 30px;
    width: 100%;

    th[class*='col'] {
      span {
        border-bottom: 1px solid #f2f4f8;
        display: block;
        padding-bottom: 10px;
        width: 100%;
      }
    }

    th:first-child {
      padding-left: 20px;
    }

    .header {
      border-bottom: 1px solid $color-bg-secondary-grey;
      color: $color-text-secondary-grey-216;
      font-variant-numeric: slashed-zero;
      font-family: Nunito Sans, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 10px;
      text-align: left;
    }
  }
</style>
