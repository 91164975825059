<template>
  <div class="add-task__form">
    <h3>Добавить {{ type !== 'subtask' ? 'задачу' : 'подзадачу' }}</h3>
    <p>Заполните поля ниже, чтобы добавить задачу.</p>
    <a-form
      class="form"
      :model="model"
      :rules="rules"
      @finish="onFinish"
      @validate="errors.onValidate"
    >
      <!-- @validate="errors.onValidate" -->
      <a-form-item
        name="name"
        :help="errors.getError('name')"
        :validate-status="errors.getStatus('name')"
      >
        <a-textarea
          id="name"
          :rows="3"
          v-model:value="model.name"
          placeholder="Сформулируйте задачу *"
          showCount
          :maxlength="10000"
        />
      </a-form-item>
      <a-form-item name="executor">
        <a-select
          id="executor"
          placeholder="Выберите сотрудника"
          v-model:value="model.userId"
          :options="model.usersList"
          optionFilterProp="label"
          @search="searchUser"
          showSearch
        />
      </a-form-item>

      <h2>Контрольные точки</h2>

      <template
        v-for="(checkpoint, index) in model.checkpoints"
        :key="checkpoint.index"
      >
        <!-- 
          TODO: not working with dynamic elements?
        :help="errors.getError('result')"
        :validate-status="errors.getStatus('result')"
       -->
        <a-form-item
          :name="['checkpoints', index, 'result']"
          :rules="{
            required: true,
            min: 2,
            max: 5000,
            message: TEXT_LENGTH_ERROR,
            trigger: 'change',
          }"
        >
          <a-input
            v-model:value="checkpoint.result"
            placeholder="Сформулируйте результат"
          >
            <template #suffix>
              <img
                v-if="model.checkpoints.length > 1"
                src="@/assets/icons/delete.svg"
                style="cursor: pointer"
                @click="removeCheckpointInput(checkpoint)"
                alt=""
              />
            </template>
          </a-input>
        </a-form-item>

        <!-- TODO (NMD): Warning: Obsolete attribute -->
        <a-row align="middle" justify="space-between">
          <a-col :span="17">
            <!-- 
          TODO: not working with dynamic elements?
              :help="errors.getError('checkpoint')"
              :validate-status="errors.getStatus('checkpoint')"
             -->
            <a-form-item
              :name="['checkpoints', index, 'date']"
              :rules="{
                required: !checkpoint.noMatter,
                message: 'Необходимо указать дату выполнения',
                trigger: 'change',
              }"
            >
              <a-date-picker
                v-model:value="checkpoint.date"
                placeholder="Дата выполнения"
                format="DD.MM.YYYY"
                :locale="locale"
                :disabled="checkpoint.noMatter"
              >
                <template #suffixIcon></template>
              </a-date-picker>
            </a-form-item>
          </a-col>
          <a-col>
            <a-form-item :name="['checkpoints', index, 'noMatter']">
              <c-checkbox
                v-model:checked="checkpoint.noMatter"
                @change="onNoMatterChange(checkpoint)"
              >
                Неважно
              </c-checkbox>
            </a-form-item>
          </a-col>
        </a-row>
      </template>

      <a-form-item>
        <a-col :span="24" class="form__add-checkpoint">
          <!-- TODO (NMD): Warning: Obsolete attribute -->
          <a-row align="middle">
            <a-col>
              <img src="@/assets/icons/circle-plus.svg" alt="" />
            </a-col>
            <a-col>
              <a @click="addNewCheckpointInput">Добавить контрольную точку</a>
            </a-col>
          </a-row>
        </a-col>
      </a-form-item>
      <a-form-item class="form__button">
        <c-button-primary html-type="submit" :loading="isLoading">
          Добавить
        </c-button-primary>
      </a-form-item>
    </a-form>
  </div>
</template>

<script setup>
  import { reactive, ref } from 'vue';
  import { useRoute } from 'vue-router';
  import { useApi } from '@/api/use-api';
  import ErrorsHandler from '@/components/form/errors-handler';
  import CButtonPrimary from '@/components/UI/CButtonPrimary';
  import CCheckbox from '@/components/UI/CCheckbox';
  import locale from 'ant-design-vue/es/date-picker/locale/ru_RU';
  import dayjs from 'dayjs';
  import { debounce } from 'lodash-es';
  import { TEXT_LENGTH_ERROR } from '@/constants/error';

  const api = useApi();
  const route = useRoute();
  const props = defineProps({
    task: Object,
    type: String,
  });

  const emit = defineEmits(['close', 'update']);
  const isLoading = ref(false);
  const model = reactive({
    name: '',
    executor: '',
    checkpoints: [
      {
        result: '',
        date: '',
        status: '',
        noMatter: false,
      },
    ],
    usersList: [],
    userId: null,
  });

  const rules = {
    name: [
      {
        type: 'string',
        required: true,
        min: 2,
        message: 'Необходимо сформулировать задачу, не менее 2 символов',
        trigger: 'change',
      },
    ],
    // TODO - errors for executor search
    // executor: [
    //   {
    //     type: "string",
    //     required: true,
    //     message: "Необходимо выбрать исполнителя",
    //     trigger: "change",
    //   },
    // ],
  };

  const errors = new ErrorsHandler({
    name: '',
    // executor: "",
    // result: '',
    // checkpoint: '',
  });

  const usersToList = (users) => {
    return users.map((user) => ({ value: user.id, label: user.fullName }));
  };

  const searchUser = debounce(async (value) => {
    try {
      const query = new URLSearchParams();

      if ('' !== value) {
        query.set('name', value);
      }

      const response = await api.user.executors(query);
      model.usersList = usersToList(response.user.items);
    } catch (error) {
      console.log(error);
    }
  }, 500);

  const onFinish = async () => {
    try {
      isLoading.value = true;
      let parentId = null;
      if (props.type === 'subtask') {
        parentId = props.task.id;
      }
      const response = await api.projectManagementPage.saveTask({
        id: null,
        projectId: route.params.id,
        userId: model.userId,
        description: model.name,
        parentId: parentId,
      });

      for (let i = 0; i < model.checkpoints.length; i++) {
        if (model.checkpoints[i].result) {
          await api.projectManagementPage.saveCheckpoint({
            id: null,
            taskId: response.taskId,
            description: model.checkpoints[i].result,
            date: model.checkpoints[i].noMatter
              ? null
              : dayjs(model.checkpoints[i].date).format('YYYY-MM-DD'),
          });
        }
      }
      emit('close');
      emit('update');
    } catch (error) {
      console.log(error);
      // errors.handleApiErrors(error);
    } finally {
      isLoading.value = false;
    }
  };

  const addNewCheckpointInput = () => {
    model.checkpoints.push({
      result: '',
      date: '',
      status: '',
      noMatter: false,
    });
  };

  const removeCheckpointInput = (checkpoint) => {
    const index = model.checkpoints.indexOf(checkpoint);
    if (index !== -1) {
      model.checkpoints.splice(index, 1);
    }
  };

  const onNoMatterChange = (checkpoint) => {
    if (checkpoint.noMatter) {
      checkpoint.date = null;
    }
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  .add-task__form {
    padding: 112px 17px 0 17px;

    h3 {
      font: 700 18px/26px 'Nunito Sans';
    }

    h2 {
      color: $color-bg-status-rework;
      font: 700 14px/20px 'Nunito Sans';
      margin-top: 30px;
    }

    p {
      margin-top: 21px;
    }

    .ant-form-item {
      margin-bottom: 14px;
    }

    #name {
      height: 105px;
      margin-top: 24px;
      resize: none;
    }

    .form__add-checkpoint {
      margin-left: 15px;

      a {
        border-bottom: 1px solid;
        color: $color-link-primary;
        text-decoration: none;
      }
    }

    .form__button {
      text-align: right;
    }

    .ant-input-textarea-show-count::after {
      color: $color-text-secondary-grey;
      font-family: Nunito Sans, sans-serif;
      font-size: 12px;
      font-weight: 400;
    }
  }
</style>
