<template>
  <project-manager-layout :requestCount="requests.length">
    <template #default>
      <a-row justify="space-between">
        <a-col>
          <main-title>Мои проекты</main-title>
        </a-col>
        <a-col>
          <c-button-primary :loading="isLoading" @click="handleStart">
            Добавить проект
          </c-button-primary>
        </a-col>
      </a-row>
      <horizontal-filter>
        <template #default>
          <filter-item hash="" text="Новые запросы" />
          <filter-item path="/leader/projects" hash="#inwork" text="В работе" />
          <filter-item path="/leader/projects" hash="#archive" text="Архив" />
        </template>
        <template #right>
          <label class="panel__total-requests">
            Всего: {{ totalRequest }}
          </label>
        </template>
      </horizontal-filter>
      <requests-table :requests="requests" />
    </template>
  </project-manager-layout>
</template>

<script setup>
  import { onMounted, ref, watch } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useApi } from '@/api/use-api';
  import ProjectManagerLayout from '@/layouts/ProjectManagerLayout.vue';
  import MainTitle from '@/components/titles/MainTitle';
  import HorizontalFilter from '@/components/filter/horizontal/HorizontalFilter';
  import FilterItem from '@/components/filter/components/FilterItem';
  import RequestsTable from './components/RequestsTable';
  import CButtonPrimary from '@/components/UI/CButtonPrimary';

  const api = useApi();
  const router = useRouter();
  const route = useRoute();
  const totalRequest = ref(0);
  const requests = ref([]);
  const isLoading = ref(false);

  onMounted(() => {
    getRequests();
  });

  watch(route, () => {
    getRequests();
  });

  const getRequests = async () => {
    requests.value = [];
    totalRequest.value = 0;
    try {
      const tab = route.hash.replace('#', '');
      const response = await api.leaderPage.requests(tab);
      requests.value = response.requests;
      totalRequest.value = requests.value.length;
    } catch (error) {
      console.error('error', error);
    }
  };

  const handleStart = async () => {
    try {
      isLoading.value = true;
      // создаем новый проект, если в createProject() не передаем аргументы, то присваиваем следующий по очереди projectId
      const response = await api.leaderPage.createInternalProject({
        parentId: null,
      });

      await router.replace({
        path: `/leader/project/${response.projectId}/form`,
      });
    } catch (error) {
      console.error('error', error);
    } finally {
      isLoading.value = false;
    }
  };
</script>

<style lang="scss" scoped></style>
