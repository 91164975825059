import { create, read, update } from '@/api/rest';

const leaderPage = {
  index: async () => await read('/leader-page'),
  view: async (id) => await read('/leader-page/' + id),
  requests: async (filter) =>
    await read('/leader-page/requests?filter=' + filter),
  projects: async (filter) =>
    await read('/leader-page/projects?filter=' + filter),
  checkRequestExistence: async (id) =>
    await read('/leader-page/check-request-existence?requestId=' + id),
  createExternalProject: async (data) =>
    await create('/leader-page/create-external-project', data),
  createInternalProject: async (data) =>
    await create('/leader-page/create-internal-project', data),
  rejectRequest: async (data) =>
    await update('/leader-page/reject-request', data),
  updateProject: async (data) =>
    await update('/leader-page/update-project', data),
  projectReview: async (data) =>
    await update('/leader-page/project-review', data),
  projectSteps: async (id) => await read('/leader-page/project-steps?id=' + id),
  getProjectCostTypes: async () =>
    await read('/leader-page/get-project-cost-types'),
  adminUnits: async () => await read('/leader-page/admin-units'), // TODO: не использовать! удалить
  adminBriefcases: async () => await read('/leader-page/admin-briefcases'),

  getStepModel: async (id, step) =>
    await read('/leader-page/get-step-model?id=' + id + '&step=' + step),
  saveProjectStage: async (data) =>
    await create('/leader-page/save-project-stage', data),
  deleteProjectStage: async (data) =>
    await create('/leader-page/delete-project-stage', data),
  saveProjectTeam: async (data) =>
    await create('/leader-page/save-project-team', data),
  deleteProjectTeam: async (data) =>
    await create('/leader-page/delete-project-team', data),
  saveProjectBudgetCost: async (data) =>
    await create('/leader-page/save-project-budget-cost', data),
  deleteProjectBudgetCost: async (data) =>
    await create('/leader-page/delete-project-budget-cost', data),
  saveProjectBudgetStage: async (data) =>
    await create('/leader-page/save-project-budget-stage', data),
  deleteProjectBudgetStage: async (data) =>
    await create('/leader-page/delete-project-budget-stage', data),

  updateProjectStages: async (data) =>
    await update('/leader-page/update-project-stages', data),
  updateProjectTeam: async (data) =>
    await update('/leader-page/update-project-team', data),
  updateProjectBudgetStages: async (data) =>
    await update('/leader-page/update-project-budget-stages', data),
  updateProjectBudgetCosts: async (data) =>
    await update('/leader-page/update-project-budget-costs', data),
  getProjectToClose: async (id) =>
    await read('/leader-page/project-to-close?id=' + id),
  createProjectApproval: async (data) =>
    await create('/leader-page/project-approval', data),
  reApproveProject: async (data) =>
    await update('/leader-page/re-approve-project', data),
};

export default leaderPage;
