<template>
  <div class="agreement-grid">
    <agreement-row
      class="agreement-row"
      :status="model.approvalStatus"
      :reason="model.approval?.rejectedReason"
      :action="() => {}"
      :action-button-text="'Согласовать'"
      :loading="isLoading"
      :error="errors.getError('approval')"
    >
      <template #icon>
        <img
          v-if="model.approval?.role === USER_ROLE_PARTNER"
          src="@/assets/icons/icon-person.svg"
          alt=""
        />
        <img v-else src="@/assets/icons/icon-bag.svg" alt="" />
      </template>
      <template #labelText>
        {{ model.approvalStatus?.actionLabel }}
      </template>
    </agreement-row>
    <a-divider />
  </div>
</template>

<script setup>
  import ErrorsHandler from '@/components/form/errors-handler';
  import { onMounted, reactive, ref, watch } from 'vue';
  // import { useApi } from '@/api/use-api';
  import AgreementRow from '@/components/projects/agreement/AgreementRow.vue';
  import { USER_ROLE_LEADER, USER_ROLE_PARTNER } from '@/api/models/UserRole';
  import { getShowcaseApprovalVisibleStatus } from '@/api/models/DocumentApprovalStatus';

  // const api = useApi();
  // const emit = defineEmits(['update']);
  const isLoading = ref(false);

  const props = defineProps({
    showcase: {
      type: Object,
      default: null,
    },
  });
  const errors = new ErrorsHandler({ approval: '' });

  const model = reactive({
    showcaseId: null,
    approval: null,
    approvalStatus: null,
  });

  onMounted(() => {
    fillModel();
  });

  watch(
    () => props.showcase,
    () => {
      fillModel();
    }
  );

  const fillModel = () => {
    if (!props.showcase) return;

    try {
      model.showcaseId = props.showcase.id;
      model.approval =
        props.showcase.approvals.length > 0
          ? props.showcase.approvals[0]
          : null;

      model.approvalStatus = model.approval
        ? getShowcaseApprovalVisibleStatus(model.approval, USER_ROLE_LEADER)
        : null;
    } catch (error) {
      console.error('error', error);
    }
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  .agreement-grid {
    margin-top: 10px;
    max-width: 902px;

    .agreement-row {
      margin-top: 30px;
    }

    .ant-divider {
      margin-top: 40px;
      margin-bottom: 10px;
    }
  }
</style>
