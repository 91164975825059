<template>
  <guest-layout>
    <template #navigation>
      <back-arrow page-name="sign-in" />
    </template>
    <template #default>
      <mega-title text="Партнер НГУ" />
      <div class="instruction">
        Введите новый пароль, чтобы восстановить доступ в Личный кабинет и войти
        в него.
      </div>
      <a-form
          class="form form_one-column"
          :model="model"
          :rules="rules"
          @validate="errors.onValidate"
          @finish="onFinish"
      >
        <a-form-item name="newPassword" :help="errors.getError('newPassword')"
                     :validate-status="errors.getStatus('newPassword')">
          <a-input
            v-model:value="model.newPassword"
            type="password"
            placeholder="Новый пароль*"
          ></a-input>
        </a-form-item>
        <a-form-item name="confirmPassword" :help="errors.getError('confirmPassword')"
                     :validate-status="errors.getStatus('confirmPassword')">
          <a-input
            v-model:value="model.confirmPassword"
            type="password"
            placeholder="Подтвердите новый пароль*"
          ></a-input>
        </a-form-item>
        <div class="buttons-block">
          <a-form-item>
            <a-button type="primary" html-type="submit">Восстановить</a-button>
          </a-form-item>
        </div>
      </a-form>
    </template>
    <template #footer>
      <div class="footer">
        Если Вам не пришло письмо с логином-паролем, то обратитесь в техническую
        поддержку - <a href="mailto:support@nsu.ru">support@nsu.ru</a>
      </div>
    </template>
  </guest-layout>
</template>

<script setup>
  import GuestLayout from '@/layouts/GuestLayout';
  import BackArrow from '@/components/navigation/BackArrow';
  import MegaTitle from '@/components/titles/MegaTitle';
  import { reactive } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import { useApi } from '@/api/use-api';
  import ErrorsHandler from "@/components/form/errors-handler";

  const router = useRouter();
  const route = useRoute();
  const api = useApi();
  const model = reactive({
    newPassword: '',
    confirmPassword: '',
  });

  const validatePassword = (rule, value) => {
    const lowercasePattern = /[a-z]/;
    const digitPattern = /\d/;

    if (value && !(lowercasePattern.test(value) && digitPattern.test(value))) {
      return Promise.reject('Пароль должен содержать хотя бы 1 латинскую букву в нижнем регистре и хотя бы 1 цифру');
    }

    return Promise.resolve();
  };

  const validatePasswordConfirm = (rule, value) => {
    if (value && value !== model.newPassword) {
      return Promise.reject('Введённые пароли не совпадают');
    }
    return Promise.resolve();
  };

  const rules = {
    newPassword: [
      {
        required: true,
        message: 'Поле не должно быть пустым',
        trigger: 'change',
      },
      {
        min: 8,
        message: 'Пароль должен содержать не менее 8 символов',
        trigger: 'blur',
      },
      {
        required: true,
        validator: validatePassword,
        trigger: 'blur',
      },
    ],
    confirmPassword: [
      {
        required: true,
        message: 'Поле не должно быть пустым',
        trigger: 'change',
      },
      {
        min: 8,
        message: 'Пароль должен содержать не менее 8 символов',
        trigger: 'blur',
      },
      {
        required: true,
        validator: validatePassword,
        trigger: 'blur',
      },
      {
        required: true,
        validator: validatePasswordConfirm,
        trigger: 'blur',
      },
    ],
  };

  const errors = new ErrorsHandler({
    newPassword: '',
    confirmPassword: '',
  });

  const onFinish = async () => {
    try {
      await api.password.change({
        newPassword: model.newPassword,
        confirmPassword: model.confirmPassword,
        token: route.params.token,
      });

      await router.push({ name: 'reset-password-success' });
    } catch (error) {
      errors.handleApiErrors(error);
    }
  };
</script>

<style lang="scss"></style>
