import {reactive} from "vue";

/**
 * Адаптер для работы с ошибками формы.
 *
 * Пример использования:
 * ```js
 * const errors = new ErrorsHandler({
 *   username: '',
 *   password: '',
 * });
 * ```
 *
 * @param initialErrors Начальные значения ошибок формы. Если какое-то поле пустое, значит ошибки в нем нет.
 * @param apiAdapter Функция-конвертер для названий полей данных, пришедших из API в название полей в форме. Если функция
 * не указана, предполагается, что название полей формы и API совпадает.
 * @returns {{getError: (function(*): *), handleApiErrors: handleApiErrors, onValidate: onValidate, setError: (function(*, *): *), clearErrors: clearErrors, getStatus: (function(*): string)}}
 * @constructor
 */
export default function ErrorsHandler(initialErrors, apiAdapter = null) {
  const errors = reactive(initialErrors);
  const adapter = (typeof apiAdapter === "function") ? apiAdapter : (fieldName) => fieldName;

  const onValidate = (name, status, errorMessages) => {
    errors[name] = !status ? errorMessages[0] : "";
  };

  const getError = (fieldName) => {
    return errors[fieldName];
  };

  const getErrors = () => {
    return errors;
  };

  const setError = (fieldName, errorMessage) => {
    return errors[fieldName] = errorMessage;
  };

  const getStatus = (fieldName) => {
    return "" !== errors[fieldName] ? "error" : "";
  };

  const clearErrors = () => {
    Object.keys(errors).forEach((key) => {
      errors[key] = "";
    });
  };

  /**
  * Обрабатывает ошибки из ответа от API.
  * Если получена 403 ошибка, то будет добавлено её сообщение к первому полю из конфигурации.
  */
  const handleApiErrors = (apiError) => {
    clearErrors();

    if (apiError.status === 403 && errors) {
      errors[Object.keys(errors)[0]] = apiError.message;
    } else {
      Object.keys(apiError.errors).forEach((key) => {
        errors[adapter(key)] = apiError.errors[key][0];
      });
    }
  };

  return {
    onValidate,
    getError,
    getErrors,
    setError,
    getStatus,
    clearErrors,
    handleApiErrors,
  };
}