<template>
  <!--<router-link :to="{ name: path }" class="filter-item" :class="{ active }">-->
  <router-link
    :to="{
      path: getPath(path),
      hash: hash !== '#' ? hash : '',
    }"
    class="filter-item"
    :class="{ active: isActive(path, hash), disabled }"
  >
    <img class="filter-item__icon" :src="icon" alt="" v-if="icon" />
    <span class="filter-item__text" v-if="text">{{ text }}</span>
  </router-link>
</template>

<script setup>
  import { useRoute } from 'vue-router';

  const route = useRoute();

  defineProps({
    icon: { type: String, default: '' },
    text: { type: String, default: '' },
    path: { type: String, default: '' },
    hash: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
  });

  const getPath = (path) => {
    return path ? path : route.path;
  };

  const isActive = (path, hash) => {
    return route.path === getPath(path) && route.hash === hash ? 'active' : '';
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  .filter-item {
    display: flex;
    height: 34px;
    padding: 4px;
    align-items: center;
    gap: 10px;
    border-radius: 4px;

    .filter-item__icon {
      width: 24px;
      height: 24px;
      flex: 0 0 24px;
    }

    .filter-item__text {
      color: $color-text-primary;
      font-variant-numeric: slashed-zero;
      font: 600 14px/26px Nunito Sans;
      padding: 6px 10px;
    }

    &.active {
      background: $color-bg-secondary-grey;

      .filter-item__text {
        font-size: 16px;
        font-weight: 700;
      }
    }

    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
      pointer-events: auto;
    }
  }
</style>
