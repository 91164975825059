<template>
  <a-spin
    :spinning="isLoading"
    class="custom-spinner"
    style="height: 100vh; top: 20%"
  >
    <administrator-project-layout :model="model">
      <template #underInfo>
        <div
          class="administrator-project__common-progress"
          v-if="checkpoints.length"
        >
          <progress-bar :items="checkpoints" showPercent />
        </div>
      </template>
      <ProjectShowcaseForm :showcase="model.showcase">
        <administrator-showcase-agreement-form
          v-if="showAgreement"
          :showcase="model.showcase"
          :publicShowcase="model.publicShowcase"
          @update="onUpdate"
        />
      </ProjectShowcaseForm>
    </administrator-project-layout>
  </a-spin>
</template>

<script setup>
  import { computed, onMounted, reactive, ref } from 'vue';
  import { useRoute } from 'vue-router';
  import { useApi } from '@/api/use-api';
  import ProgressBar from '@/components/projects/management/ProgressBar';
  import { useCheckpoints } from '@/composables/taskCount';
  import ProjectShowcaseForm from '@/components/projects/showcase/ProjectShowcaseForm.vue';
  import AdministratorProjectLayout from '@/views/administrator/projects/components/AdministratorProjectLayout.vue';
  import AdministratorShowcaseAgreementForm from './components/AdministratorShowcaseAgreementForm.vue';
  import { SHOWCASE_STATUS_DRAFT } from '@/api/models/ProjectShowcaseStatus';

  const api = useApi();
  const emit = defineEmits(['update']);
  const route = useRoute();
  const model = reactive({
    id: null,
    number: '',
    name: '',
    projectType: '',
    requestId: null,
    status: null,
    projectStatusId: null,
    showcase: null,
    publicShowcase: null,
  });
  const checkpoints = ref([]);
  const isLoading = ref(true);

  const showAgreement = computed(() => {
    return model.showcase?.statusId !== SHOWCASE_STATUS_DRAFT;
  });

  onMounted(async () => {
    await getModel();
    await getCheckpoints();
    await getShowcase();
    await getPublicShowcase();
    isLoading.value = false;
  });

  const getModel = async () => {
    try {
      const response = await api.leaderPage.view(route.params.id);
      model.name = response.project.name;
      model.number = response.project.id;
      model.id = response.project.id;
      model.type = response.project.type;
      model.requestId = response.project.requestId;
      model.status = response.project.status;
      model.projectStatusId = response.project.projectStatusId;
    } catch (error) {
      console.error('error', error);
    }
  };

  const getCheckpoints = async () => {
    try {
      const response = await api.projectManagementPage.tasks(
        new URLSearchParams({ projectId: route.params.id })
      );
      checkpoints.value = useCheckpoints(response.tasks, null, null);
    } catch (error) {
      console.error('error', error);
    }
  };

  const getShowcase = async () => {
    try {
      const response = await api.showcase.getDraft(route.params.id);
      model.showcase = response.showcase;
    } catch (error) {
      console.error('error', error);
    }
  };

  const getPublicShowcase = async () => {
    try {
      const response = await api.showcase.getPublic(route.params.id);
      model.publicShowcase = response.showcase;
    } catch (error) {
      console.log('error', error);
    }
  };

  const onUpdate = async () => {
    await getShowcase();
    await getPublicShowcase();
    emit('update');
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  * {
    margin: 0;
    padding: 0;
  }

  .ant-input {
    font-family: 'Nunito Sans', sans-serif;
    &[disabled] {
      color: $color-text-primary;
    }
  }

  .leader-project__common-progress {
    margin-top: 42px;
  }
</style>
