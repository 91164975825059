<template>
  <div class="edit-checkpoint__form">
    <h3>Редактировать контрольную точку</h3>
    <p>
      Внесите изменения и сохраните их. Если изменений нет, просто закройте это
      окно.
    </p>
    <a-form
      class="form"
      :model="model"
      :rules="rules"
      @finish="onFinish"
      @validate="errors.onValidate"
    >
      <a-form-item
        name="description"
        :help="errors.getError('description')"
        :validate-status="errors.getStatus('description')"
      >
        <a-input
          v-model:value="model.description"
          placeholder="Сформулируйте результат"
        >
          <template #suffix>
            <img
              v-if="model.description"
              src="@/assets/icons/delete.svg"
              style="cursor: pointer"
              @click="removeCheckpointDescription()"
              alt=""
            />
          </template>
        </a-input>
      </a-form-item>

      <!-- TODO (NMD): Warning: Obsolete attribute -->
      <a-row align="middle" justify="space-between">
        <a-col :span="17">
          <a-form-item
            name="date"
            :help="errors.getError('date')"
            :validate-status="errors.getStatus('date')"
          >
            <a-date-picker
              v-model:value="model.date"
              placeholder="Дата выполнения"
              format="DD.MM.YYYY"
              :locale="locale"
              :disabled="model.noMatter"
            >
              <template #suffixIcon></template>
            </a-date-picker>
          </a-form-item>
        </a-col>
        <a-col>
          <a-form-item name="noMatter">
            <c-checkbox
              v-model:checked="model.noMatter"
              @change="onNoMatterChange(checkpoint)"
            >
              Неважно
            </c-checkbox>
          </a-form-item>
        </a-col>
      </a-row>

      <a-col class="form__is-completed">
        <div class="is-completed__title">Отметить результат выполнения</div>
        <div class="is-completed__checkbox">
          <a-form-item name="is-completed">
            <c-checkbox v-model:checked="model.isCompleted">
              Выполнено
            </c-checkbox>
          </a-form-item>
        </div>
      </a-col>

      <a-form-item
        class="form__comment"
        name="comment"
        :help="errors.getError('comment')"
        :validate-status="errors.getStatus('comment')"
      >
        <a-textarea
          v-model:value="model.comment"
          placeholder="Напишите комментарий при необходимости"
          show-count
          :maxlength="10000"
          :autoSize="{ minRows: 5, maxRows: 6 }"
        />
      </a-form-item>

      <!-- TODO (NMD): Warning: Obsolete attribute -->
      <a-row align="bottom" justify="space-between" class="form__buttons">
        <a-col>
          <div class="buttons__delete">
            <a @click="handleShowForm"> Удалить </a>
          </div>
        </a-col>
        <a-col>
          <a-form-item class="buttons__save">
            <c-button-primary html-type="submit"> Сохранить </c-button-primary>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
  <c-drawer v-model:open="isShowForm" destroyOnClose>
    <component
      :is="DeleteCheckPoint"
      :checkpoint="checkpoint"
      @close="handleCloseForm"
      @update="updateTasks"
    />
  </c-drawer>
</template>

<script setup>
  import { reactive, ref } from 'vue';
  import { useApi } from '@/api/use-api';
  import ErrorsHandler from '@/components/form/errors-handler';
  import CButtonPrimary from '@/components/UI/CButtonPrimary';
  import CCheckbox from '@/components/UI/CCheckbox';
  import DeleteCheckPoint from './DeleteCheckPoint';
  import dayjs from 'dayjs';
  import locale from 'ant-design-vue/es/date-picker/locale/ru_RU';
  import CDrawer from '@/components/UI/CDrawer.vue';
  import { TEXT_LENGTH_ERROR } from '@/constants/error';

  const api = useApi();
  const props = defineProps({
    checkpoint: Object,
  });

  const emit = defineEmits(['close', 'update', 'subaction']);
  const isShowForm = ref(false);

  const model = reactive({
    description: props.checkpoint.description,
    date: props.checkpoint.date ? dayjs(props.checkpoint.date) : null,
    comment: props.checkpoint.comment,
    status: props.checkpoint.status,
    noMatter: props.checkpoint.date === null,
    isCompleted: props.checkpoint.dateCompleted !== null,
  });

  const rules = {
    description: [
      {
        type: 'string',
        required: true,
        min: 2,
        max: 5000,
        message: TEXT_LENGTH_ERROR,
        trigger: 'change',
      },
    ],
    date: [
      {
        type: 'any',
        required: !model.noMatter,
        message: 'Необходимо указать дату выполнения',
        trigger: ['change', 'blur'],
      },
    ],
    comment: [
      {
        type: 'string',
        required: false,
        min: 2,
        max: 10000,
        message: 'Введите комментарий не менее 2, но не более 10000 символов',
        trigger: 'change',
      },
    ],
  };

  const errors = new ErrorsHandler({
    description: '',
    date: '',
    comment: '',
  });

  const onFinish = async () => {
    try {
      await api.projectManagementPage.saveCheckpoint({
        id: props.checkpoint.id,
        taskId: props.checkpoint.taskId,
        description: model.description,
        date: model.noMatter ? null : dayjs(model.date).format('YYYY-MM-DD'),
        comment: model.comment,
        isCompleted: model.isCompleted,
      });

      emit('close');
      emit('update');
    } catch (error) {
      console.log(error);
      errors.handleApiErrors(error);
    }
  };

  const removeCheckpointDescription = () => {
    model.description = '';
  };

  const handleShowForm = () => {
    emit('subaction');
    isShowForm.value = true;
  };

  const handleCloseForm = () => (isShowForm.value = false);

  const onNoMatterChange = (checkpoint) => {
    if (checkpoint.noMatter) {
      checkpoint.date = null;
    }
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  .edit-checkpoint__form {
    padding: 112px 17px 0 17px;

    h3 {
      font: 700 18px/26px 'Nunito Sans';
    }

    h2 {
      color: $color-bg-status-rework;
      font: 700 14px/20px 'Nunito Sans';
      margin-top: 30px;
    }

    p {
      margin-top: 21px;
    }

    .ant-form-item {
      margin-bottom: 14px;
    }

    .form {
      &__is-completed {
        background: $color-bg-secondary-grey;
        border-radius: 6px;
        flex-direction: column;
        height: 107px;
        margin-top: 40px;
        padding: 20px;

        .is-completed__title {
          color: $color-bg-status-new;
          font: 700 14px/20px 'Nunito Sans';
        }

        .is-completed__checkbox {
          margin-top: 20px;
        }
      }

      &__buttons {
        margin-top: 50px;

        .buttons {
          &__delete {
            a {
              border-bottom: 1px solid;
              color: $color-text-accent-red;
              font: 400 14px/20px 'Nunito Sans';
              text-decoration: none;
            }
          }

          &__save {
            margin-bottom: 0;
          }
        }
      }

      .ant-input-textarea-show-count::after {
        color: $color-text-secondary-grey;
        font-family: Nunito Sans, sans-serif;
        font-size: 12px;
        font-weight: 400;
      }

      .form__comment {
        padding-top: 30px;
      }
    }
  }
</style>
