export default {
  docsStepPassed: [
    {
      type: 'boolean',
      required: true,
      validator: (rule, value) => value === true,
    },
  ],
  attachments: [
    {
      type: 'array',
      required: false,
      message: 'Вы можете добавить необходимые документы.',
      trigger: 'blur',
    },
  ],
};
