<template>
  <a-form ref="formRef" layout="vertical" :model="model" :rules="rules">
    <leader-project-form-row
      :show-available="showAvailable"
      :available="model.isAvailable"
      :offset-available="false"
      :disabled="disabled"
      @change="onAvailabilityChange"
      class="bordered"
    >
      <a-row>
        <h3>Портфель {{ index + 1 }}</h3>
        <c-button-remove
          v-if="!disabled"
          class="button-remove"
          @click="onPortfolioDelete"
        />
      </a-row>
      <a-form-item name="portfolioId" label="Название портфеля">
        <a-select
          v-model:value="model.portfolioId"
          :options="model.portfolioOptions"
          :disabled="disabled || !!portfolio.id"
          placeholder="Выберите портфель"
          @select="onPortfolioSelect"
        />
      </a-form-item>

      <a-row class="portfolio-card__label">
        Выберите показатели, которые планируется достичь. Введите значение
        показателя в окне справа.
      </a-row>
      <div class="portfolio-card">
        <a-row class="portfolio-card__indexes-header">
          <a-col :span="20">Показатели</a-col>
          <a-col :span="4" align="middle">Результат</a-col>
        </a-row>
        <div class="portfolio-card__indexes">
          <template v-for="(index, i) in model.indexes" :key="`index-` + i">
            <a-row class="portfolio-card__index">
              <a-col :span="2" class="portfolio-card__index_checkbox">
                <c-checkbox
                  :checked="index.isSelected"
                  :disabled="disabled"
                  @change="onSelectedChange(index)"
                />
              </a-col>
              <a-col :span="22">
                <a-form-item
                  :name="['indexes', i, 'planned']"
                  :rules="{
                    required: index.isSelected,
                    type: 'number',
                    min: 1,
                    trigger: ['change', 'blur'],
                    message: 'Введите значение больше 0',
                  }"
                >
                  <a-row>
                    <a-col :span="20" class="portfolio-card__index_name">
                      {{ index.name }}
                    </a-col>
                    <a-col :span="4" class="portfolio-card__index_value">
                      <a-input-number
                        v-model:value="index.planned"
                        placeholder="Введите значение"
                        :disabled="disabled || !index.isSelected"
                      />
                    </a-col>
                  </a-row>
                </a-form-item>
              </a-col>
            </a-row>
          </template>
        </div>
      </div>
      <a-form-item name="indexes" />
    </leader-project-form-row>
  </a-form>
</template>

<script setup>
  import { computed, onMounted, reactive, ref, watch } from 'vue';
  import LeaderProjectFormRow from '@/views/leader/projects/form/LeaderProjectFormRow.vue';
  import CButtonRemove from '@/components/UI/CButtonRemove.vue';
  import { useApi } from '@/api/use-api';
  import { useRoute } from 'vue-router';
  import CCheckbox from '@/components/UI/CCheckbox.vue';

  const route = useRoute();
  const api = useApi();
  const formRef = ref();
  const emit = defineEmits(['submit-result', 'remove', 'portfolio-select']);

  const props = defineProps({
    index: {
      type: Number,
      required: true,
    },
    portfolio: {
      type: Object,
      required: true,
    },
    portfolioOptions: {
      type: Array,
      required: true,
    },
    showAvailable: {
      type: Boolean,
      default: false,
    },
    offsetAvailable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  });

  const model = reactive({
    portfolioId: null,
    portfolioOptions: [],
    indexes: [],
    isAvailable: false,
  });

  const rules = computed(() => {
    return {
      portfolioId: [
        {
          type: 'number',
          message: 'Выберите портфель.',
          required: !props.portfolio.id,
          trigger: ['blur', 'change'],
        },
      ],
      indexes: [
        {
          type: 'array',
          message: 'Выберите показатели и задайте значения.',
          trigger: ['blur', 'change'],
          validator: (_rule, value, _callback, source) => {
            if (!model.portfolioId) {
              return Promise.resolve();
            }

            let dataToCheck = null;
            if (source) dataToCheck = source.indexes;
            else if (value) dataToCheck = value;
            else return Promise.reject('Rule validation failed.');

            const selectedIndexes = dataToCheck.filter(
              (item) => item.isSelected === true
            );
            if (selectedIndexes.length > 0) {
              return Promise.resolve();
            } else {
              return Promise.reject('Rule validation failed.');
            }
          },
        },
      ],
    };
  });

  onMounted(async () => {
    fillModel();
    updatePortfolioOptions();
    await getIndexes();
  });

  watch(
    () => props.portfolio,
    async () => {
      fillModel();
      await getIndexes();
    }
  );

  watch(
    () => props.portfolioOptions,
    () => {
      updatePortfolioOptions();
    },
    { deep: true }
  );

  const updatePortfolioOptions = () => {
    if (props.portfolio.id) {
      model.portfolioOptions = props.portfolioOptions.filter(
        (item) => item.id === props.portfolio.id
      );
    } else {
      model.portfolioOptions = props.portfolioOptions.filter(
        (item) => item.isSelected === false
      );
    }
  };

  const fillModel = () => {
    if (props.portfolio) {
      model.portfolioId = props.portfolio.id;
      model.isAvailable = props.portfolio.isAvailable;
    }
  };

  const getIndexes = async () => {
    if (!model.portfolioId) return;

    try {
      const response = await api.briefcase.briefcaseIndexes(
        new URLSearchParams({
          projectId: route.params.id,
          briefcaseId: model.portfolioId,
          isSelected: 0,
        })
      );
      model.indexes = response.indexes;
    } catch (error) {
      console.error('error', error);
    }
  };

  const onPortfolioDelete = async () => {
    model.indexes = [];
    emit('remove', { index: props.index });
  };

  const onPortfolioSelect = async (portfolioId) => {
    emit('portfolio-select', {
      index: props.index,
      newPortfolioId: portfolioId,
    });
    model.portfolioId = portfolioId;
    await getIndexes();
  };

  const onAvailabilityChange = () => (model.isAvailable = !model.isAvailable);

  const onSelectedChange = async (index) => {
    index.isSelected = !index.isSelected;
    await formRef.value.clearValidate();
  };

  const submit = async () => {
    const indexesToSave = model.indexes
      .filter((item) => item.isSelected === true)
      .map((item) => ({
        id: item.id,
        briefcaseIndexId: item.indexId,
        desiredValue: item.planned,
      }));

    const submitResult = {
      index: props.index,
      portfolioId: model.portfolioId,
      isAvailable: model.isAvailable,
      indexes: indexesToSave,
      formResult: false,
    };

    formRef.value
      .validate()
      .then(() => {
        submitResult.formResult = true;
        emit('submit-result', submitResult);
      })
      .catch(() => {
        submitResult.formResult = false;
        emit('submit-result', submitResult);
      });
  };

  defineExpose({
    submit,
  });
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  :deep(.ant-form-item-control-input) {
    min-height: 0 !important;
  }

  .bordered {
    border-bottom: 1px solid $color-line;
    padding-bottom: 30px !important;
    margin-bottom: 30px !important;
  }

  .button-remove {
    margin-left: 10px;
  }

  .portfolio-card__label {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.42;
    margin-bottom: 8px;
  }

  .portfolio-card__error {
    color: $color-text-accent-red;
  }

  .portfolio-card {
    background: $color-bg-secondary-grey;
    border-radius: 7px;
    border-bottom: 1px solid $color-line;
    padding-bottom: 12px;

    .portfolio-card__indexes-header {
      color: $color-bg-status-new;
      border-bottom: 1px solid $color-white;
      font-family: 'Nunito Sans', sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      justify-content: space-between;
      padding: 20px 0 12px 0;
      margin: 0 20px 12px 20px;
    }

    .portfolio-card__indexes {
      max-height: 230px;
      overflow-x: hidden;
      overflow-y: auto;
      scrollbar-color: $color-btn-primary #ebebeb;
      margin: 0 6px 0 20px;

      .portfolio-card__index {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;

        .portfolio-card__index_checkbox {
          margin-bottom: 24px;
          align-self: center;
        }

        .portfolio-card__index_name {
          color: $color-text-secondary-1;
          font-family: 'Nunito Sans', sans-serif;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          margin-left: -6px;
          align-self: center;
        }
      }
    }
  }
</style>
