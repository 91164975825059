import {
  read,
  update,
} from "@/api/rest";

const projectReport = {
  view: async (projectId)=> await read('/project-report/' + projectId),
  updateReport: async (data)=> await update('/project-report/update-report', data),
  closeReport: async (data)=> await update('/project-report/close-report', data),
};

export default projectReport;