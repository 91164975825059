<template>
  <portal-layout class="department-page">
    <template v-slot:header>
      <main-title>
        <template v-slot:default>{{ department?.name }}</template>
        <template v-slot:description>{{ department?.parentName }}</template>
      </main-title>
    </template>
    <template v-slot:default>
      <department-about-tab
        v-if="!route.params.tab"
        :department="department"
      />
      <department-concepts-tab
        v-if="route.params.tab === 'concepts'"
        :department="department"
      />
      <department-employees-tab
        v-if="route.params.tab === 'employees'"
        :department="department"
      />
      <department-publications-tab
        v-if="route.params.tab === 'publications'"
        :department="department"
      />
      <department-intellectual-tab
        v-if="route.params.tab === 'intellectual'"
        :department="department"
      />
    </template>
  </portal-layout>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useApi } from "@/api/use-api";
import { useRoute } from "vue-router";
import MainTitle from "@/views/portal/components/MainTitle.vue";
import PortalLayout from "@/layouts/PortalLayout.vue";
import DepartmentAboutTab from "@/views/portal/department/DepartmentAboutTab.vue";
import DepartmentConceptsTab from "@/views/portal/department/DepartmentConceptsTab.vue";
import DepartmentEmployeesTab from "@/views/portal/department/DepartmentEmployeesTab.vue";
import DepartmentPublicationsTab from "@/views/portal/department/DepartmentPublicationsTab.vue";
import DepartmentIntellectualTab from "@/views/portal/department/DepartmentIntellectualTab.vue";

const api = useApi();
const route = useRoute();
const department = ref({
  id: "",
  uuid: "",
  name: "",
  type: "",
  typeTitle: "",
  parentUuid: "",
  parentId: "",
  parentName: "",
});

const getDepartment = async () => {
  try {
    const response = await api.organisationalUnit.view(route.params.id);
    department.value = response.organisationalUnit;
  } catch (error) {
    console.error("error", error);
  }
};

onMounted(() => {
  getDepartment();
});
</script>

<style lang="scss" scoped>
.department-page {
}
</style>
