export const SHOWCASE_STATUS_DRAFT = 1;
export const SHOWCASE_STATUS_AGREEMENT = 2;
export const SHOWCASE_STATUS_PUBLISHED = 3;
export const SHOWCASE_STATUS_REJECTED = 4;
export const SHOWCASE_STATUS_REMOVED = 5;

export const SHOWCASE_PUBLIC_STATUS_LOOKING_FOR_PARTNER =
  'is_looking_for_partner';
export const SHOWCASE_PUBLIC_STATUS_LOOKING_FOR_PERFORMER =
  'is_looking_for_performer';
export const SHOWCASE_PUBLIC_STATUS_WAITING_FOR_OTHER = 'is_waiting_for_other';

export function getPublicStatuses(showcase) {
  const statuses = [];
  if (showcase && showcase.isLookingForPartner) {
    statuses.push({
      type: SHOWCASE_PUBLIC_STATUS_LOOKING_FOR_PARTNER,
      text: 'Ищем партнеров',
    });
  }
  if (showcase && showcase.isLookingForPerformer) {
    statuses.push({
      type: SHOWCASE_PUBLIC_STATUS_LOOKING_FOR_PERFORMER,
      text: 'Ищем исполнителей',
    });
  }
  if (showcase && showcase.isWaiting) {
    statuses.push({
      type: SHOWCASE_PUBLIC_STATUS_WAITING_FOR_OTHER,
      text: 'Ждем похожие запросы',
    });
  }
  return statuses;
}
