<template>
  <div class="attachment-file">
    <a :href="'/api/v1/attachment/' + file?.uid">
      <img class="attachment-file__icon" :src="showTypeIcon(file)" alt="" />
    </a>
    <img
      v-if="isEditable"
      class="attachment-file__delete-icon"
      src="./assets/delete.svg"
      @click="remove"
      alt=""
    />
    <span class="attachment-file__name">{{ getFileBasename }}</span>
    <span class="attachment-file__type">{{ getFileExtension }}</span>
  </div>
</template>

<script setup>
  import WordIcon from './assets/doc.svg';
  import ExcelIcon from './assets/xls.svg';
  import PdfIcon from './assets/pdf.svg';
  import ImageIcon from './assets/image.svg';
  import TextIcon from './assets/txt.svg';
  import CsvIcon from './assets/csv.svg';
  import AnyIcon from './assets/any.svg';
  import { computed } from 'vue';

  const props = defineProps({
    file: {
      type: Object,
      required: true,
    },
    isEditable: Boolean,
  });

  const emit = defineEmits(['remove']);

  const getFileBasename = computed(() => {
    return props.file?.name.split('.')[0];
  });

  const getFileExtension = computed(() => {
    const fileExtension = props.file?.name.split('.')[1];
    return fileExtension ? '.' + fileExtension : '';
  });

  const showTypeIcon = (file) => {
    const fileType = file?.name.split('.')[1].toLowerCase();
    const imagesTypes = ['png', 'jpg', 'jpeg', '.heic'];

    if (!fileType) {
      return AnyIcon;
    }

    switch (true) {
      case fileType.includes('doc'):
        return WordIcon;

      case fileType.includes('xls'):
        return ExcelIcon;

      case fileType.includes('pdf'):
        return PdfIcon;

      case fileType.includes('txt'):
        return TextIcon;

      case fileType.includes('csv'):
        return CsvIcon;

      case imagesTypes.some((type) => type === fileType):
        return ImageIcon;

      default:
        return AnyIcon;
    }
  };

  function remove() {
    emit('remove');
  }
</script>

<style lang="scss" scoped>
  .attachment-file {
    align-items: center;
    color: #2d3346;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight: 700;
    line-height: 12px;
    text-align: center;
    user-select: none;
    transition: display(none) 1s ease-out; // TODO (NMD): Error: Unknown function 'display'
    position: relative;

    &:hover {
      .attachment-file__delete-icon {
        display: block;
      }
    }

    .attachment-file__icon {
      width: 37.45px;
    }

    .attachment-file__delete-icon {
      display: none;
      align-self: flex-end;
      cursor: pointer;
      position: absolute;
      height: 20px;
      width: 20px;

      margin-right: -5px;
      top: 0;
      right: 0;
      margin-top: -13px;
    }

    .attachment-file__name {
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 6px;
    }
  }
</style>
