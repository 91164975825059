<template>
  <div class="project-agreement-container">
    <a-col class="project-agreement">
      <a-row class="actions-row" :wrap="false">
        <a-col :span="2" class="actions__icon">
          <div class="icon__wrapper">
            <slot name="icon"></slot>
          </div>
        </a-col>
        <a-col :span="reject ? 13 : 17" class="actions__label">
          <slot name="labelText"></slot>
        </a-col>
        <a-col :span="reject ? 9 : 5">
          <template
            v-if="(reject && status?.type === 'negotiation') || status == null"
          >
            <a-row type="flex" justify="end" :wrap="false">
              <c-button-danger
                v-if="reject"
                @click="reject"
                :loading="loading"
                >{{ rejectButtonText }}</c-button-danger
              >
              <c-button-primary
                class="action-button"
                @click="action"
                :loading="loading"
                >{{ actionButtonText }}</c-button-primary
              >
            </a-row>
          </template>
          <a-row v-else type="flex" justify="end" :wrap="false">
            <status-label :status="status" />
          </a-row>
        </a-col>
      </a-row>

      <a-row v-if="showReason" class="actions-row">
        <div class="actions__info">
          Причина отклонения:
          <span class="info__description">{{ reason }}</span>
        </div>
      </a-row>
      <a-row
        v-if="reapprove && status?.type === 'rejected'"
        class="actions-row"
      >
        <c-button-primary
          class="reapproval-button"
          @click="reapprove"
          :loading="loading"
        >
          Согласовать повторно
        </c-button-primary>
      </a-row>
    </a-col>
    <div v-if="error" class="project-agreement-card__error">
      {{ error }}
    </div>
  </div>
</template>

<script setup>
  import CButtonPrimary from '@/components/UI/CButtonPrimary.vue';
  import StatusLabel from '@/components/projects/components/StatusLabel.vue';
  import CButtonDanger from '@/components/UI/CButtonDanger.vue';
  import {
    DOCUMENT_APPROVAL_STATUS_REJECTED,
    DOCUMENT_APPROVAL_STATUS_REMOVED,
  } from '@/api/models/DocumentApprovalStatus';
  import { computed } from 'vue';

  const props = defineProps({
    status: {
      default: null,
      type: Object,
      require: true,
    },
    reason: {
      default: '',
      type: String,
      require: true,
    },
    action: {
      type: Function,
      require: true,
    },
    reject: {
      type: Function,
      require: true,
    },
    reapprove: Function,
    actionButtonText: {
      type: String,
      default: 'Закрыть',
    },
    rejectButtonText: {
      type: String,
      default: 'Вернуть в работу',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
    },
  });

  const showReason = computed(() =>
    [
      DOCUMENT_APPROVAL_STATUS_REJECTED,
      DOCUMENT_APPROVAL_STATUS_REMOVED,
    ].includes(props.status?.type)
  );
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  .project-agreement-container {
    .project-agreement {
      background: $color-bg-secondary-grey;
      border-radius: 7px;
      color: $color-link-secondary;
      min-height: 100px;
      padding: 20px;

      .actions-row {
        align-items: center;
        display: flex;

        .actions__icon {
          .icon__wrapper {
            width: 60px;
            height: 60px;
            flex: 0 0 60px;
            background: $color-bg-primary-white;
            border-radius: 3px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .action-button {
          margin-left: 16px;
        }

        .reapproval-button {
          margin-top: 26px;
        }

        .actions__label {
          font: 600 16px/22px 'Nunito Sans';
          flex: 1 0 auto;
          padding: 0 20px;
        }

        .actions__info {
          color: $color-bg-status-rework;
          font: 700 14px/20px 'Nunito Sans';
          margin-top: 24px;

          .info__description {
            font-weight: 400;
          }
        }
      }
    }

    .project-agreement-card__error {
      color: $color-text-accent-red;
    }
  }
</style>
