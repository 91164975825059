import ModeratorRequests from "@/views/moderator-requests/ModeratorRequests";
import ModeratorRequestForm from "@/views/moderator-requests/components/ModeratorRequestForm";

const role = "moderator";

export const moderatorRoutes = [
  {
    path: "/moderator/requests",
    name: "moderator-requests",
    component: ModeratorRequests,
    meta: { requiresAuth: true, type: "request", role: role, default: true },
  },
  {
    path: "/moderator/requests/:id",
    name: "moderator-request",
    component: ModeratorRequestForm,
    meta: { requiresAuth: true, type: "request", role: role },
  },
];
