import Schema from "async-validator";

/**
 * @property emit  - emit event to parent function. Emit must contain the 'update' event.
 */
export default function useInputRowValidator(emit) {
  /**
  * Based on the native ant form validator
  */
  const validateAndUpdate = (index, model, formRef) => {
    formRef.value && formRef.value
      .validate()
      .then(() => {
        emit('update', {index: index, data: model, hasError: false});
      })
      .catch((errors) => {
        if (errors.errorFields.length > 0) {
          emit('update', {index: index, data: model, hasError: true});
        } else {
          emit('update', {index: index, data: model, hasError: false});
        }
      });
  };

  const rawValidate = (index, model, rules) => {
    const descriptor = getRulesDescriptor(rules);

    const validator = new Schema(descriptor);
    validator.validate(model, (errors, ) => {
      if (errors) {
        emit('update', {index: index, data: model, hasError: true})
      } else {
        emit('update', {index: index, data: model, hasError: false});
      }
    })
  };

  const getRulesDescriptor = (rules) => {
    return Object.entries(rules).reduce((newObj, [key, ruleSet]) => {
      newObj[key] = ruleSet.map((rule) => {
        return Object.fromEntries(Object.entries(rule).filter(([k, ]) => k !== 'trigger'));
      });
      return newObj;
    }, {});
  }

  return {
    validateAndUpdate,
    rawValidate,
  };
}