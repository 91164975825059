<template>
  <div class="performer-project">
    <main-title>{{ model.name }}</main-title>
    <project-info
      class="performer-project__info"
      :type="model.type"
      :number="model.number"
      :requestId="model.requestId"
      :status="model.status.text"
    />

    <slot name="underInfo"></slot>

    <horizontal-filter>
      <filter-item
        :path="`/performer/project/${model?.id}/form`"
        :hash="getFormStepHash(model.id)"
        text="Карточка проекта"
      />
      <filter-item
        :path="`/performer/project/${model.id}/tasks`"
        :text="taskTabTitle"
      />
      <!-- temp wrraper -->
      <!--      <div style="opacity: 0.5">-->
      <!--        <filter-item path="?" text="Отчет" style="cursor: not-allowed" />-->
      <!--      </div>-->
    </horizontal-filter>

    <div class="performer-project__content">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
  import { useRoute } from 'vue-router';
  import MainTitle from '@/components/titles/MainTitle.vue';
  import ProjectInfo from '../components/ProjectInfo.vue';
  import HorizontalFilter from '@/components/filter/horizontal/HorizontalFilter.vue';
  import FilterItem from '@/components/filter/components/FilterItem.vue';
  import { computed } from 'vue';

  const route = useRoute();

  const props = defineProps({
    model: {
      type: Object,
      require: true,
    },
  });

  const getFormStepHash = (id) => {
    if (route.path !== '/performer/project/' + id + '/form') {
      return '#1';
    }
    return route.hash;
  };

  const taskTabTitle = computed(() => {
    return props.model.showAllTasks ? 'Все задачи' : 'Мои задачи';
  });
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  .performer-project {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 100px;

    .performer-project__info {
      align-items: center;
      margin-top: 10px;
      margin-bottom: 20px;
    }

    .performer-project__content {
      padding: 30px 0;
    }
  }
</style>
