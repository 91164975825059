<template>
  <div class="delete-task__form">
    <h3>Изменить карточку проекта?</h3>
    <p>Вы действительно хотите изменить данные в карточке проекта?</p>

    <a-form-item name="notification">
      <a-row class="form__task-info">
        <a-col>
          <h2>Внимание!</h2>
          <p>
            Проект изменит статус на "черновик". Потребуется дополнительное
            согласование.
          </p>
        </a-col>
      </a-row>
    </a-form-item>

    <div class="delete-task__form-button">
      <c-button-primary @click="onConfirm"> Продолжить </c-button-primary>
    </div>
  </div>
</template>

<script setup>
  import CButtonPrimary from '@/components/UI/CButtonPrimary.vue';
  import { useRoute } from 'vue-router';
  import { useApi } from '@/api/use-api';

  const route = useRoute();
  const api = useApi();
  const emit = defineEmits(['close', 'review']);

  const onConfirm = async () => {
    try {
      await api.leaderPage.projectReview({
        projectId: route.params.id,
      });
      emit('review');
      emit('close');
    } catch (error) {
      console.error(error);
    }
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';
  * {
    margin: 0;
  }

  .delete-task__form {
    padding: 112px 17px 0 17px;

    h2 {
      font: 700 16px/22px 'Nunito Sans';
    }

    p {
      margin-top: 21px;
    }

    .form__task-info {
      background: $color-bg-secondary-grey;
      flex-direction: column;
      height: 161px;
      margin-top: 40px;
      padding: 20px;

      .form__task-group {
        color: $color-bg-status-new;
        font: 700 14px/20px 'Nunito Sans';
        margin-top: 18px;

        .counter {
          font-weight: 400;
          margin-left: 10px;
        }
      }
    }

    &-button {
      margin-top: 50px;
      text-align: right;
    }
  }
</style>
