import CButtonPrimary from './CButtonPrimary';
import CButtonSecondary from './CButtonSecondary';
import CButtonDanger from './CButtonDanger';
import CCheckbox from './CCheckbox';
import CDrawer from './CDrawer';
import CBadge from './CBadge';
import CCheckpoint from './CCheckpoint';
import CButtonRemove from './CButtonRemove';

export default [
  CButtonPrimary,
  CButtonSecondary,
  CButtonDanger,
  CCheckbox,
  CDrawer,
  CBadge,
  CCheckpoint,
  CButtonRemove,
];
