<template>
  <GuestLayout>
    <template #default>
      <MegaTitle text="Цифровой<br/>университет. НГУ" />
    </template>
    <template #footer>
      <div class="auth-actions">
        <div class="auth-actions__title">Выберите свою роль</div>
        <div class="auth-actions__buttons">
          <a @click.prevent="login">
            <div class="role-item">
              <div class="role-item__icon">
                <div class="role-icon">
                  <img src="./employee.svg" alt="" />
                </div>
              </div>
              <div class="role-item__text">Я - сотрудник</div>
              <div class="role-item__button"></div>
            </div>
          </a>
          <a @click.prevent="toSignUpPage">
            <div class="role-item">
              <div class="role-item__icon">
                <div class="role-icon">
                  <img src="./partner.svg" alt="" />
                </div>
              </div>
              <div class="role-item__text">Я - партнер</div>
              <div class="role-item__button"></div>
            </div>
          </a>
        </div>
      </div>
    </template>
  </GuestLayout>
</template>

<script setup>
  import GuestLayout from '@/layouts/GuestLayout';
  import MegaTitle from '@/components/titles/MegaTitle.vue';
  import { getKeycloak } from '@josempgon/vue-keycloak';
  import { useRouter } from 'vue-router';

  const router = useRouter();

  const login = () => {
    const keycloak = getKeycloak();
    keycloak.login();
  };

  const toSignUpPage = () => {
    router.push({ name: 'sign-in' });
  };
</script>

<style lang="scss">
  @import '@/assets/styles/_colors.scss';

  .auth-actions {
    .auth-actions__title {
      font-size: 1rem;
      font-weight: bold;
      margin-bottom: 20px;
    }

    a .role-item {
      color: $color-link-secondary;
    }

    .role-item {
      align-items: center;
      background: $color-bg-secondary-grey;
      border-radius: 7px;
      display: flex;
      height: 100px;
      padding: 20px 0;
      margin: 0 0 10px;

      &:hover,
      &:focus,
      &:active {
        color: $color-text-secondary-white;

        .role-icon {
          background: $color-bg-secondary-grey;
        }

        .role-item__button {
          background: url('./arrow-white.svg') left center no-repeat;
        }
      }

      &:hover {
        background: $color-btn-special-hover;
      }

      &:focus,
      &:active {
        background: $color-btn-special-active;
      }

      .role-item__icon,
      .role-item__text,
      .role-item__button {
        padding: 0 20px;
      }

      .role-item__icon {
        flex: 0 0 100px;

        .role-icon {
          background: $color-bg-primary-white;
          border-radius: 7px;
          height: 60px;
          width: 60px;
          padding: 20px;

          img {
            height: 20px;
            width: 20px;
          }
        }
      }

      .role-item__text {
        flex: 1 1 auto;
      }

      .role-item__button {
        background: url('./arrow-black.svg') left center no-repeat;
        flex: 0 0 24px;
        height: 24px;
      }
    }
  }
</style>
