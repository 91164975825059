<template>
  <a-row :gutter="10" class="switch-view-buttons">
    <a-col>
      <button
        :class="{ active: typeView === 'cards' }"
        @click="typeView = 'cards'"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g fill="#2E3A59">
            <rect x="3" y="3" width="8" height="8" rx="2" />
            <rect x="13" y="3" width="8" height="8" rx="2" />
            <rect x="13" y="13" width="8" height="8" rx="2" />
            <rect x="3" y="13" width="8" height="8" rx="2" />
          </g>
        </svg>
      </button>
    </a-col>
    <a-col>
      <button
        :class="{ active: typeView === 'table' }"
        @click="typeView = 'table'"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g fill="#2E3A59">
            <rect x="3" y="3" width="4" height="4" rx="1" />
            <rect x="3" y="10" width="4" height="4" rx="1" />
            <rect x="3" y="17" width="4" height="4" rx="1" />
            <rect x="9" y="3" width="12" height="4" rx="1" />
            <rect x="9" y="10" width="12" height="4" rx="1" />
            <rect x="9" y="17" width="12" height="4" rx="1" />
          </g>
        </svg>
      </button>
    </a-col>
  </a-row>
</template>

<script setup>
import { ref, watch } from "vue";

const typeView = ref("cards");
const emit = defineEmits(["change"]);

watch(typeView, () => {
  emit("change", typeView.value);
});
</script>

<style lang="scss" scoped>
.switch-view-buttons {
  height: 100%;

  & > div {
    display: flex;
    align-items: center;
  }

  button {
    border: none;
    background: transparent;
    width: 24px;
    height: 24px;
    padding: 0;
    min-width: 24px;
    cursor: pointer;

    &.active {
      svg * {
        fill: #72c81e;
      }
    }

    &:hover {
      background: #f2f4f8;
    }

    &:active {
      outline: 1px solid #579b15;
    }
  }
}
</style>
