<template>
  <div class="requests">
    <a-row class="requests__header">
      <a-col :span="4" class="request-item__number-col"> Номер </a-col>
      <a-col :span="3" class="request-item__date-col"> Дата </a-col>
      <a-col :span="6" class="request-item__partner-col"> Партнер </a-col>
      <a-col :span="11" class="request-item__type-col"> Тип запроса </a-col>
      <a-col class="request-item__status-col"> Повторная </a-col>
    </a-row>

    <div
      v-for="request in sortedRequests"
      :key="request.id"
      class="requests__item"
    >
      <request-item
        :request="request"
        @click="
          router.push({
            path: `/moderator/requests/${request.id}`,
          })
        "
      />
    </div>
  </div>
</template>

<script setup>
  import { useRouter } from 'vue-router';
  import { computed } from 'vue';
  import dayjs from 'dayjs';
  import RequestItem from './RequestItem';

  const props = defineProps({
    requests: Array,
  });

  const router = useRouter();
  const sortedRequests = computed(() => {
    let initRequests = props.requests;
    initRequests = initRequests.sort(
      (a, b) => dayjs(a.startDate).unix() - dayjs(b.startDate).unix()
    );
    return initRequests;
  });
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';
  $color-text-secondary-grey-216: #718096;

  .requests {
    padding-top: 40px;
  }

  .requests__header {
    gap: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    border-bottom: 1px solid $color-bg-secondary-grey;
    color: $color-text-secondary-grey-216;
    font-variant-numeric: slashed-zero;
    font-family: Nunito Sans, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding: 0 15px 10px 15px;
    margin-bottom: 10px;
  }

  .requests__item {
    cursor: pointer;
    margin-bottom: 20px;
  }
</style>
