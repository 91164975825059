<template>
  <div class="administrator-project">
    <administrator-project-title :title="model.name" :status="model.status" />
    <administrator-project-info
      class="administrator-project__info"
      :type="model.type"
      :number="model.number.toString()"
      :requestId="model.requestId"
    />

    <slot name="underInfo"></slot>

    <horizontal-filter class="administrator-project__filter-panel">
      <filter-item
        :path="`/administrator/project/${model?.id}/form`"
        :hash="getFormStepHash(model.id)"
        text="Карточка проекта"
      />
      <filter-item
        :path="`/administrator/project/${model.id}/management`"
        text="Управление проектом"
      />
      <filter-item
        :path="`/administrator/project/${model?.id}/team`"
        text="Команда"
      />
      <filter-item
        v-if="model?.projectStatusId !== PROJECT_STATUS_DRAFT"
        :path="`/administrator/project/${model?.id}/showcase`"
        text="Витрина"
      />
      <filter-item
        :path="`/administrator/project/${model?.id}/indexes`"
        text="Показатели"
      />
      <filter-item
        v-if="showReportTab"
        :path="`/administrator/project/${model?.id}/report`"
        text="Отчет"
      />
      <filter-item
        v-if="showCloseTab"
        :path="`/administrator/project/${model?.id}/agreement`"
        text="Закрыть проект"
      />
    </horizontal-filter>

    <div class="administrator-project__content">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
  import { useRoute } from 'vue-router';
  import AdministratorProjectTitle from './AdministratorProjectTitle.vue';
  import AdministratorProjectInfo from './AdministratorProjectInfo.vue';
  import HorizontalFilter from '@/components/filter/horizontal/HorizontalFilter.vue';
  import FilterItem from '@/components/filter/components/FilterItem.vue';
  import {
    PROJECT_STATUS_ACCEPTANCE,
    PROJECT_STATUS_CLOSED,
    PROJECT_STATUS_DRAFT,
  } from '@/api/models/ProjectStatus';
  import { computed } from 'vue';

  const route = useRoute();

  const props = defineProps({
    model: {
      type: Object,
      require: true,
    },
  });

  const showReportTab = computed(() => {
    return (
      props.model.projectStatusId === PROJECT_STATUS_ACCEPTANCE ||
      props.model.projectStatusId === PROJECT_STATUS_CLOSED
    );
  });

  const showCloseTab = computed(() => {
    return (
      props.model.projectStatusId === PROJECT_STATUS_ACCEPTANCE ||
      props.model.projectStatusId === PROJECT_STATUS_CLOSED ||
      props.model.approval?.status.type === 'rejected'
    );
  });

  const getFormStepHash = (id) => {
    if (route.path !== '/administrator/project/' + id + '/form') {
      return '#1';
    }
    return route.hash;
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  .administrator-project {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 100px;

    .administrator-project__info {
      align-items: center;
      margin-top: 40px;
    }

    .administrator-project__filter-panel {
      margin-top: 40px;
      padding: 8px 40px 8px 20px;
      border-top: 1px solid $color-bg-secondary-grey;
      border-bottom: 1px solid $color-bg-secondary-grey;
    }

    .administrator-project__content {
      padding: 30px 0;
    }
  }
</style>
