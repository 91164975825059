<template>
  <div class="department-tab-about">
    <white-block>
      <content-container class="content-padding pt-40 pb-40">
        <bordered-block>
          <menu-department :id="department.id" :active="route.params?.tab" />
        </bordered-block>
      </content-container>
      <a-spin :spinning="isLoading" />
    </white-block>

    <white-block v-if="department.description">
      <content-container class="content-padding">
        <div class="department-page__info">
          <div class="department-page__info-text mb-80" v-html="department.description"></div>
          <div class="department-page__info-leader mb-80"></div>
        </div>
      </content-container>
    </white-block>

    <gray-block class="pt-80 pb-80">
      <content-container class="content-padding">
        <white-block class="p-30 round">
          <h3>Направления работы</h3>
          <p>
            Узнайте самые подробные результаты анализа активности
            {{ department.name }}. Указанные в этом разделе метки относятся к
            работам этих участников организации. Вместе они формируют уникальную
            картину его активности.
          </p>
          <div class="mb-40">
            <tags-percent-list type="big-gray" :tags="fingerprints" />
          </div>
          <button-primary
            type="link"
            :href="`/portal/department/` + department.id + `/concepts`"
          >
            Полный список
          </button-primary>
        </white-block>
      </content-container>
    </gray-block>

    <white-block class="pt-80 pb-80">
      <content-container class="content-padding">
        <two-columns>
          <template v-slot:left>
            <gray-block class="graph-widget h-100 p-30 round">
              <chart-wrapper
                title="Количество научных публикаций за последние 5 лет"
              >
                <chart-vertical :items="chart1Items"></chart-vertical>
              </chart-wrapper>
            </gray-block>
          </template>
          <template v-slot:right>
            <gray-block class="graph-widget h-100 p-30 round">
              <chart-wrapper>
                <template #title>
                  <a-row :gutter="20" justify="space-between" :wrap="false">
                    <a-col>
                      <h4>
                        Количество научных публикаций за последние 5 лет по
                        типам
                      </h4>
                    </a-col>
                    <a-col>
                      <button-primary type="link" href="/portal/">
                        Полный список
                      </button-primary>
                    </a-col>
                  </a-row>
                </template>
                <chart-horizontal :items="chart2Items"></chart-horizontal>
              </chart-wrapper>
            </gray-block>
          </template>
        </two-columns>
      </content-container>
    </white-block>

    <gray-block class="pt-80">
      <content-container class="content-padding pb-80" v-if="intellectuals.length > 0">
        <white-block class="p-30 round">
          <carousel-cards
            title="Интеллектуальная собственность за последние 5 лет"
            class="mb-40"
          >
            <publication-item
              v-for="publication in intellectuals"
              :key="`publication-item-` + publication.id"
              :publication="publication"
              url="intellectual"
            />
          </carousel-cards>
          <button-primary
            type="link"
            :href="`/portal/department/` + department.id + `/intellectual`"
          >
            Полный список
          </button-primary>
        </white-block>
      </content-container>
    </gray-block>

    <white-block class="pt-80">
      <content-container class="content-padding">
        <h3>Сотрудники подразделения</h3>
        <a-row :gutter="[20, 20]" class="employees">
          <a-col :sm="24" :md="12" :lg="6">
            <gray-block class="employees__link p-20">
              <div class="employees__count">{{ personsCount }}</div>
              <span>{{ personsCountString() }}</span>
              <button-primary
                type="link"
                :href="`/portal/department/` + department.id + `/employees`"
                class="mt-40"
              >
                Полный список
              </button-primary>
            </gray-block>
          </a-col>
          <a-col
            :sm="24"
            :md="12"
            :lg="6"
            v-for="person in persons"
            :key="`person-` + person.id"
          >
            <person-card
              :id="person.id"
              :name="[person.lastName, person.firstName, person.middleName]"
              :avatar="person.photo"
              :employments="person.employments"
            />
          </a-col>
        </a-row>
      </content-container>
    </white-block>
  </div>
</template>

<script setup>
  import { onMounted, ref, watch } from 'vue';
  import { useRoute } from 'vue-router';
  import { useApi } from '@/api/use-api';
  import ContentContainer from '@/views/portal/components/ContentContainer.vue';
  import WhiteBlock from '@/views/portal/components/WhiteBlock.vue';
  import ButtonPrimary from '@/views/portal/components/UI/ButtonPrimary.vue';
  import ChartVertical from '@/views/portal/components/charts/ChartVertical.vue';
  import ChartHorizontal from '@/views/portal/components/charts/ChartHorizontal.vue';
  import TwoColumns from '@/views/portal/components/TwoColumns.vue';
  import CarouselCards from '@/views/portal/components/CarouselCards.vue';
  import PublicationItem from '@/views/portal/components/publications/PublicationItem.vue';
  import PersonCard from '@/views/portal/components/cards/PersonCard.vue';
  import GrayBlock from '@/views/portal/components/GrayBlock.vue';
  import ChartWrapper from '@/views/portal/components/charts/ChartWrapper.vue';
  import TagsPercentList from '@/views/portal/components/tags/TagsPercentList.vue';
  import MenuDepartment from '@/views/portal/components/menus/MenuDepartment.vue';
  import BorderedBlock from '@/views/portal/components/BorderedBlock.vue';
  import pluralize from "pluralize-ru";
  import {TYPE_INTELLECTUALS} from "@/api/models/Publication";

  const props = defineProps({
    department: {
      type: Object,
      required: true,
    },
  });

  const isLoading = ref(true);
  const api = useApi();
  const route = useRoute();
  const intellectuals = ref([]);
  const fingerprints = ref([]);
  const persons = ref([]);
  const personsCount = ref(0);

  const getFingerprints = async () => {
    if (undefined === props.department.uuid || !props.department.uuid) return;
    try {
      const response = await api.fingerprint.index({
        page: 1,
        'per-page': 10,
        sort: '-weighted_rank',
        content_family: 1,
        content_uuid: props.department.uuid,
      });
      response.fingerprints.map((fp) => {
        fp.description = fp.thesauriName;
        fp.tooltip = fp.weightedRank;
      });
      fingerprints.value = response.fingerprints;
    } catch (error) {
      console.error('error', error);
    }
  };

  const getPersons = async () => {
    if (undefined === props.department.uuid || !props.department.uuid) return;
    try {
      const response = await api.person.index(
        new URLSearchParams({
          isEmployee: true,
          page: 1,
          'per-page': 3,
          organisationalUnitParentUuid: props.department.uuid,
          //sort: "",
        })
      );
      persons.value = response.persons;
      personsCount.value = response.total;
    } catch (error) {
      console.error('error', error);
    }
  };

  const chart1Items = ref([]);
  const chart2Items = ref([]);

  const getTotalPublicationsByYears = async () => {
    if (undefined === props.department.uuid || !props.department.uuid) return;
    try {
      const response = await api.publication.total(
          new URLSearchParams({
            organisational_unit_uuid: props.department.uuid,
            year: new Date().getFullYear() - 4,
            group: 'year',
          })
      );

      chart1Items.value = response.items;
    } catch (error) {
      console.error('error', error);
    }
  };

  const getTotalPublicationsByTypes = async () => {
    if (undefined === props.department.uuid || !props.department.uuid) return;
    try {
      const response = await api.publication.total(
          new URLSearchParams({
            organisational_unit_uuid: props.department.uuid,
            year: new Date().getFullYear() - 4,
            group: 'type',
          })
      );

      chart2Items.value = response.items;
    } catch (error) {
      console.error('error', error);
    }
  };

  const getIntellectuals = async () => {
    if (undefined === props.department.uuid || !props.department.uuid) return;
    try {
      const response = await api.publication.index(
          new URLSearchParams({
            page: 1,
            'per-page': 1,
            sort: '-status_date',
            organisational_unit_uuid: props.department.uuid,
            year: new Date().getFullYear() - 4,
            type: TYPE_INTELLECTUALS,
          })
      );

      intellectuals.value = response.publications;
    } catch (error) {
      console.error('error', error);
    }
  };

  const personsCountString = () =>
      pluralize(
          personsCount.value,
          'сотрудников',
          'сотрудник',
          'сотрудника',
          'сотрудников'
      );

  const loadContent = () => {
    isLoading.value = true;
    Promise.all([
      getPersons(),
      getIntellectuals(),
      getFingerprints(),
      getTotalPublicationsByYears(),
      getTotalPublicationsByTypes()
    ]).then(
        () => {
          isLoading.value = false;
        },
        () => {
          isLoading.value = false;
        }
    )
  }

  onMounted(() => {
    loadContent();
  });

  watch(
    () => props.department.id,
    () => {
      loadContent();
    }
  );
</script>

<style lang="scss" scoped>
  .department-tab-about {
    .graph-widget {
      border-radius: 6px;
    }

    .department-page__info {
      display: flex;
      flex-direction: row;

      .department-page__info-text {
        flex: 1 1 100%;
      }

      .department-page__info-leader {
        flex: 0 0 325px;
        margin-left: 100px;
      }
    }

    .employees {
      margin-top: 30px;

      .employees__link {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        color: #718096;
        text-align: center;
        font-variant-numeric: slashed-zero;
        font-family: 'Nunito Sans', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 1.71429;

        .employees__count {
          color: #2d3346;
          font-size: 92px;
          font-weight: 800;
          line-height: 1.15;
        }
      }
    }
  }
</style>
