<template>
  <a-button class="btn-danger">
    <slot></slot>
  </a-button>
</template>

<script setup></script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  .btn-danger {
    background-color: $color-btn-danger;
    border-color: transparent;
    border-radius: 4px;
    color: $color-text-secondary-white;
    font: 700 14px/22px 'Nunito Sans';
    min-height: 38px;
    min-width: 96px;
    outline: none;

    &:not(:disabled):hover {
      color: $color-text-secondary-white;
      background-color: $color-btn-danger;
      border-color: $color-btn-danger;
    }

    &:not(:disabled):focus {
      border-color: $color-btn-danger;
    }

    &:not(:disabled):active {
      border-color: $color-btn-danger;
    }
  }
</style>
