import { read, update } from '@/api/rest';

const showcase = {
  search: async (options) =>
    await read('/project-showcase?' + options.toString()),
  getPublic: async (projectId) =>
    await read(`/project-showcase/get-public?projectId=${projectId}`),
  getDraft: async (projectId) =>
    await read(`/project-showcase/get-draft?projectId=${projectId}`),
  saveAndApprove: async (data) =>
    await update(`/project-showcase/save-and-approve`, data),
  saveAndReApprove: async (data) =>
    await update(`/project-showcase/save-and-re-approve`, data),
};
export default showcase;
