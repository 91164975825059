<template>
  <div class="showcase-layout">
    <header-showcase>
      <slot name="header"></slot>
    </header-showcase>
    <top-content><slot name="top"></slot></top-content>
    <content-showcase>
      <slot></slot>
    </content-showcase>
    <footer-showcase></footer-showcase>
  </div>
</template>

<script setup>
  import HeaderShowcase from '@/views/projects-showcase/components/HeaderShowcase.vue';
  import TopContent from '@/views/projects-showcase/components/TopContent.vue';
  import FooterShowcase from '@/views/projects-showcase/components/FooterShowcase.vue';
  import ContentShowcase from '@/views/projects-showcase/components/ContentShowcase.vue';

  document.title = 'Проекты НГУ';
</script>

<style lang="scss"></style>
