<template>
  <a-form ref="formRef" layout="vertical">
    <administrator-project-form-row
      :show-available="false"
      :available="true"
      :offset-available="true"
      class="bordered"
    >
      <a-row>
        <h3>Портфель {{ index + 1 }}</h3>
      </a-row>
      <a-form-item name="portfolioId" label="Название портфеля">
        <a-input
          :value="model.portfolioName"
          placeholder="Выберите портфель"
          disabled
        />
      </a-form-item>

      <div class="portfolio-card">
        <a-row class="portfolio-card__indexes-header">
          <a-col :span="20">Показатели</a-col>
          <a-col :span="4" align="middle">Результат</a-col>
        </a-row>
        <div class="portfolio-card__indexes">
          <template v-for="(index, i) in model.indexes" :key="`index-` + i">
            <a-row class="portfolio-card__index">
              <a-col :span="20" class="portfolio-card__index_name">
                {{ index.name }}
              </a-col>
              <a-col :span="4" class="portfolio-card__index_value">
                <a-input
                  v-model:value="index.planned"
                  placeholder="Введите значение"
                  disabled
                />
              </a-col>
            </a-row>
          </template>
        </div>
      </div>
    </administrator-project-form-row>
  </a-form>
</template>

<script setup>
  import { onMounted, reactive, ref } from 'vue';
  import { useApi } from '@/api/use-api';
  import { useRoute } from 'vue-router';
  import AdministratorProjectFormRow from '@/views/administrator/projects/form/AdministratorProjectFormRow.vue';

  const route = useRoute();
  const api = useApi();
  const formRef = ref();

  const props = defineProps({
    index: {
      type: Number,
      required: true,
    },
    portfolio: {
      type: Object,
      required: true,
    },
  });

  const model = reactive({
    portfolioId: null,
    portfolioName: '',
    indexes: [],
    isAvailable: false,
  });

  onMounted(async () => {
    fillModel();
    // updatePortfolioOptions();
    await getIndexes();
  });

  const fillModel = () => {
    if (props.portfolio) {
      model.portfolioId = props.portfolio.id;
      model.portfolioName = props.portfolio.name;
      model.isAvailable = props.portfolio.isAvailable;
    }
  };

  const getIndexes = async () => {
    try {
      const response = await api.briefcase.briefcaseIndexes(
        new URLSearchParams({
          projectId: route.params.id,
          briefcaseId: model.portfolioId,
          isSelected: 1,
        })
      );
      model.indexes = response.indexes;
    } catch (error) {
      console.error('error', error);
    }
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  :deep(.ant-input-disabled) {
    color: $color-text-secondary-1;
    background-color: $color-bg-secondary-grey;
  }

  .bordered {
    border-bottom: 1px solid $color-line;
    padding-bottom: 30px !important;
    margin-bottom: 30px !important;
  }

  .button-remove {
    margin-left: 10px;
  }

  .portfolio-card__label {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.42;
    margin-bottom: 8px;
  }

  .portfolio-card {
    background: $color-bg-secondary-grey;
    border-radius: 7px;
    border-bottom: 1px solid $color-line;
    padding-bottom: 12px;

    .portfolio-card__indexes-header {
      color: $color-bg-status-new;
      border-bottom: 1px solid $color-white;
      font-family: 'Nunito Sans', sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      justify-content: space-between;
      padding: 20px 0 12px 0;
      margin: 0 20px 12px 20px;
    }

    .portfolio-card__indexes {
      margin: 0 6px 0 20px;

      .portfolio-card__index {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        margin: 12px 0;

        .portfolio-card__index_name {
          color: $color-text-secondary-1;
          font-family: 'Nunito Sans', sans-serif;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          margin-left: -6px;
        }
      }
    }
  }
</style>
