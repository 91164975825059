<template>
  <menu-page-horizontal :items="getItems(id, active)" />
</template>

<script setup>
import { onMounted } from "vue";
import MenuPageHorizontal from "@/views/portal/components/menus/MenuPageHorizontal.vue";

defineProps({
  id: {
    type: Number,
    default: 0,
    required: true,
  },
  active: {
    type: String,
    default: "about",
  },
});

const getItems = (id, active) => {
  return [
    {
      label: "О подразделении",
      url: "/portal/department/" + id,
      active: active === "about" || !active,
    },
    {
      label: "Направления работы",
      url: "/portal/department/" + id + "/concepts",
      active: active === "concepts",
    },
    {
      label: "Сотрудники",
      url: "/portal/department/" + id + "/employees",
      active: active === "employees",
    },
    {
      label: "Научные публикации",
      url: "/portal/department/" + id + "/publications",
      active: active === "publications",
    },
    {
      label: "Интеллектуальная собственность",
      url: "/portal/department/" + id + "/intellectual",
      active: active === "intellectual",
    },
  ];
};

onMounted(() => {});
</script>

<style lang="scss" scoped></style>
