import {vueKeycloak} from '@josempgon/vue-keycloak';

const connect = (app) => {
  app.use(vueKeycloak, {
    config: {
      url: process.env.VUE_APP_KEYCLOAK_URL,
      realm: process.env.VUE_APP_KEYCLOAK_REALM,
      clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
    },
    initOptions: {
      flow: 'standard',
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: `${location.origin}/silent-check-sso.html`
    },
  });
};

export default connect;