<template>
  <project-manager-layout :spinning="isLoading">
    <leader-project-layout :model="model">
      <template #underInfo>
        <div class="leader-project__common-progress" v-if="checkpoints.length">
          <progress-bar :items="checkpoints" showPercent />
        </div>
      </template>
      <div class="project-report" v-if="projectReportIsVisible">
        <div>
          <h3>Отчет по проекту</h3>
          <div class="description">
            Опишите полученный результат в поле ниже и прикрепите документы при
            необходимости. Вам также доступен предпросмотр того результата,
            который был запланирован при оформлении карточки проекта. Обратите
            внимание, что после закрытия проекта Вы не сможете редактировать
            отчет, и он станет доступен партнеру и администратору. Пока проект
            открыт, Вы можете вносить изменения.
          </div>

          <div v-if="!!report.initialResult">
            <h4 class="disabled">Результат *</h4>
            <a-textarea
              v-model:value="report.initialResult"
              placeholder="Опишите полученный результат"
              :autosize="{ minRows: 8, maxRows: 8 }"
              style="padding: 10px"
              disabled
            />
          </div>

          <a-form
            class="form"
            :model="report"
            :rules="rules"
            @validate="errors.onValidate"
            @finish="onFinish"
          >
            <a-form-item
              name="result"
              :help="errors.getError('result')"
              :validate-status="errors.getStatus('result')"
            >
              <slot>
                <h4 :class="reportIsClosed ? 'disabled' : ''">Результат *</h4>
              </slot>
              <a-textarea
                v-model:value="report.result"
                placeholder="Опишите полученный результат"
                :autosize="{ minRows: 8, maxRows: 8 }"
                style="padding: 10px"
                :disabled="reportIsClosed"
              />
            </a-form-item>
            <div class="attachments">
              <attachments-input
                :error="errors.getError('attachmentUids')"
                :init-attachments="report.attachments"
                :is-editable="!reportIsClosed"
                :accept="ATTACHMENT_COMMON_EXTENSIONS"
                @update="handleUpdateAttachments"
              />
            </div>

            <a-form-item class="actions">
              <c-button-primary html-type="submit" :disabled="reportIsClosed">
                Сохранить
              </c-button-primary>
            </a-form-item>
          </a-form>
        </div>
      </div>
    </leader-project-layout>
  </project-manager-layout>
</template>

<script setup>
  import { computed, onMounted, reactive, ref } from 'vue';
  import { useRoute } from 'vue-router';
  import { useApi } from '@/api/use-api';
  import ProjectManagerLayout from '@/layouts/ProjectManagerLayout.vue';
  import LeaderProjectLayout from '@/views/leader/projects/components/LeaderProjectLayout.vue';
  import ProgressBar from '@/components/projects/management/ProgressBar';
  import AttachmentsInput from '@/components/attachments/AttachmentsInput.vue';
  import CButtonPrimary from '@/components/UI/CButtonPrimary';
  import ErrorsHandler from '@/components/form/errors-handler';
  import { useCheckpoints } from '@/composables/taskCount';
  import {
    PROJECT_STATUS_ACCEPTANCE,
    PROJECT_STATUS_CLOSED,
    PROJECT_STATUS_DRAFT,
  } from '@/api/models/ProjectStatus';
  import { ATTACHMENT_COMMON_EXTENSIONS } from '@/components/attachments/AttachmentExtentions';
  import { TEXT_LENGTH_ERROR } from '@/constants/error';

  const api = useApi();
  const route = useRoute();
  const model = reactive({
    id: null,
    number: '',
    name: '',
    description: '',
    projectType: '',
    requestId: null,
    status: null,
    projectStatusId: null,
    isMyProject: false,
  });
  const report = reactive({
    closeReasonId: null,
    initialResult: '',
    result: '',
    attachments: [],
    attachmentUids: [],
  });
  const checkpoints = ref([]);
  const isLoading = ref(true);
  const projectReportIsVisible = computed(
    () => model.projectStatusId !== PROJECT_STATUS_DRAFT
  );
  const reportIsClosed = computed(
    () =>
      model.projectStatusId === PROJECT_STATUS_ACCEPTANCE ||
      model.projectStatusId === PROJECT_STATUS_CLOSED
  );

  onMounted(async () => {
    await getModel();
    await getCheckpoints();
    await getReport();
    isLoading.value = false;
  });

  const getReport = async () => {
    try {
      const response = await api.projectReport.view(route.params.id);
      report.closeReason = response.report.closeReason;
      report.initialResult = response.report.initialResult;
      report.result = response.report.result;
      report.attachments = response.report.attachments;
    } catch (error) {
      console.error('error', error);
    }
  };

  const getModel = async () => {
    try {
      const response = await api.leaderPage.view(route.params.id);
      model.name = response.project.name;
      model.number = response.project.id;
      model.id = response.project.id;
      model.type = response.project.type;
      model.requestId = response.project.requestId;
      model.status = response.project.status;
      model.projectStatusId = response.project.projectStatusId;
      model.isMyProject = response.project.isMyProject;
    } catch (error) {
      console.error('error', error);
    }
  };

  const getCheckpoints = async () => {
    try {
      const response = await api.projectManagementPage.tasks(
        new URLSearchParams({ projectId: route.params.id })
      );
      checkpoints.value = useCheckpoints(response.tasks, null, null);
    } catch (error) {
      console.error('error', error);
    }
  };

  const rules = {
    result: [
      {
        required: true,
        min: 2,
        max: 5000,
        message: TEXT_LENGTH_ERROR,
        trigger: 'change',
      },
    ],
  };

  const errors = new ErrorsHandler({
    result: '',
    attachmentUids: '',
  });

  const onFinish = async () => {
    try {
      await api.projectReport.updateReport({
        projectId: route.params.id,
        result: report.result,
        attachmentUids: report.attachmentUids,
      });
      await getModel();
      await getReport();
      window.scrollTo({ top: 0, left: 0 });
    } catch (error) {
      errors.handleApiErrors(error);
    }
  };

  const handleUpdateAttachments = (uidList) => {
    report.attachmentUids = uidList;
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  * {
    margin: 0;
    padding: 0;
  }

  .ant-input {
    font-family: 'Nunito Sans', sans-serif;
    &[disabled] {
      color: $color-text-primary;
    }
  }

  .leader-project__common-progress {
    margin-top: 42px;
  }

  .project-report {
    margin-top: 10px;
    max-width: 902px;
    padding-bottom: 100px;

    h3 {
      font-size: 18px;
      margin-bottom: 10px;
    }

    h4 {
      font-size: 14px;
      margin: 30px 0 6px;

      &.disabled {
        color: $color-bg-status-new !important;
      }
    }

    .description {
      font-family: 'Nunito Sans', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      max-width: 678px;
    }

    .attachments {
      margin-top: 28px;
    }

    .actions {
      text-align: right;
      margin-top: 26px;
    }
  }
</style>
