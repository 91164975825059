import { create, read, update } from "@/api/rest";

const requestPage = {
  new: async (type) => await read(`/request/new/${type}`),
  partnerView: async (id) => await read(`/request/partner-view/${id}`),
  leaderView: async (id) => await read(`/request/leader-view/${id}`),
  moderatorView: async (id) => await read(`/request/moderator-view/${id}`),
  administratorView: async (id) => await read(`/request/administrator-view/${id}`),
  createRequest: async (data)=> await create(`/request`, data),
  updateRequest: async (id, data)=> await update(`/request/${id}`, data),
};

export default requestPage;