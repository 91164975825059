<template>
  <SocialIcon :icon="icon" :link="generateLink(id)"/>
</template>

<script setup>
import SocialIcon from "@/components/social/components/SocialIcon.vue";
import icon from "@/components/social/assets/vk.svg";

defineProps({
  id: String, // VK ID
});

const generateLink = (id) => `https://vk.com/${id}`;
</script>

<style scoped>

</style>