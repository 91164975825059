<template>
  <portal-layout class="departments-page">
    <template v-slot:header>
      <main-title>
        <template v-slot:default>ПОДРАЗДЕЛЕНИЯ</template>
        <template v-slot:description v-if="total">
          {{ totalDepartmentsInNSUString() }} в структуре НГУ
        </template>
      </main-title>
      <div class="departments-page__search">
        <input-search
          placeholder="Ищите подразделения, сотрудников, публикации и многое другое"
        />
      </div>
    </template>
    <template v-slot:default>
      <content-container class="content-padding">
        <a-row
          class="departments-page__header"
          justify="space-between"
          align="center"
          :wrap="false"
        >
          <a-col class="sorting">
            <span class="sorting__label">Сортировать</span>
            <a-select :options="sortingList" v-model:value="sorting" />
          </a-col>
          <a-col>
            <a-row :gutter="10">
              <a-col>
                <switch-view-buttons @change="setTypeView" />
              </a-col>
              <a-col>
                <a-badge
                  :count="countFilter"
                  :number-style="{ backgroundColor: '#72c81e', color: '#fff' }"
                >
                  <button-primary @click="openFilter = true">
                    Фильтр
                  </button-primary>
                </a-badge>
                <right-panel
                  title="Фильтр"
                  v-model:open="openFilter"
                  @close="openFilter = false"
                >
                  <a-form @submit.prevent method="get">
                    <right-panel-title>Тип подразделения</right-panel-title>
                    <div>
                      <a-form-item
                        v-for="filterType in filterTypes"
                        :key="`filter-type-` + filterType.value"
                        class="checkbox-item"
                      >
                        <a-checkbox
                          v-model:checked="filterType.checked"
                        >
                          {{ filterType.label }}
                        </a-checkbox>
                      </a-form-item>
                    </div>
                    <hr class="mt-40 mb-40" />
                    <right-panel-title>Концепции</right-panel-title>
                    <a-form-item>
                      <input-tags
                        placeholder="Введите концепции"
                        :tags="filterTags"
                        @update="setFilterTags"
                      />
                    </a-form-item>
                    <right-panel-buttons>
                      <button-default @click="resetFilter">
                        Очистить
                      </button-default>
                      <button-primary @click="applyFilter">
                        Применить
                      </button-primary>
                    </right-panel-buttons>
                  </a-form>
                </right-panel>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
        <a-spin :spinning="isLoading" />
        <a-row
          class="departments-page__content"
          :class="typeView"
          :gutter="[20, 20]"
        >
          <a-col
            v-for="item in departments"
            :key="`department-` + item.id"
            class="department-item"
          >
            <department-list-card
              v-if="typeView.value === 'table'"
              :id="item.id"
              :name="item.name"
            />
            <department-card
              v-else
              :id="item.id"
              :name="item.name"
              :unit="item.parentName"
              :type="item.typeTitle"
            />
          </a-col>
        </a-row>
        <div class="departments-page__pages" v-if="total > departments.length">
          <a-pagination
            v-model:current="currentPage"
            v-model:pageSize="pageSize"
            :total="total"
            :show-size-changer="false"
          >
            <template #itemRender="{ type, originalElement }">
              <pagination-arrows
                :type="type"
                :original-element="originalElement"
              />
            </template>
          </a-pagination>
        </div>
      </content-container>
    </template>
  </portal-layout>
</template>

<script setup>
  import { computed, onMounted, ref, watch } from 'vue';
  import { useRoute, useRouter} from "vue-router";
  import { useApi } from '@/api/use-api';
  import pluralize from 'pluralize-ru';
  import PortalLayout from '@/layouts/PortalLayout.vue';
  import DepartmentCard from '@/views/portal/components/cards/DepartmentCard.vue';
  import MainTitle from '@/views/portal/components/MainTitle.vue';
  import InputSearch from '@/views/portal/components/UI/InputSearch.vue';
  import ContentContainer from '@/views/portal/components/ContentContainer.vue';
  import SwitchViewButtons from '@/views/portal/components/UI/SwitchViewButtons.vue';
  import PaginationArrows from '@/views/portal/components/UI/PaginationArrows.vue';
  import RightPanel from '@/views/portal/components/panels/RightPanel.vue';
  import RightPanelTitle from '@/views/portal/components/panels/RightPanelTitle.vue';
  import ButtonDefault from '@/views/portal/components/UI/ButtonDefault.vue';
  import ButtonPrimary from '@/views/portal/components/UI/ButtonPrimary.vue';
  import InputTags from '@/views/portal/components/UI/InputTags.vue';
  import DepartmentListCard from "@/views/portal/components/cards/DepartmentListCard.vue";
  import RightPanelButtons from "@/views/portal/components/panels/RightPanelButtons.vue";

  const isLoading = ref(true);
  const api = useApi();
  const route = useRoute();
  const router = useRouter();
  const typeView = ref('cards');
  const sorting = ref('name');
  const currentPage = ref(1);
  const pageSize = ref(12);
  const total = ref(0);
  const totalInNSU = ref(0);
  const departments = ref([]);
  const openFilter = ref(false);
  const filterTags = ref([]);
  const filterTypes = ref([]);
  const sortingList = [
    { value: 'name', label: 'В алфавитном порядке а-я' },
    { value: '-name', label: 'В обратном порядке я-а' },
  ];
  const countFilter = computed(() => {
    const typesCount = route.query.type
        ? ((typeof route.query.type === 'string') ? 1 : route.query.type.length)
        : 0;
    const tagsCount = route.query.tags
        ? ((typeof route.query.tags === 'string') ? 1 : route.query.tags.length)
        : 0;
    return typesCount + tagsCount;
  });

  const routerName = () => {
    return typeView.value === 'table'
        ? 'portal-departments-view-page'
        : 'portal-departments-page'
  }

  const getDepartments = async () => {
    try {
      let params = new URLSearchParams({
        sort: route.query.sort ?? sorting.value,
        types: route.query.type ?? [],
        tags: route.query.tags ?? [],
      });

      if (typeView.value === 'table') {
        params.set('parent_uuid', '');
        // для верхнего уровня очищаем фильтр
        params.delete('types');
        params.delete('tags');
      } else {
        params.set('page', currentPage.value.toString());
        params.set('per-page', pageSize.value.toString());
      }

      const response = await api.organisationalUnit.index(params);
      departments.value = response.organisationalUnits;
      total.value = response.total;
    } catch (error) {
      console.error('error', error);
    } finally {
      isLoading.value = false;
    }
  };

  const getTotalInNSU = async () => {
    try {
      const response = await api.organisationalUnit.totalInNSU({});
      totalInNSU.value = response.total;
    } catch (error) {
      console.error('error', error);
    }
  };

  const getFilterTypes = async () => {
    try {
      const response = await api.organisationalUnit.types();
      filterTypes.value = response.types;
      setTypesByUrl();
    } catch (error) {
      console.error('error', error);
    }
  };

  const setFilterTags = (newTags) => {
    filterTags.value = newTags;
  };

  const resetFilter = () => {
    filterTypes.value.forEach((el) => el.checked = false);
    filterTags.value = [];
    applyFilter();
  };

  const applyFilter = () => {
    openFilter.value = false;

    let types = [];
    let tags = [];
    filterTypes.value
        .filter((el) => el.checked === true)
        .forEach((el) => types.push(el.value));

    router.push({
      name: routerName(),
      params: {},
      query: {
        ...route.query,
        type: types,
        tags: tags,
        page: '1'
      }
    });
  };

  const setTypeView = (view) => {
    typeView.value = view;
  };

  const totalDepartmentsInNSUString = () =>
    pluralize(
      totalInNSU.value,
      '%d подразделений',
      '%d подразделение',
      '%d подразделения',
      '%d подразделений'
    );

  const prepareUrl = async () => {
    if (route.query.page) {
      currentPage.value = parseInt(route.query.page);
    }
    // if (route.query['per-page']) {
    //   pageSize.value = route.query['per-page'];
    // }
    if (route.query.sort) {
      sorting.value = route.query.sort;
    }
    setTypesByUrl();
  }

  const setTypesByUrl = () => {
    try {
      const typeValues = route.query.type
          ? ((typeof route.query.type === 'string') ? [route.query.type] : route.query.type)
          : [];
      filterTypes.value.map((el) => {
        el.checked = false;
        typeValues.forEach((et) => {
          if (el.value === et) el.checked = true;
        })
      });
    } catch (e) {
      console.error(e);
    }
  }

  onMounted(() => {
    prepareUrl();
    getFilterTypes();
    getDepartments();
    getTotalInNSU();
  });

  watch(sorting, () => {
    router.push({
      name: routerName(),
      params: {},
      query: {
        ...route.query,
        sort: sorting.value,
      }
    });
  });

  watch(currentPage, () => {
    router.push({
      name: routerName(),
      params: {},
      query: {
        ...route.query,
        page: currentPage.value,
      }
    });
  });

  watch(pageSize, () => {
    router.push({
      name: routerName(),
      params: {},
      query: {
        ...route.query,
        page: '1',
        'per-page': pageSize.value,
      }
    });
  });

  watch(() => route.query, () => {
    prepareUrl();
    getDepartments();
  });

  watch(typeView, () => {
    router.push({
      name: routerName(),
      params: {},
      query: {
        ...route.query,
        page: '1',
      }
    });
    getDepartments();
  });

</script>

<style lang="scss" scoped>
  .sorting {
    .sorting__label {
      color: #718096;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      margin-right: 15px;
    }
  }

  .departments-page {
    .departments-page__header {
      padding: 40px 0;
    }

    .departments-page__search {
      margin-top: 80px;
      margin-bottom: 40px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .departments-page__content {
      display: flex;

      &.cards {
        flex-direction: row;
        flex-wrap: wrap;

        .department-item {
          flex: 0 0 33.333%;
        }
      }

      &.table {
        flex-direction: column;
        flex-wrap: nowrap;
      }
    }
  }

  .checkbox-item {
    margin-bottom: 0;
  }
</style>
