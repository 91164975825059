<template>
  <a-row class="add-photo__form">
    <a-col class="form__title" :span="24">
      <h3>Добавить фотографию</h3>
    </a-col>
    <a-col class="form__fields" :span="24">
      <a-form ref="formRef" :model="formState" @submit.prevent>
        <a-row :gutter="[0, 19]">
          <a-col :span="24">
            <div class="avatar">
              <img
                class="avatar__image"
                :src="avatarUrl"
                alt=""
                v-if="avatarUrl"
                @error="onImageLoadError"
              />
              <div class="avatar__skeleton" v-else>
                <img src="./assets/camera.svg" alt="" />
              </div>
            </div>
          </a-col>
          <attachments-input
            :error="errors.getError('avatar')"
            :init-attachments="formState.attachments"
            :is-editable="true"
            :accept="ATTACHMENT_IMAGE_EXTENSIONS"
            :show-files="false"
            @update="handleUpdateAttachments"
          />
          <a-col :span="24">
            <a-row justify="end">
              <a-form-item class="form__button">
                <c-button-primary @click="onSubmit" html-type="submit">
                  Сохранить
                </c-button-primary>
              </a-form-item>
            </a-row>
          </a-col>
        </a-row>
      </a-form>
    </a-col>
  </a-row>
</template>

<script setup>
  import { reactive, ref, onMounted, computed } from 'vue';
  import { useApi } from '@/api/use-api';
  import CButtonPrimary from '@/components/UI/CButtonPrimary';
  import ErrorsHandler from '@/components/form/errors-handler';
  import AttachmentsInput from '@/components/attachments/AttachmentsInput.vue';
  import { ATTACHMENT_IMAGE_EXTENSIONS } from '@/components/attachments/AttachmentExtentions';

  const props = defineProps({
    item: Object,
  });

  const api = useApi();
  const errors = new ErrorsHandler({
    avatar: '',
  });

  const formRef = ref();
  const formState = reactive({
    avatarUid: '',
  });

  const emit = defineEmits(['close']);

  const avatarUrl = computed(() => {
    return formState.avatarUid
      ? '/api/v1/attachment/' + formState.avatarUid
      : null;
  });

  const onImageLoadError = (error) => {
    console.error(error);
    formState.avatarUid = '';
  };

  onMounted(() => {
    initAvatar();
  });

  function initAvatar() {
    if (props.item.attachmentUid) {
      formState.avatarUid = props.item.attachmentUid;
    }
  }

  async function onSubmit() {
    if (formState.avatarUid) {
      try {
        await api.avatar.saveAvatar({
          docId: props.item.personId,
          attachmentUid: formState.avatarUid,
        });

        emit('update');
        emit('close');
      } catch (error) {
        console.error(error);
        errors.handleApiErrors(error);
      }
    } else {
      emit('close');
    }
  }

  const handleUpdateAttachments = (uidList) => {
    if (uidList.length > 0) {
      formState.avatarUid = uidList[uidList.length - 1];
    }
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  .add-photo__form {
    padding: 112px 17px 0 17px;

    .form__title {
      h3 {
        font: 700 18px/26px 'Nunito Sans';
      }
    }

    .form__fields {
      margin-top: 30px;

      .avatar {
        display: flex;
        justify-content: center;
        min-height: 240px;

        .avatar__image {
          border-radius: 12px;
          max-width: 100%;
          max-height: 100%;
          margin: auto;
        }

        .avatar__skeleton {
          align-items: center;
          background-color: $color-bg-secondary-grey;
          border-radius: 12px;
          display: flex;
          justify-content: center;
          min-height: 238px;
          width: 100%;
          img {
            width: 54px;
            height: 46px;
          }
        }
      }

      .attachments {
        display: flex;
        flex-direction: column;
        background-color: $color-bg-secondary-grey;
        border-radius: 7px;
        gap: 10px;
        height: 189px;
        flex-wrap: wrap;
        padding: 14px;
      }

      .attachment-file {
        display: block;
        font-size: 12px;
        font-weight: 700;
        line-height: 12px;
        text-align: left;
        user-select: none;
      }

      .ant-form-item {
        margin: 0;
      }

      .ant-upload-text {
        color: #aeb4dd;
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        width: 194px;
      }

      .form__button {
        margin-top: 30px;
      }
    }
  }
</style>
