<template>
  <a-drawer
    :open="open"
    root-class-name="drawer"
    class="drawer-container"
    placement="right"
    width="500"
    :keyboard="false"
    :closable="false"
    @close="handleClose"
    destroyOnClose
  >
    <div class="drawer-container__close" @click="handleClose">
      <img src="./assets/bx-chevron-left.svg" alt="" />
    </div>
    <slot></slot>
  </a-drawer>
</template>

<script>
  export default {
    name: 'CDrawer',
    props: {
      open: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      handleClose() {
        this.$emit('update:open', false);
      },
    },
  };
</script>

<style lang="scss">
  .drawer {
    .ant-drawer-mask {
      background: transparent;
    }

    .ant-drawer-content-wrapper,
    .drawer-container {
      border-radius: 20px 0px 0px 20px;
    }

    .drawer-container__close {
      background-color: #72c81e;
      border-radius: 50px;
      cursor: pointer;
      height: 36px;
      left: -18px;
      top: 41px;
      transform: scale(-1);
      padding: 6px;
      position: absolute;
      width: 36px;
    }
  }
</style>
