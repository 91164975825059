<template>
  <h3>Шаг 4. Команда проекта</h3>
  <p>Общая информация о проекте</p>
  <div>
    <a-form class="form performer-project-form" layout="vertical">
      <template v-for="(user, index) in model.users" :key="`users-` + index">
        <performer-project-form-row class="bordered">
          <a-form-item label="ФИО сотрудника">
            <a-input
              v-model:value="user.fio"
              placeholder="Укажите ФИО сотрудника"
              disabled
            />
          </a-form-item>
          <a-row>
            <a-col :span="12">
              <a-form-item label="Должность">
                <a-input
                  v-model:value="user.position"
                  placeholder="Выберите должность"
                  disabled
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="Подразделение">
                <a-input
                  v-model:value="user.unit"
                  placeholder="Выберите подразделение"
                  disabled
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="Нагрузка">
                <a-input
                  v-model:value="user.workload"
                  placeholder="Укажите нагрузку"
                  disabled
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="Роль в проекте">
                <a-input
                  v-model:value="user.projectRole"
                  placeholder="Укажите роль"
                  disabled
                />
              </a-form-item>
            </a-col>
          </a-row>
        </performer-project-form-row>
      </template>
    </a-form>
  </div>
</template>

<script setup>
  import { onMounted, reactive, watch } from 'vue';
  import PerformerProjectFormRow from '../PerformerProjectFormRow.vue';

  const props = defineProps({
    project: {
      type: Object,
      require: true,
    },
  });

  const model = reactive({
    id: null,
    users: [],
  });

  onMounted(() => { props.project && fillModel(); })

  watch(()=>props.project, () => {
    props.project && fillModel();
  });

  const fillModel = () => {
    try {
      model.id = props.project.id;
      model.users = props.project.members.map((member) => {
        return {
          workload: member.workload,
          projectRole: member.projectRole,
          fio: member.fio,
          unit: member.unit,
          position: member.position,
        }
      })
    } catch (error) {
      console.error('error', error);
    }
  };
</script>

<style lang="scss" scoped>
  @import './performer-project-form';
</style>
