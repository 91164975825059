const prepareItems = (items, coloringMax, sumValues) => {
  let maxValue = 0,
    m = 0;

  if (coloringMax === undefined) coloringMax = false;
  if (sumValues === undefined) sumValues = false;

  items.forEach((item) => {
    let sValue = item.value.toString();
    let v = Number(sValue.replace(',', '.').replace(/[\D-.]/g, ''));
    item.nValue = v;
    if (v > m) m = v;
    if (sumValues) {
      maxValue += v;
    } else {
      maxValue = m;
    }
  });

  items.forEach((item) => {
    if (item.maxValue === undefined) {
      item.maxValue = maxValue;
    }
    if (coloringMax && item.nValue === m) {
      item.color = '#72C81E';
    }
  });

  return items;
};

const numberValue = (value) => {
  let sValue = value.toString();
  return Number(sValue.replace(',', '.').replace(/[\D-.]/g, ''));
};

export { prepareItems, numberValue };
