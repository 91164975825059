<template>
  <div class="add-company__form">
    <h3>Добавить организацию</h3>
    <a-form
      class="form"
      :model="model"
      :rules="rules"
      @validate="errors.onValidate"
      @finish="onFinish"
    >
      <a-form-item
        name="companyName"
        :help="errors.getError('companyName')"
        :validate-status="errors.getStatus('companyName')"
      >
        <c-input-dadata
          v-model:value="model.name"
          type="party"
          placeholder="Название компании*"
          @select="selectCompany"
          :get-label="getCompanyLabel"
        />
      </a-form-item>
      <a-form-item
        name="site"
        :help="errors.getError('site')"
        :validate-status="errors.getStatus('site')"
      >
        <a-input v-model:value="model.site" placeholder="Сайт компании" />
      </a-form-item>
      <a-form-item
        name="unit"
        :help="errors.getError('unit')"
        :validate-status="errors.getStatus('unit')"
      >
        <a-input v-model:value="model.unit" placeholder="Подразделение" />
      </a-form-item>
      <a-form-item
        name="position"
        :help="errors.getError('position')"
        :validate-status="errors.getStatus('position')"
      >
        <a-input v-model:value="model.position" placeholder="Должность" />
      </a-form-item>
      <a-form-item
        name="about"
        :help="errors.getError('about')"
        :validate-status="errors.getStatus('about')"
      >
        <a-textarea
          id="about"
          v-model:value="model.about"
          placeholder="О компании"
        />
      </a-form-item>
      <attachments-input
        :error="errors.getError('attachmentUids')"
        :is-editable="true"
        :accept="ATTACHMENT_COMMON_EXTENSIONS"
        @update="handleUpdateAttachments"
      />
      <a-form-item class="form__button">
        <c-button-primary html-type="submit">Сохранить</c-button-primary>
      </a-form-item>
    </a-form>
  </div>
</template>

<script setup>
  import { reactive } from 'vue';
  import { useApi } from '@/api/use-api';
  import CButtonPrimary from '@/components/UI/CButtonPrimary';
  import CInputDadata from '@/components/UI/CInputDadata.vue';
  import ErrorsHandler from '@/components/form/errors-handler';
  import AttachmentsInput from '@/components/attachments/AttachmentsInput.vue';
  import { ATTACHMENT_COMMON_EXTENSIONS } from '@/components/attachments/AttachmentExtentions';

  const model = reactive({
    name: '',
    fullName: '',
    site: '',
    unit: '',
    position: '',
    about: '',
    inn: '',
    attachments: [],
    attachmentUids: [],
  });

  const rules = {
    name: [
      {
        required: true,
        min: 2,
        max: 255,
        message: 'Необходимо ввести название организации, не менее 2 символов',
        trigger: 'blur',
      },
    ],
  };

  const getCompanyLabel = (item) => {
    return item.value + ' (ИНН:' + item.data.inn + ')';
  };

  const selectCompany = (v, item) => {
    model.inn = item.data.inn;
    model.fullName = item.data.name.full_with_opf;
  };

  const api = useApi();
  const errors = new ErrorsHandler({
    name: '',
    site: '',
    unit: '',
    position: '',
    about: '',
    inn: '',
    attachmentUids: '',
  });

  const emit = defineEmits(['close', 'update']);

  const onFinish = async () => {
    try {
      await api.profilePage.saveCompany({
        name: model.name,
        fullName: model.fullName,
        site: model.site,
        position: model.position,
        about: model.about,
        inn: model.inn,
        attachmentUids: model.attachmentUids,
      });
      emit('close');
      emit('update');
    } catch (error) {
      errors.handleApiErrors(error);
    }
  };

  const handleUpdateAttachments = (uidList) => {
    model.attachmentUids = uidList;
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  .add-company__form {
    padding: 112px 17px 0 17px;

    h3 {
      font: 700 18px/26px 'Nunito Sans';
    }

    .form {
      margin-top: 35px;

      #about {
        height: 124px;
        resize: none;
      }

      .ant-form-item {
        margin: 0 0 14px;
      }

      .form__button {
        margin-top: 40px;
        text-align: right;
      }
    }
  }
</style>
