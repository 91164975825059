<template>
  <project-manager-layout :spinning="isLoading">
    <leader-project-layout :model="model">
      <template #underInfo>
        <div class="leader-project__common-progress" v-if="checkpoints.length">
          <progress-bar :items="checkpoints" showPercent />
        </div>
      </template>
      <a-row class="project-management__team">
        <template v-if="!showExecutorProfile">
          <team-member-card
            v-for="(executor, index) in team"
            :key="index"
            :executor="executor"
            @click="handleShowExecutorProfile(executor)"
          />
        </template>
      </a-row>
      <team-member-profile
        @close="handleHideExecutorProfile"
        :executor="currentExecutor"
        :disabled="true"
        v-if="showExecutorProfile"
      />
    </leader-project-layout>
  </project-manager-layout>
</template>

<script setup>
  import { onMounted, reactive, ref } from 'vue';
  import { useRoute } from 'vue-router';
  import { useApi } from '@/api/use-api';
  import ProjectManagerLayout from '@/layouts/ProjectManagerLayout.vue';
  import LeaderProjectLayout from '@/views/leader/projects/components/LeaderProjectLayout.vue';
  import ProgressBar from '@/components/projects/management/ProgressBar';
  import TeamMemberCard from './TeamMember/TeamMemberCard.vue';
  import TeamMemberProfile from './TeamMember/TeamMemberProfile.vue';

  const api = useApi();
  const route = useRoute();
  const model = reactive({
    id: null,
    number: '',
    name: '',
    description: '',
    projectType: '',
    requestId: null,
    status: null,
    isMyProject: false,
    projectStatusId: null,
  });
  const showExecutorProfile = ref(false);
  const currentExecutor = ref(null);
  const team = ref([]);
  const checkpoints = ref([]);
  const isLoading = ref(true);

  onMounted(async () => {
    await getModel();
    await getTasks();
    await getTeam();
    isLoading.value = false;
  });

  const getModel = async () => {
    try {
      const response = await api.leaderPage.view(route.params.id);
      model.name = response.project.name;
      model.number = response.project.id;
      model.id = response.project.id;
      model.type = response.project.type;
      model.requestId = response.project.requestId;
      model.status = response.project.status;
      model.isMyProject = response.project.isMyProject;
      model.projectStatusId = response.project.projectStatusId;
    } catch (error) {
      console.error('error', error);
    }
  };

  const getTasks = async () => {
    try {
      const response = await api.projectManagementPage.tasks(
        new URLSearchParams({ projectId: route.params.id })
      );
      getAllCheckpoints(response.tasks);
    } catch (error) {
      console.error('error', error);
    }
  };

  const getTeam = async () => {
    try {
      const query = new URLSearchParams();
      query.set('projectId', route.params.id.toString());
      const response = await api.user.executors(query);
      team.value = response.user.items;
    } catch (error) {
      console.error('error', error);
    }
  };

  const getAllCheckpoints = (tasks) => {
    const temp = [];

    tasks.map((task) => {
      task.checkpoints.map((checkpoint) => temp.push(checkpoint));

      if (task.subtasks) {
        task?.subtasks.map((subtask) => {
          subtask?.checkpoints.map((checkpoint) => temp.push(checkpoint));
        });
      }
    });
    checkpoints.value = temp;
  };

  const handleShowExecutorProfile = (executor) => {
    currentExecutor.value = executor;
    showExecutorProfile.value = true;
  };

  const handleHideExecutorProfile = () => {
    currentExecutor.value = null;
    showExecutorProfile.value = false;
  };
</script>

<style lang="scss" scoped>
  .leader-project__common-progress {
    margin-top: 42px;
  }

  .project-management__team {
    column-gap: 20px;
    row-gap: 20px;
  }
</style>
