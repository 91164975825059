<template>
  <div class="project-card">
    <a-row :gutter="[20, 20]">
      <a-col :span="12">
        <a-row class="project-card__meta">
          <a-col class="project-card__name">
            {{ project.name }}
          </a-col>
        </a-row>

        <a-row class="project-card__info">
          <div>
            Номер проекта: <span>{{ project.id }}</span>
          </div>
          <div>
            Тип проекта:
            <span>{{ project.type }}</span>
          </div>
          <div>
            Компания:
            <span>{{ project.organizationName }}</span>
          </div>
          <div>Руководитель проекта</div>
          <project-person
            :avatar="getAvatarUrl(project.leaderAvatarUid)"
            :name="project.leaderName"
            :email="project.leaderEmail"
          />

          <a-col>
            <a-tag
              v-for="(tag, index) in project.tags"
              :key="index"
              class="project-card__tag"
              closable
              @close.prevent
              >{{ `#${tag.value}` }}
            </a-tag>
          </a-col>
          <a-col>
            <progress-bar-generated
              class="progress-bar"
              :completed="project?.completedCount"
              :completed-overdue="project?.completedOverdueCount"
              :incomplete-overdue="project?.incompleteOverdueCount"
              :incomplete="project?.incompleteCount"
            />
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="12">
        <a-row class="project-card__indexes">
          <div class="indexes__title">Показатели</div>
          <a-col class="indexes__detail">
            <div
              class="index"
              v-for="(index, idx) in project.indexes"
              :key="idx++"
            >
              <div class="index__status">
                <div
                  v-if="index.status === STATUS_INCOMPLETE_OVERDUE"
                  :class="STATUS_INCOMPLETE_OVERDUE"
                >
                  <img src="@/assets/icons/error.svg" alt="" />
                </div>
                <div v-else :class="index.status"></div>
              </div>
              <div class="index__label">
                {{ index.name }}
              </div>
              <div class="index__result">
                {{ item.result.done ?? 0 }}/{{ item.result.value }}
              </div>
            </div>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script setup>
  import ProgressBarGenerated from '@/components/projects/portfolio/components/ProgressBarGenerated.vue';
  import ProjectPerson from '@/views/administrator/projects/components/ProjectPerson.vue';
  import { STATUS_INCOMPLETE_OVERDUE } from '@/api/models/Checkpoint';

  defineProps({
    project: Object,
    isPortfolio: {
      type: Boolean,
      default: false,
    },
  });

  const getAvatarUrl = (avatar) =>
    avatar ? `/api/v1/attachment/${avatar}` : null;
</script>

<style lang="scss" scoped>
  @import 'portfolio-project-card';
</style>
