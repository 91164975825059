<template>
  <div class="progress-bar">
    <div class="progress-bar__items">
      <div
        v-for="(item, index) in props.items"
        class="progress-bar__item"
        :style="calcItemPercent(item, props.items?.length)"
        :key="index"
      />
    </div>
    <div v-if="showPercent" class="progress-bar__percentage">
      <div v-if="showLabel">Процент выполнения:</div>
      <strong>{{ calcPercentageCompletion(props?.items) }}</strong>
    </div>
  </div>
</template>

<script setup>
  import {
    Checkpoint,
    STATUS_INCOMPLETE,
    STATUS_COMPLETED,
    STATUS_INCOMPLETE_OVERDUE,
    STATUS_COMPLETED_OVERDUE,
  } from '@/api/models/Checkpoint';

  const props = defineProps({
    items: Array,
    showPercent: Boolean,
    showLabel: {
      type: Boolean,
      default: true,
    },
    incompleteColor: {
      type: String,
      default: 'rgb(242, 244, 248)',
    },
  });

  const calcItemPercent = (item, itemsCount) => {
    const width = 100 / itemsCount + '%';
    let statusColor = '';

    switch (item?.status) {
      case STATUS_INCOMPLETE:
        statusColor = props.incompleteColor;
        break;

      case STATUS_COMPLETED:
        statusColor = 'rgb(114, 200, 30)';
        break;

      case STATUS_INCOMPLETE_OVERDUE:
        statusColor = 'rgb(246, 81, 96)';
        break;

      case STATUS_COMPLETED_OVERDUE:
        statusColor = 'rgb(255, 189, 62)';
        break;
    }

    return {
      width,
      'background-color': statusColor,
    };
  };

  const calcPercentageCompletion = (items) => {
    const completedItemsCount = items.filter((item) =>
      Checkpoint.isCompleted(item.status)
    ).length;
    const percentage =
      items.length === 0
        ? 0
        : Math.round((completedItemsCount / items.length) * 100);

    return `${percentage}%`;
  };
</script>

<style lang="scss" scoped>
  .progress-bar {
    align-items: center;
    //background-color: transparent;
    border-radius: 100px;
    column-gap: 50px;
    display: flex;
    min-width: 420px;
    position: relative;
    //overflow: hidden;
    width: 100%;

    .progress-bar__items {
      display: flex;
      width: 100%;

      .progress-bar__item {
        height: 8px;
        position: relative;
        border-radius: 100px;
        transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
      }
    }

    &__percentage {
      display: flex;
      column-gap: 5px;
      justify-content: flex-end;
      width: 15%;
    }
  }
</style>
