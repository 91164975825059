export default {
  name: [
    {
      required: true,
      min: 2,
      max: 255,
      message:
        'Необходимо ввести название проекта, не менее 2 и не более 255 символов',
      trigger: 'change',
    },
  ],
  typeId: [
    {
      type: 'number',
      required: true,
      message: 'Необходимо выбрать тип проекта',
      trigger: 'blur',
    },
  ],
  unitId: [
    {
      type: 'number',
      required: false,
      message: 'Необходимо выбрать подразделение',
      trigger: ['blur', 'change'],
      validator: (_rule, value, _callback, source) => {
        if (source) {
          return Promise.resolve(); // async_validator is disabled for this property
        }

        if (source && (source.isExternal || parseInt(source.unitId))) {
          return Promise.resolve();
        }

        if (value && parseInt(value)) {
          return Promise.resolve();
        }

        return Promise.reject('Rule validation failed.');
      },
    },
  ],
};
