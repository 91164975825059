import 'ant-design-vue/dist/reset.css';
import './assets/styles/styles.scss';
import './assets/styles/ant-design.scss';
import 'dayjs/locale/ru';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from '@/store';
import components from '@/components/UI';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Breadcrumb,
  Dropdown,
  Menu,
  Select,
  Divider,
  Tag,
  DatePicker,
  Checkbox,
  Upload,
  UploadDragger,
  Spin,
  Drawer,
  Badge,
  Table,
  Carousel,
  Pagination,
  Card,
  Collapse,
  CollapsePanel,
  InputNumber,
  Tooltip,
} from 'ant-design-vue';
import connectBackend from '@/services/backend/connect';
import connectKeycloak from '@/services/keycloak/connect';

const app = createApp(App);

components.forEach((component) => {
  app.component(component.name, component);
});

connectBackend(app);
connectKeycloak(app);

// TODO: использовать https://github.com/antfu/unplugin-vue-components вместо кучи use() для каждого компонента ant design
// Справка: https://antdv.com/docs/vue/introduce#use-modularized-ant-design-vue
app
  .use(router)
  .use(store)
  .use(Row)
  .use(Col)
  .use(Form)
  .use(Input)
  .use(Button)
  .use(Breadcrumb)
  .use(Dropdown)
  .use(Menu)
  .use(Select)
  .use(Divider)
  .use(Tag)
  .use(DatePicker)
  .use(Checkbox)
  .use(Upload)
  .use(UploadDragger)
  .use(Spin)
  .use(Drawer)
  .use(Badge)
  .use(Table)
  .use(Collapse)
  .use(CollapsePanel)
  .use(Carousel)
  .use(Pagination)
  .use(Card)
  .use(InputNumber)
  .use(Tooltip)
  .mount('#app');
