<template>
  <div class="person-avatar" :style="avatarStyle(url)">
  </div>
</template>

<script setup>
  defineProps({
    url: {
      type: String,
      default: '',
    },
  });

  const avatarStyle = (url) => {
    if (!url) return "";
    return "background-image: url('"+url+"')";
  }
</script>

<style lang="scss" scoped>
  .person-avatar {
    border-radius: 6px;
    width: 170px;
    height: 170px;
    margin-bottom: 20px;
    background-color: #c4c4c4;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("assets/nobody.jpg");
  }
</style>
