import { create, read, update } from '@/api/rest';

const administratorPage = {
  index: async () => await read('/administrator-page'),
  archive: async () => await read('/administrator-page/archive'),
  view: async (id) => await read('/administrator-page/' + id),
  newItemsCount: async () => await read('/administrator-page/new-items-count'),
  saveRequest: async (data) =>
    await create('/administrator-page/save-request', data),
  enterQueue: async (data) =>
    await create('/administrator-page/enter-queue', data),
  rejectRequest: async (data) =>
    await create('/administrator-page/reject-request', data),
  getProject: async (id) => await read('/administrator-page/project?id=' + id),
  getProjectToClose: async (id) =>
    await read('/administrator-page/project-to-close?id=' + id),
  projectSteps: async (id) =>
    await read('/administrator-page/project-steps?id=' + id),
  projects: async (filter) =>
    await read('/administrator-page/projects?filter=' + filter),
  approveProject: async (data) =>
    await update('/administrator-page/approve-project', data),
  reApproveProject: async (data) =>
    await update('/administrator-page/re-approve-project', data),
  rejectProject: async (data) =>
    await update('/administrator-page/reject-project', data),
};

export default administratorPage;
