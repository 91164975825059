<template>
  <h3>Шаг 9. Согласование</h3>
  <p>
    Если Вас устраивает содержание проекта, пожалуйста, согласуйте его. Если у
    Вас есть какие-либо корректировки, отклоните проект и оставьте комментарий.
    Руководитель проекта увидит Ваши замечания, внесет изменения и отправит Вам
    проект для повторного согласования.
  </p>
  <div>
    <div class="project-agreement-grid">
      <template
        v-for="(approval, index) in model.approvals"
        :key="`approval-${index}`"
      >
        <agreement-row
          class="agreement-row"
          :status="approval.status"
          :reason="approval.rejectedReason"
          :action="() => handleApproval(approval, index)"
          :reject="() => handleReject(approval)"
          :action-button-text="'Согласовать'"
          :reject-button-text="'На доработку'"
          :loading="loaders[index]"
          :error="errors[index].getError('approval')"
        >
          <template #icon>
            <img src="@/assets/icons/icon-bag.svg" alt="" />
          </template>

          <template #labelText>
            <div>{{ approval.status?.actionLabel }}</div>
            <div>
              {{ `Портфель: ${approval.briefcaseName}` }}
            </div>
          </template>
        </agreement-row>
      </template>
    </div>
  </div>
  <c-drawer v-model:open="isShowForm" destroyOnClose>
    <component
      :is="AdministratorProjectRejectForm"
      :project-approval-id="model.rejectedProjectApprovalId"
      @close="handleCloseForm"
      @update="updateOnReject"
    />
  </c-drawer>
</template>

<script setup>
  import { onMounted, reactive, ref, watch } from 'vue';
  import AdministratorProjectRejectForm from '../AdministratorProjectRejectForm.vue';
  import CDrawer from '@/components/UI/CDrawer.vue';
  import { useApi } from '@/api/use-api';
  import ErrorsHandler from '@/components/form/errors-handler';
  import AgreementRow from '@/components/projects/agreement/AgreementRow.vue';

  const api = useApi();
  const emit = defineEmits(['update']);

  const props = defineProps({
    project: {
      type: Object,
      require: true,
    },
  });

  const model = reactive({
    id: null,
    approvals: [],
    rejectedReason: null,
    rejectedProjectApprovalId: null,
  });
  const errors = ref([]);
  const loaders = ref([]);
  const isShowForm = ref(false);

  watch(
    () => props.project,
    () => {
      fillModel();
    }
  );

  onMounted(() => {
    props.project && fillModel();
  });

  const fillModel = () => {
    try {
      model.id = props.project.id;
      model.approvals = props.project.approvals;

      errors.value = model.approvals.map(() => {
        return new ErrorsHandler({ approval: '' });
      });
      loaders.value = model.approvals.map(() => false);
    } catch (error) {
      console.error('error', error);
    }
  };

  const handleApproval = async (approval, index) => {
    try {
      loaders.value[index] = true;
      errors.value[index].clearErrors();
      await api.administratorPage.approveProject({
        projectApprovalId: approval.id,
      });
      emit('update', model);
    } catch (error) {
      errors.value[index].handleApiErrors(error);
    } finally {
      loaders.value[index] = false;
    }
  };

  const handleReject = (approval) => {
    model.rejectedProjectApprovalId = approval.id;
    isShowForm.value = true;
  };

  const handleCloseForm = () => (isShowForm.value = false);

  const updateOnReject = () => emit('update', model);
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  p {
    width: 555px;
  }

  .project-agreement-grid {
    margin-top: 10px;
    max-width: 902px;
    padding-bottom: 100px;

    .agreement-row {
      margin-top: 30px;
    }
  }
</style>
