<template>
  <h3>Шаг 7. Портфели и показатели</h3>
  <p>
    Проверьте информацию в карточке проекта и, если согласны, согласуйте проект.
  </p>

  <a-divider />

  <div>
    <a-form class="form leader-project-form" layout="vertical">
      <template
        v-for="(portfolio, index) in model.projectPortfolios"
        :key="`portfolio-${index}`"
      >
        <portfolio-card
          :show-available="false"
          :index="index"
          :portfolio="portfolio"
          :portfolio-options="model.portfolioOptions"
        />
      </template>
    </a-form>
  </div>
</template>

<script setup>
  import { onMounted, reactive } from 'vue';
  import PortfolioCard from '@/views/partner-projects/components/PortfolioCard.vue';

  const props = defineProps({
    project: {
      type: Object,
      require: true,
    },
  });

  const model = reactive({
    isExternal: false,
    projectPortfolios: [],
    portfolioOptions: [],
  });

  onMounted(async () => {
    fillModel();
  });

  const fillModel = () => {
    try {
      model.isExternal = props.project.isExternal;
      fillPortfolios();
    } catch (error) {
      console.error('error', error);
    }
  };

  const fillPortfolios = () => {
    try {
      model.projectPortfolios = props.project.briefcases
        .filter((item) => item.isSelected)
        .map((item) => ({
          id: item.id,
          name: item.name,
          isAvailable: item.isAvailable,
          briefcaseProjectId: item.briefcaseProjectId,
          indexes: [],
        }));
    } catch (error) {
      console.error(error);
    }
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  .attachments {
    display: flex;
    flex-direction: row;
    background-color: $color-bg-secondary-grey;
    border-radius: 7px;
    gap: 44px;
    flex-wrap: wrap;
    padding: 24px;
    margin-top: 20px;
  }

  .ant-upload-text {
    color: #aeb4dd;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    width: 194px;
  }
</style>
