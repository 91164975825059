import { update, read } from "@/api/rest";

const moderatorPage = {
  index: async () => await read('/moderator-page'),
  archive: async () => await read('/moderator-page/archive'),
  view: async (id) => await read('/moderator-page/' + id),
  saveRequest: async (id, data) =>
    await update(`/moderator-page/save-request/${id}`, data),
  units: async() => await read('/moderator-page/units'),
};

export default moderatorPage;