export default {
    budget: [
        {
            type: 'number',
            required: true,
            message: 'Необходимо указать сумму общего объема финансирования',
            trigger: 'change',
        },
    ],
    budgetSource: [
        {
            type: 'string',
            min: 2,
            required: true,
            message: 'Необходимо указать источник финансирования',
            trigger: 'change',
        },
    ],
};
