<template>
  <performer-projects-layout :spinning="isLoading">
    <performer-project-layout :model="model">
      <template #underInfo>
        <div class="leader-project__common-progress" v-if="checkpoints.length">
          <progress-bar :items="checkpoints" showPercent />
        </div>
      </template>
      <a-row class="performer-project-form" :wrap="false">
        <a-col :span="5">
          <div class="performer-project-form__menu">
            <router-link
              v-for="item in menuSteps"
              :key="`project-menu-` + item.step"
              :to="`#` + item.step"
              :class="{ active: item.step === step }"
            >
              {{ item.label }}
            </router-link>
          </div>
        </a-col>
        <a-col :span="19" class="performer-project-form__content">
          <template v-if="step === 1">
            <performer-project-form-step1 :project="model.project" />
          </template>
          <template v-if="step === 2">
            <performer-project-form-step2 :project="model.project" />
          </template>
          <template v-if="step === 3">
            <performer-project-form-step3 :project="model.project" />
          </template>
          <template v-if="step === 4">
            <performer-project-form-step4 :project="model.project" />
          </template>
          <template v-if="step === 5">
            <performer-project-form-step5 :project="model.project" />
          </template>
          <template v-if="step === 6">
            <performer-project-form-step6 :project="model.project" />
          </template>
          <template v-if="step === 7">
            <performer-project-form-step7 :project="model.project" />
          </template>
          <template v-if="step === 8">
            <performer-project-form-step8 :project="model.project" />
          </template>
        </a-col>
      </a-row>
    </performer-project-layout>
  </performer-projects-layout>
</template>

<script setup>
  import { onMounted, reactive, ref, watch } from 'vue';
  import { useRoute } from 'vue-router';
  import { useApi } from '@/api/use-api';
  import ProgressBar from '@/components/projects/management/ProgressBar';
  import PerformerProjectsLayout from '../layouts/PerformerProjectsLayout.vue';
  import PerformerProjectLayout from '../layouts/PerformerProjectLayout.vue';
  import PerformerProjectFormStep1 from './steps/PerformerProjectFormStep1.vue';
  import PerformerProjectFormStep2 from './steps/PerformerProjectFormStep2.vue';
  import PerformerProjectFormStep3 from './steps/PerformerProjectFormStep3.vue';
  import PerformerProjectFormStep4 from './steps/PerformerProjectFormStep4.vue';
  import PerformerProjectFormStep5 from './steps/PerformerProjectFormStep5.vue';
  import PerformerProjectFormStep6 from './steps/PerformerProjectFormStep6.vue';
  import PerformerProjectFormStep7 from './steps/PerformerProjectFormStep7.vue';
  import PerformerProjectFormStep8 from './steps/PerformerProjectFormStep8.vue';

  const api = useApi();
  const route = useRoute();
  const tasks = ref([]);
  const checkpoints = ref([]);
  const step = ref(1);
  const model = reactive({
    project: null,
    id: null,
    number: '',
    name: 'Новый проект',
    description: '',
    projectType: '',
    requestId: null,
    status: {
      type: 'draft',
      text: 'Черновик',
    },
  });
  const isLoading = ref(true);

  const menuSteps = [
    { step: 1, label: 'Информация о проекте', hide: false, completed: false },
    { step: 2, label: 'Техническое задание', hide: false, completed: false },
    { step: 3, label: 'Календарный план', hide: false, completed: false },
    { step: 4, label: 'Команда проекта', hide: false, completed: false },
    { step: 5, label: 'Финансы', hide: false, completed: false },
    { step: 6, label: 'Ресурсы', hide: false, completed: false },
    {
      step: 7,
      label: 'Портфели и показатели',
      hide: false,
      completed: false,
    },
    { step: 8, label: 'Документы', hide: false, completed: false },
  ];

  onMounted(async () => {
    await getModel();
    await getTasks();
    getStep();
    isLoading.value = false;
  });

  const getModel = async () => {
    try {
      const response = await api.performerPage.projectSteps(route.params.id);
      model.project = response.project;
      model.id = response.project.id;
      model.name = response.project.name;
      model.number = response.project.id;
      model.type = response.project.type;
      model.requestId = response.project.requestId;
      model.status = response.project.status;
    } catch (error) {
      console.error('error', error);
    }
  };

  const getStep = () => {
    let hash = route.hash.replace('#', '');
    step.value = hash ? Number(hash) : 1;
    return step.value;
  };

  const getTasks = async () => {
    try {
      const response = await api.projectManagementPage.tasks(
        new URLSearchParams({ projectId: route.params.id })
      );
      tasks.value = response.tasks;
      getAllCheckpoints(tasks.value);
    } catch (error) {
      console.error('error', error);
    }
  };

  const getAllCheckpoints = (tasks) => {
    const temp = [];

    tasks.map((task) => {
      task.checkpoints.map((checkpoint) => temp.push(checkpoint));

      if (task.subtasks) {
        task?.subtasks.map((subtask) => {
          subtask?.checkpoints.map((checkpoint) => temp.push(checkpoint));
        });
      }
    });

    checkpoints.value = temp;
  };

  watch(route, () => {
    getStep();
  });
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  h3 {
    color: $color-black;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.1;
  }

  .performer-project-form {
    .performer-project-form__menu {
      background: #f2f4f8;
      padding: 14px 6px;

      a {
        color: $color-text-primary;
        font-variant-numeric: slashed-zero;
        font-family: 'Nunito Sans', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.4;

        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        margin: 6px 0;
        padding: 6px 6px 6px 30px;
        border-radius: 4px;

        &:hover,
        &.active {
          background: #ffffff;
          font-weight: 700;
        }

        :deep(.done-circle-icon) {
          margin-left: -24px;
          margin-right: 9px;
        }
      }
    }

    .performer-project-form__content {
      padding-left: 20px;
    }
  }
</style>
