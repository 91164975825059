<template>
  <div class="publication-tab-about">
    <white-block>
      <content-container class="content-padding pt-40 pb-40">
        <bordered-block>
          <menu-publication :id="publication.id" :active="route.params?.tab" />
        </bordered-block>
      </content-container>
    </white-block>

    <white-block>
      <content-container class="content-padding">
        <div class="publication__info">

          <gray-block class="round p-30 mb-40">
            <a-row :gutter="[15,15]" class="publication__info-data">
              <a-col :span="12" v-if="publication.type"><label>Тип публикации:</label> {{ publication.type }}</a-col>
              <a-col :span="12" v-if="publication.journal"><label>Название издания:</label> {{ publication.journal }}</a-col>
              <a-col :span="12" v-if="publication.language"><label>Язык оригинала:</label> {{ publication.language }}</a-col>
              <a-col :span="12" v-if="publication.volume || publication.journalNumber"><label>Том и номер выпуска:</label>&nbsp;
                <span v-if="publication.volume">{{ publication.volume }} </span>
                <span v-if="publication.journalNumber">{{ publication.journalNumber }}</span>
              </a-col>
              <a-col :span="12" v-if="publication.articleNumber"><label>Номер статьи:</label> {{ publication.articleNumber }}</a-col>
              <a-col :span="12" v-if="publication.status && publication.status.title"><label>Статус:</label>&nbsp;
                {{ publication.status.title }}, {{ publication.status.date }}
              </a-col>
            </a-row>
          </gray-block>

          <div class="publication__info-text mb-80" v-if="publication.annotation">
            <h3>Аннтотация</h3>
            <div v-html="publication.annotation" />
          </div>
        </div>
      </content-container>
    </white-block>

    <gray-block class="pt-80 pb-80">
      <content-container class="content-padding">
        <white-block class="p-30 round">
          <h3>Направления работы</h3>
          <p>
            Узнайте самые подробные результаты анализа активности
            <span v-html="publication.title" />. Указанные в этом разделе метки относятся к
            работам этих участников организации. Вместе они формируют уникальную
            картину его активности.
          </p>
          <div class="mb-40">
            <tags-percent-list type="big-gray" :tags="fingerprints" />
          </div>
          <button-primary
              type="link"
              :href="`/portal/publication/` + publication.id + `/concepts`"
          >
            Полный список
          </button-primary>
        </white-block>
      </content-container>
    </gray-block>

    <white-block class="pt-80" v-if="persons.length > 0">
      <content-container class="content-padding">
        <h3>Авторы из числа сотрудников НГУ</h3>
        <a-row :gutter="[20, 20]" class="employees">
          <a-col
              :sm="24"
              :md="12"
              :lg="6"
              v-for="person in persons"
              :key="`person-` + person.id"
          >
            <person-card
                :id="person.id"
                :name="[person.lastName, person.firstName, person.middleName]"
                :avatar="person.photo"
                :employments="person?.employments"
            />
          </a-col>
        </a-row>
      </content-container>
    </white-block>
  </div>
</template>

<script setup>
import {defineProps, onMounted, ref, watch} from 'vue';
import {useRoute} from "vue-router";
import {useApi} from "@/api/use-api";
import WhiteBlock from "@/views/portal/components/WhiteBlock.vue";
import ContentContainer from "@/views/portal/components/ContentContainer.vue";
import BorderedBlock from "@/views/portal/components/BorderedBlock.vue";
import MenuPublication from "@/views/portal/components/menus/MenuPublication.vue";
import ButtonPrimary from "@/views/portal/components/UI/ButtonPrimary.vue";
import GrayBlock from "@/views/portal/components/GrayBlock.vue";
import TagsPercentList from "@/views/portal/components/tags/TagsPercentList.vue";
import PersonCard from "@/views/portal/components/cards/PersonCard.vue";

const api = useApi();
const route = useRoute();
const persons = ref([]);
const fingerprints = ref([]);

const props = defineProps({
  publication: {
    type: Object,
    required: true,
  },
});

const getFingerprints = async () => {
  if (undefined === props.publication.uuid || !props.publication.uuid) return;
  try {
    const response = await api.fingerprint.index({
      page: 1,
      'per-page': 8,
      sort: '-weighted_rank',
      content_family: 2,
      content_uuid: props.publication.uuid,
    });
    response.fingerprints.map((fp) => {
      fp.description = fp.thesauriName;
      fp.tooltip = fp.weightedRank;
    });
    fingerprints.value = response.fingerprints;
  } catch (error) {
    console.error('error', error);
  }
};

const getPersons = async () => {
  if (undefined === props.publication.id || !props.publication.id) return;
  try {
    const response = await api.person.index(
        new URLSearchParams({
          isEmployee: true,
          page: 1,
          'per-page': 1000,
          publicationId: props.publication.id,
          //sort: "",
        })
    );
    persons.value = response.persons;
  } catch (error) {
    console.error('error', error);
  }
};

onMounted(() => {
  getPersons();
  getFingerprints();
});

watch(
    () => props.publication.id,
    () => {
      getPersons();
      getFingerprints();
    }
);
</script>

<style lang="scss" scoped>
.publication-tab-about {
  .publication__info-data {
    color: #101426;
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.42857;

    label {
      font-weight: 700;
    }
  }

  .publication__info-text {
    color: #101426;
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.5;
  }
}
</style>