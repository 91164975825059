<template>
  <a-badge
      :count="countFilter"
      :number-style="{ backgroundColor: '#72c81e', color: '#fff' }"
  >
    <button-primary @click="openFilter = true">
      Фильтр
    </button-primary>
  </a-badge>
  <right-panel
      title="Фильтр"
      v-model:open="openFilter"
      @close="openFilter = false"
  >
    <a-form @submit.prevent method="get">
      <right-panel-title>Подразделение</right-panel-title>
      <a-form-item>
        <a-select
            :value="departments.current"
            :filter-option="false"
            show-search
            placeholder="Поиск подразделения"
            :not-found-content="departments.fetching ? undefined : null"
            :options="departments.data"
            @search="fetchDepartments"
            @change="setFilterDepartment"
        />
      </a-form-item>
      <right-panel-title>Период работы</right-panel-title>
      <a-form-item>
        <a-select
            v-model:value="filterPeriod"
            :options="periods"
        />
      </a-form-item>
      <right-panel-title>Концепции</right-panel-title>
      <a-form-item>
        <input-tags
            placeholder="Введите концепции"
            :tags="filterTags"
            @update="setFilterTags"
        />
      </a-form-item>
      <right-panel-buttons>
        <button-default @click="resetFilter">
          Очистить
        </button-default>
        <button-primary @click="applyFilter">
          Применить
        </button-primary>
      </right-panel-buttons>
    </a-form>
  </right-panel>
</template>

<script setup>
import {computed, onMounted, reactive, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useApi} from "@/api/use-api";
import {debounce} from "lodash-es";
import ButtonPrimary from "@/views/portal/components/UI/ButtonPrimary.vue";
import RightPanel from "@/views/portal/components/panels/RightPanel.vue";
import RightPanelTitle from "@/views/portal/components/panels/RightPanelTitle.vue";
import InputTags from "@/views/portal/components/UI/InputTags.vue";
import RightPanelButtons from "@/views/portal/components/panels/RightPanelButtons.vue";
import ButtonDefault from "@/views/portal/components/UI/ButtonDefault.vue";

const api = useApi();
const route = useRoute();
const router = useRouter();

const openFilter = ref(false);

const departments = reactive({
  current: '',
  data: [],
  fetching: false,
});
const periods = [
  {value: '5', label: '5 лет '},
  {value: '10', label: '10 лет'},
];
const filterTags = ref([]);
const filterDepartment = ref();
const filterPeriod = ref();

const countFilter = computed(() => {
  // const typesCount = route.query.type
  //     ? ((typeof route.query.type === 'string') ? 1 : route.query.type.length)
  //     : 0;
  const tagsCount = route.query.tags
      ? ((typeof route.query.tags === 'string') ? 1 : route.query.tags.length)
      : 0;
  // return typesCount + tagsCount;
  return (filterDepartment.value ? 1 : 0) +
      (filterPeriod.value ? 1 : 0) +
      tagsCount;
});

let lastFetchValue = '';
const fetchDepartments = debounce(async (value) => {
  if (value.length < 3) return;
  lastFetchValue = value;
  const fetch = lastFetchValue;
  // departments.data = [];
  departments.fetching = true;

  const response = await api.organisationalUnit.index(
      new URLSearchParams({
        name: value,
        page: '1',
        'per-page': '10',
        sort: 'name',
      })
  );

  if (fetch !== lastFetchValue) return;

  departments.data = response.organisationalUnits.map((el) => ({
    label: el.name,
    value: el.uuid,
  }));
  departments.fetching = false;
}, 300);

const setFilterDepartment = (value) => {
  departments.data.forEach((el) => {
    if (el.value === value) {
      departments.current = el.label;
    }
  });
  filterDepartment.value = value;
}

const resetFilter = () => {
  // TODO: clear filters
  applyFilter();
};

const applyFilter = () => {
  openFilter.value = false;

  // let types = [];
  // let tags = [];
  // filterTypes.value
  //     .filter((el) => el.checked === true)
  //     .forEach((el) => types.push(el.value));

  router.push({
    name: 'portal-persons-page',
    params: {},
    query: {
      ...route.query,
      // type: types,
      // tags: tags,
      page: '1'
    }
  });
};

const setFilterTags = (newTags) => {
  filterTags.value = newTags;
};

const prepareUrl = async () => {
  //
}

onMounted(() => {
  prepareUrl();
});

watch(filterDepartment, () => {
  departments.fetching = false;
});
</script>

<style lang="scss" scoped>





</style>