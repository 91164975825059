import {useCreate, useDelete, useRead, useUpdate} from "@/api/axios/adapter";

/**
 * @param url API URL
 * @param data Sent data
 * @param headers HTTP headers
 * @param options Available options: https://axios-http.com/docs/req_config
 * @returns {Promise<EasyUseAxiosReturn<any, axios.AxiosResponse<any>, any>>}
 */
const create = async (url, data = {}, headers = {}, options = {}) => {
  const {execute} = useCreate(headers);
  options['data'] = data;
  const {response} = await execute(url, options);

  return response.value;
};

const read = async (url, options = {}) => {
  const {execute} = useRead();
  const {response} = await execute(url, options);

  return response.value;
};

const update = async (url, data = {}, headers = {}, options = {}) => {
  const {execute} = useUpdate(headers);
  options['data'] = data;
  const {response} = await execute(url, options);

  return response.value;
};

const remove = async (url, options = {}) => {
  const {execute} = useDelete();
  const {response} = await execute(url, options);

  return response.value;
};

export {create, read, update, remove};