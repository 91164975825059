<template>
  <div class='header-panel'>
    <div class='header-panel__breadcrumb'>
      <back-arrow :page-name='backPageName' v-if='backPageName'></back-arrow>
      <a-breadcrumb>
        <a-breadcrumb-item>
          <router-link :to='homeLink'>
            Главная
          </router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item>Мои проекты</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class='header-panel__search'>
      <a-col :span='24' class='form-step__fields'>
        <a-form-item name='role' class='header-panel__search-wrapper'>
          <a-select
            id='role'
            placeholder='Роль'
            v-model:value='currentRole'
            :options='roles'
            @change='onRoleChange'
          />
        </a-form-item>
      </a-col>
      <!--      <input-->
      <!--        type="text"-->
      <!--        placeholder="Ищите документы, проекты, людей или другое"-->
      <!--      />-->
      <!--      <img src="./assets/search.svg" alt="" />-->
    </div>
    <div class='header-panel__profile'>
      <DropdownMenu :name='name' />
      <div class='header-panel__profile-email'>{{ email }}</div>
    </div>
  </div>
</template>

<script setup>
  import { ref, onMounted } from 'vue';
  import { useApi } from '@/api/use-api';
  import DropdownMenu from './components/dropdown-menu/DropdownMenu';
  import BackArrow from '@/components/navigation/BackArrow.vue';
  import { useRoute, useRouter } from 'vue-router';
  import { getDefaultRouteByRole } from '@/router';

  const api = useApi();
  const email = ref('');
  const name = ref('');
  const homeLink = ref('');
  const roles = ref([]);
  const currentRole = ref(null);
  const route = useRoute();
  const router = useRouter();

  defineProps({
    backPageName: {
      type: String,
      default: '',
    },
  });

  onMounted(() => {
    getUser();
    getRoles();
  });

  async function getUser() {
    try {
      const response = await api.user.index();
      email.value = response.email;
      name.value = response.name;
    } catch (error) {
      console.error('error', error);
    }
  }

  async function getRoles() {
    try {
      const response = await api.user.roles();
      // TODO (DM) - add roles and profile to vuex
      roles.value = response.roles.filter((item) => item.value !== 'root');
      currentRole.value = route.meta.role ?? localStorage.getItem('currentRole');
    } catch (error) {
      console.error('error', error);
    }
  }

  async function onRoleChange() {
    localStorage.setItem('currentRole', currentRole.value);
    const defaultRoute = getDefaultRouteByRole(currentRole.value);
    homeLink.value = defaultRoute.path;
    await router.push(defaultRoute);
  }
</script>

<style lang='scss' scoped>
  @import "HeaderPartnerAccount.scss";
</style>
