import { read } from '@/api/rest';

const publication = {
  /**
   * @param options
   * @returns {Promise<*>}
   */
  index: async (options) => await read('/search?' + options.toString()),
};

export default publication;
