import {
    STATUS_COMPLETED,
    STATUS_COMPLETED_OVERDUE,
    STATUS_INCOMPLETE,
    STATUS_INCOMPLETE_OVERDUE
} from "@/api/models/Checkpoint";

export function useTaskCount(tasks, userId = null) {
    return {
        total: getCheckpoints(tasks, null, userId).length,
        [STATUS_INCOMPLETE]: getCheckpoints(tasks, STATUS_INCOMPLETE, userId).length,
        [STATUS_COMPLETED]: getCheckpoints(tasks, STATUS_COMPLETED, userId).length,
        [STATUS_INCOMPLETE_OVERDUE]: getCheckpoints(tasks, STATUS_INCOMPLETE_OVERDUE, userId).length,
        [STATUS_COMPLETED_OVERDUE]: getCheckpoints(tasks, STATUS_COMPLETED_OVERDUE, userId).length,
    }
}

/**
 * Returns all checkpoints for a task list.
 * The result can be filtered by status and userId
 **/
export function useCheckpoints(tasks, status = null, userId = null) {
    return getCheckpoints(tasks, status, userId);
}

const getCheckpoints = (tasks, status = null, userId = null) => {
    return tasks
        ? tasks.reduce((acc, task) => {
            if (userId === null || task.userId === userId) {
                const checkpoints = filterByStatus(task.checkpoints, status)
                return [...acc, ...checkpoints, ...getCheckpoints(task.subtasks, status, userId)]
            } else {
                return [...acc, ...getCheckpoints(task.subtasks, status, userId)]
            }
        }, [])
        : []
}

const filterByStatus = (checkpoints, status = null) => {
    return checkpoints.filter(
        (checkpoint) => null === status || status === checkpoint.status
    );
};

export function useTasksInWork(tasks) {
    return tasks.filter((task) => {
        return getCheckpoints([task])
            .some((checkpoint) =>
                [STATUS_INCOMPLETE, STATUS_INCOMPLETE_OVERDUE].includes(checkpoint.status))
    })
}

export function useTasksDone(tasks) {
    return tasks.filter((task) => {
        return getCheckpoints([task])
            .every((checkpoint) =>
                [STATUS_COMPLETED, STATUS_COMPLETED_OVERDUE].includes(checkpoint.status))
    })
}
