<template>
  <h3>Шаг 9. Согласование</h3>
  <p>
    Если Вас устраивает содержание проекта, пожалуйста, согласуйте его. Если у
    Вас есть какие-либо корректировки, отклоните проект и оставьте комментарий.
    Руководитель проекта увидит Ваши замечания, внесет изменения и отправит Вам
    проект для повторного согласования.
  </p>
  <div v-if="model.approval" class="project-agreement-grid">
    <agreement-row
      class="agreement-row"
      :status="model.approval.status"
      :reason="model.approval.rejectedReason"
      :action="() => handleApproval()"
      :reject="() => handleReject()"
      :action-button-text="'Согласовать'"
      :reject-button-text="'На доработку'"
      :loading="isLoading"
      :error="errors.getError('approval')"
    >
      <template #icon>
        <img src="@/assets/icons/icon-person.svg" alt="" />
      </template>

      <template #labelText>
        {{ model.approval.status?.actionLabel }}
      </template>
    </agreement-row>
  </div>
  <c-drawer v-model:open="isShowForm" destroyOnClose>
    <component
      :is="PartnerProjectRejectForm"
      :project-approval-id="model.approval?.id"
      @close="handleCloseForm"
      @update="updateOnReject"
    />
  </c-drawer>
</template>

<script setup>
  import { onMounted, reactive, ref, watch } from 'vue';
  import PartnerProjectRejectForm from '../PartnerProjectRejectForm.vue';
  import CDrawer from '@/components/UI/CDrawer.vue';
  import { useApi } from '@/api/use-api';
  import ErrorsHandler from '@/components/form/errors-handler';
  import AgreementRow from '@/components/projects/agreement/AgreementRow.vue';

  const api = useApi();
  const emit = defineEmits(['update']);

  const props = defineProps({
    project: {
      type: Object,
      require: true,
    },
  });

  const model = reactive({
    id: null,
    approval: null,
    rejectedReason: '',
  });
  const isShowForm = ref(false);
  const isLoading = ref(false);

  watch(
    () => props.project,
    () => {
      fillModel();
    }
  );

  onMounted(() => {
    props.project && fillModel();
  });

  const errors = new ErrorsHandler({
    approval: '',
  });

  const fillModel = () => {
    try {
      model.id = props.project.id;
      model.approval = props.project.approval;
      model.rejectedReason = props.project.approval?.rejectedReason;
    } catch (error) {
      console.error('error', error);
    }
  };

  const handleApproval = async () => {
    try {
      isLoading.value = true;
      errors.clearErrors();
      await api.partnerProject.approveProject({
        projectApprovalId: model.approval.id,
      });
      emit('update', model);
    } catch (error) {
      errors.handleApiErrors(error);
    } finally {
      isLoading.value = false;
    }
  };

  const handleReject = () => (isShowForm.value = true);
  const handleCloseForm = () => (isShowForm.value = false);

  const updateOnReject = () => emit('update', model);
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  p {
    width: 555px;
  }

  .project-approval {
    background: $color-bg-secondary-grey;
    border-radius: 7px;
    color: $color-link-secondary;
    min-height: 100px;
    padding: 20px;

    .actions-row {
      align-items: center;
      display: flex;

      .actions__icon {
        .icon__wrapper {
          width: 60px;
          height: 60px;
          flex: 0 0 60px;
          background: $color-bg-primary-white;
          border-radius: 3px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .actions__label {
        font: 600 16px/22px 'Nunito Sans';
        flex: 1 0 auto;
        padding: 10px 20px;
      }

      .actions__buttons {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        & > button {
          margin-left: 16px;

          &:first-child {
            margin-left: 0;
          }
        }

        &.reapproval {
          justify-content: flex-start;
          margin-top: 26px;
        }
      }

      .actions__info {
        color: $color-bg-status-rework;
        font: 700 14px/20px 'Nunito Sans';
        margin-top: 24px;

        .info__description {
          font-weight: 400;
        }
      }
    }

    &.wrapped {
      flex-wrap: wrap;
    }
  }
</style>
