<template>
  <a-col class="table">
    <a-row class="header">
      <a-col :span="3">Дата</a-col>
      <a-col :span="8">Название</a-col>
      <a-col :span="7">Тип проекта</a-col>
      <a-col :span="6">Руководитель проекта</a-col>
    </a-row>
  </a-col>
  <a-col>
    <showcase-item
      v-for="showcase in showcases"
      :key="showcase.id"
      :showcase="showcase"
      @click="handlerShowcaseClick(showcase)"
    />
  </a-col>
</template>

<script setup>
  import { useRouter } from 'vue-router';
  import ShowcaseItem from '@/views/administrator/showcases/components/ShowcaseItem.vue';

  defineProps({
    showcases: Array,
  });

  const router = useRouter();

  const handlerShowcaseClick = async (showcase) => {
    await router.push({
      path: '/administrator/project/' + showcase.projectId + '/showcase',
    });
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';
  $color-text-secondary-grey-216: #718096;

  .table {
    border-collapse: separate;
    border-spacing: 0 15px;
    padding-top: 30px;
    width: 100%;

    * {
      font-family: Nunito Sans, sans-serif;
    }

    th[class*='col'] {
      span {
        border-bottom: 1px solid #f2f4f8;
        display: block;
        padding-bottom: 10px;
        width: 100%;
      }
    }

    th:first-child {
      padding-left: 20px;
    }

    .header {
      border-bottom: 1px solid $color-bg-secondary-grey;
      color: $color-text-secondary-grey-216;
      font-variant-numeric: slashed-zero;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;

      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 10px;
      margin-bottom: 10px;
      text-align: left;
    }
  }
</style>
