<template>
  <div class="task-card">
    <a-row class="task-card__meta">
      <a-col class="task-card__name">
        {{ task.description }}
      </a-col>
      <a-col class="task-card__status">
        <task-due-to-date-label
          :completed-at="task.completedAt"
          :due-to-date="task.dueToDate"
          :date="task.date"
        />
      </a-col>
    </a-row>

    <a-row class="task-card__info">
      <div>
        Номер проекта: <span>{{ task.projectId }}</span>
      </div>
      <div>
        Проект: <span>{{ task.projectName }}</span>
      </div>
      <div>Руководитель проекта</div>
      <project-person
        :avatar="getAvatarUrl(task.leaderAvatarUid)"
        :name="task.leaderName"
        :email="task.leaderEmail"
      />
    </a-row>
  </div>
</template>

<script setup>
  import ProjectPerson from './ProjectPerson.vue';
  import TaskDueToDateLabel from '@/components/task-date-label/TaskDueToDateLabel.vue';

  defineProps({
    task: Object,
  });

  const getAvatarUrl = (avatar) =>
    avatar ? `/api/v1/attachment/${avatar}` : null;
</script>

<style lang="scss">
  @import '@/assets/styles/_colors.scss';

  .task-card {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    background: $color-bg-secondary-grey;
    padding: 16px 16px 20px 20px;

    &:hover {
      box-shadow: 0 6px 15px 0 rgba(1, 1, 1, 0.15);
    }

    .task-card__meta {
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-start;
      justify-content: space-between;

      .task-card__name {
        color: $color-text-primary;
        font-family: 'Nunito Sans', sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
      }

      .task-card__status {
        margin-left: 16px;
      }
    }

    .task-card__info {
      color: $color-bg-status-new;
      display: flex;
      flex-direction: column;
      font-family: 'Nunito Sans', sans-serif;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      margin-top: 20px;
      row-gap: 18px;

      span {
        font-weight: 400;
      }
    }
  }
</style>
