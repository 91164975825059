export default {
    members: [
        {
            type: 'array',
            required: true,
            message: 'Необходимо ввести данные как минимум об одном сотруднике.',
            trigger: 'blur',
            validator: (_rule, value,) => {
                if (value && value.length > 0 && value[0].fio) {
                    return Promise.resolve();
                } else {
                    return Promise.reject('Rule validation failed.')
                }
            }
        },
    ],
};
