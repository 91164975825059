<template>
  <a-row class="index__root">
    <a-row class="index__top">
      <a-col class="index__date">
        {{ dayjs(index?.createdAt).format('DD.MM.YYYY') }}
      </a-col>
      <a-col class="index__action-more">
        <a-dropdown placement="bottomRight" :arrow="false" :trigger="['click']">
          <img
            class="action-more__icon"
            src="@/assets/icons/icon-more.svg"
            alt="Дополнительные действия"
            @click.prevent
          />
          <template #overlay>
            <a-menu>
              <a-menu-item @click="handleRemoveIndex(index?.id)">
                <p>Удалить</p>
              </a-menu-item>
              <a-menu-item
                v-if="index?.status === 'active'"
                @click="handleArchiveIndex(index?.id)"
              >
                <p>Перенести в архив</p>
              </a-menu-item>
              <a-menu-item
                v-if="index?.status === 'archive'"
                @click="handleActiveIndex(index?.id)"
              >
                <p>Возобновить</p>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </a-col>
    </a-row>

    <a-row class="index__middle">
      <a-col class="index__name" :span="14">{{ index?.name }}</a-col>
      <a-col :span="10">
        <progress-bar
          :items="[
            {
              value: getBriefcaseCompleted(index.statistics),
              status: 'completed',
            },
            {
              value: getBriefcasePromised(index.statistics),
              status: 'promised',
            },
            { value: getBriefcaseOvered(index.statistics), status: 'overed' },
          ]"
          :total="index.statistics?.planned"
        />
      </a-col>
    </a-row>

    <a-row class="index__bottom">
      <a-col>
        <c-button-collapse
          defaultLabel="Подробнее"
          collapseLabel="Свернуть"
          @click="handleCollapse"
        />
      </a-col>
      <a-col :span="10">
        <a-row class="index__status-row">
          <a-col>
            <span class="status done"></span> Выполнено проектами:
            {{ index.statistics?.completed }}
          </a-col>
          <a-col>
            <span class="status promised"></span> Обещано проектами:
            {{ index.statistics?.promised }}
          </a-col>
          <a-col>
            <span class="status planned"></span> План портфеля:
            {{ index.statistics?.planned }}
          </a-col>
        </a-row>
      </a-col>
    </a-row>
    <div class="index__projects" v-if="index?.projects" v-show="!isCollapsed">
      <a-divider />

      <a-row
        class="index__project-root"
        v-for="(project, idx) in index.projects"
        :key="idx++"
      >
        <a-col class="index__date">Проект {{ idx++ }}</a-col>
        <a-col>
          <a-row justify="space-between">
            <a-col class="index__project-name" :span="14">
              {{ project?.name }}
            </a-col>
            <a-col class="index__project-progress" :span="10">
              <progress-bar
                :items="[
                  {
                    value: project?.completed,
                    status: 'completed',
                  },
                  {
                    value: project?.promised,
                    status: 'promised',
                  },
                  {
                    value: project?.overed,
                    status: 'overed',
                  },
                  {
                    value: project?.incomplete,
                    status: 'incomplete',
                  },
                ]"
                :total="project?.total"
                showStatistic
              />
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </div>
  </a-row>
</template>

<script setup>
  import { ref } from 'vue';
  import { useApi } from '@/api/use-api';
  import dayjs from 'dayjs';
  import CButtonCollapse from '@/components/UI/CButtonCollapse.vue';
  import ProgressBar from '@/components/projects/portfolio/components/ProgressBar.vue';

  defineProps({
    index: Object,
  });

  const api = useApi();
  const isCollapsed = ref(true);
  const emit = defineEmits(['change']);

  const handleActiveIndex = async (indexId) => {
    try {
      await api.briefcaseIndex.active(indexId);
      emit('change', true);
    } catch (e) {
      console.error(e);
    }
  };

  const handleArchiveIndex = async (indexId) => {
    try {
      await api.briefcaseIndex.archive(indexId);
      emit('change', true);
    } catch (e) {
      console.error(e);
    }
  };

  const handleRemoveIndex = async (indexId) => {
    try {
      await api.briefcaseIndex.remove(indexId);
      emit('change', true);
    } catch (e) {
      console.error(e);
    }
  };

  const handleCollapse = () => (isCollapsed.value = !isCollapsed.value);

  const getBriefcaseCompleted = (statistics) =>
    Math.min(statistics.completed, statistics.planned);
  const getBriefcasePromised = (statistics) => statistics.promised;
  const getBriefcaseOvered = (statistics) =>
    Math.max(statistics.completed - statistics.planned, 0);
</script>

<style scoped lang="scss">
  @import '@/assets/styles/_colors.scss';

  * {
    font-family: 'Nunito Sans', sans-serif;
  }

  .index__root {
    background-color: $color-bg-secondary-grey;
    border-radius: 7px;
    flex-direction: column;
    padding: 20px;
    row-gap: 12px;

    .index__top {
      align-items: center;
      justify-content: space-between;

      .index__date {
        color: $color-bg-status-new;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }

      .index__action-more {
        .action-more__icon {
          cursor: pointer;
        }
      }
    }

    .index__middle {
      align-items: center;
      justify-content: space-between;

      .index__name {
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
      }
    }

    .index__bottom {
      align-items: center;
      justify-content: space-between;

      .index__status-row {
        column-gap: 15px;

        .status {
          border-radius: 50%;
          box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.9);
          display: inline-block;
          height: 10px;
          width: 10px;

          &.done {
            background-color: green;
          }

          &.promised {
            background-color: grey;
          }

          &.planned {
            background-color: white;
          }
        }
      }
    }

    .index__projects {
      display: flex;
      flex-direction: column;
      row-gap: 10px;

      .index__project-root {
        background-color: $color-bg-primary-white;
        border-radius: 7px;
        flex-direction: column;
        row-gap: 12px;
        padding: 20px 28px 20px 20px;

        .index__project-name {
          font-size: 16px;
          font-weight: 700;
          line-height: 22px;
        }
      }

      .ant-divider {
        border-color: $color-bg-primary-white;
        margin-top: 0;
        margin-bottom: 10px;
      }
    }
  }
</style>
