/**
 * Адапетр для перобразования списка месенджеров в свойства профайла и обратно.
 */
export default function MessengersAdapter() {
  const getMessengers = (personModel) => {
    let messengers = [];
    let key = 0;
    if (personModel.whatsappId) {
        key++;
        messengers.push({key: key, name: 'whatsapp', value: personModel.whatsappId});
    }
    if (personModel.telegramId) {
        key++;
        messengers.push({key: key, name: 'telegram', value: personModel.telegramId});
    }
    if (personModel.vkId) {
      key++;
      messengers.push({key: key, name: 'vk', value: personModel.vkId});
    }
    return messengers;
  }

  const getMessengerId = (messengerName, messengers) => {
    const messenger = messengers.find((item) => item.name === messengerName);
    return messenger?.value;
  }

  const getMessengersModel = (messengers) => {
    return  messengers.reduce((obj, messenger) => (
        {...obj, [messenger.name]: messenger.value}
    ), {})
  }

  return {
    getMessengers,
    getMessengersModel,
    getMessengerId,
  }
}