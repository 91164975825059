<template>
  <a-form
    ref="formRef"
    class="main-form"
    layout="vertical"
    :model="model"
    :rules="rules"
    @validate="errors.onValidate"
    @finish="onFinish"
    :scrollToFirstError="{
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    }"
  >
    <a-row class="form-step">
      <a-col :span="24">
        <div class="form-step__title">
          <span v-if="!disabled">Шаг 1.</span> Информация о заказчике
        </div>
        <div class="form-step__instructions">
          Проверьте информацию и при необходимости скорректируйте ее.
        </div>
      </a-col>
      <a-col :span="24" class="form-step__fields">
        <a-row :gutter="[20, 14]">
          <a-col :span="12">
            <a-form-item name="organizationName">
              <a-select
                id="organizationName"
                :value="model.inn"
                :options="model.organizationOptions"
                :disabled="disabled || model.organizationOptions.length < 2"
                @change="onOrganizationChange"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item name="innNumber">
              <a-input
                id="innNumber"
                :defaultValue="model.inn"
                v-model:value="model.inn"
                placeholder="ИНН"
                disabled
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item name="lastName">
              <a-input
                id="lastName"
                :defaultValue="model.lastName"
                v-model:value="model.lastName"
                placeholder="Фамилия"
                disabled
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item name="firstName">
              <a-input
                id="firstName"
                :defaultValue="model.firstName"
                v-model:value="model.firstName"
                placeholder="Имя"
                disabled
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item name="middleName">
              <a-input
                id="middleName"
                :defaultValue="model.middleName"
                v-model:value="model.middleName"
                placeholder="Отчество"
                disabled
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item name="position">
              <a-input
                id="position"
                :defaultValue="model.position"
                v-model:value="model.position"
                placeholder="Должность"
                disabled
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item name="phone">
              <a-input
                id="phone"
                :defaultValue="model.phone"
                v-model:value="model.phone"
                placeholder="Контактный телефон"
                disabled
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item name="email">
              <a-input
                id="email"
                :defaultValue="model.email"
                v-model:value="model.email"
                placeholder="E-mail"
                disabled
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-col>
    </a-row>

    <a-divider />

    <!-- RESEARCH TYPE -->
    <a-row class="form-step" v-if="model.type === REQUEST_TYPE_RESEARCH">
      <a-col :span="24">
        <div class="form-step__title">
          <span v-if="!disabled">Шаг 2.</span> Содержание запроса
        </div>
        <div v-if="!disabled" class="form-step__instructions">
          Пожалуйста, максимально подробно опишите ваш запрос:<br />
          - какая стоит перед вами проблема?<br />
          - какой результат ожидаете получить?<br />
          - как планируете использовать результат?<br />
          - с какой научной тематикой он может быть связан?<br />
          - существуют ли какие-то специальные условия, которые нам необходимо
          учитывать?<br />
          - какие ключевые слова характеризуют ваш проект?
        </div>
      </a-col>
      <a-col :span="24" class="form-step__fields">
        <a-row :gutter="[0, 14]">
          <a-col :span="24">
            <a-form-item
              name="description"
              label="Описание запроса"
              :help="errors.getError('description')"
              :validate-status="errors.getStatus('description')"
            >
              <a-textarea
                id="description"
                v-model:value="model.description"
                placeholder="Описание запроса"
                show-count
                :maxlength="5000"
                :autoSize="{ minRows: 6, maxRows: 6 }"
                :disabled="disabled"
              />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item
              name="plannedDateRange"
              label="Предпочтительные сроки выполнения"
              :help="errors.getError('plannedDateRange')"
              :validate-status="errors.getStatus('plannedDateRange')"
            >
              <a-row :gutter="[20, 0]">
                <a-col :span="12">
                  <a-range-picker
                    v-if="!disabled"
                    v-model:value="model.plannedDateRange"
                    :disabled-date="disabledDate"
                    format="DD.MM.YYYY"
                    :locale="locale"
                    :placeholder="['Начало', 'Завершение']"
                  />
                  <a-input
                    v-else
                    v-model:value="implementationPeriod"
                    placeholder="Предпочтительные сроки выполнения"
                    disabled
                  />
                </a-col>
                <a-col :span="12">
                  <a-form-item name="isFast">
                    <c-checkbox
                      v-model:checked="model.isFast"
                      :disabled="disabled"
                    >
                      Как можно скорее
                    </c-checkbox>
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form-item>
          </a-col>
          <attachments-input
            :error="errors.getError('attachmentUids')"
            :init-attachments="model.attachments"
            :is-editable="!disabled"
            :accept="ATTACHMENT_COMMON_EXTENSIONS"
            @update="handleUpdateAttachments"
          />
        </a-row>
      </a-col>
    </a-row>

    <!-- EDUCATION TYPE -->
    <a-row class="form-step" v-if="model.type === REQUEST_TYPE_EDUCATION">
      <a-col :span="24">
        <div class="form-step__title">
          <span v-if="!disabled">Шаг 2.</span> Содержание запроса
        </div>
        <div v-if="!disabled" class="form-step__instructions">
          Пожалуйста, максимально подробно опишите ваш запрос:<br />
          - название программы и ее длительность.<br />
          - целевая аудитория программы.<br />
          - в случае если это существующая в НГУ программа, планируется ли
          заключение договора о целевом обучении?<br />
          - какой результат ожидаете получить?<br />
          - как планируете использовать результат?<br />
          - с какой тематикой он может быть связан?<br />
          - существуют ли какие-то специальные условия, которые нам необходимо
          учитывать?
        </div>
      </a-col>
      <a-col :span="24" class="form-step__fields">
        <a-row :gutter="[0, 14]">
          <a-col :span="24">
            <a-row :gutter="[20, 0]">
              <a-col :span="12">
                <a-form-item
                  name="programTypeId"
                  label="Выберите тип образовательной программы"
                >
                  <a-select
                    id="programType"
                    placeholder="Выберите тип образовательной программы"
                    :value="model.programTypeId"
                    :options="model.educationProgramOptions"
                    :disabled="disabled"
                    @change="onProgramChange"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item
                  name="signAgreement"
                  label="Договор о сетевом взимодействии"
                >
                  <a-select
                    id="signAgreement"
                    placeholder="Заключение договора о сетевом взаимодействии?"
                    :value="model.signAgreement"
                    :options="model.signAgreementOptions"
                    :disabled="disabled"
                    @change="onSignAgreementChange"
                  >
                    <a-select-option value="false">Нет</a-select-option>
                    <a-select-option value="true">Да</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
          </a-col>
          <a-col
            :span="24"
            v-if="model.programType === EDUCATION_PROGRAM_OTHER"
          >
            <a-form-item
              name="customProgram"
              :help="errors.getError('customProgram')"
              :validate-status="errors.getStatus('customProgram')"
            >
              <a-textarea
                id="customProgram"
                v-model:value="model.customProgram"
                placeholder="Описание программы"
                show-count
                :maxlength="5000"
                :autoSize="{ minRows: 6, maxRows: 6 }"
                :disabled="disabled"
              />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item
              name="description"
              label="Описание запроса"
              :help="errors.getError('description')"
              :validate-status="errors.getStatus('description')"
            >
              <a-textarea
                id="description"
                v-model:value="model.description"
                placeholder="Описание запроса"
                show-count
                :maxlength="5000"
                :autoSize="{ minRows: 6, maxRows: 6 }"
                :disabled="disabled"
              />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item
              name="plannedDateRange"
              label="Предпочтительные сроки выполнения"
              :help="errors.getError('plannedDateRange')"
              :validate-status="errors.getStatus('plannedDateRange')"
            >
              <a-row :gutter="[20, 0]">
                <a-col :span="12">
                  <a-range-picker
                    v-if="!disabled"
                    v-model:value="model.plannedDateRange"
                    :disabled-date="disabledDate"
                    format="DD.MM.YYYY"
                    :locale="locale"
                    :placeholder="['Начало', 'Завершение']"
                  />
                  <a-input
                    v-else
                    v-model:value="implementationPeriod"
                    placeholder="Предпочтительные сроки выполнения"
                    disabled
                  />
                </a-col>
                <a-col :span="12">
                  <a-form-item name="isFast">
                    <c-checkbox
                      v-model:checked="model.isFast"
                      :disabled="disabled"
                    >
                      Как можно скорее
                    </c-checkbox>
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item
              name="realizationDateRange"
              label="Предпочтительные сроки реализации"
              :help="errors.getError('realizationDateRange')"
              :validate-status="errors.getStatus('realizationDateRange')"
            >
              <a-row :gutter="[20, 0]">
                <a-col :span="12">
                  <a-range-picker
                    v-if="!disabled"
                    v-model:value="model.realizationDateRange"
                    :disabled-date="disabledDate"
                    format="DD.MM.YYYY"
                    :locale="locale"
                    :placeholder="['Начало', 'Завершение']"
                  />
                  <a-input
                    v-else
                    v-model:value="realizationPeriod"
                    placeholder="Предпочтительные сроки реализации"
                    disabled
                  />
                </a-col>
                <a-col :span="12" />
              </a-row>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item
              name="budgetDescription"
              label="Финансирование или ресурсное обеспечение реализации программы"
              :help="errors.getError('budgetDescription')"
              :validate-status="errors.getStatus('budgetDescription')"
            >
              <a-textarea
                id="budgetDescription"
                v-model:value="model.budgetDescription"
                placeholder="Наличие финансирования и/или ресурсного обеспечения реализации программы"
                show-count
                :maxlength="5000"
                :autoSize="{ minRows: 6, maxRows: 6 }"
                :disabled="disabled"
              />
            </a-form-item>
          </a-col>
          <attachments-input
            :error="errors.getError('attachmentUids')"
            :init-attachments="model.attachments"
            :is-editable="!disabled"
            :accept="ATTACHMENT_COMMON_EXTENSIONS"
            @update="handleUpdateAttachments"
          />
        </a-row>
      </a-col>
    </a-row>

    <!-- EXPERTISE TYPE -->
    <a-row class="form-step" v-if="model.type === REQUEST_TYPE_EXPERTISE">
      <a-col :span="24">
        <div class="form-step__title">
          <span v-if="!disabled">Шаг 2.</span> Содержание запроса
        </div>
        <div v-if="!disabled" class="form-step__instructions">
          Пожалуйста, максимально подробно опишите ваш запрос:<br />
          - для чего вам необходимо проведение экспертизы?<br />
          - с какой научной тематикой она может быть связана?<br />
          - существуют ли какие-то специальные условия, которые нам необходимо
          учитывать?<br />
          - какие ключевые слова характеризуют результат, предлагаемый к
          экспертизе?<br />
        </div>
      </a-col>
      <a-col :span="24" class="form-step__fields">
        <a-row :gutter="[0, 14]">
          <a-col :span="24">
            <a-form-item
              name="expertiseTypeId"
              label="Тип экспертизы"
              :help="errors.getError('expertiseTypeId')"
              :validate-status="errors.getStatus('expertiseTypeId')"
            >
              <a-select
                id="expertiseType"
                placeholder="Выберите тип экспертизы"
                :value="model.expertiseTypeId"
                :options="model.expertiseTypeOptions"
                :disabled="disabled"
                @change="onExpertiseTypeChange"
              />
            </a-form-item>
          </a-col>
          <a-col :span="24" v-if="model.expertiseType === EXPERTISE_TYPE_OTHER">
            <a-form-item
              name="customExpertise"
              label="Описание экспертизы"
              :help="errors.getError('customExpertise')"
              :validate-status="errors.getStatus('customExpertise')"
            >
              <a-textarea
                id="customExpertise"
                v-model:value="model.customExpertise"
                placeholder="Описание экспертизы"
                show-count
                :maxlength="5000"
                :autoSize="{ minRows: 6, maxRows: 6 }"
                :disabled="disabled"
              />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item
              name="description"
              label="Описание запроса"
              :help="errors.getError('description')"
              :validate-status="errors.getStatus('description')"
            >
              <a-textarea
                id="description"
                v-model:value="model.description"
                placeholder="Описание запроса"
                show-count
                :maxlength="5000"
                :autoSize="{ minRows: 6, maxRows: 6 }"
                :disabled="disabled"
              />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item
              name="sourceDescription"
              label="В рамках какого проекта или договора был получен результат?"
              :help="errors.getError('sourceDescription')"
              :validate-status="errors.getStatus('sourceDescription')"
            >
              <a-textarea
                id="sourceDescription"
                v-model:value="model.sourceDescription"
                placeholder="В рамках какого проекта или договора был получен результат?"
                show-count
                :maxlength="5000"
                :autoSize="{ minRows: 6, maxRows: 6 }"
                :disabled="disabled"
              />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item
              name="sourceLink"
              label="Информация о проекте"
              :help="errors.getError('sourceLink')"
              :validate-status="errors.getStatus('sourceLink')"
            >
              <a-input
                id="sourceLink"
                v-model:value="model.sourceLink"
                placeholder="Ссылка на информацию о проекте"
                :disabled="disabled"
              />
            </a-form-item>
          </a-col>
          <attachments-input
            :error="errors.getError('attachmentUids')"
            :init-attachments="model.attachments"
            :is-editable="!disabled"
            :accept="ATTACHMENT_COMMON_EXTENSIONS"
            @update="handleUpdateAttachments"
          />
        </a-row>
      </a-col>
    </a-row>

    <!-- PARTNERSHIP TYPE -->
    <a-row class="form-step" v-if="model.type === REQUEST_TYPE_PARTNERSHIP">
      <a-col :span="24">
        <div class="form-step__title">
          <span v-if="!disabled">Шаг 2.</span> Содержание запроса
        </div>
        <div v-if="!disabled" class="form-step__instructions">
          Пожалуйста, максимально подробно опишите ваш запрос:<br />
          - в какой сфере вы хотите сотрудничать?<br />
          - какие перед вами стоят задачи и вызовы?<br />
          - как мы можем быть вам полезны?<br />
          - как вы можете быть полезны нам?<br />
          - существуют ли какие-то специальные условия, которые нам необходимо
          учитывать?
        </div>
      </a-col>
      <a-col :span="24" class="form-step__fields">
        <a-row :gutter="[0, 14]">
          <a-col :span="24">
            <a-form-item
              name="description"
              label="Описание запроса"
              :help="errors.getError('description')"
              :validate-status="errors.getStatus('description')"
            >
              <a-textarea
                id="description"
                v-model:value="model.description"
                placeholder="Описание запроса"
                show-count
                :maxlength="5000"
                :autoSize="{ minRows: 6, maxRows: 6 }"
                :disabled="disabled"
              />
            </a-form-item>
          </a-col>

          <attachments-input
            :error="errors.getError('attachmentUids')"
            :init-attachments="model.attachments"
            :is-editable="!disabled"
            :accept="ATTACHMENT_COMMON_EXTENSIONS"
            @update="handleUpdateAttachments"
          />
        </a-row>
      </a-col>
    </a-row>

    <a-divider />

    <a-row class="form-step">
      <a-col :span="24">
        <div class="form-step__title">
          <span v-if="!disabled">Шаг 3.</span> Дополнительно
        </div>
        <div v-if="!disabled" class="form-step__instructions">
          Если вы понимаете, какое подразделение университета будет выполнять
          ваш проект, или у вас есть какие-то дополнительные пожелания,
          пожалуйста, укажите их ниже.
        </div>
        <div v-else class="form-step__instructions secondary">
          Пожелание партнера по подразделению университета, которое будет
          выполнять ваш проект, или у вас есть какие-то дополнительные
          пожелания, пожалуйста, укажите их ниже.
        </div>
      </a-col>
      <a-col :span="24" class="form-step__fields">
        <a-row :gutter="[0, 14]">
          <a-col :span="24">
            <a-form-item
              name="wishes"
              :help="errors.getError('wishes')"
              :validate-status="errors.getStatus('wishes')"
            >
              <a-input
                id="wishes"
                v-model:value="model.wishes"
                placeholder="Подразделение исполнителя"
                :disabled="disabled"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-col>
    </a-row>

    <a-row
      class="form-step"
      v-if="
        model.requestStatusId === REQUEST_STATUS_REVISION ||
        model.requestStatusId === REQUEST_STATUS_REJECTED
      "
    >
      <a-divider />
      <a-col :span="24">
        <div class="form-step__title">Решение модератора</div>
        <div v-if="!disabled" class="form-step__instructions">
          Прочитайте решение модератора и внесите изменения в запрос
        </div>
      </a-col>
      <a-col :span="24" class="form-step__fields">
        <a-form-item name="solution">
          <a-select
            id="solution"
            placeholder="Решение по запросу"
            v-model:value="model.requestStatusId"
            :options="solutionsList"
            disabled
          />
        </a-form-item>
      </a-col>
      <a-col :span="24" class="form-step__fields" v-if="model.comment !== ''">
        <a-textarea
          id="comment"
          v-model:value="model.comment"
          placeholder="Комментарий"
          disabled
        />
      </a-col>
    </a-row>

    <a-row v-if="!disabled" class="form__buttons" :gutter="[10, 0]">
      <a-col>
        <a-form-item>
          <c-button-secondary @click="onCancel"> Отменить </c-button-secondary>
        </a-form-item>
      </a-col>
      <a-col>
        <a-form-item>
          <c-button-primary
            class="submit-button"
            :loading="isLoading"
            html-type="submit"
          >
            Отправить
          </c-button-primary>
        </a-form-item>
      </a-col>
    </a-row>
  </a-form>
</template>

<script setup>
  import { computed, reactive, ref, watch } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useApi } from '@/api/use-api';
  import locale from 'ant-design-vue/es/date-picker/locale/ru_RU';
  import dayjs from 'dayjs';
  import AttachmentsInput from '@/components/attachments/AttachmentsInput.vue';
  import CCheckbox from '@/components/UI/CCheckbox';
  import CButtonPrimary from '@/components/UI/CButtonPrimary';
  import CButtonSecondary from '@/components/UI/CButtonSecondary';
  import ErrorsHandler from '@/components/form/errors-handler';
  import {
    REQUEST_STATUS_DRAFT,
    REQUEST_STATUS_REJECTED,
    REQUEST_STATUS_REVISION,
    solutionsList,
  } from '@/api/models/RequestStatus';
  import {
    REQUEST_TYPE_EDUCATION,
    REQUEST_TYPE_EXPERTISE,
    REQUEST_TYPE_PARTNERSHIP,
    REQUEST_TYPE_RESEARCH,
  } from '@/api/models/RequestType';
  import { EDUCATION_PROGRAM_OTHER } from '@/api/models/EducationProgram';
  import { EXPERTISE_TYPE_OTHER } from '@/api/models/ExpertiseType';
  import { ATTACHMENT_COMMON_EXTENSIONS } from '@/components/attachments/AttachmentExtentions';

  const api = useApi();
  const router = useRouter();
  const route = useRoute();
  const formRef = ref();
  const isLoading = ref(false);

  const props = defineProps({
    request: Object,
    forceDisabled: {
      type: Boolean,
      default: false,
    },
  });

  const model = reactive({
    // header
    requestStatusId: null,
    type: null,
    typeId: null,
    status: {
      type: '',
      text: '',
    },
    requestDate: '',
    // common
    customerId: null,
    organizationName: '',
    inn: '',
    lastName: '',
    firstName: '',
    middleName: '',
    position: '',
    email: '',
    phone: '',
    description: '',
    wishes: '',
    // research & education
    plannedDateRange: [null, null],
    isFast: false,
    // education
    programTypeId: null,
    programType: '',
    customProgram: '',
    signAgreement: false,
    realizationDateRange: [null, null],
    budgetDescription: '',
    educationProgramOptions: [],
    signAgreementOptions: [
      { value: true, label: 'Да' },
      { value: false, label: 'Нет' },
    ],
    // expertise
    expertiseTypeId: null,
    expertiseType: '',
    customExpertise: '',
    sourceDescription: '',
    sourceLink: '',
    expertiseTypeOptions: [],
    // negotiation
    comment: '',
    // common lists
    organizationOptions: [],
    attachments: [],
    attachmentUids: [],
  });

  const disabled = computed(() => {
    return (
      props.forceDisabled ||
      ![REQUEST_STATUS_DRAFT, REQUEST_STATUS_REVISION].includes(
        model.requestStatusId
      )
    );
  });

  const getDateText = (date) =>
    date ? date.locale('ru').format('DD.MM.YYYY') : '';
  const getDateToSave = (date) =>
    date ? dayjs(date).locale('ru').format('YYYY-MM-DD') : null;

  const implementationPeriod = computed(() => {
    return `${getDateText(model.plannedDateRange[0])} - ${getDateText(
      model.plannedDateRange[1]
    )}`;
  });
  const realizationPeriod = computed(() => {
    return `${getDateText(model.realizationDateRange[0])} - ${getDateText(
      model.realizationDateRange[1]
    )}`;
  });

  const rules = computed(() => {
    return {
      description: [
        {
          required: true,
          min: 2,
          max: 5000,
          message:
            'Поле должно содержать не менее 2, но не более 5000 символов',
          trigger: ['change', 'blur'],
        },
      ],
      plannedDateRange: [
        {
          required: [REQUEST_TYPE_RESEARCH, REQUEST_TYPE_EDUCATION].includes(
            model.type
          ),
          message: 'Необходимо выбрать дату начала и завершения работ',
          trigger: ['change', 'blur'],
          validator: (_rule, value) => {
            if (value && value.length === 2 && value[0] && value[1]) {
              return Promise.resolve();
            } else {
              return Promise.reject('Range is not set correctly.');
            }
          },
        },
      ],
      realizationDateRange: [
        {
          required: model.type === REQUEST_TYPE_EDUCATION,
          message:
            'Необходимо выбрать предполагаемые даты реализации программы',
          trigger: ['change', 'blur'],
          validator: (_rule, value) => {
            if (value && value.length === 2 && value[0] && value[1]) {
              return Promise.resolve();
            } else {
              return Promise.reject('Range is not set correctly.');
            }
          },
        },
      ],
      programTypeId: [
        {
          required: model.type === REQUEST_TYPE_EDUCATION,
          type: 'number',
          min: 1,
          message: 'Необходимо выбрать программу',
          trigger: ['change', 'blur'],
        },
      ],
      customProgram: [
        {
          required:
            model.type === REQUEST_TYPE_EDUCATION &&
            model.programType === EDUCATION_PROGRAM_OTHER,
          type: 'string',
          min: 2,
          max: 5000,
          message: 'Необходимо ввести описание программы, не менее 2 символов',
          trigger: ['change', 'blur'],
        },
      ],
      budgetDescription: [
        {
          required: model.type === REQUEST_TYPE_EDUCATION,
          type: 'string',
          min: 2,
          max: 5000,
          message:
            'Необходимо ввести описание источников финансирования программы, не менее 2 символов',
          trigger: ['change', 'blur'],
        },
      ],
      expertiseTypeId: [
        {
          required: model.type === REQUEST_TYPE_EXPERTISE,
          type: 'number',
          min: 1,
          message: 'Необходимо выбрать тип экспертизы',
          trigger: ['change', 'blur'],
        },
      ],
      customExpertise: [
        {
          required:
            model.type === REQUEST_TYPE_EXPERTISE &&
            model.expertiseType === EXPERTISE_TYPE_OTHER,
          type: 'string',
          min: 2,
          max: 5000,
          message: 'Необходимо ввести описание экспертизы, не менее 2 символов',
          trigger: ['change', 'blur'],
        },
      ],
      sourceDescription: [
        {
          required: model.type === REQUEST_TYPE_EXPERTISE,
          type: 'string',
          min: 2,
          max: 5000,
          message:
            'Введите информацию о проекте или договоре в рамках которого получены данные, не менее 2 символов',
          trigger: ['change', 'blur'],
        },
      ],
    };
  });

  const errors = new ErrorsHandler(
    {
      description: '',

      plannedDateRange: '',
      isFast: '',
      attachmentUids: '',
      wishes: '',

      programTypeId: '',
      customProgram: '',
      realizationDateRange: '',
      budgetDescription: '',

      expertiseTypeId: '',
      customExpertise: '',
      sourceDescription: '',
      sourceLink: '',
    },
    (fieldName) => {
      if ('plannedEndDate' === fieldName) {
        return 'plannedDateRange';
      }
      if ('plannedStartDate' === fieldName) {
        return 'plannedDateRange';
      }
      if ('realizationEndDate' === fieldName) {
        return 'realizationDateRange';
      }
      if ('realizationStartDate' === fieldName) {
        return 'realizationDateRange';
      }
      return fieldName;
    }
  );

  watch(
    () => props.request,
    () => {
      fillModel();
    }
  );

  async function fillModel() {
    try {
      model.type = props.request.type;
      model.typeId = props.request.typeId;
      model.customerId = props.request.customerId;
      model.requestStatusId = props.request.requestStatusId;
      model.type = props.request.type;
      model.inn = props.request.customerInn;
      model.lastName = props.request.lastName;
      model.firstName = props.request.firstName;
      model.middleName = props.request.middleName;
      model.position = props.request.position;
      model.email = props.request.email;
      model.phone = props.request.phone;
      model.description = props.request.description ?? '';
      model.wishes = props.request.wishes;
      model.comment = props.request.comment;
      model.attachments = props.request.attachments;
      model.organizationOptions = getOrgOptions(
        props.request.personOrganizations
      );
      await fillResearchModel();
      await fillEducationModel();
      await fillExpertiseModel();
    } catch (error) {
      console.error('error', error);
    }
  }

  function getOrgOptions(organizations) {
    return organizations.map((org) => ({
      value: org.inn,
      label: org.name,
      inn: org.inn,
      position: org.position,
      customerId: org.id,
    }));
  }

  async function fillResearchModel() {
    model.type === REQUEST_TYPE_RESEARCH && fillPlannedDateRange();
  }

  async function fillEducationModel() {
    if (model.type === REQUEST_TYPE_EDUCATION) {
      fillPlannedDateRange();
      model.realizationDateRange = [
        props.request?.realizationStartDate
          ? dayjs(props.request.realizationStartDate, 'YYYY-MM-DD')
          : null,
        props.request?.realizationEndDate
          ? dayjs(props.request.realizationEndDate, 'YYYY-MM-DD')
          : null,
      ];
      model.customProgram = props.request.customProgram;
      model.budgetDescription = props.request.budgetDescription;
      model.programTypeId = props.request.programTypeId;
      model.programType = props.request.programType;
      model.signAgreement = props.request.signAgreement;
      await getEducationPrograms();
    }
  }

  async function fillExpertiseModel() {
    if (model.type === REQUEST_TYPE_EXPERTISE) {
      model.expertiseTypeId = props.request.expertiseTypeId;
      model.expertiseType = props.request.expertiseType;
      model.customExpertise = props.request.customExpertise;
      model.sourceDescription = props.request.sourceDescription;
      model.sourceLink = props.request.sourceLink;
      model.signAgreement = props.request.signAgreement;
      await getExpertiseTypes();
    }
  }

  function fillPlannedDateRange() {
    model.plannedDateRange = [
      props.request?.plannedStartDate
        ? dayjs(props.request.plannedStartDate, 'YYYY-MM-DD')
        : dayjs(),
      props.request?.plannedEndDate
        ? dayjs(props.request.plannedEndDate, 'YYYY-MM-DD')
        : null,
    ];
    model.isFast = props.request?.isFast ?? false;
  }

  async function getEducationPrograms() {
    try {
      const response = await api.educationProgram.index();
      model.educationProgramOptions = response.map((item) => ({
        value: item.id,
        label: item.description,
        type: item.type,
      }));
    } catch (reason) {
      console.log('RequestMainForm.getEducationPrograms: error=', reason);
    }
  }

  async function getExpertiseTypes() {
    try {
      const response = await api.expertiseType.index();
      model.expertiseTypeOptions = response.map((item) => ({
        value: item.id,
        label: item.description,
        type: item.type,
      }));
    } catch (reason) {
      console.log('RequestMainForm.getExpertiseTypes: error=', reason);
    }
  }

  const onCancel = () => {
    router.replace({ name: 'main' });
  };

  async function onFinish() {
    let data = {
      requestId: route.name === 'new-partner-request' ? null : route.params.id,
      type: model.type,
      typeId: model.typeId,
      customerId: model.customerId,
      description: model.description,
      wishes: model.wishes,
      attachmentUids: model.attachmentUids,
    };

    if (model.type === REQUEST_TYPE_RESEARCH) {
      const researchData = {
        plannedStartDate: getDateToSave(model.plannedDateRange[0]),
        plannedEndDate: getDateToSave(model.plannedDateRange[1]),
        isFast: model.isFast,
      };
      data = { ...data, ...researchData };
    }

    if (model.type === REQUEST_TYPE_EDUCATION) {
      const educationData = {
        programTypeId: model.programTypeId,
        customProgram: model.customProgram,
        signAgreement: model.signAgreement,
        budgetDescription: model.budgetDescription,
        plannedStartDate: getDateToSave(model.plannedDateRange[0]),
        plannedEndDate: getDateToSave(model.plannedDateRange[1]),
        isFast: model.isFast,
        realizationStartDate: getDateToSave(model.realizationDateRange[0]),
        realizationEndDate: getDateToSave(model.realizationDateRange[1]),
      };
      data = { ...data, ...educationData };
    }

    if (model.type === REQUEST_TYPE_EXPERTISE) {
      const expertiseData = {
        expertiseTypeId: model.expertiseTypeId,
        customExpertise: model.customExpertise,
        sourceDescription: model.sourceDescription,
        sourceLink: model.sourceLink,
      };
      data = { ...data, ...expertiseData };
    }

    try {
      isLoading.value = true;
      route.name === 'new-partner-request'
        ? await api.requestPage.createRequest(data)
        : await api.requestPage.updateRequest(route.params.id, data);

      await router.push({ name: 'main' });
    } catch (error) {
      errors.handleApiErrors(error);
    } finally {
      isLoading.value = false;
    }
  }

  function handleUpdateAttachments(uidList) {
    model.attachmentUids = uidList;
  }

  const onOrganizationChange = (inn) => {
    const personOrganization = model.organizationOptions.find(
      (item) => item.inn === inn
    );
    if (personOrganization) {
      model.customerId = personOrganization.customerId;
      model.inn = personOrganization.inn;
      model.position = personOrganization.position;
    }
  };

  const onProgramChange = (programId) => {
    const program = model.educationProgramOptions.find(
      (item) => item.value === programId
    );
    if (program) {
      model.programTypeId = programId;
      model.programType = program.type;
    }
  };

  const onSignAgreementChange = (value) => {
    model.signAgreement = value;
  };

  const onExpertiseTypeChange = (value) => {
    model.expertiseTypeId = value;
    const expertise = model.expertiseTypeOptions.find(
      (item) => item.value === value
    );
    if (expertise) {
      model.expertiseTypeId = value;
      model.expertiseType = expertise.type;
    }
  };

  const disabledDate = (current) => {
    return current && current < dayjs().startOf('day');
  };
</script>

<style lang="scss" scoped>
  @import '@/components/form/main-form';
</style>
