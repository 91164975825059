<template>
  <div class="switch-view-buttons">
    <icon-button
      path="#cards"
      :icon="cardsIcon"
      :active="typeView === 'cards'"
    />
    <icon-button
      path="#table"
      :icon="tableIcon"
      :active="typeView === 'table'"
    />
  </div>
</template>

<script setup>
  import { computed, ref, watch } from 'vue';
  import { useRoute } from 'vue-router';
  import IconButton from '@/components/icon-button/IconButton.vue';
  import CardsIcon from './assets/cards.svg';
  import TableIcon from './assets/table.svg';

  const route = useRoute();
  const typeView = ref('cards');
  const cardsIcon = computed(() => {
    return CardsIcon;
  });

  const tableIcon = computed(() => {
    return TableIcon;
  });

  watch(route, () => {
    let hash = route.hash.replace('#', '');
    typeView.value = hash ? hash : 'cards';
  });
</script>

<style lang="scss" scoped>
  .switch-view-buttons {
    display: flex;
    gap: 10px;
  }
</style>
