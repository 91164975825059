<template>
  <a-row class="reject__form" justify="end">
    <a-col class="form__title" :span="24">
      <h3>Вернуть проект в работу?</h3>
    </a-col>
    <a-col class="form__description" :span="24">
      <p>
        Если Вы не согласы с закрытием проекта, укажите подробно причину в поле
        ниже.
      </p>
    </a-col>
    <a-col class="form__fields" :span="24">
      <a-form
        :model="model"
        :rules="rules"
        @validate="errors.onValidate"
        @finish="onFinish"
      >
        <a-row>
          <a-col :span="24">
            <a-form-item
              name="rejectedReason"
              :help="errors.getError('rejectedReason')"
              :validate-status="errors.getStatus('rejectedReason')"
            >
              <a-textarea
                id="rejectedReason"
                v-model:value="model.rejectedReason"
                placeholder="Укажите корректировки здесь"
              />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-row justify="end">
              <a-form-item class="form__button">
                <c-button-danger :loading="isLoading" html-type="submit">
                  Вернуть в работу
                </c-button-danger>
              </a-form-item>
            </a-row>
          </a-col>
        </a-row>
      </a-form>
    </a-col>
  </a-row>
</template>

<script setup>
  import { reactive, ref } from 'vue';
  import { useApi } from '@/api/use-api';
  import CButtonDanger from '@/components/UI/CButtonDanger';
  import { useRoute, useRouter } from 'vue-router';
  import ErrorsHandler from '@/components/form/errors-handler';
  import { TEXT_LENGTH_ERROR } from '@/constants/error';

  const route = useRoute();
  const router = useRouter();
  const api = useApi();
  const emit = defineEmits(['close', 'update']);
  const isLoading = ref(false);

  const props = defineProps({
    projectApprovalId: {
      type: Number,
      required: true,
    },
  });

  const model = reactive({
    rejectedReason: '',
  });

  const rules = {
    rejectedReason: [
      {
        type: 'string',
        required: true,
        min: 2,
        max: 5000,
        message: TEXT_LENGTH_ERROR,
        trigger: 'change',
      },
    ],
  };

  const errors = new ErrorsHandler(
    {
      rejectedReason: '',
    },
    (fieldName) => {
      if ('administratorApproval' === fieldName) {
        return 'rejectedReason';
      }
      return fieldName;
    }
  );

  const onFinish = async () => {
    try {
      isLoading.value = true;
      await api.administratorPage.rejectProject({
        projectApprovalId: props.projectApprovalId,
        rejectedReason: model.rejectedReason,
      });
      emit('update');
      emit('close');
      await router.push({
        path: '/administrator/project/' + route.params.id + '/management',
      });
    } catch (error) {
      console.log(error);
      errors.handleApiErrors(error);
    } finally {
      isLoading.value = false;
    }
  };
</script>

<style lang="scss" scoped>
  $color-bg-button-delete: #f65160;
  $color-text-button-delete: #ffffff;

  .reject__form {
    padding: 112px 17px 0 17px;

    .form__title {
      h3 {
        font: 700 18px/26px 'Nunito Sans';
      }
    }

    .form__description {
      font: 400 14px/20px 'Nunito Sans';
      margin-top: 20px;
      margin-bottom: 0;
    }

    .form__content {
      margin-top: 30px;
    }

    .form__fields {
      margin-top: 40px;

      textarea {
        height: 190px;
        resize: none;
      }

      .ant-form-item {
        margin: 0;
      }

      .form__button {
        button {
          margin-top: 50px;
        }
      }
    }
  }
</style>
