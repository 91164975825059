<template>
  <div class="chart-item">
    <div class="chart-item__value">{{ value }}</div>
    <div
      class="chart-item__bar"
      :style="barStyle(value, maxValue, color)"
    ></div>
    <div class="chart-item__label" v-show="label" v-html="label"></div>
  </div>
</template>

<script setup>
  import { numberValue } from './charts';

  defineProps({
    label: {
      type: [String, Number],
      default: '',
    },
    value: {
      type: [String, Number],
      default: '0',
    },
    maxValue: {
      type: Number,
      default: 100,
    },
    color: {
      type: String,
      default: '#383D4F',
    },
  });

  let barStyle = (value, maxValue, color) => {
    const p = maxValue === 0 ? 0 : (100 * numberValue(value)) / maxValue;
    let style = '';
    style += 'background-color: ' + color + ';';
    style += 'height: ' + p + '%;';
    style += 'flex: 0 0 ' + p + '%;';
    return style;
  };
</script>

<style lang="scss" scoped>
  .chart-item {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-end;
    flex: 1 1 100%;
    height: 100%;
    margin-bottom: -30px;

    .chart-item__line {
      background: #1c1c1c0d;
    }

    .chart-item__label {
      color: #718096;
      text-align: center;
      font-family: 'Nunito Sans', sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5;

      height: 30px;
      flex: 0 0 30px;
      border-top: 1px solid #1c1c1c33;

      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      overflow: hidden;
      padding-top: 10px;
    }

    .chart-item__bar {
      width: 20px;
      height: 100px;
      border-radius: 4px 4px 0 0;
      background: #383d4f;
    }

    .chart-item__value {
      color: #101426;
      text-align: center;
      font-family: 'Nunito Sans', sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 1;
      margin-bottom: 13px;
    }
  }
</style>
