<template>
  <a-col class="request-form">
    <request-info :request="model.request" />

    <a-row
      class="request-form__inqueue"
      :span="24"
      v-if="
        (model.status?.type === 'new' || model.status?.type === 'in_queue') &&
        !model.isAvailable
      "
    >
      <a-col :span="24" class="request-form__inqueue-title">
        <p>Этот проект в работе у другого администратора.</p>
      </a-col>
      <a-col :span="24">
        <p class="request-form__inqueue-info" v-if="!model.isAvailable">
          Запрос снова будет доступен в во вкладке «Запросы», если все
          предыдущие (по очереди) администраторы откажутся от проекта (или
          выйдет их срок на работу с запросом). Хотите продолжить отслеживать
          этот проект?
        </p>
        <p class="request-form__inqueue-info" v-else>
          Вы решили отслеживать этот проект. Запрос снова будет доступен в во
          вкладке «Запросы», если все предыдущие (по очереди) администраторы
          откажутся от проекта (или выйдет их срок на работу с запросом). Хотите
          продолжить отслеживать этот проект?
        </p>
      </a-col>
      <a-col :span="24" class="request-form__inqueue-buttons">
        <a-row :gutter="[10, 0]">
          <a-col>
            <c-button-danger @click="handleShowForm('rejectRequest')"
              >Отказаться</c-button-danger
            >
          </a-col>
          <a-col v-if="!model.inQueue">
            <c-button-primary @click="handleFollowRequest">
              Отслеживать
            </c-button-primary>
          </a-col>
        </a-row>
      </a-col>
    </a-row>

    <request-main-form :request="model.request" />

    <a-row
      class="form__buttons"
      :gutter="[10, 0]"
      v-if="model.status?.type === 'new' && model.isAvailable"
    >
      <a-col>
        <c-button-danger @click="handleShowForm('rejectRequest')"
          >Отказаться</c-button-danger
        >
      </a-col>
      <a-col>
        <c-button-primary @click="handleGetRequestToWork">
          Хочу взять этот проект
        </c-button-primary>
      </a-col>
    </a-row>

    <a-row
      class="form__buttons"
      :gutter="[10, 0]"
      v-if="model.status?.type === 'in_work' && model.isAvailable"
    >
      <a-col>
        <c-button-danger @click="handleShowForm('rejectRequest')"
          >Отказаться</c-button-danger
        >
      </a-col>
      <a-col>
        <c-button-primary @click="handleShowForm('addProjectManager')">
          Назначить руководителя проекта
        </c-button-primary>
      </a-col>
    </a-row>
  </a-col>

  <c-drawer v-model:open="showForm" destroyOnClose>
    <component
      :is="form"
      v-bind:item="formItem"
      @close="() => (showForm = !showForm)"
      @update="updateProfile"
    />
  </c-drawer>
</template>

<script setup>
  import { onMounted, reactive, ref, shallowRef } from 'vue';
  import { useRoute } from 'vue-router';
  import { useApi } from '@/api/use-api';
  import CButtonPrimary from '@/components/UI/CButtonPrimary.vue';
  import CButtonDanger from '@/components/UI/CButtonDanger.vue';
  import ProjectManagerForm from './ProjectManagerForm.vue';
  import RejectRequestForm from './RejectRequestForm.vue';
  import CDrawer from '@/components/UI/CDrawer.vue';
  import RequestInfo from '@/components/requests/components/RequestInfo.vue';
  import RequestMainForm from '@/components/requests/RequestMainForm.vue';

  const api = useApi();
  const route = useRoute();
  const form = shallowRef(null);
  const formItem = ref(null);
  const showForm = ref(false);

  const model = reactive({
    request: null,
    status: null,
    isAvailable: false,
    inQueue: false,
  });

  onMounted(() => {
    getRequest();
  });

  async function getRequest() {
    try {
      const response = await api.requestPage.administratorView(route.params.id);
      model.request = response.request;
      model.status = response.request.status;
      model.isAvailable = response.request.isAvailable;
      model.inQueue = response.request.inQueue;
    } catch (error) {
      console.error('error', error);
    }
  }

  const handleGetRequestToWork = async () => {
    try {
      await api.administratorPage.saveRequest({
        id: route.params.id,
        status: 6,
      });

      await getRequest();
    } catch (error) {
      console.log(error);
    }
  };

  const handleFollowRequest = async () => {
    try {
      await api.administratorPage.enterQueue({
        id: route.params.id,
      });

      await getRequest();
    } catch (error) {
      console.log(error);
    }
  };

  const handleShowForm = (formType) => {
    switch (formType) {
      case 'addProjectManager':
        form.value = ProjectManagerForm;
        break;

      case 'rejectRequest':
        form.value = RejectRequestForm;
        break;
    }

    showForm.value = true;
  };
</script>

<style lang="scss" scoped>
  @import 'administrator-request-form';

  * {
    font-family: 'Nunito Sans', sans-serif;
    margin: 0;
  }

  .request-form {
    align-content: flex-start;
    padding-bottom: 100px;

    .administrator-request-form__request-date {
      color: $color-text-secondary-grey;
      font-size: 16px;
      font-weight: 700;
      line-height: 26px;
      margin-top: 10px;
    }

    .request-form__inqueue {
      background-color: $color-bg-secondary-grey;
      margin-top: 30px;
      margin-right: 270px;
      min-height: 207px;
      padding: 20px;

      .request-form__inqueue-title {
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
      }

      .request-form__inqueue-info {
        color: #718096;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-top: 20px;
        width: 785px;
      }

      .request-form__inqueue-buttons {
        margin-top: 50px;
      }
    }

    .request-form__form-block {
      margin-top: 40px;
    }

    .form__buttons {
      justify-content: end;
      margin-top: 40px;
      margin-right: 270px !important;
    }

    .ant-divider {
      margin: 30px 0;
    }
  }
</style>
