<template>
  <a-dropdown class="profile-dropdown" :trigger="['click']">
    <a class="profile-dropdown__username">
      <span>{{ name }}</span>
      <img src="../../assets/arrow.svg" alt="" />
    </a>
    <template #overlay>
      <a-menu class="profile-dropdown__menu">
        <a-menu-item class="profile-dropdown__menu-item">
          <router-link :to="{ name: 'profile' }">
            <img src="../../assets/bx-user-pin.svg" alt="" />
            <span>Профиль</span>
          </router-link>
        </a-menu-item>
        <a-menu-item class="profile-dropdown__menu-item">
          <a @click="logout">
            <img src="../../assets/bx-arrow-to-right.svg" alt="" />
            <span>Выйти</span>
          </a>
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
</template>

<script setup>
import { useRouter } from "vue-router";
import { useApi } from "@/api/use-api";
import { signOut } from "@/api/authentication/token-provider";
import { useStore } from "vuex";

defineProps({ name: String });

const router = useRouter();
const api = useApi();
const store = useStore();

async function logout() {
  localStorage.removeItem("currentRole");
  store.commit("setCurrentRole", "");
  await signOut(async () => await api.signOut.signOut());
  await router.push({ name: "home" });
}
</script>

<style lang="scss" scoped>
@import "DropdownMenu.scss";
</style>
