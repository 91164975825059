<template>
  <a-spin
    :spinning="isLoading"
    class="custom-spinner"
    style="height: 100vh; top: 20%"
  >
    <administrator-project-layout :model="model">
      <template #underInfo>
        <div
          class="administrator-project__common-progress"
          v-if="checkpoints.length"
        >
          <progress-bar :items="checkpoints" showPercent />
        </div>
      </template>
      <a-form
        v-if="model.approvals"
        class="form administrator-agreement-form"
        layout="vertical"
        :model="model"
        @validate="errors.onValidate"
        @finish="() => null"
      >
        <a-row class="administrator-report">
          <a-col class="administrator-report-info">
            <h3>Согласование</h3>
            <p>
              Один из участников проекта инициировал его закрытие. Пожалуйста,
              ознакомьтесь с отчетом по результатам выполнения проекта ниже.
              Если вы согласны закрыть проект, пожалуйста, подтвердите это. Если
              вы считаете, что проект должен быть продолжен, верните проект в
              работу.
            </p>
            <p>
              <span>Инициатор закрытия:&nbsp;</span
              >{{ report.closedByFio + ', ' + report.roleText.toLowerCase() }}
            </p>
            <p>
              <span>Основание для закрытия:&nbsp;</span>{{ report.closeReason }}
            </p>
            <p><span>Комментарий:&nbsp;</span>{{ report.closeComment }}</p>
          </a-col>
        </a-row>

        <div>
          <div class="project-agreement-grid">
            <template
              v-for="(approval, index) in model.approvals"
              :key="`approval-${index}`"
            >
              <agreement-row
                class="agreement-row"
                :status="approval.status"
                :reason="approval.rejectedReason"
                :action="() => handleApproval(approval, index)"
                :reject="() => handleReject(approval)"
                :action-button-text="'Согласовать'"
                :reject-button-text="'На доработку'"
                :loading="approvalLoaders[index]"
                :error="approvalErrors[index].getError('approval')"
              >
                <template #icon>
                  <img src="@/assets/icons/icon-bag.svg" alt="" />
                </template>

                <template #labelText>
                  <div>{{ approval.status?.actionLabel }}</div>
                  <div v-if="model.approvals.length > 1">
                    {{ `Портфель: ${approval.briefcaseName}` }}
                  </div>
                </template>
              </agreement-row>
            </template>
          </div>
        </div>
      </a-form>
      <c-drawer v-model:open="showForm" destroyOnClose>
        <component
          :is="AdministratorProjectRejectForm"
          :project-approval-id="model.rejectedProjectApprovalId"
          @close="() => (showForm = !showForm)"
          @update="updateOnReject"
        />
      </c-drawer>
    </administrator-project-layout>
  </a-spin>
</template>

<script setup>
  import { onMounted, reactive, ref } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useApi } from '@/api/use-api';
  import { useCheckpoints, useTasksInWork } from '@/composables/taskCount';
  import AdministratorProjectLayout from '../components/AdministratorProjectLayout';
  import ProgressBar from '@/components/projects/management/ProgressBar';
  import ErrorsHandler from '@/components/form/errors-handler';
  import AgreementRow from '@/components/projects/agreement/AgreementRow';
  import CDrawer from '@/components/UI/CDrawer';
  import AdministratorProjectRejectForm from '@/views/administrator/projects/form/AdministratorProjectRejectForm.vue';

  const api = useApi();
  const route = useRoute();
  const router = useRouter();
  const emit = defineEmits(['update']); // Parent update

  const model = reactive({
    id: null,
    number: '',
    name: 'Новый проект',
    projectType: '',
    requestId: null,
    status: {
      type: 'draft',
      text: 'Черновик',
    },
    projectStatusId: null,
    approvals: [],
    rejectedProjectApprovalId: null,
  });

  const report = reactive({
    closedByFio: '',
    closeReason: '',
    closeComment: '',
    roleText: '',
  });
  const showForm = ref(false);
  const checkpoints = ref([]);
  const isLoading = ref(true);
  const approvalErrors = ref([]);
  const approvalLoaders = ref([]);

  const errors = new ErrorsHandler({
    approval: '',
  });

  onMounted(async () => {
    await getModel();
    await getCheckpoints();
    await getReport();
    isLoading.value = false;
  });

  const getModel = async () => {
    try {
      const response = await api.administratorPage.getProjectToClose(
        route.params.id
      );
      model.name = response.project.name;
      model.number = response.project.id;
      model.id = response.project.id;
      model.type = response.project.type;
      model.requestId = response.project.requestId;
      model.status = response.project.status;
      model.projectStatusId = response.project.projectStatusId;
      model.approvals = response.project.approvals.filter(
        (item) => item.id > 0
      );

      approvalErrors.value = model.approvals.map(() => {
        return new ErrorsHandler({ approval: '' });
      });
      approvalLoaders.value = model.approvals.map(() => false);
    } catch (error) {
      console.error('error', error);
    }
  };

  const getReport = async () => {
    try {
      const response = await api.projectReport.view(route.params.id);
      report.closedByFio = response.report.closedByFio;
      report.closeReason = response.report.closeReason;
      report.closeComment = response.report.closeComment;
      report.roleText = response.report.roleText;
    } catch (error) {
      console.error('error', error);
    }
  };

  const getCheckpoints = async () => {
    try {
      const response = await api.projectManagementPage.tasks(
        new URLSearchParams({ projectId: route.params.id })
      );
      checkpoints.value = useCheckpoints(response.tasks, null, null);
      const tasksInWork = useTasksInWork(response.tasks);
      model.isAllTasksCompleted = tasksInWork.length === 0;
    } catch (error) {
      console.error('error', error);
    }
  };

  const handleApproval = async (approval, index) => {
    try {
      approvalLoaders.value[index] = true;
      approvalErrors.value[index].clearErrors();
      await api.administratorPage.approveProject({
        projectApprovalId: approval.id,
      });
      await getModel();
      emit('update');
    } catch (error) {
      approvalErrors.value[index].handleApiErrors(error);
    } finally {
      approvalLoaders.value[index] = false;
    }
  };

  const handleReject = (approval) => {
    model.rejectedProjectApprovalId = approval.id;
    showForm.value = true;
  };

  const updateOnReject = async () => {
    await router.push(`/administrator/project/` + model.id + `/form`);
    emit('update');
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  * {
    margin: 0;
    padding: 0;
  }

  .administrator-project__common-progress {
    margin-top: 42px;
  }

  .administrator-agreement-form {
    max-width: 902px;

    h3 {
      font-size: 18px;
      margin-bottom: 10px;
    }

    .administrator-report-info {
      color: $color-bg-status-new;
      display: flex;
      flex-direction: column;
      gap: 18px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin: 20px 0 40px;
      width: 785px;

      span {
        font-weight: 700;
      }
    }
  }

  .project-agreement-grid {
    margin-top: 10px;
    max-width: 902px;
    padding-bottom: 100px;

    .agreement-row {
      margin-top: 30px;
    }
  }
</style>
