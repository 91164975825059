<template>
  <a-spin
    :spinning="isLoading"
    class="custom-spinner"
    style="height: 100vh; top: 20%"
  >
    <administrator-project-layout :model="model">
      <template #underInfo>
        <div
          class="administrator-project__common-progress"
          v-if="checkpoints.length"
        >
          <progress-bar :items="checkpoints" showPercent />
        </div>
      </template>
      <a-col class="project-indexes" v-if="projectIndexesIsVisible" :span="20">
        <div>
          <h3>Показатели</h3>
          <div class="description"></div>

          <portfolio-indexes-grid
            :disabled="true"
            :briefcases="briefcases"
            :hide-save-button="true"
          />
        </div>
      </a-col>
    </administrator-project-layout>
  </a-spin>
</template>

<script setup>
  import { computed, onMounted, reactive, ref } from 'vue';
  import { useRoute } from 'vue-router';
  import { useApi } from '@/api/use-api';
  import { useCheckpoints } from '@/composables/taskCount';
  import { PROJECT_STATUS_DRAFT } from '@/api/models/ProjectStatus';
  import ProgressBar from '@/components/projects/management/ProgressBar';
  import PortfolioIndexesGrid from '@/components/projects/portfolio/components/PorfolioIndexesGrid.vue';
  import AdministratorProjectLayout from '@/views/administrator/projects/components/AdministratorProjectLayout.vue';

  const api = useApi();
  const route = useRoute();
  const model = reactive({
    id: null,
    number: '',
    name: 'Новый проект',
    description: '',
    projectType: '',
    requestId: null,
    status: null,
    projectStatusId: null,
  });

  const checkpoints = ref([]);
  const briefcases = ref([]);
  const isLoading = ref(false);
  const projectIndexesIsVisible = computed(
    () => model.projectStatusId !== PROJECT_STATUS_DRAFT
  );

  onMounted(async () => {
    await getModel();
    await getCheckpoints();
    await getPortfolioIndexes();
    isLoading.value = false;
  });

  const getModel = async () => {
    try {
      const response = await api.leaderPage.view(route.params.id);
      model.id = response.project.id;
      model.name = response.project.name;
      model.number = response.project.id;
      model.type = response.project.type;
      model.requestId = response.project.requestId;
      model.status = response.project.status;
      model.projectStatusId = response.project.projectStatusId;
    } catch (error) {
      console.error('error', error);
    }
  };

  const getCheckpoints = async () => {
    try {
      const response = await api.projectManagementPage.tasks(
        new URLSearchParams({ projectId: route.params.id })
      );
      checkpoints.value = useCheckpoints(response.tasks, null, null);
    } catch (error) {
      console.error('error', error);
    }
  };

  const getPortfolioIndexes = async () => {
    try {
      const response = await api.briefcase.administratorProjectIndexes(
        new URLSearchParams({
          projectId: route.params.id,
        })
      );
      briefcases.value = response.briefcases;
    } catch (error) {
      console.error('error', error);
    }
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  .administrator-project__common-progress {
    margin-top: 42px;
  }

  .project-indexes {
    margin-top: 10px;
    padding-bottom: 100px;

    h3 {
      font-size: 18px;
      margin-bottom: 10px;
    }

    h4 {
      font-size: 14px;
      margin: 30px 0 6px;

      &.disabled {
        color: $color-bg-status-new !important;
      }
    }

    .description {
      font-family: 'Nunito Sans', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      max-width: 678px;
    }
  }
</style>
