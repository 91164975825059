<template>
  <div class="project-card">
    <a-row class="project-card__meta">
      <a-col class="project-card__name">
        {{ project.name }}
      </a-col>
      <a-col class="project-card__status">
        <task-counter-label
          :status="project.status"
          :count="project.inworkCount"
        />
      </a-col>
    </a-row>

    <a-row class="project-card__info">
      <div>
        Номер проекта: <span>{{ project.id }}</span>
      </div>
      <div>
        Тип проекта:
        <span>{{ project.type }}</span>
      </div>
      <div>
        Компания:
        <span>{{ project.organizationName }}</span>
      </div>
      <div>Руководитель проекта</div>
      <project-person
        :avatar="getAvatarUrl(project.leaderAvatarUid)"
        :name="project.leaderName"
        :email="project.leaderEmail"
      />
    </a-row>
  </div>
</template>

<script setup>
  import ProjectPerson from './ProjectPerson.vue';
  import TaskCounterLabel from '@/components/task-counter-label/TaskCounterLabel.vue';

  defineProps({
    project: Object,
  });

  const getAvatarUrl = (avatar) => avatar ? `/api/v1/attachment/${avatar}` : null;
</script>

<style lang="scss">
  @import '@/assets/styles/_colors.scss';

  .project-card {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    background: $color-bg-secondary-grey;
    padding: 16px 16px 20px 20px;

    &:hover {
      box-shadow: 0 6px 15px 0 rgba(1, 1, 1, 0.15);
    }

    .project-card__meta {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;

      .project-card__name {
        color: $color-text-primary;
        font-family: 'Nunito Sans', sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
      }

      .project-card__status {
        margin-left: 16px;
      }
    }

    .project-card__info {
      color: $color-bg-status-new;
      display: flex;
      flex-direction: column;
      font-family: 'Nunito Sans', sans-serif;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      margin-top: 20px;
      row-gap: 18px;

      span {
        font-weight: 400;
      }
    }
  }
</style>
