<template>
  <a-card class="department-list-card" :bordered="false" @click="setParentUnit(id)">
    <h4 class="department-card__title">
      <router-link :to="`/portal/department/` + id">
        {{ name }}
      </router-link>
    </h4>
    <span>@</span>
  </a-card>
  <div class="department-list" v-if="departments.value.length > 0">
    <department-list-card
        v-for="item in departments"
        :key="`department-` + item.id"
        :id="item.id"
        :name="item.name"
    />
  </div>
</template>

<script setup>
import {ref, watch} from "vue";
import {useApi} from "@/api/use-api";

defineProps({
  id: {
    type: Number,
    default: 0,
    required: true,
  },
  name: {
    type: String,
    default: "",
    required: true,
  },
});

const api = useApi();
const parentUuid = ref(null);
const departments = ref([]);

const setParentUnit = (id) => {
  if (!parentUuid.value) {
    parentUuid.value = id;
  } else {
    parentUuid.value = null;
    departments.value = [];
  }
}

const getDepartments = async () => {
  if (!parentUuid.value) return;
  try {
    // let types = [];
    // filterTypes.value.forEach((el) => {
    //   if (el.checked === true) types.push(el.value);
    // });

    let params = new URLSearchParams({
      parent_uuid: parentUuid.value,
      // sort: sorting.value,
      // types: types,
      // tags: filterTags.value,
    });

    const response = await api.organisationalUnit.index(params);
    departments.value = response.organisationalUnits;
  } catch (error) {
    console.error('error', error);
  }
};

watch(parentUuid, () => {
  getDepartments();
});
</script>

<style lang="scss" scoped>
.department-list-card {
  font-size: 14px;
  font-style: normal;
  line-height: 1.71429;
  font-family: "Nunito Sans", sans-serif;
  border: none;
  background: #f2f4f8;
  height: 100%;

  &:hover {
    background: #fff;
    box-shadow: 0 4px 15px 0 rgba(45, 51, 70, 0.15);
  }

  :deep(.ant-card-body) {
    padding: 20px;
    border-radius: 6px;
    height: 100%;

    & > a {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      height: 100%;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .department-card__title {
    margin-bottom: auto;
    padding-bottom: 11px;
  }

  .department-card__unit {
    color: #718096;
    font-weight: 700;
    margin-bottom: 11px;
  }

  .department-card__type {
    color: #718096;
    font-weight: 400;
  }
}
</style>
