<template>
  <a-form
    ref="formRef"
    layout="vertical"
    :model="model"
    :rules="rules"
    :validate-on-rule-change="true"
    @validate="errors.onValidate"
    @finish="() => null"
  >
    <leader-project-form-row
      :available="model.isAvailable"
      @change="model.isAvailable = !model.isAvailable"
      class="bordered"
    >
      <a-row>
        <a-col :span="12">
          <a-form-item
            name="costTypeId"
            :help="errors.getError('costTypeId')"
            :validate-status="errors.getStatus('costTypeId')"
          >
            <a-select
              :options="props.costTypes"
              v-model:value="model.costTypeId"
              placeholder="Выберите статью расхода"
              :disabled="disabled"
              @select="validateAndUpdate"
            />
            <template #label>
              <span class="form-item__label">Статья расхода</span>
              <c-button-remove
                v-if="!disabled"
                class="button-remove"
                @click="() => emit('remove', index)"
              />
            </template>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item
            name="costAmount"
            label="Сумма, руб."
            :help="errors.getError('costAmount')"
            :validate-status="errors.getStatus('costAmount')"
          >
            <a-input-number
              :controls="false"
              v-model:value="model.costAmount"
              placeholder="Укажите сумму"
              :disabled="disabled"
              @change="validateAndUpdate"
            />
            <!--            <template #label>-->
            <!--              <span class="form-item__label">Сумма</span>-->
            <!--            </template>-->
          </a-form-item>
        </a-col>
      </a-row>
    </leader-project-form-row>
  </a-form>
</template>

<script setup>
  import { computed, onMounted, reactive, ref, watch } from 'vue';
  import ErrorsHandler from '@/components/form/errors-handler';
  import LeaderProjectFormRow from '@/views/leader/projects/form/LeaderProjectFormRow.vue';
  import CButtonRemove from '@/components/UI/CButtonRemove.vue';
  import useInputRowValidator from '@/composables/inputRowValidator';

  const formRef = ref();
  const emit = defineEmits(['update', 'remove']);
  const inputValidator = useInputRowValidator(emit);

  const props = defineProps({
    index: {
      type: Number,
      required: true,
    },
    maxIndex: {
      type: Number,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    costTypes: {
      type: Array,
      required: true,
    },
    apiErrors: {
      type: Object,
      required: true,
    },
    showAvailable: {
      type: Boolean,
      default: false,
    },
    offsetAvailable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  });

  const model = reactive({
    costTypeId: null,
    costAmount: null,
  });

  const validationIsRequired = computed(() => {
    return (
      model.costTypeId || model.costAmount || props.index !== props.maxIndex
    );
  });

  const rules = computed(() => {
    return {
      costTypeId: [
        {
          type: 'number',
          message: 'Выберите статью расходов.',
          required: validationIsRequired.value,
          trigger: 'blur',
        },
      ],
      costAmount: [
        {
          type: 'number',
          required: validationIsRequired.value,
          message: 'Укажите сумму расходов.',
          trigger: 'blur',
        },
      ],
    };
  });

  const errors = new ErrorsHandler({
    costTypeId: '',
    costAmount: '',
  });

  onMounted(() => {
    fillModel();
  });

  watch(
    () => props.data,
    () => {
      fillModel();
    }
  );

  watch(
    () => props.apiErrors,
    () => {
      props.apiErrors.errors && errors.handleApiErrors(props.apiErrors);
    }
  );

  watch(
    () => validationIsRequired.value,
    () => {
      setTimeout(() => {
        validateAndUpdate();
      }, 300);
    }
  );

  const fillModel = () => {
    try {
      model.costTypeId = props.data.costTypeId;
      model.costAmount = props.data.costAmount;
    } catch (error) {
      console.error('error', error);
    }
  };

  const validateAndUpdate = () =>
    inputValidator.validateAndUpdate(props.index, model, formRef);

  defineExpose({
    validateAndUpdate,
  });
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  //.form-item__label {
  //  padding-top: 15px;
  //}

  .button-remove {
    margin-left: 10px;
  }
</style>
