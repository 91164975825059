import { create, read } from '@/api/rest';

const projectManagementPage = {
  tasks: async (options) =>
    await read('/project-management/tasks?' + options.toString()),
  saveTask: async (data) => await create('/project-management/save-task', data),
  saveCheckpoint: async (data) =>
    await create('/project-management/save-checkpoint', data),
  deleteTask: async (data) =>
    await create('/project-management/delete-task', data),
  deleteCheckpoint: async (data) =>
    await create('/project-management/delete-checkpoint', data),
};

export default projectManagementPage;
