<template>
  <router-link :to="to" class="menu-pict" :style="backgroundImage(image)">
    <span class="menu-pict__icon">
      <img :src="icon" alt="" />
    </span>
    <span class="menu-pict__label">
      <slot></slot>
    </span>
  </router-link>
</template>

<script setup>
defineProps({
  to: {
    type: String,
    default: "",
  },
  icon: {
    type: String,
    default: "",
  },
  image: {
    type: String,
    default: "",
  },
});

const backgroundImage = (image) => {
  if (!image) return "";
  return "background-image: url(" + image + ")";
};
</script>

<style lang="scss" scoped>
@import "../../assets/_map-for-breakpoints";
@import "../../../../assets/styles/_breakpoints";

.menu-pict {
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  padding: 40px;
  position: relative;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  color: #ffffff;
  text-decoration-line: none;

  &:hover {
    text-decoration-line: underline;
  }

  @include breakpoint(max, lg) {
    padding: 30px;
  }

  .menu-pict__icon {
    width: 88px;
    height: 88px;
    display: flex;
    background: #fff;
    align-items: center;
    justify-content: center;

    @include breakpoint(max, lg) {
      width: 64px;
      height: 64px;
    }
  }

  .menu-pict__label {
    font-variant-numeric: slashed-zero;
    font-family: "Nunito Sans", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.2;
    height: 42px;
    flex: 0 0 42px;
    margin-top: auto;

    @include breakpoint(max, lg) {
      font-size: 16px;
    }
  }
}
</style>
