<template>
  <partner-layout :spinning="isLoading">
    <partner-project-layout :model="model">
      <template #underInfo>
        <div class="partner-project__common-progress" v-if="checkpoints.length">
          <progress-bar :items="checkpoints" showPercent />
        </div>
      </template>
      <a-form
        class="form partner-agreement-form"
        layout="vertical"
        :model="model"
        @validate="errors.onValidate"
        @finish="() => null"
      >
        <a-row class="partner-report">
          <a-col class="partner-report-info">
            <h3>Согласование</h3>
            <p>
              Один из участников проекта инициировал его закрытие. Пожалуйста,
              ознакомьтесь с отчетом по результатам выполнения проекта ниже.
              Если вы согласны закрыть проект, пожалуйста, подтвердите это. Если
              вы считаете, что проект должен быть продолжен, верните проект в
              работу.
            </p>
            <p>
              <span>Инициатор закрытия:&nbsp;</span
              >{{ report.closedByFio + ', ' + report.roleText.toLowerCase() }}
            </p>
            <p>
              <span>Основание для закрытия:&nbsp;</span>{{ report.closeReason }}
            </p>
            <p><span>Комментарий:&nbsp;</span>{{ report.closeComment }}</p>
          </a-col>
        </a-row>

        <a-form-item
          v-if="model.approval?.id"
          name="approval"
          :help="errors.getError('approval')"
          :validate-status="errors.getStatus('approval')"
        >
          <agreement-row
            :status="model.approval?.status"
            :reason="model.approval?.rejectedReason"
            :action="() => handleApproval()"
            :reject="() => (showForm = true)"
            :reapprove="() => reApprove()"
            :loading="isLoading"
          >
            <template #icon>
              <img src="@/assets/icons/icon-person.svg" alt="" />
            </template>

            <template #labelText>
              {{ model.approval?.status?.actionLabel }}
            </template>
          </agreement-row>
        </a-form-item>
      </a-form>
      <c-drawer v-model:open="showForm" destroyOnClose>
        <component
          :is="PartnerProjectRejectForm"
          :project-approval-id="model.approval?.id"
          @close="() => (showForm = !showForm)"
          @update="updateOnReject"
        />
      </c-drawer>
    </partner-project-layout>
  </partner-layout>
</template>

<script setup>
  import { onMounted, reactive, ref } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useApi } from '@/api/use-api';
  import ProgressBar from '@/components/projects/management/ProgressBar';
  import ErrorsHandler from '@/components/form/errors-handler';
  import AgreementRow from '@/components/projects/agreement/AgreementRow';
  import { useCheckpoints, useTasksInWork } from '@/composables/taskCount';
  import CDrawer from '@/components/UI/CDrawer';
  import PartnerLayout from '@/layouts/PartnerLayout';
  import PartnerProjectLayout from '@/views/partner-projects/components/PartnerProjectLayout';
  import PartnerProjectRejectForm from '@/views/partner-projects/form/PartnerProjectRejectForm.vue';

  const api = useApi();
  const route = useRoute();
  const router = useRouter();
  const emit = defineEmits(['update']);

  const model = reactive({
    id: null,
    number: '',
    name: 'Новый проект',
    projectType: '',
    requestId: null,
    status: {
      type: 'draft',
      text: 'Черновик',
    },
    projectStatusId: null,
    approval: null,
  });

  const report = reactive({
    closedByFio: '',
    closeReason: '',
    closeComment: '',
    roleText: '',
  });
  const showForm = ref(false);
  const checkpoints = ref([]);

  const errors = new ErrorsHandler(
    {
      approval: '',
    },
    (fieldName) => {
      if ('projectApproval' === fieldName) {
        return 'approval';
      }
      return fieldName;
    }
  );
  const isLoading = ref(true);

  onMounted(async () => {
    await getModel();
    await getCheckpoints();
    await getReport();
    isLoading.value = false;
  });

  const getModel = async () => {
    try {
      const response = await api.partnerProject.getProjectToClose(
        route.params.id
      );
      model.name = response.project.name;
      model.number = response.project.id;
      model.id = response.project.id;
      model.type = response.project.type;
      model.requestId = response.project.requestId;
      model.status = response.project.status;
      model.projectStatusId = response.project.projectStatusId;
      model.approval = response.project.approval;
    } catch (error) {
      console.error('error', error);
    }
  };

  const getReport = async () => {
    try {
      const response = await api.projectReport.view(route.params.id);
      report.closedByFio = response.report.closedByFio;
      report.closeReason = response.report.closeReason;
      report.closeComment = response.report.closeComment;
      report.roleText = response.report.roleText;
    } catch (error) {
      console.error('error', error);
    }
  };

  const getCheckpoints = async () => {
    try {
      const response = await api.projectManagementPage.tasks(
        new URLSearchParams({ projectId: route.params.id })
      );
      checkpoints.value = useCheckpoints(response.tasks, null, null);
      const tasksInWork = useTasksInWork(response.tasks);
      model.isAllTasksCompleted = tasksInWork.length === 0;
    } catch (error) {
      console.error('error', error);
    }
  };

  const handleApproval = async () => {
    try {
      isLoading.value = true;
      errors.clearErrors();
      await api.partnerProject.approveProject({
        projectApprovalId: model.approval.id,
      });
      await getModel();
    } catch (error) {
      errors.handleApiErrors(error);
    } finally {
      isLoading.value = false;
    }
  };

  const reApprove = async () => {
    try {
      isLoading.value = true;
      await api.partnerProject.reApproveProject({
        projectApprovalId: model.approval.id,
      });
      await getModel();
    } catch (error) {
      console.error('error', error);
    } finally {
      isLoading.value = false;
    }
  };

  const updateOnReject = async () => {
    await router.push(`/partner/project/` + model.id + `/form`);
    emit('update');
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  * {
    margin: 0;
    padding: 0;
  }

  .partner-project__common-progress {
    margin-top: 42px;
  }

  .partner-agreement-form {
    max-width: 902px;

    h3 {
      font-size: 18px;
      margin-bottom: 10px;
    }

    .partner-report-info {
      color: $color-bg-status-new;
      display: flex;
      flex-direction: column;
      gap: 18px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin: 20px 0 40px;
      width: 785px;

      span {
        font-weight: 700;
      }
    }
  }
</style>
