import AdministratorPage from '@/views/administrator/AdministratorPage.vue';
import { USER_ROLE_ADMINISTRATOR } from '@/api/models/UserRole';

const role = USER_ROLE_ADMINISTRATOR;

export const administratorRoutes = [
  {
    path: '/administrator/portfolios',
    name: 'administrator-portfolios',
    component: AdministratorPage,
    meta: { requiresAuth: true, type: 'portfolios', role: role },
  },
  {
    path: '/administrator/portfolio/:id/details',
    name: 'administrator-portfolio-details',
    component: AdministratorPage,
    meta: {
      requiresAuth: true,
      type: 'portfolios',
      filterType: 'details',
      role: role,
    },
  },
  {
    path: '/administrator/portfolio/:id/projects',
    name: 'administrator-portfolio-projects',
    hash: '#all',
    component: AdministratorPage,
    meta: {
      requiresAuth: true,
      type: 'portfolios',
      filterType: 'projects',
      role: role,
    },
  },
  {
    path: '/administrator/portfolio/:id/indexes',
    name: 'administrator-portfolio-indexes',
    component: AdministratorPage,
    meta: {
      requiresAuth: true,
      type: 'portfolios',
      filterType: 'indexes',
      role: role,
    },
  },
  {
    path: '/administrator/requests',
    name: 'administrator-requests',
    component: AdministratorPage,
    meta: { requiresAuth: true, type: 'request', role: role, default: true },
  },
  {
    path: '/administrator/requests/:id',
    name: 'administrator-requests-form',
    component: AdministratorPage,
    meta: { requiresAuth: true, type: 'request', role: role },
  },
  {
    path: '/administrator/projects',
    name: 'administrator-projects',
    component: AdministratorPage,
    meta: { requiresAuth: true, type: 'projects', role: role },
  },
  {
    path: '/administrator/project/:id/form',
    name: 'administrator-project-form',
    component: AdministratorPage,
    meta: { requiresAuth: true, type: 'projects', role: role },
  },
  {
    path: '/administrator/project/:id/management',
    name: 'administrator-project-management',
    component: AdministratorPage,
    meta: { requiresAuth: true, type: 'projects', role: role },
  },
  {
    path: '/administrator/project/:id/team',
    name: 'administrator-project-team',
    component: AdministratorPage,
    meta: { requiresAuth: true, type: 'projects', role: role },
  },
  {
    path: '/administrator/project/:id/showcase',
    name: 'administrator-project-showcase',
    component: AdministratorPage,
    meta: { requiresAuth: true, type: 'projects', role: role },
  },
  {
    path: '/administrator/project/:id/indexes',
    name: 'administrator-project-indexes',
    component: AdministratorPage,
    meta: { requiresAuth: true, type: 'project', role: role },
  },
  {
    path: '/administrator/project/:id/report',
    name: 'administrator-project-report',
    component: AdministratorPage,
    meta: { requiresAuth: true, type: 'projects', role: role },
  },
  {
    path: '/administrator/project/:id/agreement',
    name: 'administrator-project-agreement',
    component: AdministratorPage,
    meta: { requiresAuth: true, type: 'projects', role: role },
  },
  {
    path: '/administrator/showcases',
    name: 'administrator-showcases',
    component: AdministratorPage,
    hash: '#new',
    meta: { requiresAuth: true, type: 'showcases', role: role },
  },
];
