<template>
  <showcase-layout class="root">
    <template #top>
      <a-row class="top">
        <main-title>
          <template #default>
            <div class="project_name">
              {{ model.name }}
            </div>
          </template>
          <template #description>{{ model.type }}</template>
        </main-title>
      </a-row>
    </template>
    <template #default>
      <content-container class="content">
        <a-row justify="space-between" :gutter="[40, 40]">
          <a-col :span="16">
            <a-row class="meta-project">
              <a-col :span="12">
                <a-row :gutter="[20, 20]">
                  <label-text
                    label="Портфель проекта"
                    :text="model.briefcase"
                  />
                  <label-text
                    label="Заказчик
                проекта"
                    :text="model.customerName"
                  />
                </a-row>
              </a-col>
              <a-col :span="12">
                <a-row :gutter="[20, 20]">
                  <label-text
                    label="Срок реализации проекта"
                    :text="model.endDate"
                  />
                  <label-text
                    label="Бюджет проекта"
                    :text="`${Number(model.budget).toLocaleString(
                      'ru-RU'
                    )} руб.`"
                  />
                </a-row>
              </a-col>
            </a-row>

            <a-row class="about-project">
              <a-col>
                <h4>О проекте</h4>
                <p>{{ model.projectInfo }}</p>
                <div class="tags">
                  <template
                    v-for="(tag, index) in model.tags"
                    :key="`tag-` + index"
                  >
                    <tag-item>{{ `#${tag}` }}</tag-item>
                  </template>
                </div>
              </a-col>
            </a-row>
            <a-row class="result">
              <a-col>
                <h4>Ожидаемый результат</h4>
                <p>{{ model.expectedResults }}</p>
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="6">
            <a-row class="user">
              <a-col class="title">Руководитель проекта</a-col>
              <a-col>
                <div class="profile-avatar">
                  <div class="profile-avatar__image">
                    <img :src="getAvatarUrl()" alt="" v-if="getAvatarUrl()" />
                    <div class="profile-avatar__skeleton" v-else>
                      <img src="@/assets/icons/camera.svg" alt="" />
                    </div>
                  </div>
                </div>
              </a-col>
              <a-col>
                <div class="name">{{ model.leaderName }}</div>
                <div class="email">{{ model.leaderEmail }}</div>
              </a-col>
              <a-col>
                <a-row class="actions">
                  <c-button-primary v-if="model.isLookingForPerformer">
                    Присоединиться к команде
                  </c-button-primary>
                  <c-button-secondary
                    class="secondary-btn"
                    v-if="model.isLookingForPartner"
                  >
                    Связаться с командой
                  </c-button-secondary>
                  <c-button-secondary
                    class="secondary-btn"
                    v-if="model.isWaiting"
                  >
                    Подать запрос
                  </c-button-secondary>
                </a-row>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </content-container>
    </template>
  </showcase-layout>
</template>

<script setup>
  import ShowcaseLayout from '@/layouts/ShowcaseLayout.vue';
  import MainTitle from '@/views/portal/components/MainTitle.vue';
  import ContentContainer from '@/views/portal/components/ContentContainer.vue';
  import CButtonSecondary from '@/components/UI/CButtonSecondary.vue';
  import CButtonPrimary from '@/components/UI/CButtonPrimary.vue';
  import LabelText from '@/views/projects-showcase/components/LabelText.vue';
  import TagItem from '@/components/tags/TagItem.vue';
  import { useApi } from '@/api/use-api';
  import { useRoute } from 'vue-router';
  import { onMounted, reactive, ref } from 'vue';
  import dayjs from 'dayjs';

  const api = useApi();
  const route = useRoute();

  const model = reactive({
    id: 39,
    type: '',
    name: '',
    briefcase: '',
    endDate: '',
    customerName: '-',
    budget: null,
    leaderName: '',
    leaderEmail: '',
    leaderAvatarUid: null,
    projectInfo: '',
    expectedResults: '',
    isLookingForPartner: false,
    isLookingForPerformer: false,
    isWaiting: false,
    tags: [],
    showcaseId: 38,
    originShowcaseId: 16,
  });
  const isLoading = ref(true);

  onMounted(async () => {
    await getModel();
    isLoading.value = false;
  });

  const getModel = async () => {
    try {
      const response = await api.publicProject.view(route.params.id);
      model.id = response.project.id;
      model.type = response.project.type;
      model.name = response.project.name;
      model.briefcase = response.project.briefcase;
      model.endDate = response.project.endDate
        ? dayjs(response.project.endDate).locale('ru').format('DD MMMM YYYY')
        : '';
      model.customerName = response.project.customerName;
      model.budget = response.project.budget;
      model.leaderName = response.project.leaderName;
      model.leaderEmail = response.project.leaderEmail;
      model.leaderAvatarUid = response.project.leaderAvatarUid;
      model.projectInfo = response.project.projectInfo;
      model.expectedResults = response.project.expectedResults;
      model.isLookingForPartner = response.project.isLookingForPartner;
      model.isLookingForPerformer = response.project.isLookingForPerformer;
      model.isWaiting = response.project.isWaiting;
      model.tags = response.project.tags;
      model.showcaseId = response.project.showcaseId;
      model.originShowcaseId = response.project.originShowcaseId;
    } catch (error) {
      console.error('error', error);
    }
  };

  const getAvatarUrl = () =>
    model.leaderAvatarUid
      ? `/api/v1/attachment/${model.leaderAvatarUid}`
      : null;
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors';

  $color-bg-support-button: #ffffff;
  $color-text-support-button: #2d3346;

  .root {
    h4 {
      font-size: 18px;
      margin-top: 40px;
    }

    p {
      line-height: 24px;
    }

    .top {
      align-items: center;
      justify-content: center;
      padding: 110px 0 0;
      width: 100%;

      .project_name {
        text-transform: uppercase;
      }
    }

    .content {
      padding: 40px 5px;

      .meta-project {
        border-bottom: 1px solid #ebebeb;
        padding-bottom: 40px;
      }

      .about-project {
        border-bottom: 1px solid #ebebeb;
        padding-bottom: 40px;

        .tags {
          margin-top: 25px;

          :deep(.tag) {
            font-size: 12px;
          }
        }
      }

      .user {
        background-color: $color-bg-secondary-grey;
        border-radius: 10px;
        gap: 20px;
        height: 511px;
        padding: 20px;
        width: 325px;

        .title {
          font-family: Nunito Sans, sans-serif;
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          text-align: left;
        }

        .profile-avatar {
          height: 180px;
          width: 180px;

          .profile-avatar__image {
            position: absolute;

            img {
              height: 100%;
              width: 100%;
              display: block;
              resize: both;
            }

            .profile-avatar__skeleton {
              align-items: center;
              background-color: $color-bg-secondary-grey;
              border: 1px solid #fff;
              border-radius: 12px;
              display: flex;
              height: 170px;
              justify-content: center;
              width: 170px;

              img {
                width: 27px;
                height: 23px;
              }
            }
          }
        }

        .name,
        .email {
          font-family: Nunito Sans, sans-serif;
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;
          text-align: left;
        }

        .email {
          font-weight: 400;
        }

        .actions {
          gap: 10px;
          width: 248px;

          button {
            width: 100%;
          }
        }
      }
    }

    .secondary-btn {
      background-color: $color-bg-support-button;
      color: $color-text-support-button;

      &:hover,
      &:focus {
        color: rgb(65, 65, 65);
        border-color: transparent;
        outline: none;
      }
    }
  }
</style>
