<template>
  <portal-layout class="search-page">
    <template v-slot:header>
      <main-title>
        <template v-slot:default>
          Результаты поиска
        </template>
      </main-title>
      <input-search
        class="mb-40"
        placeholder="Ищите документы, проекты, людей или другое"
      />
    </template>
    <template v-slot:default>
      <white-block class="pict-text-block-wrapper">
        <content-container class="content-padding">
          <div class="mt-40" v-if="query">
            <p>
              <span v-if="total > 0">Результатов: {{ total }}</span>
              <span v-else>Ничего не найдено</span>
            </p>
          </div>

          <div class="search-items mt-40">
              <gray-block
                class="search-item rounded p-20"
                v-for="(item, index) in items"
                :key="`search-item-` + index"
              >
                <h4 class="search-item__header">
                  {{ ((currentPage - 1) * pageSize) + index + 1 }}.&nbsp;
                  <a :href="item.url" v-html="item.title" />
                </h4>
                <p v-if="item.body" class="search-item__body">{{ item.body }}</p>
                <a v-if="item.url" :href="item.url" class="search-item__link">{{ item.url }}</a>
              </gray-block>
          </div>

          <div class="departments-page__pages" v-if="total > items.length">
            <a-pagination
                v-model:current="currentPage"
                v-model:pageSize="pageSize"
                :total="total"
                :show-size-changer="false"
            >
              <template #itemRender="{ type, originalElement }">
                <pagination-arrows
                    :type="type"
                    :original-element="originalElement"
                />
              </template>
            </a-pagination>
          </div>
        </content-container>
      </white-block>
    </template>
  </portal-layout>
</template>

<script setup>
import {onMounted, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useApi} from "@/api/use-api";
import PortalLayout from "@/layouts/PortalLayout.vue";
import GrayBlock from "@/views/portal/components/GrayBlock.vue";
import WhiteBlock from "@/views/portal/components/WhiteBlock.vue";
import ContentContainer from "@/views/portal/components/ContentContainer.vue";
import PaginationArrows from "@/views/portal/components/UI/PaginationArrows.vue";
import MainTitle from '@/views/portal/components/MainTitle.vue';
import InputSearch from "@/views/portal/components/UI/InputSearch.vue";

const api = useApi();
const route = useRoute();
const router = useRouter();
const items = ref([]);
const query = ref('');
const currentPage = ref(1);
const pageSize = ref(10);
const total = ref(0);

const search = async () => {
  if (!route.query.q) return;
  query.value = route.query.q ?? '';
  const response = await api.search.index(
      new URLSearchParams({
        q: route.query.q,
        page: currentPage.value,
        'per-page': pageSize.value,
      })
  );

  items.value = response.items;
  total.value = response.total;
}

watch(currentPage, () => {
  router.push({
    name: 'portal-search-page',
    params: {},
    query: {
      ...route.query,
      page: currentPage.value,
    }
  });
});

watch(pageSize, () => {
  router.push({
    name: 'portal-search-page',
    params: {},
    query: {
      ...route.query,
      page: '1',
      'per-page': pageSize.value,
    }
  });
});

watch(query, () => {
  router.push({
    name: 'portal-search-page',
    params: {},
    query: {
      ...route.query,
      q: query.value,
      page: currentPage.value,
      'per-page': pageSize.value,
    }
  });
});

watch(() => route.query, () => {
  search();
});

onMounted(() => {
  search();
});
</script>

<style lang="scss" scoped>
.search-items {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .search-item {
    .search-item__header {
      a {
        color: inherit;
      }
    }
    .search-item__body {
      color: #2d3346;
      font-family: 'Nunito Sans', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .search-item__link {
      margin-top: 20px;
    }
  }
}
</style>