<template>
  <h3>Шаг 3. Календарный план-график</h3>
  <p>Общая информация о проекте</p>
  <div>
    <a-form class="form performer-project-form" layout="vertical">
      <performer-project-form-row class="bordered">
        <a-row>
          <a-col :span="12">
            <a-form-item name="startDate" label="Старт проекта">
              <a-date-picker
                v-model:value="model.startDate"
                placeholder="Выберите дату"
                :locale="locale"
                disabled
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item name="endDate" label="Завершение проекта">
              <a-date-picker
                v-model:value="model.endDate"
                placeholder="Выберите дату"
                :locale="locale"
                disabled
              />
            </a-form-item>
          </a-col>
        </a-row>
      </performer-project-form-row>

      <template v-for="(stage, index) in model.stages" :key="`stage-` + index">
        <div>
          <h3>Этап {{ index + 1 }}</h3>

          <performer-project-form-row
            :show-available="false"
            :offsetAvailable="false"
            class="bordered"
          >
            <a-form-item>
              <a-input
                v-model:value="stage.name"
                placeholder="Введите название этапа"
                disabled
              />
            </a-form-item>
            <a-row>
              <a-col :span="12">
                <a-form-item>
                  <a-date-picker
                    v-model:value="stage.startDate"
                    placeholder="Выберите дату старта этапа"
                    :locale="locale"
                    disabled
                  />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item>
                  <a-date-picker
                    v-model:value="stage.endDate"
                    placeholder="Выберите дату завершение этапа"
                    :locale="locale"
                    disabled
                  />
                </a-form-item>
              </a-col>
            </a-row>
          </performer-project-form-row>
        </div>
      </template>
    </a-form>
  </div>
</template>

<script setup>
  import { onMounted, reactive, watch } from 'vue';
  import locale from 'ant-design-vue/es/date-picker/locale/ru_RU';
  import PerformerProjectFormRow from '../PerformerProjectFormRow.vue';
  import dayjs from 'dayjs';

  const props = defineProps({
    project: {
      type: Object,
      require: true,
    },
  });

  const model = reactive({
    id: null,
    startDate: '',
    endDate: '',
    stages: [],
  });

  onMounted(() => { props.project && fillModel(); })

  watch(()=>props.project, () => {
    props.project && fillModel();
  });

  const fillModel = () => {
    try {
      model.startDate = props.project.startDate ? dayjs(props.project.startDate, 'YYYY-MM-DD') : '';
      model.endDate = props.project.endDate ? dayjs(props.project.endDate, 'YYYY-MM-DD') : '';
      model.stages = props.project.stages.map((stage, index)=>{
        return {
          id: index + 1,
          name: stage.name,
          startDate: dayjs(stage.startDate, 'YYYY-MM-DD'),
          endDate: dayjs(stage.endDate, 'YYYY-MM-DD'),
        }
      });
    } catch (error) {
      console.error('error', error);
    }
  };
</script>

<style lang="scss" scoped>
  @import './performer-project-form';
  .performer-project-form-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    & > button {
      margin-left: 10px;

      &:first-child {
        margin-left: 0;
      }
    }
  }
</style>
