<template>
  <guest-layout>
    <template #navigation>
      <back-arrow page-name="home" />
    </template>
    <template #default>
      <mega-title text="Партнер НГУ" />
      <div class="instruction">Авторизуйтесь с помощью логина и пароля.</div>
      <a-form
        class="form form_one-column"
        :model="model"
        :rules="rules"
        @validate="errors.onValidate"
        @finish="onFinish"
      >
        <a-form-item
          name="username"
          :help="errors.getError('username')"
          :validate-status="errors.getStatus('username')"
        >
          <a-input v-model:value="model.username" placeholder="Логин" />
        </a-form-item>
        <a-form-item
          name="password"
          :help="errors.getError('password')"
          :validate-status="errors.getStatus('password')"
        >
          <a-input
            v-model:value="model.password"
            type="password"
            placeholder="Пароль"
          />
        </a-form-item>
        <div class="buttons-block">
          <div class="buttons-block__left">
            <a-form-item>
              <a-button block type="primary" html-type="submit">Войти</a-button>
            </a-form-item>
          </div>
          <div class="buttons-block__right">
            <nav-link
              page-name="reset-password-request"
              text="Восстановить пароль"
            />
          </div>
        </div>
      </a-form>
    </template>
    <template #footer>
      <div class="registration-block">
        <div class="registration-block__item">У Вас ещё нет аккаунта?</div>
        <div class="registration-block__item">
          <nav-link page-name="sign-up" text="Зарегистрироваться" />
        </div>
      </div>
    </template>
  </guest-layout>
</template>

<script setup>
import { reactive } from "vue";
import { innerSignIn } from "@/api/authentication/token-provider";
import { useApi } from "@/api/use-api";
import { useRouter } from "vue-router";
import GuestLayout from "@/layouts/GuestLayout";
import BackArrow from "@/components/navigation/BackArrow";
import MegaTitle from "@/components/titles/MegaTitle";
import NavLink from "@/components/navigation/NavLink";
import ErrorsHandler from "@/components/form/errors-handler";

const api = useApi();
const router = useRouter();

const model = reactive({
  username: "",
  password: "",
});

const rules = {
  username: [
    {
      type: "email",
      required: true,
      message: "Необходимо ввести адрес электронной почты",
      trigger: "blur",
    },
  ],
  password: [
    {
      type: "string",
      required: true,
      message: "Необходимо ввести пароль",
      trigger: "blur",
    },
  ],
};

const errors = new ErrorsHandler({
  username: "",
  password: "",
});

const onFinish = async (values) => {
  try {
    const response = await api.signIn.signIn({
      username: values.username,
      password: values.password,
    });

    innerSignIn(response.token);
    // Add to router
    await router.push({ name: "main" });
  } catch (error) {
    errors.handleApiErrors(error);
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/_colors.scss";

.guest-content {
  .instruction {
    margin-bottom: 40px;
  }

  .form_one-column {
    max-width: 340px;
  }

  .instruction {
    margin-bottom: 40px;
  }

  .buttons-block {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .buttons-block__left {
      flex: 0 0 50%;

      .ant-form-item {
        margin-bottom: 0;
      }
    }
  }
}

.registration-block {
  display: flex;

  .registration-block__item {
    padding: 0 40px 0 0;
  }
}
</style>
