<template>
  <showcase-layout class="main-page">
    <template #top>
      <content-container class="content-padding">
        <a-row>
          <a-col :span="12">
            <main-title class="main-title">
              <template v-slot:default>ПРОЕКТЫ<br />НГУ</template>
              <template v-slot:description>
                {{ `${projectsCount} активных проектов в работе` }}
              </template>
            </main-title>
            <div>
              <input-search
                class="mb-40"
                page="search"
                placeholder="Ищите интересные Вам проекты"
              />
            </div>
          </a-col>
          <a-col :span="12">
            <a-row class="main-gallery">
              <a-col class="main-gallery-col">
                <div>
                  <a-col class="container">
                    <img src="./assets/images/innovations.png" alt="" />
                    <tag-item class="tag">#innovations</tag-item>
                  </a-col>

                  <a-col class="container">
                    <img src="./assets/images/space_exploration.png" alt="" />
                    <tag-item class="tag">#space exploration</tag-item>
                  </a-col>
                </div>
              </a-col>
              <a-col class="main-gallery-col">
                <div>
                  <a-col class="container">
                    <img src="./assets/images/biomedicine.png" alt="" />
                    <tag-item class="tag">#biomedicine</tag-item>
                  </a-col>

                  <a-col class="container">
                    <img src="./assets/images/nanotechnology.png" alt="" />
                    <tag-item class="tag">#nanotechnology</tag-item>
                  </a-col>
                </div>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </content-container>
    </template>
    <template v-slot:default>
      <content-container class="content-padding">
        <a-row class="showcase__header">
          <a-col class="sorting">
            <span class="sorting__label">Сортировать</span>
            <a-select
              class="sorting__select"
              :options="sortingList"
              v-model:value="sorting"
            />
          </a-col>
          <a-col>
            <a-row :gutter="10">
              <a-col>
                <a-badge
                  :count="countFilter"
                  :number-style="{ backgroundColor: '#72c81e', color: '#fff' }"
                >
                  <button-primary @click="openFilter = true">
                    Фильтр
                  </button-primary>
                </a-badge>
                <right-panel
                  title="Фильтр"
                  v-model:open="openFilter"
                  @close="openFilter = false"
                >
                  <a-form @submit.prevent method="get">
                    <right-panel-title>Тип проекта</right-panel-title>
                    <div>
                      <a-form-item
                        v-for="filterType in filterTypes"
                        :key="`filter-type-` + filterType.value"
                        class="checkbox-item"
                      >
                        <a-checkbox v-model:checked="filterType.checked">
                          {{ filterType.label }}
                        </a-checkbox>
                      </a-form-item>
                    </div>

                    <hr class="mt-40 mb-40" />

                    <right-panel-title>Статус</right-panel-title>
                    <div>
                      <a-form-item
                        v-for="filterStatus in filterStatuses"
                        :key="`filter-status-` + filterStatus.value"
                        class="checkbox-item"
                      >
                        <a-checkbox v-model:checked="filterStatus.checked">
                          {{ filterStatus.label }}
                        </a-checkbox>
                      </a-form-item>
                    </div>

                    <hr class="mt-40 mb-40" />
                    <right-panel-title>Ключевые слова</right-panel-title>
                    <a-form-item>
                      <input-tags
                        placeholder="Введите ключевые слова"
                        :tags="filterTags"
                        @update="setFilterTags"
                      />
                    </a-form-item>
                    <right-panel-buttons>
                      <button-default @click="resetFilter">
                        Очистить
                      </button-default>
                      <button-primary @click="applyFilter">
                        Применить
                      </button-primary>
                    </right-panel-buttons>
                  </a-form>
                </right-panel>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
        <a-spin
          :spinning="isLoading"
          class="custom-spinner"
          style="height: 100vh; top: 50%"
        >
          <a-row :gutter="[20, 20]" class="projects">
            <a-col
              :span="8"
              v-for="(project, index) in projects"
              :key="++index"
              @click="router.push(`/showcase/project/${project.id}`)"
            >
              <project-card :project="project" />
            </a-col>
          </a-row>
        </a-spin>
      </content-container>
    </template>
  </showcase-layout>
</template>

<script setup>
  import ShowcaseLayout from '@/layouts/ShowcaseLayout.vue';
  import InputSearch from '@/views/projects-showcase/components/InputSearch.vue';
  import MainTitle from '@/views/projects-showcase/components/MainTitle.vue';
  import TagItem from '@/components/tags/TagItem.vue';

  import RightPanel from '@/views/portal/components/panels/RightPanel.vue';
  import RightPanelTitle from '@/views/portal/components/panels/RightPanelTitle.vue';
  import RightPanelButtons from '@/views/portal/components/panels/RightPanelButtons.vue';
  import ContentContainer from '@/views/portal/components/ContentContainer.vue';
  import ButtonDefault from '@/views/portal/components/UI/ButtonDefault.vue';
  import ButtonPrimary from '@/views/portal/components/UI/ButtonPrimary.vue';
  import ProjectCard from '@/views/projects-showcase/components/ProjectCard.vue';
  import { useApi } from '@/api/use-api';
  import { useRoute, useRouter } from 'vue-router';
  import { computed, onMounted, ref, watch } from 'vue';
  import InputTags from '@/views/portal/components/UI/InputTags.vue';
  import {
    PROJECT_TYPE_ID_EDUCATION,
    PROJECT_TYPE_ID_EVENT,
    PROJECT_TYPE_ID_EXPERTISE,
    PROJECT_TYPE_ID_PARTNERSHIP,
    PROJECT_TYPE_ID_RESEARCH,
    PROJECT_TYPE_ID_RESOURCES,
  } from '@/api/models/ProjectType';

  const api = useApi();
  const route = useRoute();
  const router = useRouter();
  const sorting = ref('name');
  const sortingList = [{ value: 'name', label: 'По алфавиту' }];
  const projects = ref([]);
  const projectsCount = ref('-');
  const isLoading = ref(false);

  /* Filter */
  const openFilter = ref(false);
  const filterTags = ref([]);
  const filterTypes = ref([
    {
      value: PROJECT_TYPE_ID_RESEARCH,
      label: 'Проведение исследовательских работ',
      checked: false,
    },
    { value: PROJECT_TYPE_ID_EDUCATION, label: 'Образование', checked: false },
    {
      value: PROJECT_TYPE_ID_EXPERTISE,
      label: 'Проведение экспертизы',
      checked: false,
    },
    {
      value: PROJECT_TYPE_ID_PARTNERSHIP,
      label: 'Новое сотрудничество',
      checked: false,
    },
    {
      value: PROJECT_TYPE_ID_RESOURCES,
      label: 'Использование ресурсов',
      checked: false,
    },
    { value: PROJECT_TYPE_ID_EVENT, label: 'Мероприятия', checked: false },
  ]);
  const filterStatuses = ref([
    { value: 'isLookingForPartner', label: 'Ищем партнеров', checked: false },
    {
      value: 'isLookingForPerformer',
      label: 'Ищем исполнителей',
      checked: false,
    },
    { value: 'isWaiting', label: 'Ждем похожие запросы', checked: false },
  ]);
  /*---*/

  onMounted(async () => {
    await getProjects();
    await getProjectsCount();
  });

  watch(
    () => route.query,
    async () => {
      await getProjects();
    }
  );

  const getProjects = async () => {
    isLoading.value = true;
    try {
      const searchParams = new URL(document.location.toString()).searchParams;
      const response = await api.publicProject.projects(searchParams);
      projects.value = response.projects;
    } catch (error) {
      console.error('error', error);
    } finally {
      isLoading.value = false;
    }
  };

  const getProjectsCount = async () => {
    try {
      const response = await api.publicProject.projectsCount();
      projectsCount.value = response.count.toString();
    } catch (error) {
      console.error('error', error);
    } finally {
      isLoading.value = false;
    }
  };

  const countFilter = computed(() => {
    const typesCount = route.query.types
      ? route.query.types.split(',').length
      : 0;
    const statusesCount =
      (route.query.isLookingForPartner ? 1 : 0) +
      (route.query.isLookingForPerformer ? 1 : 0) +
      (route.query.isWaiting ? 1 : 0);
    const tagsCount = route.query.tags ? route.query.tags.split(',').length : 0;

    return typesCount + statusesCount + tagsCount;
  });

  const setFilterTags = (newTags) => {
    filterTags.value = newTags;
  };

  const resetFilter = async () => {
    filterTypes.value.forEach((el) => (el.checked = false));
    filterStatuses.value.forEach((el) => (el.checked = false));
    filterTags.value = [];
    await applyFilter();
  };

  const applyFilter = async () => {
    openFilter.value = false;

    const types = flattenFilterParams(filterTypes.value);
    const statuses = filterStatuses.value
      .filter((el) => el.checked)
      .reduce((acc, el) => {
        acc[el.value] = el.checked ? 1 : 0;
        return acc;
      }, {});
    const tags = flattenFilterParams(filterTags.value);

    const routeQuery = {
      page: 1,
      ...statuses,
    };
    if (types) routeQuery['types'] = types;
    if (tags) routeQuery['tags'] = tags;
    if (route.query.textSearch)
      routeQuery['textSearch'] = route.query.textSearch;

    await router.push({
      name: 'showcase-main-page',
      params: {},
      query: routeQuery,
    });
  };

  const flattenFilterParams = (filterParams) => {
    return filterParams
      .filter((el) => typeof el === 'string' || el.checked)
      .reduce(
        (acc, el, currentIndex) =>
          acc +
          (currentIndex === 0 ? '' : ',') +
          (typeof el === 'string' ? el : el.value),
        ''
      );
  };
</script>

<style lang="scss" scoped>
  @import '../portal/assets/_map-for-breakpoints';
  @import '../../assets/styles/_breakpoints';

  .main-title {
    margin: 60px 0 100px;
  }

  .main-gallery {
    flex-wrap: nowrap;
    justify-content: end;
    gap: 20px;
    margin: 28px 0 0;

    .main-gallery-col {
      div {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }

      &:last-child {
        margin-top: 20px;
      }
    }

    .tag {
      bottom: 14px;
      left: 14px;
      position: absolute;
    }
  }

  .sorting {
    .sorting__label {
      color: #718096;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      margin-right: 20px;
    }

    &__select {
      width: 208px;

      :deep(.ant-select-selection-item) {
        color: #aeb4dd;
      }
    }
  }

  .showcase__header {
    align-items: center;
    justify-content: space-between;
    padding: 0 0 20px;
    border-bottom: 1px solid #babbc2;
  }

  .showcase__search {
    margin-top: 80px;
    margin-bottom: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .showcase__content {
    display: flex;

    &.cards {
      flex-direction: row;
      flex-wrap: wrap;

      .department-item {
        flex: 0 0 33.333%;
      }
    }

    &.table {
      flex-direction: column;
      flex-wrap: nowrap;
    }
  }

  .projects {
    margin-top: 40px;
  }

  .checkbox-item {
    margin-bottom: 0;
  }

  .carousel-wrapper {
    margin-top: 110px;
    margin-bottom: 90px;
  }

  blockquote {
    padding: 100px 0;
    margin: 0 auto;

    @include breakpoint(max, lg) {
      padding: 70px 0;
    }
  }

  :deep(.content_portal) {
    // background: #f2f4f8;
  }

  .pict-text-block-wrapper {
    margin-bottom: 135px;
    @include breakpoint(max, lg) {
      margin-bottom: 80px;
    }
  }

  :deep(.content-container).content-padding {
    // padding-left: 40px;
    // padding-right: 40px;
    padding: 20px 0;
  }
</style>
