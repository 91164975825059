<template>
  <div class="two-columns">
    <div>
      <slot name="left"></slot>
    </div>
    <div>
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
  @import '../assets/_map-for-breakpoints';
  @import '../../../assets/styles/_breakpoints';

  .two-columns {
    display: flex;
    flex-direction: row;
    gap: 20px;
    @include breakpoint(max, md) {
      flex-direction: column;
    }

    & > div {
      flex: 1 1 50%;
      @include breakpoint(max, md) {
        flex: 0 0 100%;
      }
    }
  }
</style>
