<template>
  <partner-layout v-if="currentRole === 'partner'" class='profile'>
    <profile-content />
  </partner-layout>

  <project-manager-layout v-if="currentRole === 'leader'" class='profile'>
    <profile-content />
  </project-manager-layout>

  <administrator-layout v-if="currentRole === 'administrator'" class='profile'>
    <profile-content />
  </administrator-layout>

  <moderator-layout v-if="currentRole === 'moderator'" class='profile'>
    <profile-content />
  </moderator-layout>

  <performer-projects-layout v-if="currentRole === 'performer'" class='profile'>
    <profile-content />
  </performer-projects-layout>
</template>

<script setup>
  import { computed } from 'vue';
  import PartnerLayout from '@/layouts/PartnerLayout';
  import ProfileContent from '@/views/partner-profile/ProfileContent';
  import ProjectManagerLayout from '@/layouts/ProjectManagerLayout';
  import AdministratorLayout from '@/layouts/AdministratorLayout';
  import ModeratorLayout from '@/layouts/ModeratorLayout';
  import PerformerProjectsLayout from '@/views/performer/layouts/PerformerProjectsLayout';

  const currentRole = computed(() => localStorage.getItem('currentRole'));
</script>

<style lang='scss' scoped>
  @import 'partner-profile';
</style>
