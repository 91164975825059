<template>
  <h3>Шаг 5. Финансы</h3>
  <p>Укажите финансирование проекта и сформируйте смету.</p>
  <div>
    <a-form
      class="form leader-project-form"
      layout="vertical"
      :model="model"
      :rules="rules"
      @validate="errors.onValidate"
      @finish="onFinish"
      :scrollToFirstError="{
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      }"
    >
      <leader-project-form-row
        :show-available="model.isExternal"
        :available="model.isBudgetAvailable"
        :disabled="disabled"
        @change="model.isBudgetAvailable = !model.isBudgetAvailable"
        class="bordered"
      >
        <a-row>
          <a-col :span="12">
            <a-form-item
              name="budget"
              label="Общий объем финансирования, руб."
              :help="errors.getError('budget')"
              :validate-status="errors.getStatus('budget')"
            >
              <a-input-number
                :controls="false"
                v-model:value="model.budget"
                placeholder="Укажите сумму"
                :disabled="disabled"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              name="budgetSource"
              label="Источник финансирования"
              :help="errors.getError('budgetSource')"
              :validate-status="errors.getStatus('budgetSource')"
            >
              <a-input
                v-model:value="model.budgetSource"
                placeholder="Укажите источник"
                :disabled="disabled"
              />
              <!--              <a-select-->
              <!--                :value="model.field2 ? { value: model.field2 } : undefined"-->
              <!--                :options="sourcesOfFinancing"-->
              <!--                placeholder="Выберите источник"-->
              <!--                @change="setSourcesOfFinancing"-->
              <!--              ></a-select>-->
            </a-form-item>
          </a-col>
        </a-row>
      </leader-project-form-row>

      <div class="budget-stages">
        <template
          v-for="(item, index) in model.budgetStages"
          :key="`budget-stages-` + index"
        >
          <a-row>
            <h3>Этап {{ index + 1 }}</h3>
            <c-button-remove
              v-if="!disabled"
              class="button-remove"
              @click="removeStage(item)"
            />
          </a-row>
          <budget-stage-input-row
            :show-available="model.isExternal"
            ref="budgetStageRefs"
            :index="index"
            :max-index="model.budgetStages.length - 1"
            :data="item"
            :api-errors="budgetStageErrors.getApiError(index)"
            :disabled="disabled"
            @update="onBudgetStageUpdate"
            class="bordered"
          />
        </template>
      </div>

      <leader-project-form-row
        v-if="!disabled"
        :show-available="false"
        class="bordered"
      >
        <a-form-item>
          <c-button-add class="button-remove" @click="addEmptyBudgetStage">
            Добавить этап
          </c-button-add>
        </a-form-item>
      </leader-project-form-row>

      <!-- -- -->

      <leader-project-form-row
        :show-available="model.isExternal"
        :available="model.isBudgetCostsAvailable"
        :offset-available="false"
        :disabled="disabled"
        @change="model.isBudgetCostsAvailable = !model.isBudgetCostsAvailable"
      >
        <h3>Смета проекта</h3>
      </leader-project-form-row>
      <template
        v-for="(item, index) in model.budgetCosts"
        :key="`costs-` + index"
      >
        <budget-cost-input-row
          show-available
          ref="budgetCostRefs"
          :index="index"
          :max-index="model.budgetCosts.length - 1"
          :data="item"
          :cost-types="model.costTypeItems"
          :api-errors="budgetCostErrors.getApiError(index)"
          :disabled="disabled"
          @update="onBudgetCostUpdate"
          @remove="removeCost"
          class="bordered"
        />
      </template>

      <leader-project-form-row v-if="!disabled" :show-available="false">
        <a-form-item>
          <c-button-add @click="addEmptyCost"
            >Добавить статью расхода</c-button-add
          >
        </a-form-item>
      </leader-project-form-row>

      <leader-project-form-row :show-available="false">
        <leader-project-form-buttons
          v-if="project?.isMyProject"
          :project-status-id="project?.projectStatusId"
          :loading="isLoading"
          @click="setGoNext"
          @confirm-update="() => emit('confirm-update')"
        />
      </leader-project-form-row>
    </a-form>
  </div>
</template>

<script setup>
  import { onMounted, reactive, ref, watch } from 'vue';
  import { useApi } from '@/api/use-api';
  import LeaderProjectFormRow from '@/views/leader/projects/form/LeaderProjectFormRow.vue';
  import ErrorsHandler from '@/components/form/errors-handler';
  import CButtonAdd from '@/components/UI/CButtonAdd.vue';
  import CButtonRemove from '@/components/UI/CButtonRemove.vue';
  import dayjs from 'dayjs';
  import LeaderProjectFormButtons from '@/views/leader/projects/form/LeaderProjectFormButtons.vue';
  import rules from './rules/RulesStep5';
  import { useTableErrorHandler } from '@/composables/tableErrorHandler';
  import BudgetStageInputRow from '@/views/leader/projects/form/steps/inputs/BudgetStageInputRow';
  import { useRoute, useRouter } from 'vue-router';
  import BudgetCostInputRow from '@/views/leader/projects/form/steps/inputs/BudgetCostInputRow';

  const router = useRouter();
  const route = useRoute();
  const api = useApi();
  const emit = defineEmits(['update', 'confirm-update']);

  const budgetStageRefs = ref([]);
  const budgetCostRefs = ref([]);
  const isLoading = ref(false);

  const props = defineProps({
    project: {
      type: Object,
      require: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  });

  const model = reactive({
    id: null,
    isExternal: false,
    budget: null,
    budgetSource: null,
    isBudgetAvailable: false,
    isBudgetCostsAvailable: false,
    budgetStages: [],
    budgetCosts: [],
    costTypeItems: [],
  });

  watch(
    () => props.project,
    () => {
      fillModel();
    }
  );

  const errors = new ErrorsHandler({
    budget: '',
    budgetSource: '',
  });

  const budgetStageErrors = useTableErrorHandler();
  const budgetCostErrors = useTableErrorHandler();

  onMounted(() => {
    getCostTypes();
    props.project && fillModel();
  });

  watch(
    () => budgetStageRefs.value.length,
    () => {
      validateBudgetStageForms();
    }
  );
  watch(
    () => budgetCostRefs.value.length,
    () => {
      validateBudgetCostForms();
    }
  );

  const getCostTypes = async () => {
    try {
      const response = await api.leaderPage.getProjectCostTypes();
      model.costTypeItems = [...response.types];
    } catch (error) {
      console.error('error', error);
    }
  };

  const fillModel = () => {
    try {
      model.id = props.project.id;
      model.isExternal = props.project.isExternal;
      model.budget = props.project.budget;
      model.budgetSource = props.project.budgetSource;
      model.isBudgetAvailable = props.project.availabilityList['budget'];
      model.isBudgetCostsAvailable =
        props.project.availabilityList['budgetCosts'];

      model.budgetStages = [];
      budgetStageErrors.reset();
      if (props.project.budgetStages.length > 0) {
        model.budgetStages = props.project.budgetStages.map((item) => {
          return { ...item, paymentDate: dayjs(item.paymentDate) };
        });
        budgetStageErrors.init(model.budgetStages);
      } else {
        addEmptyBudgetStage();
      }

      model.budgetCosts = [];
      budgetCostErrors.reset();
      if (props.project.budgetCosts.length > 0) {
        model.budgetCosts = [...props.project.budgetCosts];
        budgetCostErrors.init(model.budgetCosts);
      } else {
        addEmptyCost();
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  const goNext = ref(false);
  const setGoNext = (value) => {
    goNext.value = !!value;
  };

  const addEmptyBudgetStage = () => {
    model.budgetStages.push({
      id: null,
      stageId: null,
      paymentDate: '',
      paymentAmount: null,
      isAvailable: true,
    });
    budgetStageErrors.addHandler();
  };

  const removeStage = (stage) => {
    const stageId = model.budgetStages.indexOf(stage);
    model.budgetStages.splice(stageId, 1);
    budgetStageErrors.removeHandler(stageId);
    if (model.budgetStages.length === 0) {
      addEmptyBudgetStage();
    }
  };

  const addEmptyCost = () => {
    model.budgetCosts.push({
      id: null,
      costTypeId: null,
      costAmount: null,
    });
    budgetCostErrors.addHandler();
  };

  const removeCost = (id) => {
    model.budgetCosts.splice(id, 1);
    budgetCostErrors.removeHandler(id);
    if (model.budgetCosts.length === 0) {
      addEmptyCost();
    }
  };

  const onFinish = async () => {
    isLoading.value = true;
    const saveStepResult = await saveStep();

    let saveBudgetStagesResult = false;
    if (!budgetStageErrors.inputsHaveErrors()) {
      saveBudgetStagesResult = await saveBudgetStages();
    } else {
      console.log(`Budget Stages Form has errors.`);
    }

    let saveBudgetCostsResult = false;
    if (!budgetCostErrors.inputsHaveErrors()) {
      saveBudgetCostsResult = await saveBudgetCosts();
    } else {
      console.log(`Budget Costs Form has errors.`);
    }

    if (saveStepResult && saveBudgetStagesResult && saveBudgetCostsResult) {
      emit('update', model);
      if (goNext.value) {
        await router.push({ hash: '#6' });
      }
    }
    isLoading.value = false;
  };

  const saveStep = async () => {
    try {
      await api.leaderPage.updateProject({
        id: route.params.id,
        budget: model.budget,
        budgetSource: model.budgetSource,
        isBudgetAvailable: model.isBudgetAvailable,
        isBudgetCostsAvailable: model.isBudgetCostsAvailable,
      });
      return true;
    } catch (error) {
      errors.handleApiErrors(error);
      return false;
    }
  };

  const saveBudgetStages = async () => {
    budgetStageErrors.clearApiErrors();
    // Если у последнего этапа не заполнены поля, то мы его не записываем
    const lastStage = model.budgetStages.at(-1);
    const stagesToSave =
      lastStage.paymentAmount !== null || lastStage.paymentDate
        ? model.budgetStages
        : model.budgetStages.slice(0, -1);

    // Data Normalization
    stagesToSave.forEach((item, index) => {
      item.stageId = index;
      item.paymentDate = item.paymentDate
        ? dayjs(item.paymentDate).locale('ru').format('YYYY-MM-DD')
        : '';
    });

    try {
      await api.leaderPage.updateProjectBudgetStages({
        projectId: route.params.id,
        payload: stagesToSave,
      });
      return true;
    } catch (error) {
      if (error.tableErrors) {
        budgetStageErrors.setTableApiErrors(error.tableErrors);
      }
      console.error('error', error);
      return false;
    }
  };

  const saveBudgetCosts = async () => {
    budgetCostErrors.clearApiErrors();
    // Если у последней статьи затрат не заполнены поля, то мы её не записываем
    const lastCost = model.budgetCosts.at(-1);
    const costsToSave =
      lastCost.costAmount !== null || lastCost.costTypeId
        ? model.budgetCosts
        : model.budgetCosts.slice(0, -1);

    try {
      await api.leaderPage.updateProjectBudgetCosts({
        projectId: route.params.id,
        payload: costsToSave,
      });
      return true;
    } catch (error) {
      if (error.tableErrors) {
        budgetCostErrors.setTableApiErrors(error.tableErrors);
      }
      console.error('error', error);
      return false;
    }
  };

  const onBudgetStageUpdate = (value) => {
    model.budgetStages[value.index] = {
      ...model.budgetStages[value.index],
      ...value.data,
    };
    budgetStageErrors.setInputError(value.index, value.hasError);
  };

  const onBudgetCostUpdate = (value) => {
    model.budgetCosts[value.index] = {
      ...model.budgetCosts[value.index],
      ...value.data,
    };
    budgetCostErrors.setInputError(value.index, value.hasError);
  };

  const validateBudgetStageForms = async () => {
    for (let i = 0; i < budgetStageRefs.value.length; i++) {
      await budgetStageRefs.value[i].validateAndUpdate();
    }
  };

  const validateBudgetCostForms = async () => {
    for (let i = 0; i < budgetCostRefs.value.length; i++) {
      await budgetCostRefs.value[i].validateAndUpdate();
    }
  };

  defineExpose({ onFinish });
</script>

<style lang="scss" scoped>
  @import './leader-project-form';

  .budget-stages {
    :deep(.bordered):last-child {
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

  .leader-project-form-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    & > button {
      margin-left: 10px;

      &:first-child {
        margin-left: 0;
      }
    }
  }
</style>
