<template>
  <div class="requests">
    <a-row class="requests__header">
      <a-col class="request-item__type-col">Тип проекта </a-col>
      <a-col class="request-item__number-col"> Номер запроса </a-col>
      <a-col class="request-item__date-col"> Дата запроса </a-col>
      <a-col class="request-item__status-col"> Статус </a-col>
    </a-row>

    <div v-for="request in requests" :key="request.id" class="requests__item">
      <request-item
        :request="request"
        @click="
          router.push({
            path: `/partner/requests/${request.id}`,
          })
        "
      />
    </div>
  </div>
</template>

<script setup>
  import { useRouter } from 'vue-router';
  import RequestItem from '@/components/requests/components/RequestItem';

  defineProps({
    requests: Array,
  });

  const router = useRouter();
</script>

<style lang="scss">
  @import '@/assets/styles/_colors.scss';
  $color-text-secondary-grey-216: #718096;

  .requests {
    padding-top: 40px;
  }

  .requests__header {
    gap: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    border-bottom: 1px solid $color-bg-secondary-grey;

    color: $color-text-secondary-grey-216;
    font-variant-numeric: slashed-zero;
    font-family: Nunito Sans, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    padding: 0 15px 10px 15px;
    margin-bottom: 10px;
  }

  .requests__item {
    margin-bottom: 20px;
  }

  .request-item__type-col {
    width: 385px;
    flex: 1 1 385px;
  }

  .request-item__number-col {
    width: 240px;
    flex: 1 1 240px;
  }

  .request-item__date-col {
    width: 270px;
    flex: 1 1 270px;
  }

  .request-item__status-col {
    text-align: center;
    width: 145px;
    flex: 1 1 145px;
  }
</style>
