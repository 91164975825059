import { setErrorsHandler } from '@/api/axios/adapter';
import errorGenerator from '@/api/error-generator/index';
import mainPage from '@/api/main-page/index';
import me from '@/api/me/index';
import password from '@/api/password/index';
import signIn from '@/api/sign-in/index';
import signOut from '@/api/sign-out/index';
import signUp from '@/api/sign-up/index';
import user from '@/api/user/index';
import profilePage from '@/api/profile-page/index';
import moderatorPage from '@/api/moderator-page/index';
import organisationalUnit from '@/api/organisational-unit/index';
import administratorPage from '@/api/administrator-page/index';
import leaderPage from '@/api/leader-page/index';
import projectManagementPage from '@/api/project-management-page/index';
import attachment from '@/api/attachment';
import avatar from '@/api/avatar';
import person from '@/api/person';
import fingerprint from '@/api/fingerprint';
import publication from '@/api/publication';
import performerPage from '@/api/performer-page';
import profileInformation from '@/api/profile-information';
import partnerProject from '@/api/partner-project';
import projectReport from '@/api/project-report';
import search from '@/api/search';
import requestPage from '@/api/request-page';
import requestType from '@/api/request-type';
import projectType from '@/api/project-type';
import educationProgram from '@/api/education-program';
import expertiseType from '@/api/expertise-type';
import support from '@/api/support';
import briefcase from '@/api/briefcase';
import briefcaseIndex from '@/api/briefcase-index';
import briefcaseProjectIndex from '@/api/briefcase-project-index';
import project from '@/api/project';
import publicProject from '@/api/public-project';
import showcase from '@/api/showcase';
import documentApproval from '@/api/documet-approval';

const controllers = {
  errorGenerator,
  mainPage,
  me,
  password,
  signIn,
  signOut,
  signUp,
  user,
  profilePage,
  moderatorPage,
  administratorPage,
  leaderPage,
  organisationalUnit,
  attachment,
  avatar,
  person,
  projectManagementPage,
  fingerprint,
  publication,
  performerPage,
  profileInformation,
  partnerProject,
  projectReport,
  search,
  support,
  requestPage,
  requestType,
  projectType,
  educationProgram,
  expertiseType,
  briefcase,
  briefcaseIndex,
  briefcaseProjectIndex,
  project,
  publicProject,
  showcase,
  documentApproval,
};

const backend = (app, options) => {
  app.config.globalProperties['$' + options.name] = controllers;
  app.provide(options.name, controllers);
  setErrorsHandler(options.errorHandler);
};

export default backend;
