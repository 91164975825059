<template>
  <h3>Шаг 5. Финансы</h3>
  <p>
    Проверьте информацию в карточке проекта и, если согласны, согласуйте проект.
  </p>
  <div>
    <a-form class="form partner-project-form" layout="vertical">
      <partner-project-form-row
        :show-available="false"
        class="bordered"
        v-if="model.availabilityList['budget']"
      >
        <a-row>
          <a-col :span="12">
            <a-form-item name="budget" label="Общий объем финансирования">
              <a-input
                v-model:value="model.budget"
                placeholder="Укажите сумму"
                disabled
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item name="budgetSource" label="Источник финансирования">
              <a-input
                v-model:value="model.budgetSource"
                placeholder="Укажите источник"
                disabled
              />
            </a-form-item>
          </a-col>
        </a-row>
      </partner-project-form-row>

      <div class="budget-stages">
        <template
          v-for="(item, index) in model.budgetStages"
          :key="`budget-stages-` + index"
        >
          <div v-if="item.isAvailable">
            <h3>Этап {{ index + 1 }}</h3>
            <partner-project-form-row :show-available="false" class="bordered">
              <a-row>
                <a-col :span="12">
                  <a-form-item name="paymentAmount" label="Размер поступления">
                    <a-input
                      v-model:value="item.paymentAmount"
                      placeholder="Укажите сумму"
                      disabled
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item name="paymentDate" label="Дата финансирования">
                    <a-date-picker
                      v-model:value="item.paymentDate"
                      placeholder="Выберите дату"
                      :locale="locale"
                      disabled
                    />
                  </a-form-item>
                </a-col>
              </a-row>
            </partner-project-form-row>
          </div>
        </template>
      </div>

      <!-- -- -->
      <div v-if="model.availabilityList['budgetCosts']">
        <partner-project-form-row
          :show-available="false"
          :offset-available="false"
        >
          <h3>Смета проекта</h3>
        </partner-project-form-row>
        <template v-for="(item, index) in model.budgetCosts" :key="`costs-` + index">
          <partner-project-form-row>
            <a-row>
              <a-col :span="12">
                <a-form-item name="costType" label="Статья расхода">
                  <a-input
                    v-model:value="item.costType"
                    placeholder="Выберите статью расхода"
                    disabled
                  />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item name="costAmount" label="Сумма">
                  <a-input
                    v-model:value="item.costAmount"
                    placeholder="Укажите сумму"
                    disabled
                  />
                </a-form-item>
              </a-col>
            </a-row>
          </partner-project-form-row>
        </template>
      </div>
    </a-form>
  </div>
</template>

<script setup>
  import { onMounted, reactive, watch } from 'vue';
  import locale from 'ant-design-vue/es/date-picker/locale/ru_RU';
  import PartnerProjectFormRow from '../PartnerProjectFormRow.vue';
  import dayjs from 'dayjs';

  const props = defineProps({
    project: {
      type: Object,
      require: true,
    },
  });

  const model = reactive({
    id: null,
    budget: null,
    budgetSource: null,
    budgetStages: [],
    budgetCosts: [],
    availabilityList: [],
  });

  onMounted(() => {
    props.project && fillModel();
  })

  watch(()=>props.project, () => {
    fillModel();
  });

  const fillModel = () => {
    try {
      model.id = props.id;
      model.availabilityList = props.project.availabilityList;
      model.budget = props.project.budget;
      model.budgetSource = props.project.budgetSource;
      model.budgetStages = props.project.budgetStages.map((stage, index) => {
        return {
          id: index + 1,
          paymentDate: dayjs(stage.paymentDate, 'YYYY-MM-DD'),
          paymentAmount: stage.paymentAmount,
          isAvailable: stage.isAvailable,
        }
      })
      model.budgetCosts = props.project.budgetCosts.map((cost, index) => {
        return {
          id: index + 1,
          costType: cost.costType,
          costAmount: cost.costAmount,
        }
      })
    } catch (error) {
      console.error('error', error);
    }
  };
</script>

<style lang="scss" scoped>
  @import './partner-project-form';

  .budget-stages {
    :deep(.bordered):last-child {
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
</style>
