<template>
  <a-row :gutter="getGutter(type)" class="tags-percent" wrap="true">
    <a-col v-for="(item, index) in tags" :key="'tag-percent-item-' + index">
      <tag-percent-item
        :class="'tag-percent-' + type"
        :value="item.value"
        :tooltip="item.tooltip"
      >
        <template v-if="item.description">
          <b>{{ item.label }}</b>
          <span>{{ item.description }}</span>
        </template>
        <template v-else>
          {{ item.label }}
        </template>
      </tag-percent-item>
    </a-col>
  </a-row>
</template>

<script setup>
import TagPercentItem from "@/views/portal/components/tags/TagPercentItem.vue";

defineProps({
  tags: Array, // {label: String, value: Integer}
  type: {
    type: String,
    default: "gray",
  },
});

const getGutter = (type) => {
  if (type === "big-gray") {
    return [14, 14];
  }
  return [6, 6];
};
</script>

<style lang="scss"></style>
