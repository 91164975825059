<template>
  <a-row :gutter="[20, 20]" class="projects">
    <a-col
      :span="12"
      v-for="(project, index) in projects"
      :key="{ id: index++, ...project }"
      @click="onProjectClick(project)"
    >
      <project-card :project="project" />
    </a-col>
  </a-row>
</template>

<script setup>
  import { onMounted, ref, watch } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useApi } from '@/api/use-api';
  import ProjectCard from './components/ProjectCard.vue';
  import {
    PROJECT_STATUS_ACCEPTANCE,
    PROJECT_STATUS_AGREEMENT,
    PROJECT_STATUS_IN_WORK,
  } from '@/api/models/ProjectStatus';

  const route = useRoute();
  const router = useRouter();
  const api = useApi();
  const typeView = ref('cards');
  const projects = ref([]);

  onMounted(() => {
    getProjects();
  });

  watch(
    () => route.hash,
    (newHash) => {
      const hash = newHash.replace('#', '');
      typeView.value = hash ? hash : 'cards';
    }
  );

  const getProjects = async () => {
    try {
      const response = await api.partnerProject.projects();
      projects.value = response.projects;
    } catch (error) {
      console.error('error', error);
    }
  };

  const onProjectClick = async (project) => {
    if (project.projectStatusId === PROJECT_STATUS_AGREEMENT) {
      await router.push(`/partner/project/` + project.id + `/form#9`);
    } else if (project.projectStatusId === PROJECT_STATUS_ACCEPTANCE) {
      await router.push(`/partner/project/` + project.id + `/agreement`);
    } else if (project.projectStatusId === PROJECT_STATUS_IN_WORK) {
      await router.push(`/partner/project/` + project.id + `/management`);
    } else {
      await router.push(`/partner/project/` + project.id + `/form`);
    }
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  .projects {
    margin-top: 30px;
    padding: 0 0 100px 0;
  }

  .horizontal-filter__panel {
    margin: 0 -40px 0 -20px;
    padding: 8px 40px 8px 20px;
    border-top: 1px solid $color-bg-secondary-grey;
    border-bottom: 1px solid $color-bg-secondary-grey;
  }
</style>
