<template>
  <a-row>
    <a-col :span='24' class='profile__title'>
      <h1>Мой профиль</h1>
    </a-col>
  </a-row>
  <a-row class='profile__info'>
    <a-col :span='24'>
      <a-row>
        <a-col>
          <div class='profile-avatar'>
            <div class='profile-avatar__image'>
              <img :src='getAvatarUrl()' alt='' v-if='getAvatarUrl()' />
              <div class='profile-avatar__skeleton' v-else>
                <img src='./assets/camera.svg' alt='' />
              </div>
            </div>
            <div
              class='profile-avatar__button'
              @click="handleShowForm('addPhoto', avatarModel)"
            >
              <img src='./assets/edit.svg' alt='' />
            </div>
          </div>
        </a-col>
        <a-col>
          <div class='profile-description'>
            <div class='profile-description__item'>
              <div class='profile-description__label'>Фамилия:</div>
              <div class='profile-description__content'>
                {{ personModel.lastName }}
              </div>
            </div>
            <div class='profile-description__item'>
              <div class='profile-description__label'>Имя:</div>
              <div class='profile-description__content'>
                {{ personModel.firstName }}
              </div>
            </div>
            <div class='profile-description__item'>
              <div class='profile-description__label'>Отчество:</div>
              <div class='profile-description__content'>
                {{ personModel.middleName }}
              </div>
            </div>
            <div class='profile-description__item'>
              <div class='profile-description__label'>E-mail:</div>
              <div class='profile-description__content'>
                {{ personModel.email }}
              </div>
            </div>
            <div class='profile-description__item'>
              <div class='profile-description__label'>Телефон:</div>
              <div class='profile-description__content'>
                {{ personModel.phone }}
              </div>
            </div>
            <div class='profile-description__item'>
              <SocialIcons :person='personModel' />
            </div>
          </div>
        </a-col>
        <a-col class='profile-edit'>
          <a @click="handleShowForm('editProfile')">Редактировать профиль</a>
        </a-col>
      </a-row>
    </a-col>
  </a-row>
  <a-divider />
  <template v-if="currentRole === 'partner'">
    <a-row>
      <a-col :span='24' class='profile-subtitle'>
        <h3>Мои компании</h3>
      </a-col>
    </a-row>
    <a-row class='profile-companies'>
      <a-col :span='24'>
        <a-row :gutter='[20, 20]'>
          <template v-if='personModel.companies?.length'>
            <a-col :span='12' v-for='company in personModel.companies' :key='company.id'>
              <company-card
                v-bind='company'
                @edit="handleShowForm('editCompany', company)"
                @delete="handleShowForm('deleteCompany', company)"
              />
            </a-col>
          </template>
          <a-col :span='12'>
            <div
              class='profile-companies__add-new'
              @click="handleShowForm('addCompany')"
            >
              <div><img src='./assets/plus.svg' alt='' /></div>
              <div>Добавить компанию</div>
            </div>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </template>
  <c-drawer v-model:open='showForm' destroyOnClose>
    <component
      :is='form'
      v-bind:item='formItem'
      @close='() => (showForm = !showForm)'
      @update='updateProfile'
    />
  </c-drawer>
</template>

<script setup>
  import { shallowRef, ref, onMounted, reactive, computed } from 'vue';
  import { useApi } from '@/api/use-api';
  import CompanyCard from '@/components/company-card/CompanyCard';
  import AddCompanyForm from '@/views/partner-profile/AddCompanyForm';
  import EditProfileForm from '@/views/partner-profile/EditProfileForm';
  import AddPhotoForm from '@/views/partner-profile/AddPhotoForm';
  import EditCompanyForm from '@/views/partner-profile/EditCompanyForm';
  import DeleteCompanyForm from '@/views/partner-profile/DeleteCompanyForm';
  import CDrawer from '@/components/UI/CDrawer.vue';
  import SocialIcons from '@/components/social/SocialIcons.vue';

  const api = useApi();
  const form = shallowRef(null);
  const formItem = ref(null);
  const showForm = ref(false);
  const currentRole = computed(() => localStorage.getItem('currentRole'));
  const personModel = reactive({
    lastName: '',
    firstName: '',
    middleName: '',
    email: '',
    phone: '',
    whatsappId: '',
    telegramId: '',
    vkId: '',
    companies: [],
  });
  const avatarModel = reactive({
    personId: 0,
    attachmentUid: '',
  });

  onMounted(() => {
    updateProfile();
  });

  function handleShowForm(formType, item) {
    switch (formType) {
      case 'addPhoto':
        form.value = AddPhotoForm;
        formItem.value = item;
        break;

      case 'addCompany':
        form.value = AddCompanyForm;
        break;

      case 'editProfile':
        form.value = EditProfileForm;
        break;

      case 'editCompany':
        form.value = EditCompanyForm;
        formItem.value = item;
        break;

      case 'deleteCompany':
        form.value = DeleteCompanyForm;
        formItem.value = item;
        break;
    }

    showForm.value = true;
  }

  function updateProfile() {
    getCompanies();
    getUser();
  }

  async function getUser() {
    try {
      const response = await api.mainPage.userData();
      personModel.lastName = response.lastName;
      personModel.firstName = response.firstName;
      personModel.middleName = response.middleName;
      personModel.email = response.email;
      personModel.phone = response.phone;
      personModel.whatsappId = response.whatsappId;
      personModel.telegramId = response.telegramId;
      personModel.vkId = response.vkId;
      avatarModel.personId = response.personId;
      avatarModel.attachmentUid = response.attachmentUid;
    } catch (error) {
      console.error('error', error);
    }
  }

  async function getCompanies() {
    try {
      const response = await api.profilePage.companies();
      personModel.companies = response.companies.map((item, index) => ({ ...item, listId: index + 1 }));
    } catch (error) {
      console.error('error', error);
    }
  }

  function getAvatarUrl() {
    return avatarModel.attachmentUid ? '/api/v1/attachment/' + avatarModel.attachmentUid : null;
  }
</script>

<style lang='scss' scoped>
  @import 'partner-profile';
</style>
