<template>
  <a-spin
    :spinning="isLoading"
    class="custom-spinner"
    style="height: 100vh; top: 50%"
  >
    <div class="portfolio-layout">
      <main-title>{{ model.name }}</main-title>

      <horizontal-filter class="administrator-portfolio__filter-panel">
        <filter-item
          :path="`/administrator/portfolio/${route.params.id}/details`"
          text="О портфеле"
        />
        <filter-item
          :path="`/administrator/portfolio/${route.params.id}/projects`"
          text="Проекты"
        />
        <filter-item
          :path="`/administrator/portfolio/${route.params.id}/indexes`"
          text="Показатели"
        />

        <template
          #right
          v-if="route.name === 'administrator-portfolio-projects'"
        >
          <switch-view-buttons />
        </template>
      </horizontal-filter>

      <div class="administrator-portfolio__content">
        <portfolio-details
          v-if="route.name === 'administrator-portfolio-details'"
          :portfolio="model.portfolio"
          @update="onPortfolioUpdate"
        />
        <portfolio-projects
          v-if="route.name === 'administrator-portfolio-projects'"
        />
        <portfolio-indexes
          v-if="route.name === 'administrator-portfolio-indexes'"
        />
      </div>
    </div>
  </a-spin>
</template>

<script setup>
  import { onMounted, reactive, ref } from 'vue';
  import { useRoute } from 'vue-router';
  import { useApi } from '@/api/use-api';
  import HorizontalFilter from '@/components/filter/horizontal/HorizontalFilter.vue';
  import FilterItem from '@/components/filter/components/FilterItem.vue';
  import MainTitle from '@/components/titles/MainTitle.vue';
  import SwitchViewButtons from '@/components/switch-view/SwitchViewButtons.vue';
  import PortfolioDetails from '@/components/projects/portfolio/PortfolioDetails.vue';
  import PortfolioProjects from '@/components/projects/portfolio/PortfolioProjects.vue';
  import PortfolioIndexes from '@/components/projects/portfolio/PortfolioIndexes.vue';

  const api = useApi();
  const route = useRoute();
  const isLoading = ref(false);

  const model = reactive({
    portfolio: null,
    name: '',
  });

  onMounted(() => {
    getPortfolio();
  });

  const getPortfolio = async () => {
    try {
      isLoading.value = true;
      const response = await api.briefcase.view(route.params.id);
      model.portfolio = response.briefcase;
      model.name = response.briefcase.name;
    } catch (error) {
      console.error('error', error);
    } finally {
      isLoading.value = false;
    }
  };

  const onPortfolioUpdate = async () => {
    await getPortfolio();
  };
</script>

<style lang="scss" scoped>
  .portfolio-layout {
    padding-bottom: 100px;
  }
</style>
