<template>
  <div class="image-side">
    <div class="image-side__cover"></div>
    <div class="image-side__content">
      <div class="image-side__header">
        <a href="/" class="logo">
          <img src="/images/logo-white.svg" alt="" />
        </a>
      </div>
      <div class="image-side__tags">
        <tags-list :tags="tags" />
      </div>
    </div>
  </div>
</template>

<script setup>
import TagsList from "@/components/tags/TagsList";

const tags = [
  "technologies",
  "innovations",
  "biomedicine",
  "nanotechnology",
  "it",
  "the arctic regions",
  "mathematics modeling",
  "archeology",
  "geophysics",
  "deep learning",
  "quantum technologies",
  "semiotics",
  "geology",
  "microelectronics",
  "photonics",
  "engineering",
  "catalytic materials",
  "energy conservation",
  "biotechnology",
  "high energies",
  "drug design",
  "bioinformatics",
  "geochemistry",
  "global priority",
];
</script>

<style lang="scss">
.image-side {
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  top: 0;

  .image-side__cover {
    background:
      linear-gradient(
        to bottom right,
        rgba(16, 20, 38, 1),
        rgba(16, 20, 38, 0.55)
      ),
      url("assets/side-cover.jpg") center/cover no-repeat;
    bottom: 0;
    left: 0;
    right: -20px;
    position: absolute;
    top: 0;
    z-index: 0;
  }

  .image-side__content {
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-between;
    padding: 40px 40px 60px 40px;
    position: relative;
    z-index: 10;

    .logo {
      display: block;

      img {
        height: 44px;
        width: 150px;
      }
    }
  }
}
</style>
