<template>
  <div class="project-card">
    <div>
      <a-row class="project-card__meta">
        <a-col class="project-card__name">
          {{ project.name }}
        </a-col>
      </a-row>

      <a-row class="project-card__info">
        <div><span>Тип проекта: </span>{{ project.type }}</div>
        <div class="overflowed-text">
          <span>О проекте: </span>{{ project.projectInfo }}
        </div>
        <span>Руководитель проекта</span>
        <project-person
          :avatar="getAvatarUrl(project.leaderAvatarUid)"
          :name="project.leaderName"
          :email="project.leaderEmail"
        />
      </a-row>
    </div>

    <a-row class="project-card__statuses">
      <div
        class="project-card__status"
        v-for="(status, index) in getPublicStatuses(project)"
        :key="++index"
      >
        <status-label :status="status" />
      </div>
    </a-row>
  </div>
</template>

<script setup>
  import ProjectPerson from './ProjectPerson.vue';
  import StatusLabel from '@/components/projects/components/StatusLabel.vue';
  import { getPublicStatuses } from '@/api/models/ProjectShowcaseStatus';

  defineProps({
    project: Object,
  });

  const getAvatarUrl = (avatar) =>
    avatar ? `/api/v1/attachment/${avatar}` : null;
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  .project-card {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: $color-bg-secondary-grey;
    padding: 16px 16px 20px 20px;
    height: 476px;
    overflow-y: auto;

    &:hover {
      box-shadow: 0 6px 15px 0 rgba(1, 1, 1, 0.15);
    }

    .project-card__meta {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;

      .project-card__name {
        color: $color-text-primary;
        font-family: 'Nunito Sans', sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
      }
    }

    .project-card__info {
      color: $color-bg-status-new;
      display: flex;
      flex-direction: column;
      font-family: 'Nunito Sans', sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      row-gap: 18px;

      span {
        font-weight: 700;
      }

      .overflowed-text {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 6;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .project-card__statuses {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      .project-card__status {
        span {
          border-radius: 7px;
        }
      }
    }
  }
</style>
