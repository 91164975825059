<template>
  <partner-layout>
    <template #default>
      <main-title>Мои проекты</main-title>
      <horizontal-filter>
        <template #default>
          <filter-item path="/partner/requests" text="Запросы" />
          <filter-item path="/partner/projects" text="Мои проекты" />
        </template>
        <template #right>
          <switch-view-buttons />
        </template>
      </horizontal-filter>
      <template v-if="route.path === '/partner/requests'">
        <template v-if="typeView === 'cards'">
          <requests-cards :requests="requests" />
        </template>
        <template v-else>
          <requests-table :requests="requests" />
        </template>
      </template>

      <template v-if="route.path === '/partner/projects'">
        <partner-projects />
      </template>
    </template>
  </partner-layout>
</template>

<script setup>
  import { onMounted, ref, watch } from 'vue';
  import { useRoute } from 'vue-router';
  import { useApi } from '@/api/use-api';
  import PartnerLayout from '@/layouts/PartnerLayout';
  import MainTitle from '@/components/titles/MainTitle';
  import HorizontalFilter from '@/components/filter/horizontal/HorizontalFilter';
  import FilterItem from '@/components/filter/components/FilterItem';
  import SwitchViewButtons from '@/components/switch-view/SwitchViewButtons';
  import RequestsCards from '@/components/requests/RequestsCards';
  import RequestsTable from '@/components/requests/RequestsTable';
  import PartnerProjects from './PartnerProjects.vue';

  const route = useRoute();
  const api = useApi();
  const typeView = ref('cards');
  const requests = ref([]);

  onMounted(() => {
    getRequests();
  });

  watch(
    () => route.hash,
    (newHash) => {
      const hash = newHash.replace('#', '');
      typeView.value = hash ? hash : 'cards';
    }
  );

  async function getRequests() {
    try {
      const response = await api.mainPage.index();
      requests.value = response.requests;
    } catch (error) {
      console.error('error', error);
    }
  }
</script>

<style lang="scss" scoped></style>
