<template>
  <div class="requests">
    <div v-for="request in requests" :key="request.id" class="requests__item">
      <request-card
        :request="request"
        @click="
          router.push({
            path: `/partner/requests/${request.id}`,
          })
        "
      />
    </div>
  </div>
</template>

<script setup>
  import { useRouter } from 'vue-router';
  import RequestCard from '@/components/requests/components/RequestCard';

  defineProps({
    requests: Array,
  });

  const router = useRouter();
</script>

<style lang="scss" scoped>
  .requests {
    display: flex;
    margin: 0 -10px;
    flex-wrap: wrap;
    padding: 0 0 100px 0;

    .requests__item {
      flex: 0 0 50%;
      padding: 10px;
    }
  }
</style>
