<template>
  <table class="requests-table">
    <thead class="requests-table__header">
      <tr v-if="inwork">
        <th class="col1"><span>Номер</span></th>
        <th class="col2"><span>Дата</span></th>
        <th class="col3"><span>Партнер</span></th>
        <th class="col4"><span>Тип запроса</span></th>
        <th class="col5">
          <span>Руководитель проекта</span>
        </th>
        <th class="col6" style="width: 5%"><span>Статус</span></th>
      </tr>
      <tr v-else>
        <th class="col1"><span>Номер</span></th>
        <th class="col2"><span>Дата</span></th>
        <th class="col3"><span>Партнер</span></th>
        <th class="col4"><span>Тип запроса</span></th>
        <th class="col6" style="width: 5%"><span>Статус</span></th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="request in sortedRequests"
        :key="request.id"
        class="requests-table__item"
        @click="
          router.push({
            path: `/administrator/requests/${request.id}`,
          })
        "
      >
        <td class="request-item__number-col">
          <span>{{ request.id }}</span>
        </td>
        <td>
          <span>{{
            dayjs(request.requestDate).locale('ru').format('DD.MM.YYYY')
          }}</span>
          <span class="request-item__sup-text">
            {{ request.requestTime }}
          </span>
        </td>
        <td>
          <span>{{ request.customerName }}</span>
          <span class="request-item__sup-text">
            ИНН: {{ request.customerInn }}
          </span>
        </td>
        <td>{{ request.typeDescription }}</td>
        <td v-if="inwork">Руководитель</td>
        <td v-if="inwork && request.manager">
          <span>{{ request.manager.fullName }}</span>
          <span class="request-item__sup-text">
            {{ request.manager.email }}
          </span>
        </td>
        <td v-if="inwork && request.timeLeft">
          <div class="request-item__timeleft">
            <span>Осталось времени: </span>
            <img src="../../../../assets/icons/error.svg" alt="" />
          </div>
          <span class="request-item__sup-text">
            {{ calcTimeLeft(request.timeLeft) }}
          </span>
        </td>
        <td>
          <status-label :status="request.status" />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script setup>
  import { useRouter } from 'vue-router';
  import { computed } from 'vue';
  import dayjs from 'dayjs';
  import StatusLabel from '@/components/projects/components/StatusLabel.vue';
  import relativeTime from 'dayjs/plugin/relativeTime';

  dayjs.extend(relativeTime);
  const props = defineProps({
    requests: Array,
    inwork: Boolean,
  });
  const router = useRouter();

  const calcTimeLeft = (endDate) => {
    const now = dayjs();
    const diff = dayjs(endDate).diff(now, 'day');
    const cases = [2, 0, 1, 1, 1, 2];
    const forms = ['день', 'дня', 'дней'];
    return (
      diff +
      ' ' +
      forms[
        diff % 100 > 4 && diff % 100 < 20 ? 2 : cases[Math.min(diff % 10, 5)]
      ]
    );
  };

  const sortedRequests = computed(() => {
    if (!props.requests) return [];

    let initRequests = props.requests;
    initRequests = initRequests.sort(
      (a, b) => dayjs(b.startDate).unix() - dayjs(a.startDate).unix()
    );
    return initRequests;
  });
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/colors';
  $color-text-secondary-grey-216: #718096;

  .requests-table {
    border-collapse: separate;
    border-spacing: 0 15px;
    padding-top: 30px;
    width: 100%;

    th[class*='col'] {
      span {
        border-bottom: 1px solid #f2f4f8;
        display: block;
        padding-bottom: 10px;
        width: 100%;
      }
    }

    :is(th, td):first-child {
      padding-left: 20px;
    }

    td:last-child {
      padding-right: 20px;
      text-align: right;
    }

    .requests-table__header {
      border-bottom: 1px solid $color-bg-secondary-grey;
      color: $color-text-secondary-grey-216;
      font-variant-numeric: slashed-zero;
      font-family: Nunito Sans, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 10px;
      text-align: left;
    }

    .requests-table__item {
      cursor: pointer;
      background: $color-bg-secondary-grey;
      font: 400 14px/20px Nunito Sans;
      height: 88px;
      width: 100%;

      .request-item__number-col {
        font: 700 14px/20px Nunito Sans !important;
      }

      .request-item__sup-text {
        color: $color-text-secondary-grey-216;
        display: flex;
        margin-top: 10px;
      }

      .request-item__timeleft {
        align-items: self-end;
        display: flex;

        img {
          filter: invert(40%) sepia(72%) saturate(1854%) hue-rotate(326deg)
            brightness(101%) contrast(93%);
        }
      }
    }
  }
</style>
