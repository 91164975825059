<template>
  <partner-layout>
    <a-col class="request-form">
      <request-info :request="model.request" />
      <request-main-form :request="model.request" />
    </a-col>
  </partner-layout>
</template>
<script setup>
  import { onMounted, reactive } from 'vue';
  import { useRoute } from 'vue-router';
  import { useApi } from '@/api/use-api';
  import PartnerLayout from '@/layouts/PartnerLayout';
  import RequestInfo from "@/components/requests/components/RequestInfo.vue";
  import RequestMainForm from "@/components/requests/RequestMainForm.vue";

  const api = useApi();
  const route = useRoute();

  const model = reactive({
    type: null,
    request: null,
    requestStatusId: null,
  });

  onMounted(async () => {
    await getModel();
  });

  async function getModel() {
    try {
      const response = route.name === 'new-partner-request'
          ? await api.requestPage.new(route.params.type)
          : await api.requestPage.partnerView(route.params.id)
      model.request = response.request;
      model.requestStatusId = response.request.requestStatusId;
    } catch (error) {
      console.error('error', error);
    }
  }
</script>

<style lang="scss" scoped>
  * {
    font-family: 'Nunito Sans', sans-serif;
    margin: 0;
  }

  .request-form {
    align-content: flex-start;
    height: 100%;
    overflow-y: auto;
    padding-bottom: 100px;

    .request-form__form-block {
      margin-top: 40px;
    }

    .ant-divider {
      margin: 30px 0;
    }
  }
</style>
