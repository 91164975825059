<template>
  <a-row class="horizontal-filter">
    <a-col>
      <a-row class="horizontal-filter__items">
        <slot></slot>
      </a-row>
    </a-col>
    <a-col class="horizontal-filter__right">
      <div class="horizontal-filter__items">
        <slot name="right"></slot>
      </div>
    </a-col>
  </a-row>
</template>

<script setup></script>

<style lang="scss" scoped>
  @import '@/assets/styles/colors';

  .horizontal-filter {
    margin: 30px -40px 0 -20px;
    padding: 8px 40px 8px 20px;
    border-top: 1px solid $color-bg-secondary-grey;
    border-bottom: 1px solid $color-bg-secondary-grey;

    .horizontal-filter__right {
      align-self: center;
      font: 700 14px/20px 'Nunito Sans';
      margin-left: auto;
    }

    .horizontal-filter__items {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 16px;
    }
  }
</style>
