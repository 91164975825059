export default {
    description: [
        {
            required: true,
            min: 2,
            message: 'Необходимо ввести краткое описание проекта',
            trigger: 'blur',
        },
    ],
    projectGoal: [
        {
            required: true,
            min: 2,
            message: 'Необходимо ввести цель проекта',
            trigger: 'blur',
        },
    ],
    projectObjectives: [
        {
            required: true,
            min: 2,
            message: 'Необходимо ввести задачи проекта',
            trigger: 'blur',
        },
    ],
    result: [
        {
            required: true,
            min: 2,
            message: 'Необходимо ввести планируемый результат проекта',
            trigger: 'blur',
        },
    ],
};
