import LeaderProjectForm from '@/views/leader/projects/form/LeaderProjectForm.vue';
import LeaderRequests from '@/views/leader/requests/LeaderRequests.vue';
import LeaderProjectManagement from '@/views/leader/projects/management/LeaderProjectManagement.vue';
import LeaderProjectTeam from '@/views/leader/projects/team/LeaderProjectTeam.vue';
import LeaderRequestViewForm from '@/views/leader/requests/LeaderRequestViewForm.vue';
import LeaderProjectReport from '@/views/leader/projects/report/LeaderProjectReport.vue';
import LeaderProjectAgreement from '@/views/leader/projects/agreement/LeaderProjectAgreement.vue';
import LeaderProjects from '@/views/leader/projects/form/LeaderProjects.vue';
import LeaderProjectIndexes from '@/views/leader/projects/indexes/LeaderProjectIndexes.vue';
import LeaderPortfolios from '@/views/leader/portfolios/LeaderPortfolios.vue';
import LeaderPortfolioLayout from '@/views/leader/portfolios/LeaderPortfolioLayout.vue';
import { USER_ROLE_LEADER } from '@/api/models/UserRole';
import LeaderProjectShowcase from '@/views/leader/showcase/LeaderProjectShowcase.vue';

const role = USER_ROLE_LEADER;

export const projectManagerRoutes = [
  {
    path: '/leader/requests',
    name: 'project-manager-requests',
    component: LeaderRequests,
    meta: {
      requiresAuth: true,
      type: 'request',
      role: role,
    },
  },
  {
    path: '/leader/requests/:id',
    name: 'leader-request-view-form',
    component: LeaderRequestViewForm,
    meta: { requiresAuth: true, type: 'request', role: role },
  },
  {
    path: '/leader/projects',
    name: 'project-manager-projects',
    hash: '#inwork',
    component: LeaderProjects,
    meta: { requiresAuth: true, type: 'projects', role: role, default: true },
  },
  {
    path: '/leader/project/:id/form',
    name: 'leader-project-form',
    component: LeaderProjectForm,
    meta: { requiresAuth: true, type: 'project', role: role },
  },
  {
    path: '/leader/project/:id/management',
    name: 'leader-project-management',
    component: LeaderProjectManagement,
    meta: { requiresAuth: true, type: 'project', role: role },
  },
  {
    path: '/leader/project/:id/team',
    name: 'leader-project-team',
    component: LeaderProjectTeam,
    meta: { requiresAuth: true, type: 'project', role: role },
  },
  {
    path: '/leader/project/:id/showcase',
    name: 'leader-project-showcase',
    component: LeaderProjectShowcase,
    meta: { requiresAuth: true, type: 'project', role: role },
  },
  {
    path: '/leader/project/:id/indexes',
    name: 'leader-project-indexes',
    component: LeaderProjectIndexes,
    meta: { requiresAuth: true, type: 'project', role: role },
  },
  {
    path: '/leader/project/:id/report',
    name: 'leader-project-report',
    component: LeaderProjectReport,
    meta: { requiresAuth: true, type: 'project', role: role },
  },
  {
    path: '/leader/project/:id/agreement',
    name: 'leader-project-agreement',
    component: LeaderProjectAgreement,
    meta: { requiresAuth: true, type: 'project', role: role },
  },
  {
    path: '/leader/portfolios',
    name: 'leader-portfolios',
    component: LeaderPortfolios,
    meta: { requiresAuth: true, type: 'portfolios', role: role },
  },
  {
    path: '/leader/portfolio/:id/details',
    name: 'leader-portfolio-details',
    component: LeaderPortfolioLayout,
    meta: {
      requiresAuth: true,
      type: 'portfolios',
      filterType: 'details',
      role: role,
    },
  },
  {
    path: '/leader/portfolio/:id/projects',
    name: 'leader-portfolio-projects',
    component: LeaderPortfolioLayout,
    meta: {
      requiresAuth: true,
      type: 'portfolios',
      filterType: 'projects',
      role: role,
    },
  },
  {
    path: '/leader/portfolio/:id/indexes',
    name: 'leader-portfolio-indexes',
    component: LeaderPortfolioLayout,
    meta: {
      requiresAuth: true,
      type: 'portfolios',
      filterType: 'indexes',
      role: role,
    },
  },
];
