<template>
  <a-collapse
      v-model:activeKey="activeKey"
      expand-icon-position="end"
      ghost
      accordion
  >
    <template #expandIcon="{ isActive }">
      <collapse-arrow :is-active="isActive" />
    </template>
    <a-collapse-panel
        v-for="(item, index) in items"
        :key="index"
        :header="item.title"
    >
      <white-block class="p-30 round" v-html="item.text" />
    </a-collapse-panel>
  </a-collapse>
</template>

<script setup>
import {defineProps, ref} from 'vue';
import WhiteBlock from "@/views/portal/components/WhiteBlock.vue";
import CollapseArrow from "@/views/portal/components/UI/CollapseArrow.vue";

const activeKey = ref('');

defineProps({
  items: {
    type: Array,
    required: true
  }
});
</script>

<style lang="scss" scoped>
.ant-collapse {
  & > .ant-collapse-item {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    :deep(.ant-collapse-header) {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: nowrap;
      padding: 22px 30px;
      background: #ffffff;
      border-radius: 6px;
      color: #101426;
      font-family: "Nunito Sans", sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 1.5;
    }

    :deep(.ant-collapse-content-box) {
      padding: 5px 0 0 0;
    }

    :deep(.ant-collapse-content-box),
    :deep(.ant-collapse-content-box) * {
      color: #2D3346;
      font-family: "Nunito Sans", sans-serif;
      font-size: 16px;
      line-height: 1.5;
      font-style: normal;
      font-weight: 300;
    }

    :deep(.ant-collapse-content-box) b,
    :deep(.ant-collapse-content-box) strong {
      font-weight: 700;
    }

    :deep(.ant-collapse-content-box) i {
      font-style: italic;
    }
  }
}
</style>