<template>
  <div class="partner-project">
    <leader-project-title
      class="partner-project__title"
      :title="model.name"
      :status="model.status"
    />
    <partner-project-info
      class="partner-project__info"
      :type="model.type"
      :number="model.number"
      :requestId="model.requestId"
    />

    <slot name="underInfo"></slot>

    <horizontal-filter class="partner-project__filter-panel">
      <filter-item
        :path="`/partner/project/${model?.id}/form`"
        :hash="getFormStepHash(model.id)"
        text="Карточка проекта"
      />
      <filter-item
        :path="`/partner/project/${model.id}/management`"
        text="Управление проектом"
      />
      <filter-item
        v-if="showReportTab"
        :path="`/partner/project/${model?.id}/report`"
        text="Отчет"
      />
      <filter-item
        v-if="showCloseTab"
        :path="`/partner/project/${model?.id}/agreement`"
        text="Закрыть проект"
      />
    </horizontal-filter>

    <div class="partner-project__content">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
  import { useRoute } from 'vue-router';
  import LeaderProjectTitle from '@/views/leader/projects/components/LeaderProjectTitle.vue';
  import PartnerProjectInfo from '@/views/partner-projects/components/PartnerProjectInfo.vue';
  import HorizontalFilter from '@/components/filter/horizontal/HorizontalFilter.vue';
  import FilterItem from '@/components/filter/components/FilterItem.vue';
  import {
    PROJECT_STATUS_ACCEPTANCE,
    PROJECT_STATUS_CLOSED,
  } from '@/api/models/ProjectStatus';
  import { computed } from 'vue';

  const route = useRoute();

  const props = defineProps({
    model: {
      type: Object,
      require: true,
    },
  });

  const showReportTab = computed(() => {
    return (
      props.model.projectStatusId === PROJECT_STATUS_ACCEPTANCE ||
      props.model.projectStatusId === PROJECT_STATUS_CLOSED
    );
  });

  const showCloseTab = computed(() => {
    return (
      props.model.projectStatusId === PROJECT_STATUS_ACCEPTANCE ||
      props.model.projectStatusId === PROJECT_STATUS_CLOSED ||
      props.model.approval?.status.type === 'rejected'
    );
  });

  const getFormStepHash = (id) => {
    if (route.path !== '/partner/project/' + id + '/form') {
      return '#1';
    }
    return route.hash;
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  .partner-project {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 100px;

    .partner-project__info {
      align-items: center;
      margin-top: 40px;
    }

    .partner-project__filter-panel {
      margin-top: 40px;
      padding: 8px 40px 8px 20px;
      border-top: 1px solid $color-bg-secondary-grey;
      border-bottom: 1px solid $color-bg-secondary-grey;
    }

    .partner-project__content {
      padding: 30px 0;
    }
  }
</style>
