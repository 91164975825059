export default {
    startDate: [
        {
            type: 'date',
            required: true,
            message: 'Необходимо ввести дату старта проекта',
            trigger: ['change', 'blur'],
        },
    ],
    endDate: [
        {
            type: 'date',
            required: true,
            message: 'Необходимо ввести дату завершения проекта',
            trigger: ['change', 'blur'],
        },
    ],
};
