import { read } from '@/api/rest';

const performerPage = {
  executorWithTasks: async (projectId) =>
    await read('/performer-page/executor-with-tasks?projectId=' + projectId),
  checkpoints: async (options) =>
    await read('/performer-page/checkpoints?' + options.toString()),
  project: async (id) => await read('/performer-page/project/' + id),
  projects: async () => await read('/performer-page/projects'),
  projectSteps: async (id) =>
    await read('/performer-page/project-steps?id=' + id),
};

export default performerPage;
