<template>
  <h3>Шаг 4. Команда проекта</h3>
  <p>
    Проверьте информацию в карточке проекта и, если согласны, согласуйте проект.
  </p>
  <div>
    <a-form class="form partner-project-form" layout="vertical">
      <template v-for="(user, index) in users" :key="`users-` + index">
        <partner-project-form-row
          v-if="user.isAvailable"
          :show-available="false"
          class="bordered"
        >
          <a-form-item label="ФИО сотрудника">
            <a-input
              v-model:value="user.fio"
              placeholder="Укажите ФИО сотрудника"
              disabled
            />
          </a-form-item>
          <a-row>
            <a-col :span="12">
              <a-form-item label="Должность">
                <a-input
                  v-model:value="user.position"
                  placeholder="Выберите должность"
                  disabled
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="Подразделение">
                <a-input
                  v-model:value="user.unit"
                  placeholder="Выберите подразделение"
                  disabled
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="Нагрузка">
                <a-input
                  v-model:value="user.workload"
                  placeholder="Укажите нагрузку"
                  disabled
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="Роль в проекте">
                <a-input
                  v-model:value="user.projectRole"
                  placeholder="Укажите роль"
                  disabled
                />
              </a-form-item>
            </a-col>
          </a-row>
        </partner-project-form-row>
      </template>
    </a-form>
  </div>
</template>

<script setup>
  import { onMounted, reactive, watch } from 'vue';
  import PartnerProjectFormRow from '../PartnerProjectFormRow.vue';

  const props = defineProps({
    project: {
      type: Object,
      require: true,
    },
  });

  let users = reactive([]);

  const model = reactive({
    id: null,
  });

  onMounted(() => {
    props.project && fillModel();
  });

  watch(() => props.project, () => { fillModel(); });

  const fillModel = () => {
    try {
      model.id = props.project.id;

      let projectMembers = props.project.members;
      if (projectMembers.length) {
        for (let i = 0; i < projectMembers.length; i++) {
          users.push({
            id: projectMembers[i].id,
            workload: projectMembers[i].workload,
            projectRole: projectMembers[i].projectRole,
            fio: projectMembers[i].fio,
            unit: projectMembers[i].unit,
            position: projectMembers[i].position,
            isAvailable: projectMembers[i].isAvailable,
          });
        }
      } else {
        addUser();
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  const addUser = () => {
    users.push({
      id: null,
      workload: '',
      projectRole: '',
      fio: '',
      unit: '',
      position: '',
    });
  };
</script>

<style lang="scss" scoped>
  @import './partner-project-form';
</style>
