import {getToken, useKeycloak} from "@josempgon/vue-keycloak";
import Field from "@/storage/local/Field";
import {ref, watch} from "vue";

const innerToken = new Field('access_token');
const isInnerAuthenticated = () => null !== innerToken.get();
const {isAuthenticated: isKeycloakAuthenticated, isPending: isKeycloakPending} = useKeycloak();
const isAuthenticated = ref(isInnerAuthenticated() || isKeycloakAuthenticated.value);
const isPending = ref(undefined);

watch(
  isKeycloakAuthenticated,
  (newValue) => {
    isAuthenticated.value = isInnerAuthenticated() || newValue;
  }
);

watch(
  isKeycloakPending,
  (newValue) => {
    isPending.value = newValue;
  }
);

const getAccessToken = async () => innerToken.get() || await getToken();
const useAuthenticated = () => isAuthenticated;
const usePending = () => isPending;

const innerSignIn = (token) => {
  innerToken.set(token);
  isAuthenticated.value = isInnerAuthenticated() || isKeycloakAuthenticated.value;
};

/**
 * @param callback Inner sign out callback
 * @returns {Promise<void>}
 */
const innerSignOut = async (callback) => {
  await callback();
  innerToken.remove();
  isAuthenticated.value = isInnerAuthenticated() || isKeycloakAuthenticated.value;
};

const signOut = async (callback = async () => {}) => {
  if (isInnerAuthenticated()) {
    await innerSignOut(callback);
  } else if (isKeycloakAuthenticated.value) {
    const {keycloak} = useKeycloak();
    await keycloak.logout();
  }
};

export {getAccessToken, useAuthenticated, usePending, innerSignIn, signOut};