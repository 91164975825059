<template>
  <project-manager-layout :spinning="isLoading">
    <leader-project-layout :model="model">
      <template #underInfo>
        <div class="leader-project__common-progress" v-if="checkpoints.length">
          <progress-bar :items="checkpoints" showPercent />
        </div>
      </template>
      <div class="leader-project-agreement" v-if="projectAgreementIsVisible">
        <div v-if="showRejectedApprovals" class="rejected_reasons">
          <h3>Закрытие было отклонено</h3>
          <a-collapse
            v-model:activeKey="activeKey"
            :accordion="false"
            :bordered="false"
            style="background: rgb(255, 255, 255)"
          >
            <a-collapse-panel key="1" header="Посмотреть причины">
              <template
                v-for="(approval, index) in getRejectedApprovals()"
                :key="`approval-${index}`"
              >
                <agreement-row
                  class="agreement-row"
                  :status="approval.status"
                  :reason="approval.rejectedReason"
                  :action="() => handleApproval(approval, index)"
                  :action-button-text="'Согласовать'"
                  :loading="approvalLoaders[index]"
                >
                  <template #icon>
                    <img
                      v-if="approval.role === USER_ROLE_PARTNER"
                      src="@/assets/icons/icon-person.svg"
                      alt=""
                    />
                    <img v-else src="@/assets/icons/icon-bag.svg" alt="" />
                  </template>

                  <template #labelText>
                    <div v-if="approval.role === USER_ROLE_PARTNER">
                      Партнер отправил проект на доработку
                    </div>
                    <div v-else>
                      Администратор отправил проект на доработку<br />
                      Портфель: {{ approval.briefcaseName }}
                    </div>
                  </template>
                </agreement-row>
              </template>
            </a-collapse-panel>
          </a-collapse>
        </div>

        <template v-if="!reportIsClosed">
          <a-form
            class="form"
            :model="report"
            :rules="rules"
            @validate="errors.onValidate"
            @finish="onFinish"
          >
            <h3>Закрыть проект</h3>
            <div class="description">
              Выберите основание для закрытия проекта и напишите комментарий при
              необходимости. Обратите внимание, что для закрытия проекта
              необходимо предварительно заполнить отчет. После закрытия проекта
              Вы не сможете редактировать отчет, и он станет доступен партнеру и
              администратору. Пока проект открыт, Вы можете вносить изменения.
            </div>
            <a-form-item
              name="closeReason"
              :help="errors.getError('closeReason')"
              :validate-status="errors.getStatus('closeReason')"
            >
              <slot>
                <h4>Основание для закрытия проекта *</h4>
              </slot>
              <a-select
                v-model:value="report.closeReason"
                placeholder="Выберите основание"
                :options="closeReasonList"
                :disabled="closeReasonList.length === 0"
                @select="onReasonSelect"
              />
            </a-form-item>
            <a-form-item class="comment">
              <slot>
                <h4>Комментарий</h4>
              </slot>
              <a-textarea
                v-model:value="report.closeComment"
                placeholder="Напишите комментарий при необходимости"
                :autoSize="{ minRows: 8, maxRows: 8 }"
                style="padding: 10px"
              />
            </a-form-item>

            <a-form-item class="actions">
              <c-button-primary
                :disabled="reportIsClosed || closeReasonList.length === 0"
                :loading="isLoading"
                html-type="submit"
              >
                Закрыть проект
              </c-button-primary>
            </a-form-item>
          </a-form>
        </template>

        <template v-else>
          <h3>Согласование</h3>

          <div class="description">
            <p>
              Согласуйте завершение проекта и отчет. Вы можете отправить на
              согласование одновременно администратору и партнеру или выбрать
              нужную Вам очередность согласования.
            </p>
            <p>
              <span>Основание для закрытия:&nbsp;</span>{{ report.closeReason }}
            </p>
            <p><span>Комментарий:&nbsp;</span>{{ report.closeComment }}</p>
          </div>

          <div class="project-agreement-grid">
            <template
              v-for="(approval, index) in model.approvals"
              :key="`approval-${index}`"
            >
              <agreement-row
                class="agreement-row"
                :status="approval.status"
                :reason="approval.rejectedReason"
                :action="() => handleApproval(approval, index)"
                :reapprove="() => reApprove(approval, index)"
                :action-button-text="'Согласовать'"
                :loading="approvalLoaders[index]"
                :error="approvalErrors[index].getError('approval')"
              >
                <template #icon>
                  <img
                    v-if="approval.role === USER_ROLE_PARTNER"
                    src="@/assets/icons/icon-person.svg"
                    alt=""
                  />
                  <img v-else src="@/assets/icons/icon-bag.svg" alt="" />
                </template>

                <template #labelText>
                  <div v-if="approval.status?.type === 'rejected'">
                    <div v-if="approval.role === USER_ROLE_PARTNER">
                      Партнер отправил проект на доработку
                    </div>
                    <div v-else>
                      Администратор отправил проект на доработку<br />
                      Портфель: {{ approval.briefcaseName }}
                    </div>
                  </div>
                  <div v-else>
                    {{ getActionText(approval) }}
                  </div>
                </template>
              </agreement-row>
            </template>
          </div>
        </template>
      </div>
    </leader-project-layout>
  </project-manager-layout>
</template>

<script setup>
  import { computed, onMounted, reactive, ref } from 'vue';
  import { useRoute } from 'vue-router';
  import { useApi } from '@/api/use-api';
  import ProjectManagerLayout from '@/layouts/ProjectManagerLayout.vue';
  import LeaderProjectLayout from '@/views/leader/projects/components/LeaderProjectLayout.vue';
  import ProgressBar from '@/components/projects/management/ProgressBar';
  import CButtonPrimary from '@/components/UI/CButtonPrimary';
  import ErrorsHandler from '@/components/form/errors-handler';
  import AgreementRow from '@/components/projects/agreement/AgreementRow';
  import { useCheckpoints, useTasksInWork } from '@/composables/taskCount';
  import {
    REASON_DONE,
    REASON_NOT_DONE_FROM_CONTRACTOR,
    REASON_NOT_DONE_FROM_PARTNER,
  } from '@/api/models/ProjectCloseReason';
  import { USER_ROLE_LEADER, USER_ROLE_PARTNER } from '@/api/models/UserRole';
  import {
    PROJECT_STATUS_ACCEPTANCE,
    PROJECT_STATUS_AGREEMENT,
    PROJECT_STATUS_CLOSED,
    PROJECT_STATUS_DRAFT,
    PROJECT_STATUS_IN_WORK,
  } from '@/api/models/ProjectStatus';

  const api = useApi();
  const route = useRoute();
  const approvalErrors = ref([]);
  const approvalLoaders = ref([]);
  const activeKey = ref(null);

  const model = reactive({
    id: null,
    isExternal: true,
    isMyProject: false,
    number: '',
    name: '',
    projectType: '',
    requestId: null,
    status: null,
    projectStatusId: null,
    approvals: [],
    isAllTasksCompleted: false,
    allIndexesCompleted: false,
  });

  const getActionText = (approval) => {
    return approval.role === USER_ROLE_PARTNER
      ? 'Согласовать завершение проекта с партнером'
      : `Согласовать завершение проекта с администратором порфеля: ${approval.briefcaseName}`;
  };

  const getRejectedApprovals = () => {
    return model.approvals.filter((item) => item.status?.type === 'rejected');
  };

  const closeReasonList = computed(() => {
    const reasons = [
      { value: REASON_DONE, label: 'Проект успешно выполнен' },
      {
        value: REASON_NOT_DONE_FROM_CONTRACTOR,
        label: 'Проект не выполнен, закрыт исполнителем',
      },
      {
        value: REASON_NOT_DONE_FROM_PARTNER,
        label: 'Проект не выполнен, закрыт заказчиком',
      },
    ];
    return model.isAllTasksCompleted && model.allIndexesCompleted
      ? reasons
      : reasons.slice(1);
  });
  const projectAgreementIsVisible = computed(
    () =>
      ![PROJECT_STATUS_DRAFT, PROJECT_STATUS_AGREEMENT].includes(
        model.projectStatusId
      )
  );
  const reportIsClosed = computed(
    () =>
      model.projectStatusId === PROJECT_STATUS_ACCEPTANCE ||
      model.projectStatusId === PROJECT_STATUS_CLOSED
  );
  const showRejectedApprovals = computed(
    () =>
      model.projectStatusId === PROJECT_STATUS_IN_WORK &&
      model.approvals.some((item) => item.status?.type === 'rejected')
  );

  const report = reactive({
    closeReasonId: null,
    closeReason: null,
    closeComment: null,
  });
  const checkpoints = ref([]);

  const rules = {
    closeReason: [
      {
        required: true,
        message: 'Необходимо указать основание для закрытия проекта',
        trigger: 'change',
      },
    ],
  };

  const errors = new ErrorsHandler(
    {
      closeReason: '',
    },
    (fieldName) => {
      if ('role' === fieldName) {
        return 'closeReason';
      }
      return fieldName;
    }
  );
  const isLoading = ref(true);

  onMounted(async () => {
    await getModel();
    await getCheckpoints();
    await getReport();
    isLoading.value = false;
  });

  const getModel = async () => {
    try {
      const response = await api.leaderPage.getProjectToClose(route.params.id);
      model.name = response.project.name;
      model.isExternal = response.project.isExternal;
      model.isMyProject = response.project.isMyProject;
      model.number = response.project.id;
      model.id = response.project.id;
      model.type = response.project.type;
      model.requestId = response.project.requestId;
      model.status = response.project.status;
      model.projectStatusId = response.project.projectStatusId;
      model.approvals = response.project.approvals;
      model.allIndexesCompleted = response.project.allIndexesCompleted;

      approvalErrors.value = model.approvals.map(() => {
        return new ErrorsHandler({ approval: '' });
      });
      approvalLoaders.value = model.approvals.map(() => false);
    } catch (error) {
      console.error('error', error);
    }
  };

  const getReport = async () => {
    try {
      const response = await api.projectReport.view(route.params.id);
      report.closeReasonId = response.report.closeReasonId;
      report.closeReason = response.report.closeReason;
      report.closeComment = response.report.closeComment;
    } catch (error) {
      console.error('error', error);
    }
  };

  const getCheckpoints = async () => {
    try {
      const response = await api.projectManagementPage.tasks(
        new URLSearchParams({ projectId: route.params.id })
      );
      checkpoints.value = useCheckpoints(response.tasks, null, null);
      const tasksInWork = useTasksInWork(response.tasks);
      model.isAllTasksCompleted = tasksInWork.length === 0;
    } catch (error) {
      console.error('error', error);
    }
  };

  const onFinish = async () => {
    try {
      isLoading.value = true;
      await api.projectReport.closeReport({
        projectId: route.params.id,
        role: USER_ROLE_LEADER,
        closeReasonId: report.closeReasonId,
        closeComment: report.closeComment,
      });
      await getModel();
    } catch (error) {
      errors.handleApiErrors(error);
    } finally {
      isLoading.value = false;
    }
  };

  const handleApproval = async (approval, index) => {
    try {
      approvalLoaders.value[index] = true;
      approvalErrors.value[index].clearErrors();
      await api.leaderPage.createProjectApproval({
        projectId: model.id,
        role: approval.role,
        briefcaseProjectId: approval.briefcaseProjectId,
      });
      await getModel();
    } catch (error) {
      approvalErrors.value[index].handleApiErrors(error);
    } finally {
      approvalLoaders.value[index] = false;
    }
  };

  const reApprove = async (approval, index) => {
    try {
      approvalLoaders.value[index] = true;
      approvalErrors.value[index].clearErrors();
      await api.leaderPage.reApproveProject({
        projectApprovalId: approval.id,
      });
      await getModel();
    } catch (error) {
      approvalErrors.value[index].handleApiErrors(error);
    } finally {
      approvalLoaders.value[index] = false;
    }
  };

  const onReasonSelect = (reasonId) => {
    report.closeReasonId = reasonId;
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  * {
    margin: 0;
    padding: 0;
  }

  :deep(.ant-collapse-header) {
    padding: 0 0 12px 0 !important;
  }

  .leader-project__common-progress {
    margin-top: 42px;
  }

  .leader-project-agreement {
    margin-top: 10px;
    max-width: 902px;
    padding-bottom: 100px;

    h3 {
      font-size: 18px;
      margin-bottom: 10px;
    }

    h4 {
      font-size: 14px;
      margin: 30px 0 6px;

      &.disabled {
        color: $color-bg-status-new !important;
      }
    }

    .rejected_reasons {
      margin-bottom: 30px;
    }

    .description {
      color: $color-bg-status-new;
      display: flex;
      flex-direction: column;
      font-family: 'Nunito Sans', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      gap: 18px;
      line-height: 20px;
      max-width: 678px;
    }

    .project-agreement-grid {
      margin-top: 10px;
      max-width: 902px;
      padding-bottom: 100px;

      .agreement-row {
        margin-top: 30px;
      }
    }

    .attachments {
      margin-top: 14px;
    }

    .statuses {
      display: flex;
      flex-direction: column;
      gap: 14px;
      margin-top: 30px;
    }

    .actions {
      text-align: right;
      margin-top: 26px;
    }
  }
</style>
