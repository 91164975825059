<template>
  <a-form
    ref="formRef"
    layout="vertical"
    :model="model"
    :rules="rules"
    :validate-on-rule-change="true"
    @validate="errors.onValidate"
    @finish="() => null"
  >
    <leader-project-form-row
      :show-available="showAvailable"
      :available="model.isAvailable"
      :disabled="disabled"
      @change="onChangeAvailability"
      class="bordered"
    >
      <a-form-item
        class="form-item__members"
        name="fio"
        :help="errors.getError('fio')"
        :validate-status="errors.getStatus('fio')"
      >
        <a-input
          v-model:value="model.fio"
          placeholder="Укажите ФИО сотрудника"
          :disabled="disabled"
          @change="validateAndUpdate"
        />
        <template #label>
          <span class="form-item__label">ФИО сотрудника</span>
          <c-button-remove
            v-if="!disabled"
            class="button-remove"
            @click="() => emit('remove', index)"
          />
        </template>
      </a-form-item>
      <a-row>
        <a-col :span="12">
          <a-form-item
            label="Должность"
            name="position"
            :help="errors.getError('position')"
            :validate-status="errors.getStatus('position')"
          >
            <a-input
              v-model:value="model.position"
              placeholder="Выберите должность"
              :disabled="disabled"
              @change="validateAndUpdate"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item
            label="Подразделение"
            name="unit"
            :help="errors.getError('unit')"
            :validate-status="errors.getStatus('unit')"
          >
            <a-input
              v-model:value="model.unit"
              placeholder="Выберите подразделение"
              :disabled="disabled"
              @change="validateAndUpdate"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item
            label="Нагрузка"
            name="workload"
            :help="errors.getError('workload')"
            :validate-status="errors.getStatus('workload')"
          >
            <a-input
              v-model:value="model.workload"
              placeholder="Укажите нагрузку"
              :disabled="disabled"
              @change="validateAndUpdate"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item
            label="Роль в проекте"
            name="projectRole"
            :help="errors.getError('projectRole')"
            :validate-status="errors.getStatus('projectRole')"
          >
            <a-input
              v-model:value="model.projectRole"
              placeholder="Укажите роль"
              :disabled="disabled"
              @change="validateAndUpdate"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </leader-project-form-row>
  </a-form>
</template>

<script setup>
  import { computed, onMounted, reactive, ref, watch } from 'vue';
  import ErrorsHandler from '@/components/form/errors-handler';
  import LeaderProjectFormRow from '@/views/leader/projects/form/LeaderProjectFormRow.vue';
  import CButtonRemove from '@/components/UI/CButtonRemove.vue';
  import useInputRowValidator from '@/composables/inputRowValidator';

  const formRef = ref();
  const emit = defineEmits(['update', 'remove']);
  const inputValidator = useInputRowValidator(emit);

  const props = defineProps({
    index: {
      type: Number,
      required: true,
    },
    maxIndex: {
      type: Number,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    apiErrors: {
      type: Object,
      required: true,
    },
    showAvailable: {
      type: Boolean,
      default: false,
    },
    offsetAvailable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  });

  const model = reactive({
    fio: '',
    position: '',
    unit: '',
    workload: '',
    projectRole: '',
    isAvailable: true,
  });

  const validationIsRequired = computed(() => {
    return (
      model.position ||
      model.unit ||
      model.workload ||
      model.projectRole ||
      props.index !== props.maxIndex
    );
  });

  const rules = computed(() => {
    return {
      fio: [
        {
          type: 'string',
          message: 'Укажите ФИО.',
          required: validationIsRequired.value,
          trigger: 'blur',
        },
      ],
      position: [
        {
          type: 'string',
          message: 'Укажите должность.',
          trigger: 'blur',
        },
      ],
      unit: [
        {
          type: 'string',
          message: 'Укажите подразделение.',
          trigger: 'blur',
        },
      ],
      workload: [
        {
          type: 'string',
          message: 'Укажите нагрузку.',
          trigger: 'blur',
        },
      ],
      projectRole: [
        {
          type: 'string',
          message: 'Укажите роль в проекте.',
          trigger: 'blur',
        },
      ],
    };
  });

  const errors = new ErrorsHandler({
    fio: '',
    position: '',
    unit: '',
    workload: '',
    projectRole: '',
    isAvailable: '',
  });

  onMounted(() => {
    fillModel();
  });

  watch(
    () => props.data,
    () => {
      fillModel();
    }
  );

  watch(
    () => props.apiErrors,
    () => {
      props.apiErrors.errors && errors.handleApiErrors(props.apiErrors);
    }
  );

  watch(
    () => validationIsRequired.value,
    () => {
      setTimeout(() => {
        validateAndUpdate();
      }, 300);
    }
  );

  const fillModel = () => {
    try {
      model.fio = props.data.fio;
      model.position = props.data.position;
      model.unit = props.data.unit;
      model.workload = props.data.workload;
      model.projectRole = props.data.projectRole;
      model.isAvailable = props.data.isAvailable;
    } catch (error) {
      console.error('error', error);
    }
  };

  const onChangeAvailability = () => {
    model.isAvailable = !model.isAvailable;
    validateAndUpdate();
  };

  const validateAndUpdate = () =>
    inputValidator.validateAndUpdate(props.index, model, formRef);

  defineExpose({
    validateAndUpdate,
  });
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  .form-item__label {
    padding-top: 15px;
  }

  .button-remove {
    margin-left: 10px;
  }
</style>
