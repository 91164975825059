<template>
  <menu-page-horizontal :items="getItems(id, active)" />
</template>

<script setup>
  import { onMounted } from 'vue';
  import MenuPageHorizontal from '@/views/portal/components/menus/MenuPageHorizontal.vue';

  defineProps({
    id: {
      type: Number,
      default: 0,
      required: true,
    },
    active: {
      type: String,
      default: 'profile',
    },
  });

  const getItems = (id, active) => {
    return [
      {
        label: 'Профиль',
        url: '/portal/person/' + id,
        active: active === 'profile' || !active,
      },
      {
        label: 'Направления работы',
        url: '/portal/person/' + id + '/concepts',
        active: active === 'concepts',
      },
      {
        label: 'Научные публикации',
        url: '/portal/person/' + id + '/publications',
        active: active === 'publications',
      },
      {
        label: 'Интеллектуальная собственность',
        url: '/portal/person/' + id + '/intellectuals',
        active: active === 'intellectuals',
      },
    ];
  };

  onMounted(() => {});
</script>

<style lang="scss" scoped></style>
