<template>
  <partner-layout :spinning="isLoading">
    <partner-project-layout :model="model">
      <a-row class="partner-project-form" :wrap="false">
        <a-col :span="5">
          <div class="partner-project-form__menu">
            <router-link
              v-for="item in menuSteps"
              :key="`project-menu-` + item.step"
              :to="`#` + item.step"
              :class="{ active: item.step === step }"
            >
              {{ item.label }}
            </router-link>
          </div>
        </a-col>
        <a-col :span="19" class="partner-project-form__content">
          <template v-if="step === 1">
            <partner-project-form-step1 :project="model.project" />
          </template>
          <template v-if="step === 2">
            <partner-project-form-step2 :project="model.project" />
          </template>
          <template v-if="step === 3">
            <partner-project-form-step3 :project="model.project" />
          </template>
          <template v-if="step === 4">
            <partner-project-form-step4 :project="model.project" />
          </template>
          <template v-if="step === 5">
            <partner-project-form-step5 :project="model.project" />
          </template>
          <template v-if="step === 6">
            <partner-project-form-step6 :project="model.project" />
          </template>
          <template v-if="step === 7">
            <partner-project-form-step7 :project="model.project" />
          </template>
          <template v-if="step === 8">
            <partner-project-form-step8 :project="model.project" />
          </template>
          <template v-if="step === 9">
            <partner-project-form-step9
              :project="model.project"
              @update="getModel"
            />
          </template>
        </a-col>
      </a-row>
    </partner-project-layout>
  </partner-layout>
</template>

<script setup>
  import { computed, onMounted, reactive, ref } from 'vue';
  import { useRoute } from 'vue-router';
  import { useApi } from '@/api/use-api';
  import PartnerLayout from '@/layouts/PartnerLayout.vue';
  import PartnerProjectLayout from '../components/PartnerProjectLayout.vue';
  import PartnerProjectFormStep1 from './steps/PartnerProjectFormStep1.vue';
  import PartnerProjectFormStep2 from './steps/PartnerProjectFormStep2.vue';
  import PartnerProjectFormStep3 from './steps/PartnerProjectFormStep3.vue';
  import PartnerProjectFormStep4 from './steps/PartnerProjectFormStep4.vue';
  import PartnerProjectFormStep5 from './steps/PartnerProjectFormStep5.vue';
  import PartnerProjectFormStep6 from './steps/PartnerProjectFormStep6.vue';
  import PartnerProjectFormStep7 from './steps/PartnerProjectFormStep7.vue';
  import PartnerProjectFormStep8 from './steps/PartnerProjectFormStep8.vue';
  import PartnerProjectFormStep9 from './steps/PartnerProjectFormStep9.vue';

  const api = useApi();
  const route = useRoute();
  const model = reactive({
    project: null,
    id: null,
    number: '',
    name: 'Новый проект',
    description: '',
    projectType: '',
    requestId: null,
    status: {
      type: 'draft',
      text: 'Черновик',
    },
    projectStatusId: null,
  });
  const isLoading = ref(true);

  const menuSteps = computed(() => {
    const steps = [
      { step: 1, label: 'Информация о проекте', hide: false, completed: true },
      { step: 2, label: 'Техническое задание', hide: false, completed: false },
      { step: 3, label: 'Календарный план', hide: false, completed: false },
      { step: 4, label: 'Команда проекта', hide: true, completed: false },
      { step: 5, label: 'Финансы', hide: true, completed: false },
      { step: 6, label: 'Ресурсы', hide: true, completed: false },
      {
        step: 7,
        label: 'Портфели и показатели',
        hide: false,
        completed: false,
      },
      { step: 8, label: 'Документы', hide: false, completed: false },
      { step: 9, label: 'Согласование', hide: true, completed: false },
    ];
    return model.project?.approval ? steps : steps.slice(0, -1);
  });

  const getModel = async () => {
    try {
      const response = await api.partnerProject.projectSteps(route.params.id);
      model.project = response.project;
      model.id = response.project.id;
      model.name = response.project.name;
      model.number = response.project.id;
      model.type = response.project.type;
      model.requestId = response.project.requestId;
      model.status = response.project.status;
      model.projectStatusId = response.project.projectStatusId;
    } catch (error) {
      console.error('error', error);
    }
  };

  onMounted(async () => {
    await getModel();
    isLoading.value = false;
  });

  const step = computed(() => {
    return route.hash ? Number(route.hash.replace('#', '')) : 1;
  });
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  h3 {
    color: $color-black;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.1;
  }

  .partner-project-form {
    .partner-project-form__menu {
      background: #f2f4f8;
      padding: 14px 6px;

      a {
        color: $color-text-primary;
        font-variant-numeric: slashed-zero;
        font-family: 'Nunito Sans', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.4;

        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        margin: 6px 0;
        padding: 6px 6px 6px 30px;
        border-radius: 4px;

        &:hover,
        &.active {
          background: #ffffff;
          font-weight: 700;
        }

        :deep(.done-circle-icon) {
          margin-left: -24px;
          margin-right: 9px;
        }
      }
    }

    .partner-project-form__content {
      padding-left: 20px;
    }
  }
</style>
