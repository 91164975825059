<template>
  <a-card class="person-card" :bordered="false">
    <router-link :to="`/portal/person/` + id">
      <person-avatar :url="avatar"></person-avatar>
      <h4 class="person-card__name">{{ getFullName(name) }}</h4>
      <div class="person-card__unit">
        {{ mainOrganisational }}
      </div>
      <div class="person-card__position">
        {{ mainPosition }}
      </div>
    </router-link>
  </a-card>
</template>

<script setup>
import {onMounted, ref} from "vue";
import PersonAvatar from '@/views/portal/components/persons/PersonAvatar.vue';

const mainOrganisational = ref('');
const mainPosition = ref('');

const props = defineProps({
  id: {
    type: Number,
    required: true,
  },
  name: {
    type: Array,
    required: true,
  },
  avatar: {
    type: String,
    default: '',
  },
  employments: Array,
});

const getFullName = (names) => {
  return names ? names.join(' ') : '';
};

const getMainPosition = (employments) => {
  if (employments.length === 0) return;
  let main = employments[0];
  employments.forEach((e) => {
    if (e.contractType === 'main') main = e;
  });
  mainPosition.value = main.position;
  mainOrganisational.value = main.organisational;
};

onMounted(() => {
  getMainPosition(props.employments);
});
</script>

<style lang="scss" scoped>
.person-card {
  border-radius: 6px;
  background: #f2f4f8;
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  line-height: 1.71429;
  padding: 20px;
  height: 100%;

  &:hover {
    background: #fff;
    box-shadow: 0 4px 15px 0 rgba(45, 51, 70, 0.15);
  }

  :deep(.ant-card-body) {
    padding: 0;
  }

  .person-card__name {
    color: #101426;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  .person-card__unit {
    color: #718096;
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.42857;
    margin-bottom: 10px;
  }

  .person-card__position {
    color: #718096;
    font-size: 14px;
    font-weight: 400;
  }
}
</style>
