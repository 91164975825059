<template>
  <div class="delete-task__form">
    <h3>Удалить {{ type !== "subtask" ? "задачу" : "подзадачу" }}?</h3>
    <p>Вы действительно хотите удалить задачу из списка?</p>
    <a-row class="form__task-info">
      <a-col>
        <h2>{{ task.name }}</h2>
      </a-col>
      <a-col class="form__task-group" v-if="task.subtasks">
        Подзадач:<span class="counter">{{ task.subtasks.length }}</span>
      </a-col>
      <a-col class="form__task-group">
        Контрольных точек:<span class="counter">{{
          getCheckpointsCount(task)
        }}</span>
      </a-col>
    </a-row>

    <div class="delete-task__form-button">
      <c-button-danger @click="deleteTask"> Удалить </c-button-danger>
    </div>
  </div>
</template>

<script setup>
import { useApi } from "@/api/use-api";
// import { useRoute } from 'vue-router';
import CButtonDanger from "@/components/UI/CButtonDanger";

const props = defineProps({
  task: Object,
  type: String,
});

const api = useApi();
// const route = useRoute();
const emit = defineEmits(["close", "update"]);

const getCheckpointsCount = (task) => {
  const taskCheckpointsCount = task.checkpoints.length;

  if (task.subtasks) {
    const subtasksCheckpointsCount = task.subtasks
      .map((subtask) => subtask.checkpoints)
      .flat().length;
    return taskCheckpointsCount + subtasksCheckpointsCount;
  }

  return taskCheckpointsCount;
};

const deleteTask = async () => {
  try {
    await api.projectManagementPage.deleteTask({
      id: props.task.id,
    });
    emit("close");
    emit("update");
  } catch (error) {
    console.error("error", error);
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_colors.scss";
* {
  margin: 0;
}

.delete-task__form {
  padding: 112px 17px 0 17px;

  h2 {
    font: 700 16px/22px "Nunito Sans";
  }

  p {
    margin-top: 21px;
  }

  .form__task-info {
    background: $color-bg-secondary-grey;
    flex-direction: column;
    height: 161px;
    margin-top: 40px;
    padding: 20px;

    .form__task-group {
      color: $color-bg-status-new;
      font: 700 14px/20px "Nunito Sans";
      margin-top: 18px;

      .counter {
        font-weight: 400;
        margin-left: 10px;
      }
    }
  }

  &-button {
    margin-top: 50px;
    text-align: right;
  }
}
</style>
