<template>
  <a-row class="guest-layout">
    <a-col :span="12" class="guest-layout__left-side">
      <image-side />
    </a-col>
    <a-col :span="12" class="guest-layout__right-side">
      <c-button-primary class="showcase-button" @click="onShowcaseClick">
        Витрина проектов
      </c-button-primary>
      <a-row class="guest-navigation">
        <a-col :span="4">
          <slot name="navigation"></slot>
        </a-col>
      </a-row>
      <a-row class="guest-content">
        <a-col :span="16" :offset="4">
          <slot></slot>
        </a-col>
      </a-row>
      <a-row class="guest-footer">
        <a-col :span="16" :offset="4">
          <slot name="footer"></slot>
        </a-col>
      </a-row>
    </a-col>
  </a-row>
</template>

<script setup>
  import ImageSide from '@/components/image-side/ImageSide';
  import CButtonPrimary from '@/components/UI/CButtonPrimary.vue';
  import { useRouter } from 'vue-router';

  const router = useRouter();

  document.title = 'Цифровой университет. НГУ';

  const onShowcaseClick = async () =>
    await router.push({ name: 'showcase-main-page' });
</script>

<style lang="scss">
  @import '@/assets/styles/_colors.scss';

  .guest-layout {
    background: $color-bg-primary-black-100;
    min-height: 100vh;

    .showcase-button {
      top: 46px;
      right: 30px;
      position: absolute;
    }

    .guest-layout__right-side {
      background: $color-bg-primary-white;
      border-radius: 20px 0 0 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 30px 40px 60px 30px;
    }
  }
</style>
