<template>
  <button type="button" class="button-remove">
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5 3.98985L8.78064 0.209209C9.05958 -0.0697365 9.51185 -0.0697365 9.79079 0.209209C10.0697 0.488155 10.0697 0.940416 9.79079 1.21936L6.01015 5L9.79079 8.78064C10.0697 9.05958 10.0697 9.51184 9.79079 9.79079C9.51184 10.0697 9.05958 10.0697 8.78064 9.79079L5 6.01015L1.21936 9.79079C0.940416 10.0697 0.488155 10.0697 0.209209 9.79079C-0.0697365 9.51184 -0.0697365 9.05958 0.209209 8.78064L3.98985 5L0.209209 1.21936C-0.0697365 0.940416 -0.0697365 0.488155 0.209209 0.209209C0.488155 -0.0697365 0.940416 -0.0697365 1.21936 0.209209L5 3.98985Z"
      />
    </svg>
  </button>
</template>

<script setup></script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  .button-remove {
    padding: 0;
    background: transparent;
    border: 0;
    height: 100%;
    cursor: pointer;

    & > svg {
      fill: $color-btn-danger;
    }
  }
</style>
