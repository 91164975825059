import {
  read,
  update,
} from "@/api/rest";

const partnerProject = {
  projects: async () => await read('/partner-project/projects'),
  getProject: async (id) => await read('/partner-project/project?id=' + id),
  getProjectToClose: async (id) => await read('/partner-project/project-to-close?id=' + id),
  projectSteps: async (id) => await read('/partner-project/project-steps?id=' + id),
  approveProject: async (data) =>
      await update('/partner-project/approve-project', data),
  reApproveProject: async (data) =>
      await update('/partner-project/re-approve-project', data),
  rejectProject: async (data) =>
      await update('/partner-project/reject-project', data),
};

export default partnerProject;