import { read, update } from '@/api/rest';

const documentApproval = {
  getApprovals: async (options) =>
    await read('/document-approval?' + options.toString()),
  approve: async (data) => await update(`/document-approval/approve`, data),
  reApprove: async (data) =>
    await update(`/document-approval/re-approve`, data),
  edit: async (data) => await update(`/document-approval/edit`, data),
  remove: async (data) => await update(`/document-approval/remove`, data),
  reject: async (data) => await update(`/document-approval/reject`, data),
};

export default documentApproval;
