<template>
  <a-row class="refuse-request__form" justify="end">
    <a-col class="form__title" :span="24">
      <h3>Отказаться от проекта?</h3>
    </a-col>
    <a-col class="form__description" :span="24">
      <p>Вы действительно хотите отказаться от проекта?</p>
    </a-col>
    <a-col class="form__fields" :span="24">
      <a-form :model="model" @finish="refuseRequest">
        <a-row>
          <a-col :span="24">
            <a-form-item name="reasonForReject">
              <a-textarea
                id="reasonForReject"
                v-model:value="model.reasonForReject"
                placeholder="При необходимости укажите причину отказа"
              />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-row justify="end">
              <a-form-item class="form__button">
                <c-button-danger html-type="submit">
                  Отказаться
                </c-button-danger>
              </a-form-item>
            </a-row>
          </a-col>
        </a-row>
      </a-form>
    </a-col>
  </a-row>
</template>

<script setup>
  import { reactive } from 'vue';
  import { useApi } from '@/api/use-api';
  import CButtonDanger from '@/components/UI/CButtonDanger.vue';
  import { useRoute, useRouter } from 'vue-router';

  const router = useRouter();
  const route = useRoute();
  const api = useApi();
  const emit = defineEmits(['close', 'update']);
  const model = reactive({
    reasonForReject: '',
  });

  const refuseRequest = async () => {
    try {
      await api.administratorPage.rejectRequest({
        id: route.params.id,
      });
      emit('close');
      emit('update');
      await router.replace({ name: 'administrator-requests' });
    } catch (error) {
      console.error(error);
    }
  };
</script>

<style lang="scss" scoped>
  $color-bg-button-delete: #f65160;
  $color-text-button-delete: #ffffff;

  .refuse-request__form {
    padding: 112px 17px 0 17px;

    .form__title {
      h3 {
        font: 700 18px/26px 'Nunito Sans';
      }
    }

    .form__description {
      font: 400 14px/20px 'Nunito Sans';
      margin-top: 20px;
      margin-bottom: 0;
    }

    .form__content {
      margin-top: 30px;
    }

    .form__fields {
      margin-top: 40px;

      textarea {
        height: 190px;
        resize: none;
      }

      .ant-form-item {
        margin: 0;
      }

      .form__button {
        button {
          margin-top: 50px;
        }
      }
    }
  }
</style>
