<template>
  <div class="project-icon">
    <img :src="iconImage" alt="" />
  </div>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import EduIcon from './assets/icon-edu.svg';
  import ResearchIcon from './assets/icon-research.svg';
  import ResourceIcon from './assets/icon-resource.svg';
  import ReviewIcon from './assets/icon-review.svg';
  import CollaborationIcon from './assets/icon-collaboration.svg';
  import {
    REQUEST_TYPE_EDUCATION,
    REQUEST_TYPE_EXPERTISE,
    REQUEST_TYPE_PARTNERSHIP,
    REQUEST_TYPE_RESEARCH,
    REQUEST_TYPE_RESOURCES,
  } from '@/api/models/RequestType';

  const props = defineProps({
    type: String,
  });

  const iconImage = computed(() => {
    const type = ref('');
    switch (props.type) {
      case REQUEST_TYPE_EDUCATION:
        type.value = EduIcon;
        break;
      case REQUEST_TYPE_RESEARCH:
        type.value = ResearchIcon;
        break;
      case REQUEST_TYPE_RESOURCES:
        type.value = ResourceIcon;
        break;
      case REQUEST_TYPE_EXPERTISE:
        type.value = ReviewIcon;
        break;
      case REQUEST_TYPE_PARTNERSHIP:
        type.value = CollaborationIcon;
        break;
    }
    return type.value;
  });
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  .project-icon {
    width: 60px;
    height: 60px;
    flex: 0 0 60px;
    background: $color-bg-primary-white;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
