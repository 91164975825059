<template>
  <a-row class="project-manager-layout">
    <a-col flex="250px" class="project-manager-layout__left-side">
      <SideBar :items="sidebarItems" />
    </a-col>
    <a-col flex="auto" class="project-manager-layout__right-side">
      <div class="project-manager-layout__header">
        <!-- HeaderPartnerAccount back-page-name="project-manager-requests" /-->
        <HeaderPartnerAccount />
      </div>
      <div class="project-manager-layout__content">
        <a-spin
          :spinning="spinning"
          class="custom-spinner"
          style="height: 100vh; top: 20%"
        >
          <slot></slot>
        </a-spin>
      </div>
    </a-col>
  </a-row>
</template>

<script setup>
  import { ref } from 'vue';
  import SideBar from '@/components/sidebar/SideBar';
  import HeaderPartnerAccount from '@/components/header/HeaderPartnerAccount';

  document.title = 'Цифровой университет. НГУ';

  defineProps({
    requestCount: {
      type: Number,
      default: 0,
    },
    spinning: {
      type: Boolean,
      default: false,
    },
  });

  const sidebarItems = ref([
    {
      id: 1,
      text: 'Мои портфели',
      path: 'leader-portfolios',
      type: 'portfolios',
      count: 0,
    },
    {
      id: 1,
      text: 'Мои проекты',
      path: 'project-manager-projects',
      hash: '#inwork',
      type: 'projects',
      count: 0,
    },
  ]);

  // watch(
  //   () => props.requestCount,
  //   () => {
  //     sidebarItems.value[0].count = props.requestCount;
  //   }
  // );
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  .project-manager-layout {
    min-height: 100vh;
    flex-wrap: nowrap;

    .project-manager-layout__header {
      border-bottom: 1px solid $color-bg-secondary-grey;
      background-color: $color-bg-primary-white;
      position: sticky;
      padding: 17px 40px 17px 20px;
      top: 0;
      z-index: 1;
    }

    .project-manager-layout__content {
      padding: 30px 40px 0 20px;
    }
  }
</style>
