<template>
  <div class="root">
    <div class="progress-line">
      <div
        v-for="(item, index) in items"
        class="progress-item"
        :style="calcItemPercent(item, total)"
        :key="index"
      />
    </div>
    <div v-if="showStatistic" class="statistic">
      <strong>{{ `${calcTotalCompleted(items)}/${total}` }}</strong>
    </div>
  </div>
</template>

<script setup>
  defineProps({
    items: Array,
    total: Number,
    showStatistic: {
      type: Boolean,
      default: false,
    },
  });

  const calcItemPercent = (item, total) => {
    const width = (total ? 100 / total : 100) * item.value + '%';
    let statusColor = '';

    switch (item?.status) {
      case 'completed':
        statusColor = '#72C81E';
        break;

      case 'promised':
        statusColor = '#BABBC2';
        break;

      case 'overed':
        statusColor = '#1EB2C8';
        break;

      case 'incomplete':
        statusColor = '#f65160';
    }

    return {
      width,
      'background-color': statusColor,
    };
  };

  const calcTotalCompleted = (items) => {
    let total = 0;
    items.map((item) => {
      if (item.status === 'completed' || item.status === 'overed') {
        total += item?.value ?? 0;
      }
    });

    return total;
  };
</script>

<style lang="scss" scoped>
  .root {
    align-items: center;
    border-radius: 100px;
    display: flex;
    position: relative;
    overflow: hidden;
    width: 100%;

    .progress-line {
      display: flex;
      background-color: rgb(255, 255, 255);
      height: 9px;
      width: 100%;
    }

    .progress-item {
      position: relative;
      border-radius: 100px;
      transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    }

    .statistic {
      text-align: right;
      margin-left: 20px;
    }
  }
</style>
