<template>
  <span class="tag-percent">
    <span class="tag-percent__graph" :title="tooltip">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
      >
        <g transform="rotate(-90 11 11)" fill="none" stroke-width="4">
          <circle
            id="back"
            cx="11"
            cy="11"
            r="9"
            stroke="#2D3346"
            :stroke-dasharray="calcDasharrayBack(value)"
            :stroke-dashoffset="calcDashoffsetBack(value)"
          ></circle>
          <circle
            id="front"
            cx="11"
            cy="11"
            r="9"
            stroke="#72C81E"
            :stroke-dasharray="calcDasharray(value)"
            stroke-dashoffset="0"
          />
        </g>
      </svg>
    </span>
    <span class="tag-percent__label">
      <slot></slot>
    </span>
  </span>
</template>

<script setup>
import { onMounted } from "vue";

defineProps({
  value: {
    type: Number,
    default: 0,
    required: true
  },
  tooltip: {
    type: [String, Number],
    default: null,
  },
});

// const size = ref(22);

const l = 56.5;
const calcDasharray = (value) => {
  const lx = (value * l) / 100;
  return lx + " " + (l - lx);
};
const calcDasharrayBack = (value) => {
  const lx = (value * l) / 100;
  return l - lx + " ";
};
const calcDashoffsetBack = (value) => {
  return -(value * l) / 100;
};

onMounted(() => {});
</script>

<style lang="scss" scoped>
.tag-percent {
  border-radius: 4px;
  background: #fff;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  padding: 4px 7px;
  height: 30px;

  &.tag-percent-gray {
    background: #f2f4f8;
  }

  &.tag-percent-white {
    background: #ffffff;
  }

  &.tag-percent-big-gray {
    background: #f2f4f8;
    height: 54px;

    .tag-percent__graph {
      width: 34px;
      height: 34px;
      margin-right: 10px;
    }

    .tag-percent__label {
      .label {
        color: #2d3346;
        font-weight: 700;
      }
    }
  }

  .tag-percent__graph {
    width: 22px;
    height: 22px;
    margin-right: 7px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .tag-percent__label {
    color: #2d3346;
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
}
</style>
