<template>
  <a-collapse
    v-model:activeKey="activeKey"
    :bordered="false"
    class="custom-collapse"
    style="background: rgb(255, 255, 255)"
  >
    <template #expandIcon="{ isActive }">
      <a-row style="text-align: left; min-width: 25rem">
        <a-col :span="24">
          <span class="task-label" style="margin-right: 1rem">
            Задача {{ task.number }}
          </span>
          <img
            src="@/assets/icons/circle-chevron-right.svg"
            alt=""
            class="collapsed"
            :class="!isActive ? 'active' : ''"
          />
        </a-col>
        <a-col
          :span="24"
          class="task-name"
          :class="preview && 'preview'"
          style="margin-top: 1rem"
        >
          {{ task.name }}

          <span class="edit-button" v-if="checkIfEditable">
            <img
              src="@/assets/icons/edit.svg"
              @click.stop="checkIfEditable && handleShowForm('editTask')"
              alt=""
            />
          </span>
        </a-col>
      </a-row>
    </template>

    <a-collapse-panel key="1" class="task-card">
      <template #extra>
        <progress-bar :items="getCheckpoints(task)" incompleteColor="white" />
      </template>

      <div :class="preview && 'preview'">
        <template v-if="task.checkpoints">
          <checkpoints
            :taskId="task.id"
            :checkpoints="task.checkpoints"
            :disabled="!checkIfEditable"
            @update="updateTasks"
          />

          <a-row class="task-executor">
            <a-col :span="24">
              Исполнитель:
              <span class="task-executor__fullname">{{ task.executor }} </span>
            </a-col>
          </a-row>
        </template>

        <template v-if="task.subtasks">
          <sub-task
            v-for="(subtask, index) in task.subtasks"
            :key="index"
            :item="{ ...subtask, number: `${task.number}.${++index}` }"
            :disabled="!checkIfSubTaskEditable(subtask)"
            @update="updateTasks"
          />
        </template>

        <template v-if="checkIfEditable">
          <a-divider />

          <a-row class="task-card__add-subtask">
            <a-col :span="12">
              <a @click="handleShowForm('addSubTask')">Создать подзадачу</a>
            </a-col>
            <a-col
              :span="12"
              class="task-card__partner-visibility"
              v-if="isLeader"
            >
              <c-checkbox
                v-if="showAvailability"
                v-model:checked="isVisible"
                @click="changeVisibility"
              >
                Сделать задачу видимой для партнера
              </c-checkbox>
            </a-col>
          </a-row>
        </template>
      </div>
    </a-collapse-panel>
  </a-collapse>
  <c-drawer v-model:open="isShowForm" destroyOnClose>
    <component
      :is="form"
      :task="task"
      :type="taskType"
      @close="handleCloseForm"
      @update="updateTasks"
      @subaction="handleShowForm('deleteTask')"
    />
  </c-drawer>
</template>

<script setup>
  import { computed, ref, shallowRef } from 'vue';
  import ProgressBar from '@/components/projects/management/ProgressBar';
  import Checkpoints from '../CheckPoints/CheckPoints';
  import CCheckbox from '@/components/UI/CCheckbox';
  import AddTask from './AddTask';
  import EditTask from './EditTask';
  import DeleteTask from './DeleteTask';
  import SubTask from '../SubTask/SubTask';
  import CDrawer from '@/components/UI/CDrawer.vue';

  const props = defineProps({
    task: Object,
    preview: {
      required: false,
      type: Boolean,
    },
    disabled: {
      required: true,
      default: false,
    },
    showAvailability: {
      type: Boolean,
      default: true,
    },
    editorUserId: Number,
  });

  const isLeader = localStorage?.getItem('currentRole') === 'leader';
  const activeKey = ref([]);
  const isVisible = ref(props.task?.visibility);
  const form = shallowRef(null);
  const isShowForm = ref(false);
  const taskType = ref('');
  const emit = defineEmits(['close', 'update', 'change']);

  const getCheckpoints = (task) => {
    if (!task.checkpoints) return;

    const taskCheckpoints = task.checkpoints;

    if (task.subtasks) {
      const subtasksCheckpoints = task.subtasks
        .map((subtask) => subtask.checkpoints)
        .flat();

      if (taskCheckpoints) {
        return [...taskCheckpoints, ...subtasksCheckpoints];
      }

      return [...subtasksCheckpoints];
    }

    return taskCheckpoints;
  };

  const handleShowForm = (formType) => {
    switch (formType) {
      case 'addSubTask':
        taskType.value = 'subtask';
        form.value = AddTask;
        break;

      case 'editTask':
        form.value = EditTask;
        break;

      case 'deleteTask':
        form.value = DeleteTask;
        break;
    }

    isShowForm.value = true;
  };

  const changeVisibility = async () => {
    emit('change');
  };

  const updateTasks = async () => {
    emit('update');
  };

  const handleCloseForm = () => {
    isShowForm.value = false;
    form.value = null;
  };

  const checkIfEditable = computed(() => {
    return (
      !props.disabled &&
      (props.editorUserId ? props.editorUserId === props.task.userId : true)
    );
  });

  const checkIfSubTaskEditable = (subtask) => {
    return (
      !props.disabled &&
      (props.editorUserId
        ? props.editorUserId === props.task.userId ||
          props.editorUserId === subtask.userId
        : true)
    );
  };
</script>

<style lang="scss" scoped>
  @import 'task-card';
</style>
