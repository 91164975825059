<template>
  <div class="leader-project">
    <leader-project-title
      class="leader-project__title"
      :title="model.name"
      :status="model.status"
    />
    <leader-project-info
      class="leader-project__info"
      :type="model.type"
      :number="model.number ? model.number.toString() : ''"
      :requestId="model.requestId"
    />

    <slot name="underInfo"></slot>

    <horizontal-filter>
      <filter-item
        :path="`/leader/project/${model?.id}/form`"
        :hash="getFormStepHash(model.id)"
        text="Карточка проекта"
      />
      <filter-item
        v-if="model?.isMyProject"
        :path="`/leader/project/${model?.id}/management`"
        text="Управление проектом"
      />
      <filter-item
        v-if="model?.isMyProject"
        :path="`/leader/project/${model?.id}/team`"
        text="Команда"
      />
      <filter-item
        v-if="
          model?.isMyProject && model?.projectStatusId !== PROJECT_STATUS_DRAFT
        "
        :path="`/leader/project/${model?.id}/showcase`"
        text="Витрина"
      />
      <filter-item
        v-if="model?.isMyProject"
        :path="`/leader/project/${model?.id}/indexes`"
        text="Показатели"
      />
      <filter-item
        v-if="model?.isMyProject"
        :path="`/leader/project/${model?.id}/report`"
        text="Отчет"
      />
      <filter-item
        v-if="model?.isMyProject"
        :path="`/leader/project/${model?.id}/agreement`"
        text="Закрыть проект"
      />
    </horizontal-filter>

    <div class="leader-project__content">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
  import { useRoute } from 'vue-router';
  import LeaderProjectTitle from '@/views/leader/projects/components/LeaderProjectTitle.vue';
  import LeaderProjectInfo from '@/views/leader/projects/components/LeaderProjectInfo.vue';
  import HorizontalFilter from '@/components/filter/horizontal/HorizontalFilter.vue';
  import FilterItem from '@/components/filter/components/FilterItem.vue';
  import { PROJECT_STATUS_DRAFT } from '@/api/models/ProjectStatus';

  const route = useRoute();

  defineProps({
    model: {
      type: Object,
      require: true,
    },
  });

  const getFormStepHash = (id) => {
    if (route.path !== '/leader/project/' + id + '/form') {
      return '#1';
    }
    return route.hash;
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  .leader-project {
    height: 100%;

    .leader-project__info {
      align-items: center;
      margin-top: 40px;
    }

    .leader-project__content {
      padding: 30px 0 0;
    }
  }
</style>
