<template>
  <h3>Шаг 3. Календарный план-график</h3>
  <p>
    Проверьте информацию в карточке проекта и, если согласны, согласуйте проект.
  </p>
  <div>
    <a-form class="form partner-project-form" layout="vertical">
      <partner-project-form-row
        :show-available="false"
        v-if="model.availabilityList['date']"
        class="bordered"
      >
        <a-row>
          <a-col :span="12">
            <a-form-item name="startDate" label="Старт проекта">
              <a-date-picker
                v-model:value="model.startDate"
                placeholder="Выберите дату"
                :locale="locale"
                disabled
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item name="endDate" label="Завершение проекта">
              <a-date-picker
                v-model:value="model.endDate"
                placeholder="Выберите дату"
                :locale="locale"
                disabled
              />
            </a-form-item>
          </a-col>
        </a-row>
      </partner-project-form-row>

      <template v-for="(stage, index) in stages" :key="`stage-` + index">
        <div v-if="stage.isAvailable">
          <h3>Этап {{ index + 1 }}</h3>

          <partner-project-form-row
            :show-available="false"
            :offsetAvailable="false"
            class="bordered"
          >
            <a-form-item>
              <a-input
                v-model:value="stage.name"
                placeholder="Введите название этапа"
                disabled
              />
            </a-form-item>
            <a-row>
              <a-col :span="12">
                <a-form-item>
                  <a-date-picker
                    v-model:value="stage.startDate"
                    placeholder="Выберите дату старта этапа"
                    :locale="locale"
                    disabled
                  />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item>
                  <a-date-picker
                    v-model:value="stage.endDate"
                    placeholder="Выберите дату завершение этапа"
                    :locale="locale"
                    disabled
                  />
                </a-form-item>
              </a-col>
            </a-row>
          </partner-project-form-row>
        </div>
      </template>
    </a-form>
  </div>
</template>

<script setup>
  import { onMounted, reactive, watch } from 'vue';
  import locale from 'ant-design-vue/es/date-picker/locale/ru_RU';
  import PartnerProjectFormRow from '../PartnerProjectFormRow.vue';
  import dayjs from 'dayjs';

  const props = defineProps({
    project: {
      type: Object,
      require: true,
    },
  });

  const model = reactive({
    id: null,
    startDate: '',
    endDate: '',
    availabilityList: [],
  });


  onMounted(() => {
    props.project && fillModel();
  });

  watch(() => props.project, () => { fillModel(); });

  const fillModel = () => {
    try {
      model.id = props.project.id;

      if (props.project.startDate === null) {
        model.startDate = props.project.startDate;
        model.endDate = props.project.endDate;
      } else {
        model.startDate = dayjs(props.project.startDate);
        model.endDate = dayjs(props.project.endDate);
      }

      model.availabilityList = props.project.availabilityList;

      let projectStages = props.project.stages;
      if (projectStages.length) {
        for (let i = 0; i < projectStages.length; i++) {
          stages.push({
            id: projectStages[i].id,
            name: projectStages[i].name,
            startDate: dayjs(projectStages[i].startDate),
            endDate: dayjs(projectStages[i].endDate),
            isAvailable: projectStages[i].isAvailable,
          });
        }
      } else {
        addStage();
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  const stages = reactive([]);
  const addStage = () => {
    stages.push({
      id: null,
      name: '',
      startDate: '',
      endDate: '',
    });
  };
</script>

<style lang="scss" scoped>
  @import './partner-project-form';
</style>
