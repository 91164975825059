import { read } from '@/api/rest';

const publicProject = {
  projects: async (searchParams) =>
    await read('/public-project/projects?' + searchParams),
  projectsCount: async () => await read('/public-project/projects-count'),
  view: async (id) => await read(`/public-project/${id}`),
};

export default publicProject;
