<template>
  <a-form
    ref="formRef"
    class="form"
    layout="vertical"
    :model="model"
    :rules="rules"
    @validate="errors.onValidate"
    @finish="onFinish"
    :scrollToFirstError="{
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    }"
  >
    <div class="title">{{ `Показатель № ${props.indexCount}` }}</div>
    <a-row :gutter="[20]">
      <a-col :span="19">
        <a-form-item
          name="name"
          :help="errors.getError('name')"
          :validate-status="errors.getStatus('name')"
        >
          <a-input
            v-model:value="model.name"
            placeholder="Название показателя"
          />
        </a-form-item>
      </a-col>
      <a-col :span="5">
        <a-form-item
          name="value"
          :help="errors.getError('value')"
          :validate-status="errors.getStatus('value')"
        >
          <a-input
            v-model:value="model.value"
            placeholder="Плановый показатель"
          />
        </a-form-item>
      </a-col>
    </a-row>
    <a-form-item class="action">
      <c-button-primary :loading="isLoading" html-type="submit"
        >Сохранить</c-button-primary
      >
    </a-form-item>
  </a-form>
</template>

<script setup>
  import { computed, reactive, ref } from 'vue';
  import CButtonPrimary from '@/components/UI/CButtonPrimary.vue';
  import ErrorsHandler from '@/components/form/errors-handler';

  const props = defineProps({
    indexCount: Number,
    onSubmit: Function,
  });

  const isLoading = ref(false);
  const formRef = ref();
  const model = reactive({
    briefcaseId: null,
    name: '',
    value: '',
  });

  const errors = new ErrorsHandler({
    name: '',
    value: '',
  });

  const rules = computed(() => {
    return {
      name: [
        {
          required: true,
          min: 2,
          max: 255,
          message: 'Поле должно содержать не менее 2, но не более 255 символов',
          trigger: ['blur'],
        },
      ],
      value: [
        {
          required: true,
          validator: checkIndexValue,
          trigger: ['blur'],
        },
      ],
    };
  });

  const checkIndexValue = async (_rule, value) => {
    if (!value) {
      return Promise.reject('Пожалуйста, укажите плановый показатель');
    }
    if (isNaN(value)) {
      return Promise.reject('Поле должно содержать только цифры');
    } else if (parseInt(value) < 1) {
      return Promise.reject('Значение должно быть больше 0');
    } else {
      return Promise.resolve();
    }
  };

  const onFinish = async () => {
    isLoading.value = true;
    try {
      await props.onSubmit(model);
    } catch (e) {
      console.log(e);
    } finally {
      isLoading.value = false;
    }
  };
</script>

<style scoped lang="scss">
  @import '@/assets/styles/_colors.scss';

  .form {
    background-color: $color-bg-secondary-grey;
    padding: 20px 230px 20px 20px;

    .title {
      color: $color-bg-status-new;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 14px;
    }

    .action {
      margin-top: 40px;
      text-align: right;
    }
  }
</style>
