<template>
  <div id="portal" class="portal-layout">
    <HeaderPortal>
      <slot name="header"></slot>
    </HeaderPortal>
    <ContentPortal>
      <slot></slot>
    </ContentPortal>
    <FooterPortal></FooterPortal>
  </div>
</template>

<script setup>
import HeaderPortal from "@/views/portal/components/HeaderPortal.vue";
import FooterPortal from "@/views/portal/components/FooterPortal.vue";
import ContentPortal from "@/views/portal/components/ContentPortal.vue";

document.title = "Исследовательский портал НГУ";
</script>

<style lang="scss">
@import "../views/portal/assets/portal-styles.scss";
</style>
