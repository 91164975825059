<template>
  <div class="main-title">
    <h1 class="main-title__text">
      <slot></slot>
    </h1>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
  .main-title {
    .main-title__text {
      font: normal 32px/1.3 'Nunito Sans';
      font-weight: 700;
      margin: 0;
      text-transform: none;
    }
  }
</style>
