<template>
  <progress-bar
    class="progress-bar"
    :items="items"
    :showLabel="false"
    showPercent
  />
</template>

<script setup>
  import ProgressBar from '@/components/projects/management/ProgressBar.vue';
  import {
    STATUS_COMPLETED,
    STATUS_COMPLETED_OVERDUE,
    STATUS_INCOMPLETE,
    STATUS_INCOMPLETE_OVERDUE,
  } from '@/api/models/Checkpoint';
  import { onMounted, ref } from 'vue';

  const items = ref([]);

  const props = defineProps({
    completed: {
      type: Number,
      default: 0,
    },
    completedOverdue: {
      type: Number,
      default: 0,
    },
    incompleteOverdue: {
      type: Number,
      default: 0,
    },
    incomplete: {
      type: Number,
      default: 0,
    },
  });

  onMounted(() => {
    getItems();
  });

  const getItems = () => {
    items.value = [
      ...new Array(props.completed).fill({ status: STATUS_COMPLETED }),
      ...new Array(props.completedOverdue).fill({
        status: STATUS_COMPLETED_OVERDUE,
      }),
      ...new Array(props.incompleteOverdue).fill({
        status: STATUS_INCOMPLETE_OVERDUE,
      }),
      ...new Array(props.incomplete).fill({ status: STATUS_INCOMPLETE }),
    ];
  };
</script>

<style lang="scss" scoped>
  @import 'portfolio-project-card';
</style>
