<template>
  <a-button class="btn-secondary">
    <slot></slot>
  </a-button>
</template>

<script setup></script>

<style lang="scss" scoped>
@import "@/assets/styles/_colors.scss";

.btn-secondary {
  background-color: $color-btn-secondary;
  border-color: transparent;
  border-radius: 4px;
  color: $color-text-primary;
  font: 700 14px/22px "Nunito Sans";
  min-height: 38px;
  min-width: 96px;
  outline: none;

  &:not(:disabled):hover {
    color: $color-text-primary;
    background-color: $color-btn-secondary-hover;
    border-color: transparent;
  }

  &:not(:disabled):focus,
  &:not(:disabled):active {
    color: $color-text-primary;
    background-color: $color-bg-secondary-grey;
    border-color: $color-btn-secondary-active;
  }

  &:disabled {
    color: $color-text-secondary-grey;
    background-color: $color-bg-secondary-grey;
  }
}
</style>
