<template>
  <project-manager-layout>
    <a-col class="request-form">
      <request-info :request="model.request" />
      <request-main-form :request="model.request"/>
      <a-row class="form__buttons" :gutter="[10, 0]" v-if="model.requestStatusId === REQUEST_STATUS_LEADER">
        <a-col>
          <c-button-danger @click="handleRefuse">Отказаться</c-button-danger>
        </a-col>
        <a-col>
          <a-form-item>
            <c-button-primary @click="handleStart">
              Начать проект
            </c-button-primary>
          </a-form-item>
        </a-col>
      </a-row>
    </a-col>
  </project-manager-layout>
</template>

<script setup>
  import { onMounted, reactive } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import { useApi } from '@/api/use-api';
  import ProjectManagerLayout from '@/layouts/ProjectManagerLayout.vue';
  import CButtonPrimary from '@/components/UI/CButtonPrimary';
  import CButtonDanger from '@/components/UI/CButtonDanger.vue';
  import { REQUEST_STATUS_LEADER } from "@/api/models/RequestStatus";
  import RequestInfo from "@/components/requests/components/RequestInfo.vue";
  import RequestMainForm from "@/components/requests/RequestMainForm.vue";

  const api = useApi();
  const router = useRouter();
  const route = useRoute();
  const model = reactive({
    request: null,
    requestId: null,
    requestStatusId: null,
  });

  onMounted(() => {
    getRequest();
  });

  const getRequest = async () => {
    try {
      const response = await api.requestPage.leaderView(route.params.id);
      model.request = response.request;
      model.requestStatusId = response.request.requestStatusId;
    } catch (error) {
      console.error('error', error);
    }
  };

  const handleRefuse = async () => {
    try {
      await api.leaderPage.rejectRequest({ requestId: route.params.id});

      await router.replace({ name: 'project-manager-requests' });
    } catch (error) {
      console.error('error', error);
    }
  };

  const handleStart = async () => {
    const id = route.params.id;

    try {
      let response = await api.leaderPage.checkRequestExistence(id);

      if (response.exist === false) {
        response = await api.leaderPage.createExternalProject({
          requestId: id,
        });
      }

      await router.replace({
        path: `/leader/project/${response.projectId}/form`,
      });
    } catch (error) {
      console.error('error', error);
    }
  }
</script>

<style lang="scss" scoped>
  * {
    font-family: 'Nunito Sans', sans-serif;
    margin: 0;
  }

  .request-form {
    align-content: flex-start;
    height: 100%;
    overflow-y: auto;
    padding-bottom: 100px;

    .request-form__form-block {
      margin-top: 40px;
    }

    .form__buttons {
      justify-content: end;
      margin-top: 40px;
      margin-right: 270px !important;
    }
  }
</style>
