<template>
  <div class="department-tab-about">
    <white-block>
      <content-container class="content-padding pt-40 pb-40">
        <bordered-block>
          <a-row justify="space-between" :wrap="false">
            <a-col>
              <menu-department
                :id="department.id"
                :active="route.params?.tab"
              />
            </a-col>
            <a-col class="sorting">
              <span class="sorting__label">Сортировать</span>
              <a-select :options="sortingList" v-model:value="sorting" />
            </a-col>
          </a-row>
        </bordered-block>
      </content-container>
      <a-spin :spinning="isLoading" />
    </white-block>

    <white-block>
      <content-container class="content-padding">
        <a-row :gutter="[20, 20]" class="employees">
          <a-col
            :sm="24"
            :md="12"
            :lg="6"
            v-for="person in persons"
            :key="`person-` + person.id"
          >
            <person-card
              :id="person.id"
              :name="[person.lastName, person.firstName, person.middleName]"
              :avatar="person.photo"
              :employments="person.employments"
            />
          </a-col>
        </a-row>

        <div class="department-tab-about__pages" v-if="total > persons.length">
          <a-pagination
            v-model:current="currentPage"
            v-model:pageSize="pageSize"
            :total="total"
            :show-size-changer="false"
          >
            <template #itemRender="{ type, originalElement }">
              <pagination-arrows
                :type="type"
                :original-element="originalElement"
              />
            </template>
          </a-pagination>
        </div>
      </content-container>
    </white-block>
  </div>
</template>

<script setup>
  import { onMounted, ref, watch } from 'vue';
  import { useRoute } from 'vue-router';
  import { useApi } from '@/api/use-api';
  import WhiteBlock from '@/views/portal/components/WhiteBlock.vue';
  import PersonCard from '@/views/portal/components/cards/PersonCard.vue';
  import ContentContainer from '@/views/portal/components/ContentContainer.vue';
  import PaginationArrows from '@/views/portal/components/UI/PaginationArrows.vue';
  import MenuDepartment from '@/views/portal/components/menus/MenuDepartment.vue';
  import BorderedBlock from '@/views/portal/components/BorderedBlock.vue';

  const props = defineProps({
    department: {
      type: Object,
      required: true,
    },
  });

  const isLoading = ref(true);
  const api = useApi();
  const route = useRoute();
  const persons = ref([]);
  const sorting = ref('last_name');
  const currentPage = ref(1);
  const pageSize = ref(16);
  const total = ref(0);
  const sortingList = [
    { value: 'last_name', label: 'В алфавитном порядке а-я' },
    { value: '-last_name', label: 'В обратном порядке я-а' },
  ];

  const getPersons = async () => {
    if (undefined === props.department.uuid || !props.department.uuid) return;
    try {
      isLoading.value = true;
      const response = await api.person.index(
        new URLSearchParams({
          isEmployee: true,
          page: currentPage.value,
          'per-page': pageSize.value,
          sort: sorting.value,
          organisationalUnitParentUuid: props.department.uuid,
        })
      );
      persons.value = response.persons;
      total.value = response.total;
    } catch (error) {
      console.error('error', error);
    } finally {
      isLoading.value = false;
    }
  };

  onMounted(() => {
    getPersons();
  });

  watch(sorting, () => {
    getPersons();
  });

  watch(currentPage, () => {
    getPersons();
  });

  watch(pageSize, () => {
    currentPage.value = 1;
    getPersons();
  });

  watch(
    () => props.department.uuid,
    () => getPersons()
  );
</script>

<style lang="scss" scoped>
  .sorting {
    .sorting__label {
      color: #718096;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      margin-right: 15px;
    }
  }
</style>
