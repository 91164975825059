<template>
  <div class="intellectual-tab-concepts">
    <white-block>
      <content-container class="content-padding pt-40 pb-40">
        <bordered-block>
          <menu-intellectual :id="publication.id" :active="route.params?.tab" />
        </bordered-block>

        <div class="intellectual-tab-concepts__info pt-40">
          <p>
            Узнайте самые подробные результаты анализа активности
            <span v-html="publication.title" />. Указанные в этом разделе метки относятся к
            работам этих участников организации. Вместе они формируют уникальную
            картину его активности.
          </p>
        </div>
      </content-container>
    </white-block>

    <gray-block class="pt-80 pb-40">
      <content-container class="content-padding">
        <white-block
            class="p-30 mb-40 round"
            v-for="(concetptsBlock, index) in fingerprints"
            :key="`concepts-` + index"
        >
          <h3>{{ concetptsBlock.label }}</h3>
          <div>
            <tags-percent-list type="gray" :tags="concetptsBlock.items" />
          </div>
        </white-block>
      </content-container>
    </gray-block>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useApi } from '@/api/use-api';
import WhiteBlock from '@/views/portal/components/WhiteBlock.vue';
import ContentContainer from '@/views/portal/components/ContentContainer.vue';
import GrayBlock from '@/views/portal/components/GrayBlock.vue';
import BorderedBlock from '@/views/portal/components/BorderedBlock.vue';
import TagsPercentList from '@/views/portal/components/tags/TagsPercentList.vue';
import MenuIntellectual from "@/views/portal/components/menus/MenuIntellectual.vue";

const api = useApi();
const route = useRoute();
const fingerprints = ref([]);

const props = defineProps({
  publication: {
    type: Object,
    required: true,
  },
});

const getFingerprints = async () => {
  if (undefined === props.publication.uuid || !props.publication.uuid) return;
  try {
    const response = await api.fingerprint.groups({
      page: 1,
      'per-page': 1000,
      sort: '-weighted_rank',
      content_family: 2,
      content_uuid: props.publication.uuid,
    });

    response.fingerprints.map((fps) => {
      fps.label = fps.thesauriName;
      fps.items.map((fp) => {
        fp.tooltip = fp.weightedRank;
      });
    });

    fingerprints.value = response.fingerprints;
  } catch (error) {
    console.error('error', error);
  }
};

onMounted(() => {
  getFingerprints();
});

watch(
    () => props.publication.id,
    () => {
      getFingerprints();
    }
);
</script>

<style lang="scss" scoped>
.intellectual-tab-concepts {
  .intellectual-tab-concepts__info {
    max-width: 900px;
  }
}
</style>