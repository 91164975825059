<template>
  <a-divider />

  <a-row>
    <a-col class="subtask-label" @click.stop="handleShowForm('editTask')">
      Подзадача {{ item.number }} {{ item.name }}
      <span class="edit-button" v-if="checkIfEditable">
        <img
          src="@/assets/icons/edit.svg"
          @click.stop="checkIfEditable && handleShowForm('editTask')"
          alt=""
        />
      </span>
    </a-col>
  </a-row>
  <div class="subtask-checkpoints">
    <checkpoints
      :task-id="item.id"
      :checkpoints="item.checkpoints"
      :disabled="!checkIfEditable"
      @update="updateTasks"
    ></checkpoints>
  </div>

  <div class="subtask-executor">
    <div class="subtask-executor__name">
      Исполнитель:
      <span class="subtask-executor__fullname">{{ item.executor }} </span>
    </div>
    <div class="subtask-executor__progress">
      <progress-bar
        :items="item.checkpoints"
        incompleteColor="white"
      ></progress-bar>
    </div>
  </div>
  <c-drawer v-model:open="isShowForm" destroyOnClose>
    <component
      :is="form"
      :task="item"
      type="subtask"
      @close="handleCloseForm"
      @update="updateTasks"
      @subaction="handleShowForm('deleteTask')"
    />
  </c-drawer>
</template>

<script setup>
  import { computed, ref, shallowRef } from 'vue';
  import Checkpoints from '../CheckPoints/CheckPoints';
  import ProgressBar from '@/components/projects/management/ProgressBar';
  import EditTask from '../Task/EditTask';
  import DeleteTask from '../Task/DeleteTask';
  import CDrawer from '@/components/UI/CDrawer.vue';

  const emit = defineEmits(['close', 'update']);

  const props = defineProps({
    item: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    editorUserId: Number,
  });

  const updateTasks = () => {
    emit('update');
  };

  const form = shallowRef(null);
  const isShowForm = ref(false);
  const taskType = ref('');

  const handleShowForm = (formType) => {
    switch (formType) {
      case 'editTask':
        taskType.value = 'subtask';
        form.value = EditTask;
        break;

      case 'deleteTask':
        form.value = DeleteTask;
        break;
    }

    isShowForm.value = true;
  };

  const handleCloseForm = () => {
    isShowForm.value = false;
    form.value = null;
  };

  const checkIfEditable = computed(() => {
    return (
      !props.disabled &&
      (props.editorUserId ? props.editorUserId === props.item.userId : true)
    );
  });
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  .subtask-label {
    color: $color-bg-status-new;
    cursor: pointer;
    font: 700 16px/22px 'Nunito Sans';

    &:hover {
      .edit-button {
        visibility: visible;
        opacity: 1;
      }
    }

    .edit-button {
      bottom: 10px;
      visibility: hidden;
      opacity: 0;
      margin-left: 10px;
      position: absolute;
      transition: all 0.25s ease-in-out;
    }
  }

  .subtask-checkpoints {
    margin-top: 20px;
    margin-left: 20px;
  }

  .subtask-executor,
  .subtask-executor__fullname {
    color: #718096;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }

  .subtask-executor {
    display: flex;
    justify-content: space-between;
    margin: 30px 0;

    &__fullname {
      font-weight: 400;
      margin-left: 10px;
    }
  }

  .ant-divider {
    border-color: #ffffff;
  }
</style>
