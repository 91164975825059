<template>
  <a-row :gutter="[20, 20]" class="projects">
    <a-col
      :span="24"
      v-for="(project, index) in projects"
      :key="{ id: index++, ...project }"
      @click="handleProjectClick(project)"
    >
      <portfolio-project-card :project="project" />
    </a-col>
  </a-row>
</template>

<script setup>
  import PortfolioProjectCard from '@/components/projects/portfolio/components/PortfolioProjectCard.vue';

  defineProps({
    projects: {
      type: Array,
      default: () => [],
    },
  });

  const emit = defineEmits(['project-click']);

  const handleProjectClick = async (project) => {
    emit(
      'project-click',
      project.id,
      project.projectStatusId,
      project.isMyProject
    );
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  .projects {
    margin-top: 30px;
    padding: 0 0 100px 0;
  }

  .horizontal-filter__panel {
    margin: 0 -40px 0 -20px;
    padding: 8px 40px 8px 20px;
    border-top: 1px solid $color-bg-secondary-grey;
    border-bottom: 1px solid $color-bg-secondary-grey;
  }
</style>
