<template>
  {{ fullName(person) }}
</template>

<script setup>
import {defineProps} from 'vue';

defineProps({
  person: {
    type: Object,
    required: true
  }
});

const fullName = (person) => {
  let s = "";
  if (person.lastName) s += (s ? " " : "" ) + person.lastName;
  if (person.firstName) s += (s ? " " : "" ) + person.firstName;
  if (person.middleName) s += (s ? " " : "" ) + person.middleName;
  return s;
}
</script>

<style lang="scss" scoped>

</style>