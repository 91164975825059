<template>
  <a-row class="right-panel-buttons mt-40">
    <slot></slot>
  </a-row>
</template>

<script setup></script>

<style lang="scss" scoped>
.right-panel-buttons {
  justify-content: flex-end;
  gap: 15px;
}
</style>