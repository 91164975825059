<template>
  <div>
    <a-select
      v-bind="$attrs"
      :value="getValue"
      :filter-option="false"
      :not-found-content="state.fetching ? undefined : null"
      :options="state.data"
      show-search
      @search="fetchOptions"
    >
      <template #notFoundContent v-if="state.fetching">
        <a-spin size="small"/>
      </template>
    </a-select>
  </div>
</template>

<script setup>
import {reactive, watch, computed} from "vue";
import {debounce} from "lodash-es";

const props = defineProps({
  type: String,
  modelValue: String,
  getLabel: {
    type: Function,
    default: (item) => {
      return item.value;
    },
  },
});

const url = process.env.VUE_APP_DADATA_SUGGEST_URL + props.type;
const token = process.env.VUE_APP_DADATA_API_KEY;

const getValue = computed(() => {
  return props.modelValue ? {value: props.modelValue} : undefined;
});

let lastFetchId = 0;
const state = reactive({
  data: [],
  value: [],
  fetching: false,
});

const fetchOptions = debounce((value) => {
  lastFetchId += 1;
  const fetchId = lastFetchId;
  let options = {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Token " + token,
    },
    body: JSON.stringify({query: value}),
  };
  state.data = [];
  state.fetching = true;
  fetch(url, options)
    .then((response) => response.json())
    .then((body) => {
      if (fetchId !== lastFetchId) return;
      state.data = body.suggestions.map((item) => ({
        value: item.value,
        data: item.data !== undefined ? item.data : null,
        label: props.getLabel(item),
      }));
      state.fetching = false;
    });
}, 300);

watch(state.value, () => {
  state.data = [];
  state.fetching = false;
});
</script>

<style scoped></style>
