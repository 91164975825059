<template>
  <SocialIcon :icon="icon" :link="generateLink(id)"/>
</template>

<script setup>
import SocialIcon from "@/components/social/components/SocialIcon.vue";
import icon from "@/components/social/assets/whatsapp.svg";

defineProps({
  id: String, // WhatsApp ID
});

const generateLink = (id) => `https://wa.me/${id}`; // https://faq.whatsapp.com/425247423114725
</script>

<style scoped>

</style>