<template>
  <a-row class="container">
    <a-col :span="24" class="container__title">Контрольные точки</a-col>
    <a-col :span="24">
      <a-row class="container__checkpoints">
        <a-col
          v-for="(checkpoint, index) in checkpoints"
          :span="24"
          class="checkpoints__item"
          :key="index"
        >
          <c-checkpoint
            :status="checkpoint.status"
            :disabled="disabled"
            @click="!disabled && handleShowForm('editCheckPoints', checkpoint)"
          >
            {{ ++index }}.
            <span v-if="checkpoint.date">
              {{ dayjs(checkpoint.date).format('DD.MM.YYYY') }} -
            </span>
            {{ checkpoint.description }}
          </c-checkpoint>
        </a-col>
      </a-row>
    </a-col>
    <a-col :span="24" class="container__add-checkpoint" v-if="!disabled">
      <!-- TODO (NMD): Warning: Obsolete attribute -->
      <a-row align="middle">
        <a-col>
          <img src="@/assets/icons/circle-plus.svg" alt="" />
        </a-col>
        <a-col>
          <a @click="handleShowForm('addCheckPoints', checkpoints[0])">
            Добавить контрольную точку
          </a>
        </a-col>
      </a-row>
    </a-col>
  </a-row>
  <c-drawer v-model:open="isShowForm" destroyOnClose>
    <component
      :is="form"
      :checkpoint="checkpoint"
      :task-id="props.taskId"
      @close="handleCloseForm"
      @update="updateTasks"
      @subaction="handleShowForm('deleteCheckpoint', checkpoint)"
    />
  </c-drawer>
</template>

<script setup>
  import { ref, shallowRef } from 'vue';
  import dayjs from 'dayjs';
  import AddCheckPoints from './AddCheckPoints';
  import EditCheckPoint from './EditCheckPoint';
  import DeleteCheckPoint from './DeleteCheckPoint';
  import CCheckpoint from '@/components/UI/CCheckpoint';
  import CDrawer from '@/components/UI/CDrawer.vue';

  const props = defineProps({
    taskId: Number,
    checkpoints: Array,
    disabled: {
      type: Boolean,
      default: false,
    },
  });

  const emit = defineEmits(['close', 'update']);
  const form = shallowRef(null);
  const checkpoint = ref(null);
  const isShowForm = ref(false);

  const handleShowForm = (formType, item) => {
    switch (formType) {
      case 'addCheckPoints':
        checkpoint.value = item;
        form.value = AddCheckPoints;
        break;

      case 'editCheckPoints':
        checkpoint.value = item;
        form.value = EditCheckPoint;
        break;

      case 'deleteCheckpoint':
        form.value = DeleteCheckPoint;
        break;
    }

    isShowForm.value = true;
  };

  const updateTasks = () => {
    emit('update');
  };

  const handleCloseForm = () => {
    isShowForm.value = false;
    form.value = null;
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  * {
    font-family: 'Nunito Sans', sans-serif;
  }

  .container {
    &__title {
      color: $color-bg-status-new;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      margin-bottom: 20px;
    }

    &__checkpoints {
      .checkpoints__item {
        margin-bottom: 15px;
      }
    }

    &__add-checkpoint {
      margin-left: 30px;

      a {
        border-bottom: 1px solid;
        color: $color-link-primary;
        text-decoration: none;
      }
    }
  }
</style>
