<template>
  <guest-layout>
    <template #navigation>
      <back-arrow :page-name="goBackTo" />
    </template>
    <template #default>
      <mega-title text="Партнер НГУ" />
      <div class="instruction">
        Мы отправили Вам ссылку для сброса пароля на указанный Вами e-mail.
        Проверьте Ваш почтовый ящик и пройдите по ссылке из письма. Если письма
        в папке «Входящие» нет, проверьте папку «Спам».
      </div>
      <nav-button page-name="sign-in" text="Понятно" />
    </template>
  </guest-layout>
</template>

<script setup>
  import { ref } from 'vue';
  import GuestLayout from '@/layouts/GuestLayout';
  import BackArrow from '@/components/navigation/BackArrow';
  import MegaTitle from '@/components/titles/MegaTitle';
  import NavButton from '@/components/navigation/NavButton';

  const goBackTo = ref('sign-in');
</script>

<style lang="scss"></style>
