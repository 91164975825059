<template>
  <portal-layout class="person-page">
    <template v-slot:default>
      <content-container class="content-padding">
        <div class="person-card p-30">
          <a-row :gutter="[35, 20]" :wrap="false">
            <a-col>
              <person-avatar :url="person.photo" />
            </a-col>
            <a-col>
              <h1><person-full-name :person="person" /></h1>

              <template v-if="person.titles && person.titles.length > 0">
                <p
                    v-for="(personTitle, index) in person.titles"
                    :key="`person-title-` + index"
                >
                  <b>{{ personTitle.type }}:</b>
                  {{ personTitle.value }}
                </p>
              </template>

              <p v-if="mainEmployment.organisational" class="person-card__employment">
                <b>Место работы: </b>
                <router-link :to="`/portal/department/` + mainEmployment.organisationalId">
                  {{ mainEmployment.organisational }}
                </router-link>
              </p>
              <p v-if="mainEmployment.position">
                <b>Должность:</b> {{ mainEmployment.position }}
              </p>
            </a-col>
            <a-col class="person-card__contacts">
              <a-row :gutter="[20, 20]" :justify="flex-end">
                <a-col v-if="person.email"><b>E-mail:</b> <a :href="`mailto:` + person.email">{{ person.email }}</a></a-col>
                <a-col v-if="person.phone"><b>Телефон:</b> {{ person.phone }}</a-col>
                <a-col></a-col>
              </a-row>
            </a-col>
          </a-row>
        </div>
      </content-container>
      <person-profile-tab v-if="!route.params.tab" :person="person" />
      <person-concepts-tab
        v-if="route.params.tab === 'concepts'"
        :person="person"
      />
      <person-publications-tab
        v-if="route.params.tab === 'publications'"
        :person="person"
      />
      <person-intellectual-tab
        v-if="route.params.tab === 'intellectuals'"
        :person="person"
      />
    </template>
  </portal-layout>
</template>

<script setup>
  import {onMounted, ref, watch} from 'vue';
  import { useRoute } from 'vue-router';
  import { useApi } from '@/api/use-api';
  import PortalLayout from '@/layouts/PortalLayout.vue';
  import PersonProfileTab from '@/views/portal/person/PersonProfileTab.vue';
  import ContentContainer from '@/views/portal/components/ContentContainer.vue';
  import PersonAvatar from '@/views/portal/components/persons/PersonAvatar.vue';
  import PersonPublicationsTab from "@/views/portal/person/PersonPublicationsTab.vue";
  import PersonIntellectualTab from '@/views/portal/person/PersonIntellectualTab.vue';
  import PersonConceptsTab from "@/views/portal/person/PersonConceptsTab.vue";
  import PersonFullName from "@/views/portal/components/persons/PersonFullName.vue";

  const api = useApi();
  const route = useRoute();
  const person = ref({
    id: '',
    uuid: '',
    name: '',
    type: '',
    typeTitle: '',
    parentUuid: '',
    parentId: '',
    parentName: '',
  });
  const mainEmployment = ref({
    id: '',
    contractType: '',
    organisational: '',
    position: '',
  });

  const getPerson = async () => {
    try {
      const response = await api.person.view(route.params.id);
      person.value = response.person;
      mainEmployment.value = getMainEmployment(response.person.employments);
    } catch (error) {
      console.error('error', error);
    }
  };

  const getMainEmployment = (employments) => {
    if (employments.length === 0) return mainEmployment.value;
    let main = employments[0];
    employments.forEach((e) => {
      if (e.contractType === 'main') main = e;
    });
    return main;
  };

  onMounted(() => {
    getPerson();
  });

  watch(
      () => route.params.id,
      () => {
        getPerson();
      }
  );
</script>

<style lang="scss" scoped>
  .person-card {
    margin-top: -100px;
    border-radius: 6px;
    background: var(--Bg-White, #fff);
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.25);
    position: relative;
    z-index: 10;

    h1 {
      color: #2d3346;
      font-variant-numeric: slashed-zero;
      font-family: 'Nunito Sans', sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
    }

    .person-card__employment {
      a {
        color: inherit;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .person-card__contacts {
      margin-left: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      a {
        color: inherit;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
</style>
