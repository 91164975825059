import {
  USER_ROLE_ADMINISTRATOR,
  USER_ROLE_LEADER,
  USER_ROLE_PARTNER,
} from '@/api/models/UserRole';

export const DOCUMENT_APPROVAL_STATUS_TO_APPROVE = 'to_approve';
export const DOCUMENT_APPROVAL_STATUS_NEGOTIATION = 'negotiation';
export const DOCUMENT_APPROVAL_STATUS_APPROVED = 'approved';
export const DOCUMENT_APPROVAL_STATUS_REJECTED = 'rejected';
export const DOCUMENT_APPROVAL_STATUS_REMOVED = 'removed';
export const DOCUMENT_APPROVAL_STATUS_UNDEFINED = 'undefined';

const getDocumentApprovalStatus = (approval) => {
  if (approval.docId == null) {
    return DOCUMENT_APPROVAL_STATUS_TO_APPROVE;
  }
  if (approval.approvedAt == null && approval.rejectedAt == null) {
    return DOCUMENT_APPROVAL_STATUS_NEGOTIATION;
  }
  if (approval.approvedAt && approval.rejectedAt == null) {
    return DOCUMENT_APPROVAL_STATUS_APPROVED;
  }
  if (approval.approvedAt == null && approval.rejectedAt) {
    return DOCUMENT_APPROVAL_STATUS_REJECTED;
  }
  if (approval.approvedAt && approval.rejectedAt) {
    return DOCUMENT_APPROVAL_STATUS_REMOVED;
  }
  return DOCUMENT_APPROVAL_STATUS_UNDEFINED;
};

export const getShowcaseApprovalVisibleStatus = (approval, role) => {
  const status = getDocumentApprovalStatus(approval);

  switch (role) {
    case USER_ROLE_LEADER:
      return getProjectShowcaseApprovalStatusForLeader(status);
    case USER_ROLE_ADMINISTRATOR:
      return getProjectShowcaseApprovalStatusAdmin(status);
    case USER_ROLE_PARTNER:
      return getProjectShowcaseApprovalStatusPartner(status);
    default:
      return {
        type: 'undefined',
        text: 'Undefined',
        actionLabel: 'Undefined',
        description: 'Undefined',
      };
  }
};

const getProjectShowcaseApprovalStatusForLeader = (status) => {
  switch (status) {
    case DOCUMENT_APPROVAL_STATUS_TO_APPROVE:
      return {
        type: status,
        text: 'Черновик',
        actionLabel: 'Требуется согласование публикации проекта',
        description: '',
      };
    case DOCUMENT_APPROVAL_STATUS_NEGOTIATION:
      return {
        type: status,
        text: 'На согласовании',
        actionLabel: 'Согласовать публикацию проекта с администратором',
        description: '',
      };
    case DOCUMENT_APPROVAL_STATUS_APPROVED:
      return {
        type: status,
        text: 'Согласовано',
        actionLabel: 'Согласовать публикацию проекта с администратором',
        description: '',
      };
    case DOCUMENT_APPROVAL_STATUS_REJECTED:
    case DOCUMENT_APPROVAL_STATUS_REMOVED:
      return {
        type: status,
        text: 'Отклонено',
        actionLabel: 'Согласовать публикацию проекта с администратором',
        description: '',
      };
    default:
      return {
        type: 'undefined',
        text: 'Undefined',
        actionLabel: 'Undefined',
        description: '',
      };
  }
};

const getProjectShowcaseApprovalStatusAdmin = (status) => {
  switch (status) {
    case DOCUMENT_APPROVAL_STATUS_TO_APPROVE:
      return {
        type: status,
        text: 'Черновик',
        actionLabel: 'Публикацию проекта необходимо отправить на согласование',
        description:
          'Вам пока ничего не нужно делать. ' +
          'Руководитель проекта скоро отправит этот проект на согласование для публикации на витрине.',
      };
    case DOCUMENT_APPROVAL_STATUS_NEGOTIATION:
      return {
        type: status,
        text: 'На согласовании',
        actionLabel: 'Требуется согласовать публикацию проекта на витрине',
        description:
          'Руководитель проекта хочет опубликовать этот проект на витрине. Проект будет опубликовна только после ' +
          'Вашего согласования. Согласуйте публикацию проекта на витрине, нажав на кнопку ниже, ' +
          'или отклоните публикацию проекта, если Вы против или имеются корректировки к описанию проекта.',
      };
    case DOCUMENT_APPROVAL_STATUS_APPROVED:
      return {
        type: status,
        text: 'Согласовано',
        actionLabel: 'Вы согласовали публикацию проекта на витрине',
        description:
          'Вы согласовали публикацию проекта на витрине. ' +
          'Если Вы изменили решение или у Вас появились корректировки к описанию проекта, ' +
          'Вы можете снять с публикации проект в любое время. ',
      };
    case DOCUMENT_APPROVAL_STATUS_REJECTED:
      return {
        type: status,
        text: 'Отклонено',
        actionLabel: 'Вы отклонили публикацию проекта',
        description:
          'Вы отклонили публикацию проекта. Если требуется опубликовать проект на витрине, нажмите на кнопку ниже.',
      };
    case DOCUMENT_APPROVAL_STATUS_REMOVED:
      return {
        type: status,
        text: 'Отозвано',
        actionLabel: 'Вы сняли проект с публикации',
        description:
          'Вы сняли проект с публикации. Если требуется вернуть проект на витрину, нажмите на кнопку ниже.',
      };
    default:
      return {
        type: 'undefined',
        text: 'Undefined',
        actionLabel: 'Undefined',
        description: 'Undefined status',
      };
  }
};

const getProjectShowcaseApprovalStatusPartner = (status) => {
  return getProjectShowcaseApprovalStatusForLeader(status);
};
