<template>
  <div class="progress-row">
    <div class="progress-row__indicator" :style="calcItemPercent(item)" />
    <a-tooltip placement="top">
      <template #title>
        {{ item.name }}
      </template>

      <div class="progress-row__name">
        {{ `${item.idx + 1}. ${item.name}` }}
      </div>
    </a-tooltip>

    <div class="progress-row__percentage">
      {{ item.completed ?? 0 }}/{{ item.planned }}
    </div>
  </div>
</template>

<script setup>
  defineProps({
    item: Object,
  });

  const calcItemPercent = (item) => {
    const width = ((item.completed ?? 0 - item.planned) / item.planned) * 100;

    return {
      width: width + '%',
      'background-color':
        item.completed > 0
          ? `rgba(87, 155, 21, ${width / 100})`
          : 'transparent',
    };
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  .progress-row {
    align-items: center;
    column-gap: 20px;
    display: flex;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
    width: 100%;

    &__indicator {
      background-color: $color-btn-primary-hover;
      height: 40px;
      position: relative;
      transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    }

    &__name {
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 10px 0 10px 15px;
      position: absolute;
      white-space: nowrap;
      width: 80%;
    }

    &__percentage {
      margin-right: 6%;
      width: 5%;
    }
  }
</style>
