<template>
  <project-manager-layout :spinning="isLoading">
    <leader-project-layout :model="model">
      <a-row class="leader-project-form" :wrap="false">
        <a-col :span="5">
          <project-menu
            :project="model.project"
            :menu-steps="menuSteps"
            @validate="onValidationChange"
          />
        </a-col>
        <a-col :span="19" class="leader-project-form__content">
          <template v-if="step < 9">
            <component
              ref="stepRef"
              :is="stepComponents[step - 1]"
              :project="model.project"
              :disabled="disabled"
              @update="getModel"
              @confirm-update="onConfirmUpdate"
              @update-type="onUpdateType"
            />
          </template>
          <template v-else>
            <component
              :is="stepComponents[step - 1]"
              :project="model.project"
              :is-valid="model.isValid"
              @update="getModel"
            />
          </template>
        </a-col>
        <c-drawer v-model:open="showUpdateConfirmation" destroyOnClose>
          <component
            :is="ProjectUpdateConfirmation"
            @close="showUpdateConfirmation = !showUpdateConfirmation"
            @review="onReview"
          />
        </c-drawer>
      </a-row>
    </leader-project-layout>
  </project-manager-layout>
</template>

<script setup>
  import { computed, onMounted, reactive, ref } from 'vue';
  import { useRoute } from 'vue-router';
  import { useApi } from '@/api/use-api';
  import ProjectManagerLayout from '@/layouts/ProjectManagerLayout.vue';
  import LeaderProjectLayout from '@/views/leader/projects/components/LeaderProjectLayout.vue';
  import LeaderProjectFormStep1 from '@/views/leader/projects/form/steps/LeaderProjectFormStep1.vue';
  import LeaderProjectFormStep2 from '@/views/leader/projects/form/steps/LeaderProjectFormStep2.vue';
  import LeaderProjectFormStep3 from '@/views/leader/projects/form/steps/LeaderProjectFormStep3.vue';
  import LeaderProjectFormStep4 from '@/views/leader/projects/form/steps/LeaderProjectFormStep4.vue';
  import LeaderProjectFormStep5 from '@/views/leader/projects/form/steps/LeaderProjectFormStep5.vue';
  import LeaderProjectFormStep6 from '@/views/leader/projects/form/steps/LeaderProjectFormStep6.vue';
  import LeaderProjectFormStep7 from '@/views/leader/projects/form/steps/LeaderProjectFormStep7.vue';
  import LeaderProjectFormStep8 from '@/views/leader/projects/form/steps/LeaderProjectFormStep8.vue';
  import LeaderProjectFormStep9 from '@/views/leader/projects/form/steps/LeaderProjectFormStep9.vue';
  import ProjectMenu from '@/views/leader/projects/components/ProjectMenu.vue';
  import CDrawer from '@/components/UI/CDrawer.vue';
  import ProjectUpdateConfirmation from '@/views/leader/projects/components/ProjectUpdateConfirmation.vue';
  import { useStore } from 'vuex';
  import {
    PROJECT_STATUS_ACCEPTANCE,
    PROJECT_STATUS_CLOSED,
  } from '@/api/models/ProjectStatus';

  const api = useApi();
  const route = useRoute();
  const store = useStore();
  const isLoading = ref(true);
  const showUpdateConfirmation = ref(false);
  const stepRef = ref();
  const stepComponents = [
    LeaderProjectFormStep1,
    LeaderProjectFormStep2,
    LeaderProjectFormStep3,
    LeaderProjectFormStep4,
    LeaderProjectFormStep5,
    LeaderProjectFormStep6,
    LeaderProjectFormStep7,
    LeaderProjectFormStep8,
    LeaderProjectFormStep9,
  ];

  const model = reactive({
    project: null,
    id: null,
    type: '',
    number: '',
    name: '',
    description: '',
    isExternal: false,
    isMyProject: false,
    requestId: null,
    status: null,
    isValid: false, // Карточка проекта заполнена (заполнены требуемые поля)
    projectStatusId: null,
  });

  const menuSteps = [
    { step: 1, label: 'Информация о проекте', hide: false, validate: true },
    { step: 2, label: 'Техническое задание', hide: false, validate: true },
    { step: 3, label: 'Календарный план', hide: false, validate: true },
    { step: 4, label: 'Команда проекта', hide: false, validate: true },
    { step: 5, label: 'Финансы', hide: false, validate: true },
    { step: 6, label: 'Ресурсы', hide: false, validate: true },
    { step: 7, label: 'Портфели и показатели', hide: false, validate: true },
    { step: 8, label: 'Документы', hide: false, validate: true },
    { step: 9, label: 'Согласование', hide: false, validate: true },
  ];

  const getModel = async () => {
    try {
      const response = await api.leaderPage.projectSteps(route.params.id);
      model.project = response.project;
      model.id = response.project.id;
      model.type = response.project.type;
      model.name = response.project.name;
      model.number = response.project.id;
      model.isExternal = response.project.isExternal;
      model.isMyProject = response.project.isMyProject;
      model.requestId = response.project.requestId;
      model.status = response.project.status;
      model.projectStatusId = response.project.projectStatusId;
    } catch (error) {
      console.error('error', error);
    }
  };

  const disabled = computed(
    () =>
      !model.isMyProject ||
      [PROJECT_STATUS_ACCEPTANCE, PROJECT_STATUS_CLOSED].includes(
        model.project?.projectStatusId
      )
  );

  onMounted(async () => {
    await getModel();
    isLoading.value = false;
  });

  const step = computed(() => {
    return route.hash ? Number(route.hash.replace('#', '')) : 1;
  });

  const onValidationChange = (event) => {
    model.isValid = event;
  };

  const onConfirmUpdate = () => {
    showUpdateConfirmation.value = true;
  };

  const onReview = async () => {
    await stepRef.value.onFinish();
  };

  const onUpdateType = (typeId) => {
    model.type = store.getters.projectTypeTextById(typeId);
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  h3 {
    color: $color-black;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.1;
  }

  .leader-project-form {
    .leader-project-form__content {
      padding-left: 20px;
      padding-bottom: 100px;
    }
  }
</style>
