export default {
    resources: [
        {
            required: true,
            min: 2,
            message:
                'Необходимо описать необходимые для выполнения проекта ресурсы',
            trigger: 'blur',
        },
    ],
};
