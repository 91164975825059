<template>
  <a-row class="leader-project-form__row">
    <a-col>
      <slot></slot>
    </a-col>
    <a-col class="leader-project-form__available">
      <c-checkbox
        v-if="showAvailable"
        :checked="available"
        @change="changeHandler"
        :class="{ margined: offsetAvailable }"
        :disabled="disabled"
      >
        Доступно для партнера
      </c-checkbox>
    </a-col>
  </a-row>
</template>

<script setup>
  import CCheckbox from '@/components/UI/CCheckbox.vue';

  const emit = defineEmits(['change']);

  defineProps({
    showAvailable: {
      type: Boolean,
      default: false,
    },
    offsetAvailable: {
      type: Boolean,
      default: true,
    },
    available: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  });

  const changeHandler = () => {
    emit('change');
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  .leader-project-form__row {
    flex-wrap: nowrap;
    & > .ant-col:first-child {
      flex: 1 1 100%;
    }

    &.bordered {
      border-bottom: 1px solid $color-line;
      padding-bottom: 6px;
      margin-bottom: 30px;
    }

    :deep(.button-add) {
      margin-left: 30px;
    }
  }
  .leader-project-form__available {
    flex: 0 0 215px;
    padding-left: 20px;
    padding-top: 9px;

    .margined {
      margin-top: 30px;
    }
  }
</style>
