<template>
  <a-checkbox class="c-checkbox">
    <slot></slot>
  </a-checkbox>
</template>

<script setup></script>

<style lang="scss">
  .c-checkbox.ant-checkbox-wrapper {
    font-family: 'Nunito Sans';
    user-select: none;

    &:hover .ant-checkbox .ant-checkbox-inner {
      border-color: #579b15;
    }

    &:hover + .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #72c81e;
      border-color: transparent;
    }

    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: #72c81e;
    }

    .ant-checkbox-input:focus-visible + .ant-checkbox-inner {
      outline: none;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #72c81e !important;
      border-color: #72c81e !important;
    }

    .ant-checkbox-checked:after {
      border-color: #72c81e !important;
    }
  }
</style>
