<template>
  <div class="leader-project-form-buttons">
    <c-button-secondary
      v-if="isDraft"
      html-type="submit"
      :loading="loading && isSecondaryPressed"
      :disabled="isDisabled || (loading && isPrimaryPressed)"
      @click="handleClick(false)"
    >
      Сохранить черновик
    </c-button-secondary>
    <c-button-primary
      v-if="isDraft"
      html-type="submit"
      :loading="loading && isPrimaryPressed"
      :disabled="isDisabled || (loading && isSecondaryPressed)"
      @click="handleClick(true)"
    >
      Продолжить
    </c-button-primary>
    <c-button-primary
      v-else
      :loading="loading && isPrimaryPressed"
      :disabled="isDisabled || (loading && isSecondaryPressed)"
      @click="() => emit('confirm-update')"
    >
      Изменить
    </c-button-primary>
  </div>
</template>

<script setup>
  import CButtonSecondary from '@/components/UI/CButtonSecondary.vue';
  import CButtonPrimary from '@/components/UI/CButtonPrimary.vue';
  import {
    PROJECT_STATUS_ACCEPTANCE,
    PROJECT_STATUS_CLOSED,
    PROJECT_STATUS_DRAFT,
  } from '@/api/models/ProjectStatus';
  import { computed, ref } from 'vue';

  const emit = defineEmits(['click', 'confirm-update']);
  const props = defineProps({
    projectStatusId: Number,
    loading: {
      type: Boolean,
      default: false,
    },
  });
  const isPrimaryPressed = ref(false);
  const isSecondaryPressed = ref(false);

  const isDraft = computed(
    () =>
      props.projectStatusId && props.projectStatusId === PROJECT_STATUS_DRAFT
  );
  const isDisabled = computed(() => {
    return (
      props.projectStatusId &&
      (props.projectStatusId === PROJECT_STATUS_ACCEPTANCE ||
        props.projectStatusId === PROJECT_STATUS_CLOSED)
    );
  });

  const handleClick = (goNext) => {
    if (goNext) {
      isPrimaryPressed.value = true;
      isSecondaryPressed.value = false;
    } else {
      isPrimaryPressed.value = false;
      isSecondaryPressed.value = true;
    }
    emit('click', goNext);
  };
</script>

<style lang="scss" scoped>
  .leader-project-form-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    & > button {
      margin-left: 10px;

      &:first-child {
        margin-left: 0;
      }
    }
  }
</style>
