<template>
  <performer-projects-layout>
    <template #default>
      <main-title>Мои проекты</main-title>
      <horizontal-filter>
        <template #default>
          <filter-item hash="" text="В работе" />
          <!-- temp wrapper -->
          <div style="opacity: 0.5">
            <filter-item
              hash="#archive"
              text="Архив"
              style="pointer-events: none"
            />
          </div>
        </template>
        <template #right>
          <label class="panel__total-requests">
            Всего: {{ totalProjects }}
          </label>
        </template>
      </horizontal-filter>
      <projects-list :projects="projects" />
    </template>
  </performer-projects-layout>
</template>

<script setup>
  import { onMounted, ref } from 'vue';
  import { useApi } from '@/api/use-api';
  import PerformerProjectsLayout from './layouts/PerformerProjectsLayout.vue';
  import MainTitle from '@/components/titles/MainTitle';
  import HorizontalFilter from '@/components/filter/horizontal/HorizontalFilter';
  import FilterItem from '@/components/filter/components/FilterItem';
  import ProjectsList from './components/ProjectsList.vue';

  const api = useApi();
  const totalProjects = ref(0);
  const projects = ref([]);
  onMounted(() => {
    getProjects();
  });

  const getProjects = async () => {
    try {
      const response = await api.performerPage.projects();
      projects.value = response.projects;
      totalProjects.value = projects.value?.length;
    } catch (error) {
      console.error('error', error);
    }
  };
</script>

<style lang="scss" scoped></style>
