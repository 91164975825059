<template>
  <guest-layout>
    <template #navigation>
      <back-arrow page-name="sign-in" />
    </template>
    <template #default>
      <mega-title text="Партнер НГУ" />
      <div class="instruction">
        <p>Создайте аккаунт, заполнив форму ниже.</p>
        <p>
          После подтверждения учетной записи на указанную Вами почту будет
          отправлен логин и пароль.
        </p>
      </div>
      <a-form
        class="form form_two-columns sign-up-form"
        :model="model"
        :rules="rules"
        @validate="errors.onValidate"
        @finish="onFinish"
      >
        <a-row>
          <a-col :span="12">
            <a-form-item
              name="lastName"
              :help="errors.getError('lastName')"
              :validate-status="errors.getStatus('lastName')"
            >
              <a-input v-model:value="model.lastName" placeholder="Фамилия*" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              name="firstName"
              :help="errors.getError('firstName')"
              :validate-status="errors.getStatus('firstName')"
            >
              <a-input v-model:value="model.firstName" placeholder="Имя*" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item
              name="middleName"
              :help="errors.getError('middleName')"
              :validate-status="errors.getStatus('middleName')"
            >
              <a-input
                v-model:value="model.middleName"
                placeholder="Отчество"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              name="position"
              :help="errors.getError('position')"
              :validate-status="errors.getStatus('position')"
            >
              <a-input
                v-model:value="model.position"
                placeholder="Должность*"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item
              name="phone"
              :help="errors.getError('phone')"
              :validate-status="errors.getStatus('phone')"
            >
              <a-input
                v-model:value="model.phone"
                v-maska
                data-maska="+7 (###) ###-####"
                placeholder="Контактный телефон*"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              name="email"
              :help="errors.getError('email')"
              :validate-status="errors.getStatus('email')"
            >
              <c-input-dadata
                v-model:value="model.email"
                type="email"
                placeholder="Email*"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item
              name="companyName"
              :help="errors.getError('companyName')"
              :validate-status="errors.getStatus('companyName')"
            >
              <c-input-dadata
                v-model:value="model.companyName"
                type="party"
                placeholder="Название компании*"
                @select="selectCompany"
                :get-label="getCompanyLabel"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              name="companySite"
              :help="errors.getError('companySite')"
              :validate-status="errors.getStatus('companySite')"
            >
              <a-input
                v-model:value="model.companySite"
                placeholder="Сайт компании"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-item
              name="companyAbout"
              :help="errors.getError('companyAbout')"
              :validate-status="errors.getStatus('companyAbout')"
            >
              <a-textarea
                v-model:value="model.companyAbout"
                placeholder="О компании"
              />
            </a-form-item>
          </a-col>
          <attachments-input
            :error="errors.getError('attachmentUids')"
            :is-editable="true"
            :accept="ATTACHMENT_COMMON_EXTENSIONS"
            @update="handleUpdateAttachments"
          />
        </a-row>
        <div class="buttons-block">
          <a-form-item>
            <a-button class="submit-button" type="primary" html-type="submit">
              Зарегистрироваться
            </a-button>
          </a-form-item>
        </div>
      </a-form>
    </template>
    <template #footer>
      <div class="footer">
        Если Вам не пришло письмо с логином-паролем, то обратитесь в техническую
        поддержку - <a href="mailto:support@nsu.ru">support@nsu.ru</a>
      </div>
    </template>
  </guest-layout>
</template>

<script setup>
  import { reactive } from 'vue';
  import { useRouter } from 'vue-router';
  import { useApi } from '@/api/use-api';
  import GuestLayout from '@/layouts/GuestLayout';
  import BackArrow from '@/components/navigation/BackArrow';
  import MegaTitle from '@/components/titles/MegaTitle';
  import CInputDadata from '@/components/UI/CInputDadata.vue';
  import { vMaska } from 'maska';
  import ErrorsHandler from '@/components/form/errors-handler';
  import AttachmentsInput from '@/components/attachments/AttachmentsInput.vue';
  import { ATTACHMENT_COMMON_EXTENSIONS } from '@/components/attachments/AttachmentExtentions';

  const router = useRouter();
  const model = reactive({
    lastName: '',
    firstName: '',
    middleName: '',
    position: '',
    phone: '',
    email: '',
    companyName: '',
    companyFullName: '',
    companyInn: '',
    companySite: '',
    companyAbout: '',
    attachmentUids: [],
  });

  const rules = {
    lastName: [
      {
        required: true,
        min: 2,
        max: 255,
        message: 'Необходимо ввести фамилию, не менее 2 символов',
        trigger: 'blur',
      },
    ],
    firstName: [
      {
        required: true,
        min: 2,
        max: 255,
        message: 'Необходимо ввести имя, не менее 2 символов',
        trigger: 'blur',
      },
    ],
    position: [
      {
        required: true,
        message: 'Необходимо ввести должность',
        trigger: 'blur',
      },
    ],
    phone: [
      {
        required: true,
        message: 'Необходимо ввести номер телефона',
        trigger: 'blur',
      },
    ],
    email: [
      {
        required: true,
        type: 'email',
        message: 'Введён некорректный адрес электронной почты',
        trigger: 'blur',
      },
    ],
    companyName: [
      {
        required: true,
        message: 'Необходимо ввести название компании',
        trigger: 'blur',
      },
    ],
  };

  const getCompanyLabel = (item) => {
    return item.value + ' (ИНН:' + item.data.inn + ')';
  };

  const selectCompany = (v, item) => {
    model.companyInn = item.data.inn;
    model.companyFullName = item.data.name.full_with_opf;
  };

  const api = useApi();
  const errors = new ErrorsHandler(
    {
      lastName: '',
      firstName: '',
      middleName: '',
      position: '',
      phone: '',
      email: '',
      companyName: '',
      companyInn: '',
      companySite: '',
      companyAbout: '',
      attachmentUids: '',
    },
    (fieldName) => {
      if ('site' === fieldName) {
        return 'companySite';
      }

      if ('about' === fieldName) {
        return 'companyAbout';
      }

      return fieldName;
    }
  );

  const onFinish = async () => {
    try {
      await api.signUp.signUp({
        email: model.email,
        phone: model.phone,
        firstName: model.firstName,
        lastName: model.lastName,
        middleName: model.middleName,
        position: model.position,
        companyName: model.companyName,
        companyFullName: model.companyFullName,
        companyInn: model.companyInn,
        site: model.companySite,
        about: model.companyAbout,
        attachmentUids: model.attachmentUids,
      });

      await router.push({ name: 'sign-up-success' });
    } catch (error) {
      errors.handleApiErrors(error);
    }
  };

  const handleUpdateAttachments = (uidList) => {
    model.attachmentUids = uidList;
  };
</script>

<style lang="scss" scoped>
  .form_two-columns {
    .ant-row {
      margin: 0 -10px;

      .ant-col {
        padding: 0 10px;
      }
    }
  }

  .sign-up-form {
    textarea {
      height: 100px;
      resize: none;
    }

    .buttons-block {
      display: flex;
      justify-content: flex-end;
    }
  }
</style>
