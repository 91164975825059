<template>
  <a-form class="form" ref="formRef" :model="model" @finish="onFinish()">
    <div class="indexes-card">
      <a-row class="header">
        <a-col :span="12">Показатель</a-col>
        <a-col :span="4">План</a-col>
        <a-col :span="4">Факт</a-col>
      </a-row>

      <a-row class="indexes">
        <a-col :span="24" v-for="(index, idx) in model.indexes" :key="idx">
          <a-row class="index-row">
            <a-col :span="12">
              <span
                class="index-status"
                :class="index.completed === index.planned ? 'done' : ''"
              />
              {{ index.name }}
            </a-col>
            <a-col :span="4">
              <a-input
                :defaultValue="index.planned"
                placeholder="Введите"
                disabled
              />
            </a-col>
            <a-col :span="4">
              <a-form-item
                :name="['indexes', idx, 'completed']"
                :rules="{
                  required: true,
                  type: 'number',
                  min: 0,
                  trigger: ['change', 'blur'],
                  message: 'Введите положительное значение или 0',
                }"
              >
                <a-input-number
                  v-model:value="index.completed"
                  placeholder="Введите"
                  :disabled="disabled"
                />
              </a-form-item>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </div>
    <a-form-item v-if="!hideSaveButton" class="actions">
      <c-button-primary
        html-type="submit"
        :loading="isLoading"
        :disabled="disabled"
      >
        Сохранить
      </c-button-primary>
    </a-form-item>
  </a-form>
</template>

<script setup>
  import { onMounted, reactive, ref } from 'vue';
  import { useApi } from '@/api/use-api';
  import CButtonPrimary from '@/components/UI/CButtonPrimary';

  const api = useApi();

  const props = defineProps({
    briefcase: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideSaveButton: {
      type: Boolean,
      default: false,
    },
  });

  const model = reactive({
    id: null,
    // number: '',
    name: '',
    indexes: [],
  });
  const isLoading = ref(false);

  onMounted(async () => {
    fillModel();
    isLoading.value = false;
  });

  const fillModel = () => {
    try {
      model.id = props.briefcase.id;
      model.name = props.briefcase.name;
      model.indexes = props.briefcase.indexes;
    } catch (error) {
      console.error('error', error);
    }
  };

  const onFinish = async () => {
    try {
      isLoading.value = true;
      const indexesToSave = props.briefcase.indexes.map((item) => ({
        id: item.id,
        value: item.completed,
      }));
      await api.briefcaseProjectIndex.updateCompleted({
        payload: indexesToSave,
      });
    } catch (error) {
      console.error('error', error);
    } finally {
      isLoading.value = false;
    }
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  .leader-project__common-progress {
    margin-top: 42px;
  }

  .ant-input {
    font-family: 'Nunito Sans', sans-serif;
    &[disabled] {
      color: $color-text-primary;
    }
  }

  .ant-form-item {
    margin-bottom: 0 !important;
  }

  .project-indexes {
    margin-top: 10px;
    padding-bottom: 100px;

    h3 {
      font-size: 18px;
      margin-bottom: 10px;
    }

    h4 {
      font-size: 14px;
      margin: 30px 0 6px;

      &.disabled {
        color: $color-bg-status-new !important;
      }
    }

    .description {
      font-family: 'Nunito Sans', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      max-width: 678px;
    }

    .divider {
      margin: 40px 0 30px;
    }

    .indexes-card {
      border-radius: 7px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: stretch;
      background: $color-bg-secondary-grey;
      padding: 20px;
      margin-top: 20px;

      .header {
        color: $color-bg-status-new;
        border-bottom: 1px solid $color-white;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 13px;
        padding-bottom: 12px;
        column-gap: 65px;
      }

      .indexes {
        row-gap: 16px;

        .index-row {
          align-items: center;
          column-gap: 65px;

          .index-status {
            background-color: $color-bg-primary-white;
            border-radius: 10px;
            display: inline-block;
            margin-right: 14px;
            height: 10px;
            width: 10px;

            &.done {
              background-color: $color-bg-status-done;
            }
          }

          .ant-input-number {
            width: 100%;
          }
        }
      }
    }

    .actions {
      text-align: right;
      margin-top: 26px;
    }
  }
</style>
