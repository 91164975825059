<template>
  <div class="chart-item">
    <div class="chart-item__label" v-show="label" v-html="label"></div>
    <div class="chart-item__box">
      <div class="chart-item__barbox">
        <div
          class="chart-item__bar"
          :style="barStyle(value, maxValue, color)"
        ></div>
      </div>
      <div class="chart-item__value">{{ value }}</div>
    </div>
  </div>
</template>

<script setup>
import { numberValue } from "./charts";

defineProps({
  label: {
    type: String,
    default: "",
  },
  value: {
    type: String,
    default: "0",
  },
  maxValue: {
    type: Number,
    default: 100,
  },
  color: {
    type: String,
    default: "#383D4F",
  },
});

const barStyle = (value, maxValue, color) => {
  const p = maxValue === 0 ? 0 : (100 * numberValue(value)) / maxValue;
  return "background-color: " + color + ";" + "width: " + p + "%;";
};
</script>

<style lang="scss" scoped>
.chart-item {
  .chart-item__label {
    color: #718096;
    font-variant-numeric: slashed-zero;
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.28;
    margin-bottom: 0.7em;
  }

  .chart-item__box {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    .chart-item__barbox {
      flex: 0 0 75%;
      height: 20px;
      border-radius: 0 20px 20px 0;
      background: #1c1c1c0d;
      overflow: hidden;

      .chart-item__bar {
        content: "";
        display: block;
        border-radius: 0 4px 4px 0;
        height: 100%;
        width: 0;
        background: #383d4f;
      }
    }

    .chart-item__value {
      color: #101426;
      text-align: right;
      font-family: "Nunito Sans", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 1;
      margin-left: 13px;
    }
  }
}
</style>
