<template>
  <portal-layout class="intellectuals-page">
    <template v-slot:header>
      <main-title>
        <template v-slot:default>ИНТЕЛЛЕКТУАЛЬНАЯ СОБСТВЕННОСТЬ</template>
        <template v-slot:description>

        </template>
      </main-title>
      <div class="intellectuals-page__search">
        <input-search
            placeholder="Ищите подразделения, сотрудников, публикации и многое другое"
        />
      </div>
    </template>
    <template v-slot:default>
      <white-block>
        <content-container class="content-padding">
          <a-row
              class="intellectuals-page__header"
              justify="space-between"
              align="center"
              :wrap="false"
          >
            <a-col class="sorting">
              <span class="sorting__label">Сортировать</span>
              <a-select :options="sortingList" v-model:value="sorting" />
            </a-col>
            <a-col>
              <a-badge
                  :count="countFilter"
                  :number-style="{ backgroundColor: '#72c81e', color: '#fff' }"
              >
                <button-primary @click="openFilter = true">
                  Фильтр
                </button-primary>
              </a-badge>
              <right-panel
                  title="Фильтр"
                  v-model:open="openFilter"
                  @close="openFilter = false"
              >
                <a-form @submit.prevent>
                  <!-- TODO: other filters -->
                  <right-panel-title>Концепции</right-panel-title>
                  <a-form-item>
                    <input-tags
                        placeholder="Введите концепции"
                        :tags="filterTags"
                        @update="setFilterTags"
                    ></input-tags>
                  </a-form-item>
                  <div class="filter-buttons mt-40">
                    <button-default @click="resetFilter">
                      Очистить
                    </button-default>
                    <button-primary @click="applyFilter">
                      Применить
                    </button-primary>
                  </div>
                </a-form>
              </right-panel>
            </a-col>
          </a-row>

          <div class="intellectuals-page__info pt-40 pb-40">
            <two-columns>
              <template v-slot:left>
                <gray-block class="h-100 p-30 round">
                  <chart-wrapper
                      title="Количество объектов интеллектуальной собственности за последние 5 лет"
                  >
                    <chart-vertical :items="chart1Items" />
                  </chart-wrapper>
                </gray-block>
              </template>
              <template v-slot:right>
                <gray-block class="h-100 p-30 round">
                  <chart-wrapper
                      title="Количество патентов/свидетельств по видам за последние 5 лет"
                  >
                    <chart-horizontal :items="chart2Items" />
                  </chart-wrapper>
                </gray-block>
              </template>
            </two-columns>
          </div>
        </content-container>

        <content-container class="content-padding">
          <publications-list :publications="publications" url="intellectual" />
          <div
              class="intellectuals-page__pages"
              v-if="total > publications.length"
          >
            <a-pagination
                v-model:current="currentPage"
                v-model:pageSize="pageSize"
                :total="total"
                :show-size-changer="false"
            >
              <template #itemRender="{ type, originalElement }">
                <pagination-arrows
                    :type="type"
                    :original-element="originalElement"
                />
              </template>
            </a-pagination>
          </div>
        </content-container>
      </white-block>
    </template>
  </portal-layout>
</template>

<script setup>
import {computed, onMounted, ref, watch} from "vue";
import {useApi} from "@/api/use-api";
import PortalLayout from "@/layouts/PortalLayout.vue";
import InputSearch from "@/views/portal/components/UI/InputSearch.vue";
import ContentContainer from "@/views/portal/components/ContentContainer.vue";
import MainTitle from "@/views/portal/components/MainTitle.vue"
import TwoColumns from "@/views/portal/components/TwoColumns.vue";
import GrayBlock from "@/views/portal/components/GrayBlock.vue";
import ChartWrapper from "@/views/portal/components/charts/ChartWrapper.vue";
import ChartVertical from "@/views/portal/components/charts/ChartVertical.vue";
import WhiteBlock from "@/views/portal/components/WhiteBlock.vue";
import PaginationArrows from "@/views/portal/components/UI/PaginationArrows.vue";
import PublicationsList from "@/views/portal/components/publications/PublicationsList.vue";
import ButtonPrimary from "@/views/portal/components/UI/ButtonPrimary.vue";
import RightPanel from "@/views/portal/components/panels/RightPanel.vue";
import RightPanelTitle from "@/views/portal/components/panels/RightPanelTitle.vue";
import InputTags from "@/views/portal/components/UI/InputTags.vue";
import ButtonDefault from "@/views/portal/components/UI/ButtonDefault.vue";
import ChartHorizontal from "@/views/portal/components/charts/ChartHorizontal.vue";
import {TYPE_INTELLECTUALS} from "@/api/models/Publication";

const api = useApi();
const sorting = ref('title');
const currentPage = ref(1);
const pageSize = ref(12);
const total = ref(0);
const publications = ref([]);
const openFilter = ref(false);
const filterTags = ref([]);
// const filterTypes = ref([]);
const sortingList = [
  { value: 'title', label: 'В алфавитном порядке а-я' },
  { value: '-title', label: 'В обратном порядке я-а' },
];
const countFilterTypes = ref(0);
const countFilterTags = ref(0);
const countFilter = computed(() => {
  return countFilterTypes.value + countFilterTags.value;
});

const chart1Items = ref([]);
const chart2Items = ref([]);

const getPublications = async () => {
  try {
    const response = await api.publication.index(
        new URLSearchParams({
          page: currentPage.value,
          'per-page': pageSize.value,
          sort: sorting.value,
          type: TYPE_INTELLECTUALS,
        })
    );

    publications.value = response.publications;
    total.value = response.total;
  } catch (error) {
    console.error('error', error);
  }
};

const getTotalPublicationsByYears = async () => {
  try {
    const response = await api.publication.total(
        new URLSearchParams({
          year: new Date().getFullYear() - 4,
          group: 'year',
          type: TYPE_INTELLECTUALS,
        })
    );

    chart1Items.value = response.items;
  } catch (error) {
    console.error('error', error);
  }
};

const getTotalPublicationsByTypes = async () => {
  try {
    const response = await api.publication.total(
        new URLSearchParams({
          year: new Date().getFullYear() - 4,
          group: 'type',
          type: TYPE_INTELLECTUALS,
        })
    );

    chart2Items.value = response.items;
  } catch (error) {
    console.error('error', error);
  }
};

const resetFilter = () => {
  // filterTypes.value.map((el) => {
  //   el.checked = false;
  // });
  filterTags.value = [];
  countFilterTypes.value = 0;
  countFilterTags.value = 0;
  currentPage.value = 1;
  openFilter.value = false;
  getPublications();
};

const applyFilter = () => {
  currentPage.value = 1;
  openFilter.value = false;
  getPublications();
};

onMounted(() => {
  getPublications();
  getTotalPublicationsByYears();
  getTotalPublicationsByTypes();
});

// watch(
//     () => props.department.id,
//     () => {
//       getPublications();
//       getTotalPublicationsByYears();
//       getTotalPublicationsByTypes();
//     }
// );

watch(sorting, () => {
  getPublications();
});

watch(currentPage, () => {
  getPublications();
});

watch(pageSize, () => {
  currentPage.value = 1;
  getPublications();
});
</script>

<style lang="scss" scoped>
.sorting {
  .sorting__label {
    color: #718096;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    margin-right: 15px;
  }
}

.intellectuals-page {
  .intellectuals-page__header {
    padding: 40px 0;
  }

  .intellectuals-page__search {
    margin-top: 80px;
    margin-bottom: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.filter-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  & > * {
    margin-left: 15px;

    &:first-child {
      margin-left: 0;
    }
  }
}
</style>