<template>
  <a-row class="administrator-layout">
    <a-col flex="250px" class="administrator-layout__left-side">
      <SideBar :items="sidebarItems" />
    </a-col>
    <a-col flex="auto" class="administrator-layout__right-side">
      <div class="administrator-layout__header">
        <HeaderPartnerAccount />
      </div>
      <div class="administrator-layout__content">
        <slot></slot>
      </div>
    </a-col>
  </a-row>
</template>

<script setup>
  import { ref, watch } from 'vue';
  import SideBar from '@/components/sidebar/SideBar';
  import HeaderPartnerAccount from '@/components/header/HeaderPartnerAccount';

  const props = defineProps({
    requestCount: Number,
    projectCount: Number,
    showcaseCount: Number,
    spinning: {
      type: Boolean,
      default: false,
    },
  });

  // TODO - show for administrator right number on left SideBar
  // Path here - is name from router

  const sidebarItems = ref([
    {
      id: 1,
      text: 'Портфели',
      path: 'administrator-portfolios',
      hash: '#all',
      type: 'portfolios',
      count: 0,
      isCollapsed: true,
      submenu: [
        {
          id: 1,
          text: 'Все портфели',
          path: 'administrator-portfolios',
          hash: '#all',
          type: 'portfolios',
          count: 0,
        },
        {
          id: 2,
          text: 'Мои портфели',
          path: 'administrator-portfolios',
          hash: '#my',
          type: 'portfolios',
          count: 0,
        },
      ],
    },
    {
      id: 2,
      text: 'Запросы',
      path: 'administrator-requests',
      type: 'request',
      count: 0,
    },
    {
      id: 3,
      text: 'Мои проекты',
      path: 'administrator-projects',
      type: 'projects',
      count: 0,
    },
    {
      id: 4,
      text: 'Витрина',
      path: 'administrator-showcases',
      hash: '#new',
      type: 'showcases',
      count: 0,
    },
  ]);
  //TODO: portfolio counter
  // watch(
  //     () => props.projectCount,
  //     () => {
  //       sidebarItems.value[0].count = props.projectCount;
  //     }
  // );

  watch(
    () => props.requestCount,
    () => {
      sidebarItems.value[1].count = props.requestCount;
    }
  );

  watch(
    () => props.projectCount,
    () => {
      sidebarItems.value[2].count = props.projectCount;
    }
  );

  watch(
    () => props.showcaseCount,
    () => {
      sidebarItems.value[3].count = props.showcaseCount;
    }
  );
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  .administrator-layout {
    min-height: 100vh;
    flex-wrap: nowrap;

    .administrator-layout__right-side {
      overflow: hidden;
      height: 100vh;
    }

    .administrator-layout__header {
      padding: 17px 40px 17px 20px;
      border-bottom: 1px solid $color-bg-secondary-grey;
    }

    .administrator-layout__content {
      overflow-y: auto;
      height: 100%;
      padding: 30px 40px 100px 20px;
    }
  }
</style>
