<template>
  <div class="agreement-form">
    <a-row class="partner-report">
      <a-col class="partner-report-title">
        <h4>{{ model.status?.actionLabel }}</h4>
      </a-col>
      <a-col class="partner-report-info">
        <p>{{ model.status?.description }}</p>

        <div
          v-if="
            [
              DOCUMENT_APPROVAL_STATUS_REMOVED,
              DOCUMENT_APPROVAL_STATUS_REJECTED,
            ].includes(model.status?.type) ||
            (model.status?.type === DOCUMENT_APPROVAL_STATUS_NEGOTIATION &&
              model.approval?.rejectedReason)
          "
        >
          <p>
            <span>{{
              model.status?.type === DOCUMENT_APPROVAL_STATUS_NEGOTIATION
                ? 'Причина, по которой Вы отклонили проект в прошлый раз: '
                : 'Комментарий: '
            }}</span
            >{{ model.approval?.rejectedReason }}
          </p>
        </div>
      </a-col>
      <a-col class="partner-report-buttons" v-if="model.status">
        <a-row
          :gutter="[10, 0]"
          v-if="model.status?.type === DOCUMENT_APPROVAL_STATUS_NEGOTIATION"
        >
          <a-col>
            <c-button-danger @click="() => (showForm = true)">
              Отклонить
            </c-button-danger>
          </a-col>
          <a-col>
            <c-button-primary @click="handleApprove">
              Согласовать
            </c-button-primary>
          </a-col>
        </a-row>

        <a-row
          :gutter="[10, 0]"
          v-if="model.status?.type === DOCUMENT_APPROVAL_STATUS_APPROVED"
        >
          <a-col>
            <c-button-danger @click="onRemoveClick">
              Снять с публикации
            </c-button-danger>
          </a-col>
        </a-row>

        <a-row
          :gutter="[10, 0]"
          v-if="
            [
              DOCUMENT_APPROVAL_STATUS_REMOVED,
              DOCUMENT_APPROVAL_STATUS_REJECTED,
            ].includes(model.status?.type)
          "
        >
          <a-col>
            <c-button-primary @click="handleApprove">
              Опубликовать
            </c-button-primary>
          </a-col>
        </a-row>
      </a-col>
    </a-row>

    <a-divider />

    <c-drawer v-model:open="showForm" destroyOnClose>
      <component
        :is="AdministratorShowcaseDenyForm"
        :approval="model.approval"
        :deny-status="model.denyStatus"
        @close="() => (showForm = !showForm)"
        @update="updateOnDeny"
      />
    </c-drawer>
  </div>
</template>

<script setup>
  import ErrorsHandler from '@/components/form/errors-handler';
  import { onMounted, reactive, ref, watch } from 'vue';
  import { useApi } from '@/api/use-api';
  import CButtonDanger from '@/components/UI/CButtonDanger.vue';
  import CButtonPrimary from '@/components/UI/CButtonPrimary.vue';
  import {
    DOCUMENT_APPROVAL_STATUS_APPROVED,
    DOCUMENT_APPROVAL_STATUS_NEGOTIATION,
    DOCUMENT_APPROVAL_STATUS_REJECTED,
    DOCUMENT_APPROVAL_STATUS_REMOVED,
    getShowcaseApprovalVisibleStatus,
  } from '@/api/models/DocumentApprovalStatus';
  import { USER_ROLE_ADMINISTRATOR } from '@/api/models/UserRole';
  import AdministratorShowcaseDenyForm from './AdministratorShowcaseDenyForm.vue';
  import CDrawer from '@/components/UI/CDrawer.vue';

  const api = useApi();
  const emit = defineEmits(['update']);
  const showForm = ref(false);
  const isLoading = ref(false);

  const props = defineProps({
    showcase: {
      type: Object,
      default: null,
    },
    publicShowcase: {
      type: Object,
      default: null,
    },
  });
  const errors = new ErrorsHandler({ approval: '' });

  const model = reactive({
    showcaseId: null,
    approval: null, // Select only the first approval. This should be enough for the current admin approval workflow
    status: null,
    denyStatus: '',
    publicShowcase: null,
  });

  onMounted(() => {
    fillModel();
  });

  watch([() => props.showcase, () => props.publicShowcase], () => {
    fillModel();
  });

  const fillModel = () => {
    try {
      if (props.showcase) {
        model.showcaseId = props.showcase.id;
        model.approval =
          props.showcase.approvals.length > 0
            ? props.showcase.approvals[0]
            : null;
      }
      if (props.publicShowcase) {
        model.publicShowcase = props.publicShowcase;
      }

      model.status = model.approval
        ? getShowcaseApprovalVisibleStatus(
            model.approval,
            USER_ROLE_ADMINISTRATOR
          )
        : null;
    } catch (error) {
      console.error('error', error);
    }
  };

  const handleApprove = async () => {
    try {
      isLoading.value = true;
      errors.clearErrors();
      await api.documentApproval.approve({
        docApprovalId: model.approval.id,
      });

      emit('update');
    } catch (error) {
      errors.handleApiErrors(error);
    } finally {
      isLoading.value = false;
    }
  };

  const onRemoveClick = () => {
    model.denyStatus = DOCUMENT_APPROVAL_STATUS_REMOVED;
    showForm.value = true;
  };

  const updateOnDeny = () => {
    model.denyStatus = '';
    emit('update');
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  .agreement-form {
    margin-top: 10px;
    max-width: 902px;

    .ant-divider {
      margin-top: 40px;
      margin-bottom: 10px;
    }

    .partner-report {
      background-color: $color-bg-secondary-grey;
      margin-top: 30px;
      min-height: 207px;
      padding: 20px;

      .partner-report-title {
        h4 {
          font-size: 16px;
          font-weight: 700;
          line-height: 22px;
          margin: 0;
        }
      }

      .partner-report-info {
        color: $color-bg-status-new;
        display: flex;
        flex-direction: column;
        gap: 18px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-top: 20px;
        //width: 785px;

        span {
          font-weight: 700;
        }
      }

      .partner-report-buttons {
        margin-top: 40px;
      }
    }
  }
</style>
