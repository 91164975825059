<template>
  <a-row class="moderator-layout">
    <a-col flex="250px" class="moderator-layout__left-side">
      <SideBar :items="sidebarItems" />
    </a-col>
    <a-col flex="auto" class="moderator-layout__right-side">
      <div class="moderator-layout__header">
        <HeaderPartnerAccount />
      </div>
      <div class="moderator-layout__content">
        <slot></slot>
      </div>
    </a-col>
  </a-row>
</template>

<script setup>
  import { ref, watch } from 'vue';
  import SideBar from '@/components/sidebar/SideBar';
  import HeaderPartnerAccount from '@/components/header/HeaderPartnerAccount';

  document.title = 'Цифровой университет. НГУ';

  const props = defineProps({
    requestCount: {
      type: Number,
      default: 0,
    },
  });

  // TODO - show for moderator right number on left SideBar
  // Path here - is name from router

  const sidebarItems = ref([
    {
      id: 1,
      text: 'Запросы',
      path: 'moderator-requests',
      type: 'request',
      count: 0,
    },
    {
      id: 2,
      text: 'Инфо',
      path: 'main',
      type: 'info',
      url: 'https://info.nsu.ru',
    },
  ]);

  watch(
    () => props.requestCount,
    () => {
      sidebarItems.value[0].count = props.requestCount; //костыль :)
    }
  );
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  .moderator-layout {
    min-height: 100vh;
    flex-wrap: nowrap;

    .moderator-layout__header {
      padding: 17px 40px 17px 20px;
      border-bottom: 1px solid $color-bg-secondary-grey;
    }

    .moderator-layout__content {
      padding: 30px 40px 10px 20px;
    }
  }
</style>
