<template>
  <a-row class="request-support__form">
    <a-col>
      <a-row class="form__title">
        <a-col :span="24">
          <h3>Написать в техподдержку</h3>
        </a-col>
      </a-row>
      <a-row class="form__description">
        <a-col :span="24">
          <p>
            Если у вас возникла техническая проблема и вам требуется помощь,
            пожалуйста, опишите ее подробно в форме ниже. Если возможно,
            приложите файлы и скриншоты, чтобы проиллюстрировать проблему.
          </p>
        </a-col>
      </a-row>
      <a-row class="form__fields">
        <a-form
          class="form form_two-columns sign-up-form"
          :model="model"
          :rules="rules"
          @validate="errors.onValidate"
          @finish="onFinish"
        >
          <a-row :gutter="[0, 14]">
            <a-col :span="24">
              <a-form-item name="message">
                <a-textarea
                  v-model:value="model.message"
                  placeholder="Опишите свой запрос"
                />
              </a-form-item>
            </a-col>
            <attachments-input
              :error="errors.getError('attachmentUids')"
              :init-attachments="model.attachments"
              :is-editable="true"
              :accept="ATTACHMENT_COMMON_EXTENSIONS"
              @update="handleUpdateAttachments"
            />
            <a-col :span="24">
              <a-row justify="end">
                <a-form-item class="form__button">
                  <c-button-primary html-type="submit"
                    >Сохранить</c-button-primary
                  >
                </a-form-item>
              </a-row>
            </a-col>
          </a-row>
        </a-form>
      </a-row>
    </a-col>
  </a-row>
</template>

<script setup>
  import { reactive } from 'vue';
  import { useApi } from '@/api/use-api';
  import CButtonPrimary from '@/components/UI/CButtonPrimary';
  import AttachmentsInput from '@/components/attachments/AttachmentsInput.vue';
  import ErrorsHandler from '@/components/form/errors-handler';
  import { ATTACHMENT_COMMON_EXTENSIONS } from '@/components/attachments/AttachmentExtentions';

  const model = reactive({
    message: '',
    attachments: [],
    attachmentUids: [],
  });

  const rules = {
    message: [
      {
        required: true,
        message: 'Поле не должно быть пустым',
        trigger: 'change',
      },
    ],
  };

  const emit = defineEmits(['close']);

  const api = useApi();
  const errors = new ErrorsHandler({
    message: '',
    attachmentUids: '',
  });

  const onFinish = async () => {
    try {
      await api.support.create({
        message: model.message,
        attachmentUids: model.attachmentUids,
      });

      emit('close');
    } catch (error) {
      errors.handleApiErrors(error);
    }
  };

  function handleUpdateAttachments(uidList) {
    model.attachmentUids = uidList;
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  .request-support__form {
    padding: 112px 17px 0 17px;

    .form__title {
      h3 {
        font: 700 18px/26px 'Nunito Sans';
      }
    }

    .form__description {
      margin-top: 20px;
    }

    .form__fields {
      margin-top: 35px;

      #request-support {
        height: 124px;
        resize: none;
      }

      .attachments {
        display: flex;
        flex-direction: column;
        background-color: $color-bg-secondary-grey;
        border-radius: 7px;
        gap: 10px;
        height: 189px;
        flex-wrap: wrap;
        padding: 14px;
      }

      .attachment-file {
        display: block;
        font-size: 12px;
        font-weight: 700;
        line-height: 12px;
        text-align: left;
        user-select: none;
      }

      .ant-form-item {
        margin: 0;
      }

      .ant-upload-text {
        color: #aeb4dd;
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        width: 194px;
      }

      .form__button {
        button {
          margin-top: 10px;
        }
      }
    }
  }
</style>
