<template>
  <a-row class="edit-profile__form">
    <a-col>
      <a-row class="form__title">
        <a-col :span="24">
          <h3>Редактировать профиль</h3>
        </a-col>
      </a-row>
      <a-row class="form__fields">
        <a-form
            :model="model"
            :rules="rules"
            @validate="errors.onValidate"
            @finish="onFinish"
        >
          <a-row :gutter="[0, 14]">
            <a-col :span="24">
              <a-form-item name="lastName">
                <a-input
                  v-model:value="model.lastName"
                  placeholder="Фамилия"
                />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item name="firstName">
                <a-input
                  v-model:value="model.firstName"
                  placeholder="Имя"
                />
              </a-form-item>
            </a-col>
            <a-col :span="24" name="middleName">
              <a-form-item>
                <a-input
                  v-model:value="model.middleName"
                  placeholder="Отчество"
                />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item name="email">
                <a-input v-model:value="model.email" placeholder="E-mail" disabled />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item name="phone">
                <a-input
                  v-model:value="model.phone"
                  v-maska data-maska="+7 (###) ###-####"
                  placeholder="Телефон"
                />
              </a-form-item>
            </a-col>
            <a-col
              :span="24"
              v-for="(messenger, index) in model.messengers"
              :key="messenger.key"
            >
              <a-form-item :name="['messengers', index, 'value']">
                <a-input
                  v-model:value="messenger.value"
                  v-maska
                  :data-maska="getMessengerMaska(messenger.name)"
                  data-maska-tokens="X:[A-Za-z]|Y:[0-9A-Za-z_]"
                >
                  <template #prefix>
                    <img :src="getMessengerIcon(messenger)" alt="" />
                  </template>
                  <template #suffix>
                    <img
                      src="./assets/delete.svg"
                      alt=""
                      style="cursor: pointer"
                      @click="removeMessengerInput(messenger)"
                    />
                  </template>
                </a-input>
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item name="messenger">
                <a-select
                  placeholder="Добавить мессенджер"
                  :options="messengersList"
                  @change="addNewMessengerInput"
                />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-row justify="end">
                <a-form-item class="form__button">
                  <c-button-primary html-type="submit">
                    Сохранить
                  </c-button-primary>
                </a-form-item>
              </a-row>
            </a-col>
          </a-row>
        </a-form>
      </a-row>
    </a-col>
  </a-row>
</template>

<script setup>
  import { reactive, onMounted } from 'vue';
  import { vMaska } from 'maska';
  import { useApi } from '@/api/use-api';
  import CButtonPrimary from '@/components/UI/CButtonPrimary';
  import ErrorsHandler from "@/components/form/errors-handler";
  import MessengersAdapter from "@/api/profile-page/messengers-adapter";

  const api = useApi();
  const messengerAdapter = new MessengersAdapter();
  const messengersList = [
    {
      value: 'whatsapp',
      label: 'Whatsapp',
      dataMask: '+7 ### ### ####',
      defaultValue: '+7'
    },
    {
      value: 'telegram',
      label: 'Telegram',
      dataMask: '!@XYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYY',
      defaultValue: '@'
    },
    {
      value: 'vk',
      label: 'VK',
      dataMask: '###################',
      defaultValue: '',
    },
  ];

  const model = reactive({
    personId: '',
    lastName: '',
    firstName: '',
    middleName: '',
    email: '',
    phone: '',
    whatsappId: '',
    telegramId: '',
    vkId: '',
    messengers: [],
  });

  const rules = {
    lastName: [
      {
        required: true,
        message: 'Поле не должно быть пустым',
        trigger: 'change',
      },
      {
        min: 2,
        max: 255,
        message: 'Фамилия должна содержать хотя бы 2 символа',
        trigger: 'blur',
      },
    ],

    firstName: [
      {
        required: true,
        message: 'Поле не должно быть пустым',
        trigger: 'change',
      },
      {
        min: 2,
        max: 255,
        message: 'Имя должно содержать хотя бы 2 символа',
        trigger: 'blur',
      },
    ],
    email: [
      {
        required: true,
        type: 'email',
        message: 'Введён некорректный E-Mail',
        trigger: 'change',
      },
    ],
    phone: [
      {
        required: true,
        message: 'Поле не должно быть пустым',
        trigger: 'change',
      },
    ],
  };

  const emit = defineEmits(['close', 'update']);

  onMounted(() => getUser());

  const getMessengerMaska = (messengerName) => {
    const messengerProps = messengersList.find((item) => item.value === messengerName);
    return  messengerProps?.dataMask;
  }

  const getMessengerIcon = (messenger) => {
    return  require(`./assets/${messenger.name}.svg`);
  }

  async function getUser() {
    try {
      const response = await api.mainPage.userData();
      model.lastName = response.lastName;
      model.firstName = response.firstName;
      model.middleName = response.middleName;
      model.email = response.email;
      model.phone = response.phone;
      model.messengers = messengerAdapter.getMessengers(response);
    } catch (error) {
      console.error('error', error);
    }
  }

  const errors = new ErrorsHandler(
    {
      lastName: "",
      firstName: "",
      middleName: "",
      email: "",
      phone: "",
      messengers: "",
  });

  const onFinish = async () => {
    try {
      await api.mainPage.updateUserData({
        lastName: model.lastName,
        firstName: model.firstName,
        middleName: model.middleName,
        phone: model.phone,
        whatsappId: messengerAdapter.getMessengerId('whatsapp', model.messengers),
        telegramId: messengerAdapter.getMessengerId('telegram', model.messengers),
        vkId: messengerAdapter.getMessengerId('vk', model.messengers),
      });

      emit('close');
      emit('update');
    } catch (error) {
      errors.handleApiErrors(error);
    }
  };


  function addNewMessengerInput(value, item) {
    model.messengers.push({
      value: item.defaultValue,
      name: value,
      key: model.messengers.length + 1,
    });
  }

  function removeMessengerInput(item) {
    const index = model.messengers.indexOf(item);
    if (index !== -1) {
      model.messengers.splice(index, 1);
    }
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  .edit-profile__form {
    padding: 112px 17px 0 17px;

    .form__title {
      h3 {
        font: 700 18px/26px 'Nunito Sans';
      }
    }

    .form__fields {
      margin-top: 35px;

      #organizationAbout {
        height: 124px;
        resize: none;
      }

      .ant-form-item {
        margin: 0;
      }

      .form__button {
        button {
          margin-top: 10px;
        }
      }
    }
  }
</style>
