<template>
  <span class="status-label" :class="status"> {{ date ? dueToDate : 'Неважно' }} </span>
</template>

<script setup>
  import { computed } from "vue";

  const props = defineProps({
    completedAt: String,
    dueToDate: String,
    date: String,
  });

  const status = computed(() => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const completedAt = props.completedAt ? new Date(props.completedAt) : null;
    completedAt?.setHours(0, 0, 0, 0);
    const dueToDate = new Date(props.dueToDate);
    dueToDate.setHours(0, 0, 0, 0)
    const taskDate = props.date ? new Date(props.date) : null;
    taskDate?.setHours(0, 0, 0, 0)

    if (completedAt && completedAt <= dueToDate) {
      return taskDate ? 'completed' : 'completed no-matter'
    }

    if (completedAt && dueToDate < completedAt) {
      return taskDate ? 'expired' : 'expired no-matter'
    }

    if (currentDate <= dueToDate) {
      return taskDate ? 'incomplete' : 'incomplete no-matter'
    } else {
      return taskDate ? 'incomplete-overdue' : 'incomplete-overdue no-matter'
    }
  });
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  .status-label {
    padding: 8px 12px;
    border-radius: 2px;
    background-color: $color-text-secondary-grey;
    color: $color-text-secondary-white;
    text-align: center;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    display: inline-block;
    white-space: nowrap;

    &.incomplete-overdue {
      background: $color-bg-status-reject;

      &.no-matter {
        color: $color-test-accent-red-disabled;
      }
    }

    &.incomplete {
      background: $color-bg-primary-white;
      color: $color-text-primary;

      &.no-matter {
        color: $color-text-secondary-grey;
      }
    }

    &.completed {
      background: $color-bg-status-done;

      &.no-matter {
        color: $color-btn-secondary;
      }
    }

    &.expired {
      background: $color-bg-status-expired;

      &.no-matter {
        color: $color-bg-status-expired-disabled;
      }
    }
  }
</style>
