<template>
  <a-row class="application-type-list">
    <a-col :span="24" class="application-type-list__title">
      <h1>Запрос на проект</h1>
    </a-col>
    <a-col :span="24" class="application-type-list__description">
      <p>
        Пожалуйста, выберите тип проекта, который вы планируете запустить. Если
        вы хотите сотрудничать в нескольких направлениях, пожалуйста, создайте
        отдельные заявки на каждый проект.
      </p>
    </a-col>
    <a-col :span="24">
      <a-row :gutter="[20, 20]" class="application-type-list__cards">
        <a-col :span="12" v-for="item in applicationTypeList" :key="item.id">
          <application-type-card
            :path="item.path"
            :title="item.title"
            :type="item.type"
          />
        </a-col>
      </a-row>
    </a-col>
  </a-row>
</template>

<script setup>
  import ApplicationTypeCard from './ApplicationTypeCard';
  import {
    REQUEST_TYPE_EDUCATION,
    REQUEST_TYPE_EXPERTISE,
    REQUEST_TYPE_PARTNERSHIP,
    REQUEST_TYPE_RESEARCH,
    REQUEST_TYPE_RESOURCES,
  } from "@/api/models/RequestType";

  const applicationTypeList = [
    {
      id: 1,
      title: 'Запрос на проведение исследовательских работ',
      type: REQUEST_TYPE_RESEARCH,
      path: 'new-partner-request',

    },
    {
      id: 2,
      title: 'Запрос на образование',
      type: REQUEST_TYPE_EDUCATION,
      path: 'new-partner-request',
    },
    {
      id: 3,
      title: 'Запрос на экспертизу',
      type: REQUEST_TYPE_EXPERTISE,
      path: 'new-partner-request',
    },
    {
      id: 4,
      title: 'Запрос на использование ресурсов',
      type: REQUEST_TYPE_RESOURCES,
      path: '',
    },
    {
      id: 5,
      title: 'Запрос на сотрудничество',
      type: REQUEST_TYPE_PARTNERSHIP,
      path: 'new-partner-request',
    },
  ];
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors';
  * {
    margin: 0;
  }
  .application-type-list {
    align-content: flex-start;
    height: 100%;
    overflow-y: auto;
    padding-bottom: 100px;

    .application-type-list__title {
      h1 {
        font: 700 32px/28px 'Nunito Sans';
      }
    }

    .application-type-list__description {
      color: #101426;
      font: 400 14px/20px 'Nunito Sans';
      height: 60px;
      margin-top: 34px;
      max-width: 555px;
    }

    .application-type-list__cards {
      padding-top: 60px;
      width: 100%;
    }
  }
</style>
