import { read, update } from '@/api/rest';

const briefcase = {
  briefcases: async (options) => await read('/briefcase?' + options.toString()),
  view: async (id) => await read(`/briefcase/${id}`),
  update: async (id, data) => await update(`/briefcase/${id}`, data),
  projects: async (id) => await read('/briefcase/projects?id=' + id),
  indexes: async (id) => await read('/briefcase/indexes?id=' + id),
  projectBriefcaseIndexes: async (options) =>
    await read('/briefcase/project-briefcase-indexes?' + options.toString()),
  administratorProjectIndexes: async (options) =>
    await read(
      '/briefcase/administrator-project-indexes?' + options.toString()
    ),
  briefcaseIndexes: async (options) =>
    await read('/briefcase/briefcase-indexes?' + options.toString()),
  saveManyProjectBriefcases: async (data) =>
    await update('/briefcase/save-project-briefcases', data),
};

export default briefcase;
