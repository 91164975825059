<template>
  <button type="button" class="button-add">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g stroke-width="2" stroke="#00BDE8">
        <circle cx="12" cy="12" r="11" />
        <line x1="12" y1="6" x2="12" y2="18" />
        <line x1="6" y1="12" x2="18" y2="12" />
      </g>
    </svg>
    <span><slot></slot></span>
  </button>
</template>

<script setup></script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  .button-add {
    padding: 0;
    background: transparent;
    border: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    cursor: pointer;
    & > svg {
      margin-right: 15px;
    }

    & > span {
      color: $color-link-primary;
      font-family: 'Nunito Sans', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-decoration-line: underline;
    }

    &:hover {
      & > span {
        text-decoration-line: none;
      }
    }
  }
</style>
