<template>
  <a-spin
    :spinning="isLoading"
    class="custom-spinner"
    style="height: 100vh; top: 50%"
  >
    <main-title>Витрина проектов</main-title>
    <horizontal-filter>
      <template #default>
        <filter-item hash="#new" text="Новые запросы" />
        <filter-item hash="#published" text="Опубликованные" />
        <filter-item hash="#rejected" text="Отклоненные" />
      </template>
      <template #right>
        <label class="panel__total-showcases">
          Всего: {{ totalShowcases }}
        </label>
      </template>
    </horizontal-filter>
    <showcases-table :showcases="showcases" />
  </a-spin>
</template>

<script setup>
  import { onMounted, ref, watch } from 'vue';
  import { useRoute } from 'vue-router';
  import { useApi } from '@/api/use-api';
  import MainTitle from '@/components/titles/MainTitle';
  import HorizontalFilter from '@/components/filter/horizontal/HorizontalFilter';
  import FilterItem from '@/components/filter/components/FilterItem';
  import ShowcasesTable from '@/views/administrator/showcases/components/ShowcasesTable.vue';

  const api = useApi();
  const route = useRoute();
  const totalShowcases = ref(0);
  const showcases = ref([]);
  const isLoading = ref(false);

  onMounted(() => {
    getShowcases();
  });

  watch(route, () => {
    getShowcases();
  });

  const getShowcases = async () => {
    try {
      const hash = route.hash.replace('#', '');
      const tab = hash ?? 'new';
      const response = await api.showcase.search(
        new URLSearchParams({
          role: route.meta.role,
          isPublic: tab === 'published' ? 1 : 0,
          filter: tab,
        })
      );
      showcases.value = response.showcases;
      totalShowcases.value = showcases.value.length;
    } catch (error) {
      console.error('error', error);
    }
  };
</script>

<style lang="scss" scoped></style>
