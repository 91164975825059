<template>
  <portal-layout class="publication-page">
    <template v-slot:header>
      <main-title>
        <template v-slot:default>
          <span v-html="publication?.title" />
        </template>
        <template v-slot:description>
          <publication-info :publication="publication" />
        </template>
      </main-title>
    </template>
    <template v-slot:default>
      <intellectual-about-tab
          v-if="!route.params.tab"
          :publication="publication"
      />
      <intellectual-concepts-tab
          v-if="route.params.tab === 'concepts'"
          :publication="publication"
      />
      <intellectual-citations-tab
          v-if="route.params.tab === 'employees'"
          :publication="publication"
      />
    </template>
  </portal-layout>
</template>

<script setup>
import {onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import {useApi} from "@/api/use-api";
import PortalLayout from "@/layouts/PortalLayout.vue";
import MainTitle from "@/views/portal/components/MainTitle.vue"
import PublicationInfo from "@/views/portal/components/publications/PublicationInfo.vue";
import IntellectualAboutTab from "@/views/portal/intellectual/IntellectualAboutTab.vue";
import IntellectualConceptsTab from "@/views/portal/intellectual/IntellectualConceptsTab.vue";
import IntellectualCitationsTab from "@/views/portal/intellectual/IntellectualCitationsTab.vue";

const api = useApi();
const route = useRoute();
const publication = ref({
  uuid: "",
  title: "",
  source: "",
  status: null,
  authors: [],
  totalAuthors: 0,
  tags: [],
});

const getPublication = async () => {
  try {
    const response = await api.publication.view(route.params.id);
    publication.value = response.publication;
  } catch (error) {
    console.error("error", error);
  }
};

onMounted(() => {
  getPublication();
});
</script>

<style lang="scss" scoped>

</style>