export const REQUEST_STATUS_DRAFT = 1;
export const REQUEST_STATUS_REVISION = 2;
export const REQUEST_STATUS_REJECTED = 3;
export const REQUEST_STATUS_MODERATOR = 4;
export const REQUEST_STATUS_ADMIN_FREE = 5;
export const REQUEST_STATUS_ADMIN_QUEUE = 6;
export const REQUEST_STATUS_LEADER = 7;
export const REQUEST_STATUS_PROJECT_START = 8;

export const solutionsList = [
  {
    value: 2,
    label: 'Вернуть на доработку',
  },
  {
    value: 3,
    label: 'Отказ',
  },
  {
    value: 5,
    label: 'Отправить подразделениям в работу',
  },
  {
    value: 6,
    label: 'Отправить всем',
  },
];