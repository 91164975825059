<template>
  <project-manager-layout>
    <a-spin
      :spinning="isLoading"
      class="custom-spinner"
      style="height: 100vh; top: 50%"
    >
      <main-title>Мои портфели</main-title>
      <a-row :gutter="[20, 20]" class="portfolios">
        <a-col
          :span="12"
          v-for="(portfolio, i) in portfolios"
          :key="'portfolio-' + i"
          @click="handleOpenPortfolio(portfolio.id)"
        >
          <portfolio-card :portfolio="portfolio" />
        </a-col>
      </a-row>
    </a-spin>
  </project-manager-layout>
</template>

<script setup>
  import { onMounted, ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { useApi } from '@/api/use-api';
  import MainTitle from '@/components/titles/MainTitle.vue';
  import PortfolioCard from '@/components/projects/portfolio/components/PortfolioCard.vue';
  import ProjectManagerLayout from '@/layouts/ProjectManagerLayout.vue';
  import { USER_ROLE_LEADER } from '@/api/models/UserRole';
  import { BRIEFCASE_FILTER_MY } from '@/api/models/Briefcase';

  const api = useApi();
  const router = useRouter();
  const isLoading = ref(false);
  const portfolios = ref([]);

  onMounted(() => {
    getPortfolios();
  });

  const getPortfolios = async () => {
    try {
      isLoading.value = true;
      const response = await api.briefcase.briefcases(
        new URLSearchParams({
          filter: BRIEFCASE_FILTER_MY,
          role: USER_ROLE_LEADER,
        })
      );
      portfolios.value = response.briefcases;
    } catch (error) {
      console.error('error', error);
    } finally {
      isLoading.value = false;
    }
  };

  const handleOpenPortfolio = (id) => {
    router.push(`/leader/portfolio/` + id + `/details`);
  };
</script>

<style scoped lang="scss">
  @import '@/assets/styles/_colors.scss';

  .portfolios {
    margin-top: 30px;
    padding: 0 0 100px 0;
  }

  .horizontal-filter__panel {
    margin: 0 -40px 0 -20px;
    padding: 8px 40px 8px 20px;
    border-top: 1px solid $color-bg-secondary-grey;
    border-bottom: 1px solid $color-bg-secondary-grey;
  }
</style>
