<template>
  <a-form
    ref="formRef"
    layout="vertical"
    :model="model"
    :rules="rules"
    :validate-on-rule-change="true"
    @validate="errors.onValidate"
    @finish="() => null"
  >
    <leader-project-form-row
      :show-available="showAvailable"
      :available="model.isAvailable"
      :offsetAvailable="false"
      :disabled="disabled"
      @change="onChangeAvailability"
      class="bordered"
    >
      <a-form-item
        name="name"
        :help="errors.getError('name')"
        :validate-status="errors.getStatus('name')"
      >
        <a-input
          v-model:value="model.name"
          placeholder="Введите название этапа"
          :disabled="disabled"
          @change="validateAndUpdate"
        />
      </a-form-item>
      <a-row>
        <a-col :span="12">
          <a-form-item
            name="startDate"
            :help="errors.getError('startDate')"
            :validate-status="errors.getStatus('startDate')"
          >
            <a-date-picker
              v-model:value="model.startDate"
              placeholder="Выберите дату старта этапа"
              :disabled-date="(current) => disabledStepStartDate(current)"
              format="DD.MM.YYYY"
              :locale="locale"
              :disabled="disabled"
              @change="validateAndUpdate"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item
            name="endDate"
            :help="errors.getError('endDate')"
            :validate-status="errors.getStatus('endDate')"
          >
            <a-date-picker
              v-model:value="model.endDate"
              placeholder="Выберите дату завершение этапа"
              :disabled-date="(current) => disabledStepEndDate(current)"
              format="DD.MM.YYYY"
              :locale="locale"
              :disabled="disabled"
              @change="validateAndUpdate"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </leader-project-form-row>
  </a-form>
</template>

<script setup>
  import { computed, onMounted, reactive, ref, watch } from 'vue';
  import ErrorsHandler from '@/components/form/errors-handler';
  import LeaderProjectFormRow from '@/views/leader/projects/form/LeaderProjectFormRow.vue';
  import locale from 'ant-design-vue/es/date-picker/locale/ru_RU';
  import dayjs from 'dayjs';
  import useInputRowValidator from '@/composables/inputRowValidator';

  const formRef = ref();
  const emit = defineEmits(['update']);
  const inputValidator = useInputRowValidator(emit);

  const props = defineProps({
    index: {
      type: Number,
      required: true,
    },
    maxIndex: {
      type: Number,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    projectStartDate: {
      type: Object,
      required: false,
    },
    projectEndDate: {
      type: Object,
      required: false,
    },
    apiErrors: {
      type: Object,
      required: true,
    },
    showAvailable: {
      type: Boolean,
      default: false,
    },
    offsetAvailable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  });

  const model = reactive({
    name: '',
    startDate: null,
    endDate: null,
    isAvailable: true,
  });

  const validationIsRequired = computed(() => {
    return model.startDate || model.endDate || props.index !== props.maxIndex;
  });

  const rules = computed(() => {
    return {
      name: [
        {
          type: 'string',
          message: 'Укажите название этапа.',
          required: validationIsRequired.value,
          trigger: 'blur',
        },
      ],
      startDate: [
        {
          type: 'date',
          required: model.name || model.endDate,
          message: 'Укажите дату старта этапа.',
          trigger: 'blur',
        },
      ],
      endDate: [
        {
          type: 'date',
          required: model.name || model.startDate,
          message: 'Укажите дату завершения этапа.',
          trigger: 'blur',
        },
      ],
    };
  });

  const errors = new ErrorsHandler({
    name: '',
    startDate: '',
    endDate: '',
    isAvailable: '',
  });

  onMounted(() => {
    fillModel();
  });

  watch(
    () => props.data,
    () => {
      fillModel();
    }
  );

  watch(
    () => props.apiErrors,
    () => {
      props.apiErrors.errors && errors.handleApiErrors(props.apiErrors);
    }
  );

  watch(
    () => validationIsRequired.value,
    () => {
      setTimeout(() => {
        validateAndUpdate();
      }, 300);
    }
  );

  const fillModel = () => {
    try {
      model.name = props.data.name;
      model.startDate = props.data.startDate
        ? dayjs(props.data.startDate)
        : null;
      model.endDate = props.data.endDate ? dayjs(props.data.endDate) : null;
      model.isAvailable = props.data.isAvailable;
    } catch (error) {
      console.error('error', error);
    }
  };

  const onChangeAvailability = () => {
    model.isAvailable = !model.isAvailable;
    validateAndUpdate();
  };

  const validateAndUpdate = () =>
    inputValidator.validateAndUpdate(props.index, model, formRef);

  defineExpose({
    validateAndUpdate,
  });

  const disabledStepStartDate = (currentDate) => {
    return (
      currentDate &&
      ((props.projectStartDate &&
        currentDate < dayjs(props.projectStartDate)) ||
        currentDate > dayjs(model.endDate) ||
        (props.projectEndDate &&
          currentDate > dayjs(props.projectEndDate).add(1, 'day')))
    );
  };

  const disabledStepEndDate = (currentDate) => {
    return (
      currentDate &&
      (currentDate < dayjs(model.startDate) ||
        (props.projectEndDate &&
          currentDate > dayjs(props.projectEndDate).add(1, 'day')))
    );
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  .form-item__label {
    padding-top: 15px;
  }

  .button-remove {
    margin-left: 10px;
  }
</style>
