export const REQUEST_TYPE_RESEARCH = 'research';
export const REQUEST_TYPE_EXPERTISE = 'expertise';
export const REQUEST_TYPE_PARTNERSHIP = 'partnership';
export const REQUEST_TYPE_EDUCATION = 'education';
export const REQUEST_TYPE_RESOURCES = 'resources';

export function getRequestTitleByType(type) {
  switch (type) {
    case REQUEST_TYPE_RESEARCH:
      return 'Запрос на проведение исследовательских работ';
    case REQUEST_TYPE_EXPERTISE:
      return 'Запрос на проведение экспертизы';
    case REQUEST_TYPE_PARTNERSHIP:
      return 'Запрос на новое сотрудничество';
    case REQUEST_TYPE_EDUCATION:
      return 'Запрос на разработку образовательной программы';
    case REQUEST_TYPE_RESOURCES:
      return 'Запрос на использование ресурсов';
    default:
      return undefined;
  }
}
