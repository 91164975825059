<template>
  <div class="person-tab-publications">
    <white-block>
      <content-container class="content-padding pt-40 pb-40">
        <bordered-block>
          <menu-person :id="person.id" :active="route.params?.tab" />
        </bordered-block>
        <a-spin :spinning="isLoading" />
        <div class="person-tab-publications__info pt-40">
          <two-columns>
            <template v-slot:left>
              <white-block class="h-100 p-30">
                <gray-block class="h-100 p-30">
                  <chart-wrapper
                    title="Количество научных публикаций за последние 5 лет"
                  >
                    <chart-vertical :items="chart1Items"></chart-vertical>
                  </chart-wrapper>
                </gray-block>
              </white-block>
            </template>
            <template v-slot:right>
              <white-block class="h-100 p-30">
                <gray-block class="h-100 p-30">
                  <chart-wrapper
                    title="Количество научных публикаций за последние 5 лет по типам"
                  >
                    <chart-horizontal :items="chart2Items"></chart-horizontal>
                  </chart-wrapper>
                </gray-block>
              </white-block>
            </template>
          </two-columns>
        </div>
      </content-container>
    </white-block>

    <gray-block class="pt-80 pb-80 mb-80">
      <content-container class="content-padding">
        <white-block class="p-30">
          <publications-list :publications="publications" />
          <div
            class="person-tab-publications-page__pages"
            v-if="total > publications.length"
          >
            <a-pagination
              v-model:current="currentPage"
              v-model:pageSize="pageSize"
              :total="total"
              :show-size-changer="false"
            >
              <template #itemRender="{ type, originalElement }">
                <pagination-arrows
                  :type="type"
                  :original-element="originalElement"
                />
              </template>
            </a-pagination>
          </div>
        </white-block>
      </content-container>
    </gray-block>
  </div>
</template>

<script setup>
  import { onMounted, ref, watch } from 'vue';
  import { useRoute } from 'vue-router';
  import { useApi } from '@/api/use-api';
  import WhiteBlock from '@/views/portal/components/WhiteBlock.vue';
  import ContentContainer from '@/views/portal/components/ContentContainer.vue';
  import BorderedBlock from '@/views/portal/components/BorderedBlock.vue';
  import GrayBlock from '@/views/portal/components/GrayBlock.vue';
  import PaginationArrows from '@/views/portal/components/UI/PaginationArrows.vue';
  import ChartWrapper from '@/views/portal/components/charts/ChartWrapper.vue';
  import ChartHorizontal from '@/views/portal/components/charts/ChartHorizontal.vue';
  import ChartVertical from '@/views/portal/components/charts/ChartVertical.vue';
  import TwoColumns from '@/views/portal/components/TwoColumns.vue';
  import MenuPerson from "@/views/portal/components/menus/MenuPerson.vue";
  import PublicationsList from "@/views/portal/components/publications/PublicationsList.vue";
  import {TYPE_PUBLICATIONS} from "@/api/models/Publication";

  const isLoading = ref(true);
  const api = useApi();
  const route = useRoute();
  const publications = ref([]);
  const sorting = ref('title');
  const currentPage = ref(1);
  const pageSize = ref(6);
  const total = ref(0);

  const props = defineProps({
    person: {
      type: Object,
      required: true,
    },
  });

  const chart1Items = ref([]);
  const chart2Items = ref([]);

  const getPublications = async () => {
    if (undefined === props.person.uuid || !props.person.uuid) return;
    try {
      isLoading.value = true;
      const response = await api.publication.index(
        new URLSearchParams({
          page: currentPage.value,
          'per-page': pageSize.value,
          sort: sorting.value,
          author_uuid: props.person.uuid,
          type: TYPE_PUBLICATIONS,
        })
      );

      publications.value = response.publications;
      total.value = response.total;
    } catch (error) {
      console.error('error', error);
    } finally {
      //isLoading.value = false;
    }
  };

  const getTotalPublicationsByYears = async () => {
    if (undefined === props.person.uuid || !props.person.uuid) return;
    try {
      const response = await api.publication.total(
        new URLSearchParams({
          author_uuid: props.person.uuid,
          year: new Date().getFullYear() - 4,
          group: 'year',
          type: TYPE_PUBLICATIONS,
        })
      );

      chart1Items.value = response.items;
    } catch (error) {
      console.error('error', error);
    }
  };

  const getTotalPublicationsByTypes = async () => {
    if (undefined === props.person.uuid || !props.person.uuid) return;
    try {
      const response = await api.publication.total(
        new URLSearchParams({
          author_uuid: props.person.uuid,
          year: new Date().getFullYear() - 4,
          group: 'type',
          type: TYPE_PUBLICATIONS,
        })
      );

      chart2Items.value = response.items;
    } catch (error) {
      console.error('error', error);
    }
  };

  onMounted(() => {
    getPublications();
    getTotalPublicationsByYears();
    getTotalPublicationsByTypes();
  });

  watch(
    () => props.person.id,
    () => {
      getPublications();
      getTotalPublicationsByYears();
      getTotalPublicationsByTypes();
    }
  );

  watch(sorting, () => {
    getPublications();
  });

  watch(currentPage, () => {
    getPublications();
  });

  watch(pageSize, () => {
    currentPage.value = 1;
    getPublications();
  });
</script>

<style lang="scss" scoped>
</style>
