<template>
  <a-row class="item">
    <a-col :span="3">
      <a-row>{{ model.date }}</a-row>
      <a-row class="sup-text">{{ model.time }}</a-row>
    </a-col>
    <a-col :span="8"
      ><span>{{ showcase.projectName }}</span></a-col
    >
    <a-col :span="7">{{ showcase.projectType }}</a-col>
    <a-col :span="6">
      <span>{{ showcase.leaderName }}</span>
      <span class="sup-text">{{ showcase.leaderEmail }}</span>
    </a-col>
  </a-row>
</template>

<script setup>
  import dayjs from 'dayjs';
  import { onMounted, reactive } from 'vue';
  import {
    SHOWCASE_STATUS_AGREEMENT,
    SHOWCASE_STATUS_PUBLISHED,
    SHOWCASE_STATUS_REJECTED,
    SHOWCASE_STATUS_REMOVED,
  } from '@/api/models/ProjectShowcaseStatus';
  const props = defineProps({ showcase: Object });

  const model = reactive({
    date: '-',
    time: '-',
  });

  onMounted(() => {
    fillModel();
  });

  const fillModel = () => {
    let datetime;
    switch (props.showcase.statusId) {
      case SHOWCASE_STATUS_AGREEMENT:
        datetime = props.showcase.approvalCreatedAt;
        break;
      case SHOWCASE_STATUS_PUBLISHED:
        datetime = props.showcase.updatedAt;
        break;
      case SHOWCASE_STATUS_REJECTED:
      case SHOWCASE_STATUS_REMOVED:
        datetime = props.showcase.approvalRejectedAt;
        break;
    }

    if (datetime) {
      model.date = dayjs.unix(datetime).format('DD.MM.YYYY');
      model.time = dayjs.unix(datetime).format('HH:mm');
    }
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';
  $color-text-secondary-grey-216: #718096;

  * {
    font-family: 'Nunito Sans', sans-serif;
  }

  .item {
    cursor: pointer;
    background: $color-bg-secondary-grey;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    height: 88px;
    width: 100%;
    margin-top: 15px;
    padding: 20px;

    .sup-text {
      color: $color-text-secondary-grey-216;
      display: flex;
      margin-top: 10px;
    }
  }
</style>
