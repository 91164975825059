<template>
  <portal-layout class="main-page">
    <template v-slot:header>
      <main-title class="mb-80">
        <template v-slot:default>ИССЛЕДОВАТЕЛЬСКИЙ<br />ПОРТАЛ НГУ</template>
        <template v-slot:description>Все о науке в университете</template>
      </main-title>
      <input-search
        class="mb-40"
        page="search"
        placeholder="Ищите документы, проекты, людей или другое"
      />
      <!--div class="carousel-wrapper">
        <carousel-tags :items="carouselItems"></carousel-tags>
      </div-->
    </template>
    <template v-slot:default>
      <white-block class="pict-text-block-wrapper">
        <content-container class="content-padding">
          <blockquote>
            Новосибирский государственный университет – уникальное место
            пересечения науки и образования
          </blockquote>
          <pict-text-block type="left" image="/images/portal/pict-1.png">
            Здесь эти две сферы неотделимы друг от друга: именно они помогают
            университету двигаться вперед, открывая своим студентам и
            выпускникам совершенно невероятные возможности для работы и
            дальнейших исследований после выпуска. Вокруг университета собраны
            исследовательские институты более чем 100 научных направлений,
            которыми занимаются ученые во всем мире. Это позволяет студентам НГУ
            с самых ранних курсов заниматься серьезной – настоящей – наукой и
            быть частью научного сообщества.
          </pict-text-block>
        </content-container>
      </white-block>

      <content-container class="content-padding mb-80">
        <two-columns>
          <template v-slot:left>
            <white-block class="round h-100 p-30">
              <gray-block class="round h-100 p-30">
                <chart-wrapper
                  title="Количество патентов/свидетельств по видам"
                >
                  <chart-horizontal :items="chart2Items"></chart-horizontal>
                </chart-wrapper>
              </gray-block>
            </white-block>
          </template>
          <template v-slot:right>
            <white-block class="round h-100 p-30">
              <gray-block class="round h-100 p-30">
                <chart-wrapper
                  title="Количество научных публикаций за последние 5 лет"
                >
                  <chart-vertical :items="chart1Items"></chart-vertical>
                </chart-wrapper>
              </gray-block>
            </white-block>
          </template>
        </two-columns>
      </content-container>

      <white-block style="height: 1px" class="mb-80"></white-block>

      <content-container class="content-padding mb-80">
        <white-block class="p-30 round">
          <carousel-cards
            title="Публикаций в журналах Nature и Science за последние 5 лет"
          >
            <publication-item
              v-for="publication in publications"
              :key="`publication-item-` + publication.id"
              :publication="publication"
            />
          </carousel-cards>
        </white-block>
      </content-container>

      <white-block v-if="department.description">
        <gray-block>
          <content-container class="content-padding pict-text-block-wrapper">
            <pict-text-block type="right" image="/images/portal/pict-2.png" class="no-top-padding">
              <div class="nsu-description" v-html="department.description" />
            </pict-text-block>
          </content-container>
        </gray-block>

        <content-container>
          <menu-picts />
        </content-container>
      </white-block>
    </template>
  </portal-layout>
</template>

<script setup>
  import { onMounted, ref } from 'vue';
  import {useApi} from "@/api/use-api";
  import PortalLayout from '@/layouts/PortalLayout';
  import MainTitle from '@/views/portal/components/MainTitle.vue';
  import InputSearch from '@/views/portal/components/UI/InputSearch.vue';
  import ContentContainer from '@/views/portal/components/ContentContainer.vue';
  import WhiteBlock from '@/views/portal/components/WhiteBlock.vue';
  import PictTextBlock from '@/views/portal/components/PictTextBlock.vue';
  import TwoColumns from '@/views/portal/components/TwoColumns.vue';
  import MenuPicts from '@/views/portal/components/menus/MenuPicts.vue';
  import ChartHorizontal from '@/views/portal/components/charts/ChartHorizontal.vue';
  import ChartVertical from '@/views/portal/components/charts/ChartVertical.vue';
  import CarouselCards from '@/views/portal/components/CarouselCards.vue';
  import PublicationItem from '@/views/portal/components/publications/PublicationItem.vue';
  import GrayBlock from '@/views/portal/components/GrayBlock.vue';
  import ChartWrapper from '@/views/portal/components/charts/ChartWrapper.vue';
  import {TYPE_INTELLECTUALS, TYPE_PUBLICATIONS} from "@/api/models/Publication";

  //import CarouselTags from "@/views/portal/components/carousel-tags/CarouselTags.vue";
  // import Image1 from "@/views/portal/components/carousel-tags/assets/1.png";
  // import Image2 from "@/views/portal/components/carousel-tags/assets/2.png";
  // import Image3 from "@/views/portal/components/carousel-tags/assets/3.png";
  // import Image4 from "@/views/portal/components/carousel-tags/assets/4.png";
  // import Image5 from "@/views/portal/components/carousel-tags/assets/5.png";

  const api = useApi();
  const department = ref([]);
  const publications = ref([]);
  const chart1Items = ref([]);
  const chart2Items = ref([]);

  // const carouselItems = [
  //   {
  //     tag: "#innovations",
  //     image: Image1,
  //   },
  //   {
  //     tag: "#biomedicine",
  //     image: Image2,
  //   },
  //   {
  //     tag: "#space exploration",
  //     image: Image3,
  //   },
  //   {
  //     tag: "#nanotechnology",
  //     image: Image4,
  //   },
  //   {
  //     tag: "#it",
  //     image: Image5,
  //   },
  // ];

  const getPublications = async () => {
    try {
      const response = await api.publication.index(
          new URLSearchParams({
            page: 1,
            'per-page': 1,
            sort: '-status_date',
            type: TYPE_PUBLICATIONS,
          })
      );

      publications.value = response.publications;
    } catch (error) {
      console.error('error', error);
    }
  };

  const getTotalPublicationsByYears = async () => {
    try {
      const response = await api.publication.total(
          new URLSearchParams({
            year: new Date().getFullYear() - 4,
            group: 'year',
            type: TYPE_PUBLICATIONS
          })
      );

      chart1Items.value = response.items;
    } catch (error) {
      console.error('error', error);
    }
  };

  const getTotalIntellectualsByTypes = async () => {
    try {
      const response = await api.publication.total(
          new URLSearchParams({
            year: new Date().getFullYear() - 4,
            group: 'type',
            type: TYPE_INTELLECTUALS
          })
      );

      chart2Items.value = response.items;
    } catch (error) {
      console.error('error', error);
    }
  };

  const getDepartment = async () => {
    try {
      const response = await api.organisationalUnit.view(1);
      department.value = response.organisationalUnit;
    } catch (error) {
      console.error("error", error);
    }
  };

  onMounted(() => {
    getDepartment();
    getPublications();
    getTotalPublicationsByYears();
    getTotalIntellectualsByTypes();
  });
</script>

<style lang="scss" scoped>
  @import 'assets/_map-for-breakpoints';
  @import '../../assets/styles/_breakpoints';

  .carousel-wrapper {
    margin-top: 110px;
    margin-bottom: 90px;
  }
  blockquote {
    padding: 100px 0;
    margin: 0 auto;

    @include breakpoint(max, lg) {
      padding: 70px 0;
    }
  }
  :deep(.content_portal) {
    background: #f2f4f8;
  }
  .pict-text-block-wrapper {
    margin-bottom: 135px;
    @include breakpoint(max, lg) {
      margin-bottom: 80px;
    }
  }
  :deep(.content-container).content-padding {
    padding-left: 40px;
    padding-right: 40px;
  }
</style>
