<template>
  <a-row class="success-message">
    <a-col>
      <a-row class="success-message__title">
        <a-col :span="24">
          <h3>Написать в техподдержку</h3>
        </a-col>
      </a-row>
      <a-row class="success-message__description">
        <a-col :span="24">
          <p>
            Ваш запрос отправлен в тех.поддержку. Ожидайте ответа специалиста на
            указанный при регистрации e-mail.
          </p>
        </a-col>
      </a-row>
    </a-col>
  </a-row>
</template>

<script setup></script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors';

  .success-message {
    padding: 112px 17px 0 17px;

    .success-message__title {
      h3 {
        font: 700 18px/26px 'Nunito Sans';
      }
    }

    .success-message__description {
      margin-top: 20px;
    }
  }
</style>
