<template>
  <moderator-layout>
    <a-col class="request-form">
      <request-info :request="model.request" />
      <request-main-form :request="model.request" :force-disabled="true" />
      <a-divider v-if="showModeratorForm" />
      <a-col class="main-form">
        <a-form
          :model="model"
          :rules="rules"
          @validate="errors.onValidate"
          @finish="onFinish"
          v-if="showModeratorForm"
        >
          <a-row class="form-step">
            <a-col :span="24">
              <div class="form-step__title">Принять решение</div>
              <div class="form-step__instructions">
                Выберите решение по запросу в поле ниже и напишите комментарий
                при необходимости.
              </div>
            </a-col>
            <a-col :span="24" class="form-step__fields">
              <a-form-item
                name="solution"
                :help="errors.getError('solution')"
                :validate-status="errors.getStatus('solution')"
              >
                <a-select
                  id="solution"
                  placeholder="Решение по запросу"
                  v-model:value="model.solution"
                  :options="solutionsList"
                  @change="onSolutionChange"
                />
              </a-form-item>
            </a-col>

            <a-col
              :span="24"
              class="form-step__fields"
              v-if="model.solution === REQUEST_STATUS_ADMIN_FREE"
            >
              <a-form-item
                name="briefcases"
                :help="errors.getError('briefcases')"
                :validate-status="errors.getStatus('briefcases')"
              >
                <a-select
                  id="units"
                  v-model:value="model.briefcases"
                  mode="multiple"
                  placeholder="Выберите портфель"
                  :maxTagCount="2"
                  :options="model.briefcaseOptions"
                />
              </a-form-item>
            </a-col>
            <a-col
              :span="24"
              class="form-step__fields"
              v-if="
                model.solution === REQUEST_STATUS_REVISION ||
                model.solution === REQUEST_STATUS_REJECTED
              "
            >
              <a-form-item
                name="comment"
                :help="errors.getError('comment')"
                :validate-status="errors.getStatus('comment')"
              >
                <a-textarea
                  id="comment"
                  v-model:value="model.comment"
                  placeholder="Комментарий"
                  :disabled="moderatorFormDisabled"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row
            class="form__buttons"
            :gutter="[10, 0]"
            v-if="!moderatorFormDisabled"
          >
            <a-col>
              <c-button-secondary @click="onCancel"
                >Отменить</c-button-secondary
              >
            </a-col>
            <a-col>
              <a-form-item>
                <c-button-primary
                  class="submit-button"
                  :loading="isLoading"
                  html-type="submit"
                >
                  Отправить
                </c-button-primary>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-col>
    </a-col>
  </moderator-layout>
</template>

<script setup>
  import { computed, onMounted, reactive, ref } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import { useApi } from '@/api/use-api';
  import ErrorsHandler from '@/components/form/errors-handler';
  import ModeratorLayout from '@/layouts/ModeratorLayout';
  import CButtonPrimary from '@/components/UI/CButtonPrimary';
  import CButtonSecondary from '@/components/UI/CButtonSecondary';
  import {
    REQUEST_STATUS_ADMIN_FREE,
    REQUEST_STATUS_MODERATOR,
    REQUEST_STATUS_REJECTED,
    REQUEST_STATUS_REVISION,
    solutionsList,
  } from '@/api/models/RequestStatus';
  import RequestInfo from '@/components/requests/components/RequestInfo.vue';
  import RequestMainForm from '@/components/requests/RequestMainForm.vue';

  const api = useApi();
  const router = useRouter();
  const route = useRoute();
  const isLoading = ref(false);
  const model = reactive({
    request: null,
    requestStatusId: null,
    comment: '',
    solution: null,
    isUnitsSelectShow: false,
    briefcases: [],
    briefcaseOptions: [],
  });

  const moderatorFormDisabled = computed(() => {
    return model.requestStatusId !== REQUEST_STATUS_MODERATOR;
  });

  const showModeratorForm = computed(() => {
    return [REQUEST_STATUS_MODERATOR].includes(model.requestStatusId);
  });

  const rules = {
    solution: [
      {
        required: true,
        message: 'Необходимо выбрать решение',
        trigger: ['blur', 'change'],
      },
    ],
    briefcases: [
      {
        type: 'array',
        min: 1,
        required: model.solution === REQUEST_STATUS_ADMIN_FREE,
        message: 'Необходимо выбрать подразделение',
        trigger: ['blur', 'change'],
      },
    ],
    comment: [
      {
        required: ['reject', 'rework'].some(
          (solution) => solution === model.solution
        ),
        message: 'Необходимо ввести комментарий',
        trigger: 'blur',
      },
    ],
  };

  onMounted(() => {
    getRequest();
    getUnits();
  });

  async function getUnits() {
    try {
      const response = await api.moderatorPage.units();
      model.briefcaseOptions = response.briefcases.map((item) => {
        return {
          value: item.id,
          label: item.name,
          ...item,
        };
      });
      console.log(
        `ModeratorRequestForm.getUnits: model.briefcases=`,
        model.briefcases
      );
    } catch (error) {
      console.log(error);
    }
  }

  async function getRequest() {
    try {
      const response = await api.requestPage.moderatorView(route.params.id);
      model.request = response.request;
      model.requestStatusId = response.request.requestStatusId;
      model.comment = response.request.comment;
    } catch (error) {
      console.error('error', error);
    }
  }

  const errors = new ErrorsHandler({
    solution: '',
    briefcases: '',
    comment: '',
  });

  const onCancel = () => {
    router.replace({ name: 'moderator-requests' });
  };

  const onFinish = async () => {
    try {
      isLoading.value = true;
      await api.moderatorPage.saveRequest(route.params.id, {
        status: model.solution,
        comment: model.comment,
        briefcases: model.briefcases,
      });

      await router.replace({ name: 'moderator-requests' });
    } catch (error) {
      errors.handleApiErrors(error);
    } finally {
      isLoading.value = false;
    }
  };

  function onSolutionChange() {
    model.units = [];
  }
</script>

<style lang="scss" scoped>
  @import '@/components/form/main-form';

  * {
    font-family: 'Nunito Sans', sans-serif;
    margin: 0;
  }

  .request-form {
    align-content: flex-start;
    height: 100%;
    overflow-y: auto;
    padding-bottom: 100px;

    .request-form__form-block {
      margin-top: 40px;
    }

    .form__buttons {
      justify-content: end;
      margin-top: 40px;
      margin-right: 270px !important;
    }

    .ant-divider {
      margin: 30px 0;
    }
  }
</style>
