<template>
  <a class="navigation-button" @click.prevent="goToPage(pageName)">
    <a-button type="primary">{{ text }}</a-button>
  </a>
</template>

<script setup>
  import { useRouter } from 'vue-router';

  defineProps({
    pageName: String,
    text: String,
  });

  const router = useRouter();

  function goToPage(name) {
    router.push({ name });
  }
</script>

<style lang="scss"></style>
