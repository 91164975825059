<template>
  <h3>Шаг 1. Информация о проекте</h3>
  <p>
    Проверьте информацию в карточке проекта и, если согласны, согласуйте проект.
  </p>
  <div>
    <a-form class="form leader-project-form" layout="vertical">
      <partner-project-form-row
        v-if="model.availabilityList['name']"
        :show-available="false"
      >
        <a-form-item name="name" label="Название проекта">
          <a-input
            v-model:value="model.name"
            placeholder="Введите название проекта"
            disabled
          />
        </a-form-item>
      </partner-project-form-row>

      <partner-project-form-row
        v-if="model.availabilityList['type']"
        :show-available="false"
      >
        <a-form-item name="projectType" label="Тип проекта">
          <a-input
            v-model:value="model.projectType"
            placeholder="Выберите тип проекта"
            disabled
          />
        </a-form-item>
      </partner-project-form-row>

      <partner-project-form-row
        :show-available="false"
        v-if="model.availabilityList['company']"
      >
        <a-form-item name="companyName" label="Компания-партнёр">
          <a-input v-model:value="model.companyName" disabled />
        </a-form-item>
      </partner-project-form-row>

      <partner-project-form-row
        :show-available="false"
        v-if="model.availabilityList['leader']"
      >
        <a-form-item name="leaderName" label="Руководитель проекта">
          <a-input v-model:value="model.leaderName" disabled />
        </a-form-item>
      </partner-project-form-row>

      <partner-project-form-row
        :show-available="false"
        v-if="model.availabilityList['scientificAdviser']"
      >
        <a-form-item name="scientificAdviser" label="Научный руководитель">
          <a-input v-model:value="model.scientificAdviser" disabled />
        </a-form-item>
      </partner-project-form-row>

      <partner-project-form-row
        :show-available="false"
        v-if="model.availabilityList['tags']"
      >
        <a-form-item name="new-tag" label="Ключевые слова">
          <div class="input-tags__list" v-if="model.tags.length">
            <div class="tags">
              <span
                class="tag"
                v-for="(tag, index) in model.tags"
                :key="`tag-` + index"
              >
                #{{ tag }}
              </span>
            </div>
          </div>
        </a-form-item>
      </partner-project-form-row>
    </a-form>
  </div>
</template>

<script setup>
  import { onMounted, reactive, watch } from 'vue';
  import PartnerProjectFormRow from '../PartnerProjectFormRow.vue';

  const props = defineProps({
    project: {
      type: Object,
      require: true,
    },
  });

  const model = reactive({
    id: '',
    name: '',
    projectType: '',
    companyName: '',
    leaderName: '',
    unit: '',
    scientificAdviser: '',
    tags: [],
    availabilityList: [],
  });

  onMounted(() => {
    props.project && fillModel();
  });

  watch(() => props.project, () => { fillModel(); });

  const fillModel = () => {
    try {
      model.id = props.project.id;
      model.name = props.project.name;
      model.projectType = props.project.type;
      model.companyName = props.project.organizationName;
      model.leaderName = props.project.leaderName;
      model.unit = props.project.unit;
      model.scientificAdviser = props.project.scientificAdviser;
      model.tags = props.project.tags;
      model.availabilityList = props.project.availabilityList;
    } catch (error) {
      console.error('error', error);
    }
  };
</script>

<style lang="scss" scoped>
  @import './partner-project-form';
</style>
