<template>
  <a-input
    class="search-input"
    :placeholder="placeholder"
    v-model:value="searchString"
    @keyup.enter="runSearching(page)"
  />
</template>

<script setup>
  import { onMounted, ref } from 'vue';
  import { useRoute, useRouter } from 'vue-router';

  const route = useRoute();
  const router = useRouter();

  let searchString = ref('');

  defineProps({
    page: {
      type: String,
      default: 'search',
    },
    placeholder: {
      type: String,
      default: 'Поиск...',
    },
  });

  const runSearching = () => {
    if (!searchString.value) return;
    router.push({
      name: 'showcase-main-page',
      query: {
        ...route.query,
        textSearch: searchString.value,
        page: 1,
        pageSize: 20,
      },
    });
  };

  onMounted(() => {
    searchString.value = route.query.textSearch ?? '';
  });
</script>

<style lang="scss" scoped>
  .search-input {
    width: 100%;
    max-width: 440px;
    background: url('../assets/search.svg') no-repeat right 8px center #f2f4f8;

    &.dark {
      background-color: #383d4f;
      border-color: #383d4f;
      color: #babbc2;

      &::placeholder {
        color: #babbc2;
      }
    }
  }
</style>
