<template>
  <a-row class="delete-company__form" justify="end">
    <a-col class="form__title" :span="24">
      <h3>Удалить организацию?</h3>
    </a-col>
    <a-col class="form__description" :span="24">
      <p>Вы действительно хотите удалить организацию из списка?</p>
    </a-col>
    <a-col class="form__content" :span="24">
      <company-card v-bind="item" :menu="false" />
    </a-col>
    <a-col class="form__actions" :offset="24">
      <a-button @click="deleteCompany(item)">Удалить</a-button>
    </a-col>
  </a-row>
</template>

<script setup>
  import { useApi } from '@/api/use-api';
  import CompanyCard from '@/components/company-card/CompanyCard';

  defineProps({
    item: Object,
  });

  const api = useApi();
  const emit = defineEmits(['close', 'update']);

  async function deleteCompany(item) {
    try {
      await api.profilePage.deleteCompany(
        '/profile-page/delete-company?id=' + item.id.toString()
      );

      emit('close');
      emit('update');
    } catch (error) {
      console.error('error', error);
    }
  }
</script>

<style lang="scss" scoped>
  $color-bg-button-delete: #f65160;
  $color-text-button-delete: #ffffff;

  .delete-company__form {
    padding: 112px 17px 0 17px;

    .form__title {
      h3 {
        font: 700 18px/26px 'Nunito Sans';
      }
    }

    .form__description {
      margin-top: 20px;
    }

    .form__content {
      margin-top: 30px;
    }

    .form__actions {
      margin-top: 50px;

      button {
        align-items: center;
        background-color: $color-bg-button-delete;
        border-radius: 4px;
        color: $color-text-button-delete;
        display: inline-flex;
        font: 700 14px/22px 'Nunito Sans';
        height: 38px;
        justify-content: center;
        min-height: 38px;
        min-width: 96px;
        padding: 8px 30px;
        outline: none;

        &:not(:disabled):hover {
          background-color: $color-bg-button-delete;
          border-color: $color-bg-button-delete;
        }

        &:not(:disabled):focus {
          border-color: $color-bg-button-delete;
        }

        &:not(:disabled):active {
          border-color: $color-bg-button-delete;
        }
      }
    }
  }
</style>
