<template>
  <a-form
    ref="formRef"
    layout="vertical"
    :model="model"
    :rules="rules"
    :validate-on-rule-change="true"
    @validate="errors.onValidate"
    @finish="() => null"
  >
    <leader-project-form-row
      :show-available="showAvailable"
      :available="model.isAvailable"
      :disabled="disabled"
      @change="onChangeAvailability"
      class="bordered"
    >
      <a-row>
        <a-col :span="12">
          <a-form-item
            name="paymentAmount"
            label="Размер поступления, руб."
            :help="errors.getError('paymentAmount')"
            :validate-status="errors.getStatus('paymentAmount')"
          >
            <a-input-number
              :controls="false"
              v-model:value="model.paymentAmount"
              placeholder="Укажите сумму"
              :disabled="disabled"
              @change="validateAndUpdate"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item
            name="paymentDate"
            label="Дата финансирования"
            :help="errors.getError('paymentDate')"
            :validate-status="errors.getStatus('paymentDate')"
          >
            <a-date-picker
              v-model:value="model.paymentDate"
              placeholder="Выберите дату"
              format="DD.MM.YYYY"
              :locale="locale"
              :disabled="disabled"
              @change="validateAndUpdate"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </leader-project-form-row>
  </a-form>
</template>

<script setup>
  import { computed, onMounted, reactive, ref, watch } from 'vue';
  import locale from 'ant-design-vue/es/date-picker/locale/ru_RU';
  import ErrorsHandler from '@/components/form/errors-handler';
  import LeaderProjectFormRow from '@/views/leader/projects/form/LeaderProjectFormRow.vue';
  import useInputRowValidator from '@/composables/inputRowValidator';

  const formRef = ref();
  const emit = defineEmits(['update']);
  const inputValidator = useInputRowValidator(emit);

  const props = defineProps({
    index: {
      type: Number,
      required: true,
    },
    maxIndex: {
      type: Number,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    apiErrors: {
      type: Object,
      required: true,
    },
    showAvailable: {
      type: Boolean,
      default: false,
    },
    offsetAvailable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  });

  const model = reactive({
    paymentAmount: null,
    paymentDate: null,
    isAvailable: true,
  });

  const validationIsRequired = computed(() => {
    return (
      model.paymentAmount || model.paymentDate || props.index !== props.maxIndex
    );
  });

  const rules = computed(() => {
    return {
      paymentAmount: [
        {
          type: 'number',
          min: 1,
          message: 'Укажите сумму поступленя.',
          required: validationIsRequired.value,
          trigger: 'blur',
        },
      ],
      paymentDate: [
        {
          type: 'date',
          required: validationIsRequired.value,
          message: 'Необходимо указать дату поступления денежных средств.',
          trigger: 'blur',
        },
      ],
    };
  });

  const errors = new ErrorsHandler({
    paymentAmount: '',
    paymentDate: '',
    isAvailable: '',
  });

  onMounted(() => {
    fillModel();
  });

  watch(
    () => props.data,
    () => {
      fillModel();
    }
  );

  watch(
    () => props.apiErrors,
    () => {
      props.apiErrors.errors && errors.handleApiErrors(props.apiErrors);
    }
  );

  watch(
    () => validationIsRequired.value,
    () => {
      setTimeout(() => {
        validateAndUpdate();
      }, 300);
    }
  );

  const fillModel = () => {
    try {
      model.paymentAmount = props.data.paymentAmount;
      model.paymentDate = props.data.paymentDate;
      model.isAvailable = props.data.isAvailable;
    } catch (error) {
      console.error('error', error);
    }
  };

  const onChangeAvailability = () => {
    model.isAvailable = !model.isAvailable;
    validateAndUpdate();
  };

  const validateAndUpdate = () =>
    inputValidator.validateAndUpdate(props.index, model, formRef);

  defineExpose({
    validateAndUpdate,
  });
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';
</style>
