export default {
  project: [
    {
      type: 'array',
      required: true,
      validator: (_rule, _value, _callback, source) => {
        if (source) {
          const allApproved =
            source.approvals.length > 0 &&
            source.approvals.every((item) => item.status?.type === 'approved');
          if (allApproved) {
            return Promise.resolve();
          } else {
            return Promise.reject('Rule validation failed.');
          }
        }
      },
    },
  ],
};
