<template>
  <div class="content-container">
    <slot></slot>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
@import "../assets/_map-for-breakpoints";
@import "../../../assets/styles/_breakpoints";

.content-container {
  margin: 0 auto;
  width: 100%;

  @include breakpoint(max, sm) {
    width: 100%;
  }
  @include breakpoint(between, sm md) {
    width: 560px;
  }
  @include breakpoint(between, md lg) {
    width: 670px;
  }
  @include breakpoint(between, lg xl) {
    width: 944px;
  }
  @include breakpoint(between, xl xxl) {
    width: 1200px;
  }
  @include breakpoint(between, xxl xxxl) {
    width: 1360px;
  }
  @include breakpoint(min, xxxl) {
    width: 1600px;
  }
}
</style>
