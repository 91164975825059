import { create, read, update, remove } from "@/api/rest";

const briefcaseIndex = {
  index: async (options) => await read('/briefcase-index?' + options.toString()),
  view: async (id) => await read(`/briefcase-index/${id}`),
  create: async (data) => await create(`/briefcase-index/create`, data),
  update: async (id, data) => await update(`/briefcase-index/update/${id}`, data),
  archive: async (id) => await update(`/briefcase-index/archive/${id}`),
  active: async (id) => await update(`/briefcase-index/active/${id}`),
  remove: async (id) => await remove(`/briefcase-index/delete/${id}`),
};

export default briefcaseIndex;