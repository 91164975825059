export default class Field {
  constructor(name) {
    this.name = name;
  }

  get() {
    return localStorage.getItem(this.name);
  }

  set(value) {
    localStorage.setItem(this.name, value);
  }

  remove() {
    localStorage.removeItem(this.name);
  }
}