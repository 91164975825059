<template>
  <a-row :gutter="[20, 20]" class="tasks">
    <a-col
      :span="12"
      v-for="(task, index) in tasks"
      :key="{ id: index++, ...task }"
      @click="router.push(`/performer/project/${task.projectId}/tasks`)"
    >
      <task-card :task="task" />
    </a-col>
  </a-row>
</template>

<script setup>
  import { useRouter } from 'vue-router';
  import TaskCard from "@/views/performer/components/TaskCard.vue";

  defineProps({ tasks: Array });
  const router = useRouter();
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_colors.scss';

  .tasks {
    margin-top: 30px;
    padding: 0 0 100px 0;
  }

  .horizontal-filter__panel {
    margin: 0 -40px 0 -20px;
    padding: 8px 40px 8px 20px;
    border-top: 1px solid $color-bg-secondary-grey;
    border-bottom: 1px solid $color-bg-secondary-grey;
  }
</style>
