<template>
  <div class="content_portal">
    <div class="container">
      <slot></slot>
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.content_portal {
  flex: 1 1 100%;
}
</style>
